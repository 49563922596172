
import { defineComponent } from "vue";
import "@fullcalendar/core/vdom";
import FullCalendar, { CalendarOptions, EventApi } from "@fullcalendar/vue3";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { sampleEvents } from "@/core/store/sample2";
import circle from "../assets/icons/circle.svg";
import cross from "../assets/icons/cross1.svg";
import triangle from "../assets/icons/triangle.svg";

// import circle from "../assets/icons/return.svg;

export default defineComponent({
  name: "MobileCalendar",
  components: {
    FullCalendar,
  },
  methods: {
    prevWeek() {
      let calendarApi = (
        this.$refs.fullCalendar as InstanceType<typeof FullCalendar>
      ).getApi();
      calendarApi.prev();
    },
    nextWeek() {
      let calendarApi = (
        this.$refs.fullCalendar as InstanceType<typeof FullCalendar>
      ).getApi();
      calendarApi.next();
    },
    title() {
      document.getElementsByClassName("fc-timegrid-axis-frame")[0].innerHTML =
        "日時";
      let th = document.querySelector("th.fc-timegrid-axis");
    },
  },
  data() {
    return {
      circle: circle,
      cross: cross,
      triangle: triangle,
      calendarOptions: {
        allDaySlot: false,
        timeZone: "UTC",
        locale: "ja",
        headerToolbar: {
          left: "",
          center: "title",
          right: "",
        },
        titleFormat: { year: "numeric", month: "numeric" },
        dayHeaderFormat: {
          day: "numeric",
          weekday: "short",
        },
        dayHeaderContent: (dayHeaderContent) =>
          dayHeaderContent.text.replace("日", "\n"),
        contentHeight: 912,
        slotMinTime: "09:00",
        slotMaxTime: "21:30",
        slotLabelInterval: "00:30",
        slotLabelFormat: {
          hour: "2-digit",
          minute: "2-digit",
          meridiem: false,
          hour12: false,
        },
        plugins: [
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        expandRows: true,
        eventDisplay: "auto",
        displayEventTime: false,
        events: sampleEvents,
        eventContent: function (args: any) {
          let div = document.createElement("div");
          div.id = "myEvent";

          if (args.event.extendedProps.status === "full") {
            div.innerHTML += `<img src='${cross}' width='16' height='16'>`;
            div.style.backgroundColor = "#f0f2f0";
          } else if (args.event.extendedProps.status === "open") {
            div.innerHTML += `<img src='${circle}' width='16' height='16'>`;
          } else if (args.event.extendedProps.status === "few") {
            div.innerHTML += `<img src='${triangle}' width='16' height='16'>`;
          }

          let arrayOfDomNodes = [div];
          return { domNodes: arrayOfDomNodes };
        },
        businessHours: {
          daysOfWeek: [0, 1, 2, 3, 4, 5],
          startTime: "9:00",
          endTime: "21:30",
        },
      } as CalendarOptions,
      currentEvents: [] as EventApi[],
    };
  },
  mounted() {
    this.title();
  },
});
