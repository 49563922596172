
import { defineComponent, watch, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
// import DatePicker from "../../components/DatePicker.vue";
import { store as post, fetch } from '@/core/api/api-service';
import { store } from '@/core/store';
import moment from 'moment';

export default defineComponent({
  name: 'Table3',
  components: {},
  emits: ['customer'],
  props: {
    customerListData: {
      type: Array,
      required: true
    }
  },

  setup(props: any, { emit }) {
    const router = useRouter();
    const selected = ref(props.customerListData);
    let sort = ref({
      sortUp1: false,
      sortUp2: false,
      sortUp3: false,
      sortUp4: false,
      sortUp5: false,
      sortUp6: false,
      sortUp7: false,
      sortUp8: false
    });
    let selectedSort = ref({ field: '', sortBy: '' });
    watchEffect(() => {
      selected.value = props.customerListData;
    });

    const onSort = (evt: any, type: any) => {
      if (type === 'sortUp1') {
        if (sort.value.sortUp1 === true) {
          selectedSort.value = { field: 'customerNumber', sortBy: 'ASC' };
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
        } else {
          selectedSort.value = { field: 'customerNumber', sortBy: 'DESC' };
          sort.value = {
            sortUp1: true,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
        }
      }
      if (type === 'sortUp2') {
        if (sort.value.sortUp2 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'store', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: true,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'store', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp3') {
        if (sort.value.sortUp3 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'customerName', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: true,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'customerName', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp4') {
        if (sort.value.sortUp4 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'telephoneNumber', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: true,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'telephoneNumber', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp5') {
        if (sort.value.sortUp5 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'lastVisitDate', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: true,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'lastVisitDate', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp6') {
        if (sort.value.sortUp6 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'remainingCoupon', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: true,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'remainingCoupon', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp7') {
        if (sort.value.sortUp7 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'review', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: true,
            sortUp8: false
          };
          selectedSort.value = { field: 'review', sortBy: 'DESC' };
        }
      }
      if (type === 'sortUp8') {
        if (sort.value.sortUp8 === true) {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: false
          };
          selectedSort.value = { field: 'managementCoupon', sortBy: 'ASC' };
        } else {
          sort.value = {
            sortUp1: false,
            sortUp2: false,
            sortUp3: false,
            sortUp4: false,
            sortUp5: false,
            sortUp6: false,
            sortUp7: false,
            sortUp8: true
          };
          selectedSort.value = { field: 'managementCoupon', sortBy: 'DESC' };
        }
      }
      emit('customer', selectedSort.value);
    };

    const reviewSelection = (event: any, eventData: any, fieldName: any) => {
      const urlType = `customers/${eventData?.customerNumber}`;
      if (fieldName === 'reviewEkiten') {
        let formData = {
          _method: '',
          reviewEquiten: ''
        };
        formData._method = 'PUT';
        formData.reviewEquiten = event;
        customerUpdate(urlType, formData);
      } else if (fieldName === 'reviewGReview') {
        let formData = {
          _method: '',
          reviewGReview: ''
        };
        formData._method = 'PUT';
        formData.reviewGReview = event;
        customerUpdate(urlType, formData);
      } else {
        let formData = {
          _method: '',
          reviewWB: ''
        };
        formData._method = 'PUT';
        formData.reviewWB = event;
        customerUpdate(urlType, formData);
      }
    };

    const customerUpdate = (url: any, formData: any) => {
      return post(`${process.env.VUE_APP_BASE_API_URL}/api/v1/${url}`, formData).then((res) => {
        console.log(res, 'rews3');
      });
    };

    const goToCustomerDetail = async (details: any, type: string, event: any) => {
      if (type === 'button' && event.target.id === 'medical') {
        store.dispatch('setFromCustomer', true);
        router.push({
          name: 'ReservationList',
          query: { type: 'history' },
          params: {
            customerNumber: `${await details?.customerNumber}`,
            customerName: `${await details?.surname}`,
            phoneNumber: `${
              (await details?.telephoneNumber) ? details?.telephoneNumber : details?.cellphoneNumber
            }`,
            store: ` ` // Send no value to unselect the store value on reservation list
          }
        });
      }
      if (type == 'row' && event.target.id !== 'medical') {
        router.push({
          name: 'CustomerDetails',
          params: {
            id: `edit?id=?${await details?.customerNumber}`,
            customerNumberFromList: `?customerNumber=${details?.customerNumber}`,
            symptom: `${details?.symptom == 1 ? 'true' : 'false'}`
          }
        });

        //   params: {
        //     id: `edit`,
        //         customerNumberFromList: `?customerNumber=${details?.customerNumber}`,
        //   },
        //   query: {
        //     id: `${await details?.customerNumber}`,
        //         customerNumber: `${details?.customerNumber}`
        //   }
        // });
      }
    };

    const dateFormat = (date: any) => {
      return moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD');
    };

    return {
      dateFormat,
      reviewSelection,
      onSort,
      selected,
      selectedSort,
      sort,
      goToCustomerDetail
    };
  }
});
