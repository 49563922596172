
import { Customer } from "@/core/interface";
import { defineComponent, ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import Step1 from "../components/preliminary-examination-slip-steps/Step1.vue";
import Step2 from "../components/preliminary-examination-slip-steps/Step2.vue";
import Step3 from "../components/preliminary-examination-slip-steps/Step3.vue";
import Step4 from "../components/preliminary-examination-slip-steps/Step4.vue";
import { store } from "@/core/store";
import { consultationClassifications } from "@/core/store/constants";
import { CONSULTATION_CLASSIFICATION } from "@/core/store/enums";

export default defineComponent({
  name: "PreliminaryExaminationSteps",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const reservation = store?.getters?.reservation || null;
    let initialPageNumber = 1;
    if (Number(route.query.step)) {
      initialPageNumber = Number(route.query.step);
    }
    let symptomQuestions: any = null;
    let reviewQuestions: any = null;
    let customerData = {
      katakanaName: "",
      katakanaSurname: "",
      chineseFirstName: "",
      chineseLastName: "",
      gender: 0,
      dateOfBirth: "",
      postCode: "",
      cityAddress: "",
      nameOfBuildingsEtc: "",
      cellNumber: "",
      homeNumber: "",
      profession: "",
    };
    const selectedStep = ref<number>(initialPageNumber);

    const displayStep = ($event: number) => {
      window.scrollTo(0, 0);
      selectedStep.value = $event;
    };

    // onMounted(async () => {
    //   // console.log(window.history,'window.history')
    //   // window.onpopstate = function (event:any) {
    //   //   console.log(event,'event')
    //   //   event.preventDefault();
    //   //   alert("Back button pressed");
    //   // }
    //   // window.history.pushState(null, '', window.location.href);
    //   window.onpopstate = function () {
    //     // window.location.reload();
    //     window.history.forward()
    //   };
    // })

    const completeStep = () => {
      // Promise.all([
      //   store.dispatch("setStepCustomerRegistration", customerData),
      //   store.dispatch("setStepSymptomQuestions", symptomQuestions),
      //   store.dispatch("setStepReviewQuestions", reviewQuestions),
      // ]).then(() => {
      router.push({ name: "ReviewPreliminaryExaminationSlip" });
      // });
    };

    const getCustomer = (customer: Customer) => {
      store.dispatch("setStepCustomerRegistration", customer).then(() => {
        customerData = customer;
      });
    };

    const getSymptomQuestions = (symptomQuestionData: any) => {
      symptomQuestions = symptomQuestionData;
    };

    const getReviewQuestions = (reviewQuestionData: any) => {
      reviewQuestions = reviewQuestionData;
      store.dispatch("setStepReviewQuestions", reviewQuestions);
    };

    return {
      CONSULTATION_CLASSIFICATION,
      consultationClassifications,
      reservation,
      //
      customerData,
      selectedStep,
      symptomQuestions,
      reviewQuestions,
      displayStep,
      completeStep,
      getCustomer,
      getSymptomQuestions,
      getReviewQuestions,
    };
  },
});
