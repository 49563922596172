import { store } from "@/core/store";
import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from "vue-router";
import Home from "../views/home/Home.vue";
import NotFound from "../views/404.vue";
import Top from "../views/home/Top.vue";
import Login from "../views/login/Login.vue";
import ReservationCalendar from "../views/reservation-calendar/ReservationCalendar.vue";
import ReservationDetail from "../views/reservation-detail/ReservationDetail.vue";
import ReservationList from "../views/ReservationList.vue";
import CustomerList from "../views/customers/CustomerList.vue";
import PriceMedical from "../views/PriceMedical.vue";
import TaskSchedule from "../views/TaskSchedule.vue";
import ReservationRegistration from "../views/ReservationRegistration.vue";
import PreliminaryExaminationSteps from "../views/PreliminaryExaminationSteps.vue";
import PreliminaryExaminationSlip from "../views/PreliminaryExaminationSlip.vue";
import Symptoms from "../views/Symptoms.vue";
import ReviewPreliminaryExaminationSlip from "../views/ReviewPreliminaryExaminationSlip.vue";
import CheckInformationReExamination from "../views/CheckInformationReExamination.vue";
import CustomerDetails from "@/views/customers/CustomerDetails.vue";
import TodayList from "../views/TodayList.vue";
import ReservationStoreSelection from "../views/ReservationStoreSelection.vue";
import IdLinkage from "../views/IdLinkage.vue";
import MobileCalendar from "../views/ReservationCalendarMobile.vue";
import EnterReservationInformation from "../views/EnterReservationInformation.vue";
import ReservationAcceptanceCompleted from "../views/ReservationAcceptanceCompleted.vue";
import DuringMaintenance from "../views/DuringMaintenance.vue";
import LastTime from "../views/LastTime.vue";

const protectedRoutes = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (!store.state.authenticated) {
    next(`/login`);
  } else {
    next();
  }
};

const guestRoutes = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (!store.state.authenticated) {
    next();
  } else {
    next(`/`);
  }
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    beforeEnter: guestRoutes,
  },
  {
    path: "/",
    component: Top,
    beforeEnter: protectedRoutes,
    children: [
      {
        path: "during-maintenance",
        name: "DuringMaintenance",
        component: DuringMaintenance,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "Reservation-acceptance-completed",
        name: "ReservationAcceptanceCompleted",
        component: ReservationAcceptanceCompleted,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "enter-reservation-information",
        name: "EnterReservationInformation",
        component: EnterReservationInformation,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "id-linkage",
        name: "IdLinkage",
        component: IdLinkage,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "mobile-calendar",
        name: "MobileCalendar",
        component: MobileCalendar,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "reservation-store-selection",
        name: "ReservationStoreSelection",
        component: ReservationStoreSelection,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "today-list",
        name: "TodayList",
        component: TodayList,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "check-information-re-examination",
        name: "CheckInformationReExamination",
        component: CheckInformationReExamination,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "review-preliminary-slip",
        name: "ReviewPreliminaryExaminationSlip",
        component: ReviewPreliminaryExaminationSlip,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "symptoms",
        name: "Symptoms",
        component: Symptoms,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "preliminary-examination-slip",
        name: "PreliminaryExaminationSlip",
        component: PreliminaryExaminationSlip,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "reservation-calendar",
        name: "ReservationCalendar",
        component: ReservationCalendar,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "reservations/:id",
        name: "ReservationDetail",
        component: ReservationDetail,
        // beforeEnter: protectedRoutes,
      },
      {
        path: "reservation-list",
        name: "ReservationList",
        component: ReservationList,
      },
      {
        path: "customers",
        name: "CustomerList",
        component: CustomerList,
      },
      {
        path: "customers/:id",
        name: "CustomerDetails",
        component: CustomerDetails,
      },
      {
        path: "price-medical",
        name: "PriceMedical",
        component: PriceMedical,
      },
      {
        path: "task-schedule",
        name: "TaskSchedule",
        component: TaskSchedule,
      },
      {
        path: "reservation-registration",
        name: "ReservationRegistration",
        component: ReservationRegistration,
      },
      {
        path: "preliminary-examination-steps",
        name: "PreliminaryExaminationSteps",
        component: PreliminaryExaminationSteps,
      },
      { path: "/404", name: "NotFound", component: NotFound },
      { path: "/:catchAll(.*)", redirect: "/404" },
      {
        path: "",
        name: "Home",
        component: Home,
      },
    ],
  },
  {
    path: "/last-time",
    name: "LastTime",
    component: LastTime,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;
