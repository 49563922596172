import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5d25aa7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-dialog modal-dialog-centered" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-body" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "me-3" }
const _hoisted_7 = { class: "col-md-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: "modal fade",
    ref: _ctx.id,
    tabindex: "-1",
    "data-bs-backdrop": "static",
    "data-bs-keyboard": "false",
    "aria-hidden": "true"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          type: "button",
          class: "btn-close",
          "data-bs-dismiss": "modal",
          "aria-label": "Close",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.resetField && _ctx.resetField(...args)))
        }),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_DatePicker, {
                type: "previousMonth",
                date: _ctx.datePrevious,
                onPrevBtnClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handlePrevBtnClick())),
                onSelectedDate: _ctx.handleSelectedDate
              }, null, 8, ["date", "onSelectedDate"])
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createVNode(_component_DatePicker, {
                type: "nextMonth",
                date: _ctx.dateNow,
                onNextBtnClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleNextBtnClick())),
                onSelectedDate: _ctx.handleSelectedDate
              }, null, 8, ["date", "onSelectedDate"])
            ])
          ])
        ])
      ])
    ])
  ], 8, _hoisted_1))
}