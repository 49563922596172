import { EmployeeDivision } from "../interface";
import moment from "moment";

export const getCurrentDivision = (
  employeeDivisionList: Array<EmployeeDivision>
) => {
  if (!employeeDivisionList) {
    return null;
  }
  const currentDivision = employeeDivisionList.filter((division) => {
    const currentDate = moment(moment().format("YYYY-MM-DD"));
    const startDate = moment(division.applicationStartDate);
    const endDate = moment(division.applicationEndDate);
    return (
      currentDate.isSame(startDate) ||
      currentDate.isBetween(startDate, endDate) ||
      currentDate.isSame(endDate)
    );
  })[0];
  return currentDivision;
};

export const isCurrentDateBetween = (
  currentDate: string,
  startDate: string,
  endDate: string
) => {
  const currentMomentDate = moment(moment(currentDate).format("YYYY-MM-DD"));
  const startMomentDate = moment(startDate, "YYYY-MM-DD");
  const endMomentDate = moment(endDate, "YYYY-MM-DD");
  return (
    currentMomentDate.isSame(startMomentDate) ||
    currentMomentDate.isBetween(startMomentDate, endMomentDate) ||
    currentMomentDate.isSame(endMomentDate)
  );
};

export const buildQuestionJSONWithAnswers = (questions: any) => {
  const updatedQuestions = { ...questions };
  if (updatedQuestions.contents) {
    updateAnswers(updatedQuestions.contents);
  }
  return updatedQuestions;
};

const updateAnswers = (options: any) => {
  if (Array.isArray(options)) {
    options.forEach((option: any) => {
      if (option.anserType === "checkbox") {
        option.answers = [];
      } else if (option.anserType === "radio" || option.anserType === "text") {
        option.answer = "";
      }
      if (option.options) {
        updateAnswers(option.options);
      } else if (option.children) {
        updateAnswers(option.children);
      } else {
        return;
      }
    });
  } else {
    if (options.anserType === "checkbox") {
      options.answers = [];
    } else if (options.anserType === "radio" || options.anserType === "text") {
      options.answer = "";
    }
    if (options.options) {
      updateAnswers(options.options);
    } else if (options.children) {
      updateAnswers(options.children);
    } else {
      return;
    }
  }
};

export const paginate = (
  totalItems: number,
  currentPage = 1,
  pageSize = 10,
  maxPages = 10
) => {
  // calculate total pages
  const totalPages = Math.ceil(totalItems / pageSize);

  // ensure current page isn't out of range
  if (currentPage < 1) {
    currentPage = 1;
  } else if (currentPage > totalPages) {
    currentPage = totalPages;
  }

  let startPage: number, endPage: number;
  if (totalPages <= maxPages) {
    // total pages less than max so show all pages
    startPage = 1;
    endPage = totalPages;
  } else {
    // total pages more than max so calculate start and end pages
    const maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
    const maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
    if (currentPage <= maxPagesBeforeCurrentPage) {
      // current page near the start
      startPage = 1;
      endPage = maxPages;
    } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
      // current page near the end
      startPage = totalPages - maxPages + 1;
      endPage = totalPages;
    } else {
      // current page somewhere in the middle
      startPage = currentPage - maxPagesBeforeCurrentPage;
      endPage = currentPage + maxPagesAfterCurrentPage;
    }
  }

  // calculate start and end item indexes
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

  // create an array of pages to ng-repeat in the pager control
  const pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
    (i) => startPage + i
  );

  // return object with all pager properties required by the view
  return {
    totalItems: totalItems,
    currentPage: currentPage,
    pageSize: pageSize,
    totalPages: totalPages,
    startPage: startPage,
    endPage: endPage,
    startIndex: startIndex,
    endIndex: endIndex,
    pages: pages,
  };
};

export const convertToFullWidthKana = (s: string) => {
  return s.normalize("NFKC");
};

export const convertBigKana = (kanaVal: string) => {
  const smallKana = [
    "ァ",
    "ィ",
    "ゥ",
    "ェ",
    "ォ",
    "ヵ",
    "ヶ",
    "ㇱ",
    "ㇲ",
    "ッ",
    "ㇳ",
    "ㇴ",
    "ㇵ",
    "ㇶ",
    "ㇷ",
    "ㇸ",
    "ㇹ",
    "ㇺ",
    "ャ",
    "ュ",
    "ョ",
    "ㇻ",
    "ㇼ",
    "ㇽ",
    "ㇾ",
    "ㇿ",
    "ヮ",
  ];
  const bigKana = [
    "ア",
    "イ",
    "ウ",
    "エ",
    "オ",
    "カ",
    "ケ",
    "シ",
    "ス",
    "ツ",
    "ト",
    "ヌ",
    "ハ",
    "ヒ",
    "フ",
    "ヘ",
    "ホ",
    "ム",
    "ヤ",
    "ユ",
    "ヨ",
    "ラ",
    "リ",
    "ル",
    "レ",
    "ロ",
    "ワ",
  ];
  let ckanaVal = "";
  for (let i = 0; i < kanaVal.length; i++) {
    const index = smallKana.indexOf(kanaVal.charAt(i));
    if (index !== -1) {
      ckanaVal += bigKana[index];
    } else {
      ckanaVal += kanaVal.charAt(i);
    }
  }

  return ckanaVal;
};
export const remove_duplicates_es6 = (arr: any) => {
  const s = new Set(arr);
  const it = s.values();
  return Array.from(it);
};
