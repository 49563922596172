
import { defineComponent, PropType, watch, toRefs, watchEffect, onMounted, ref } from 'vue';
import { consultationClassifications } from '@/core/store/constants';
import { CONSULTATION_CLASSIFICATION } from '@/core/store/enums';
import moment from 'moment';
import { useRouter } from 'vue-router';
import { useToast, POSITION } from 'vue-toastification';

moment.locale('ja');

export default defineComponent({
  name: 'ReservationDetailCard',
  props: {
    reservation: {
      required: true,
      type: Object
    }
  },
  setup(props) {
    const router = useRouter();
    const reservationData: any = ref();

    onMounted(async () => {
      reservationData.value = props?.reservation;
    });

    watchEffect(() => {
      reservationData.value = props?.reservation;
    });
    const getMomentJpDate = (date: string) => {
      if (!date) {
        return '';
      }
      return moment.utc(date).format('LL (dd)');
    };

    const redirectToCustomerDetail = (reservation: any) => {
      if (reservationData.value.customer?.customerNumber) {
        router.push({
          name: 'CustomerDetails',
          params: {
            // id: `edit?id=?${reservationData.value.customer?.customerNumber}`,
            id : `edit?id=?${reservationData.value.customer?.customerNumber}`,
            customerNumberFromList: `?customerNumber=${reservationData.value.customer?.customerNumber}`,
            storeID: 312,
            smaregiID: 1212
          }
        });
      } else {
        const toast = useToast();
        toast.error('顧客情報を登録してください', {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
      }
    };

    return {
      reservationData,
      consultationClassifications,
      CONSULTATION_CLASSIFICATION,
      getMomentJpDate,
      redirectToCustomerDetail
    };
  }
});
