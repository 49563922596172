import {
  RESERVATION_TYPE,
  RESERVATION_STATUS,
  RESERVATION_ROUTE,
  SYMPTOMS,
  CONSULTATION_CLASSIFICATION,
  TASK_STATUS,
  TIME_FRAME,
  CANCELLATION_TYPE,
  STAGE,
  COUPON_PAYMENT_METHODS,
  MEMBER_PAYMENT_METHODS,
} from "../enums/index";
import i18n from "@/i18n";

export const reservationTypes: any = {
  [RESERVATION_TYPE.RESERVE]: i18n.global.t("Form.Reserve"),
  [RESERVATION_TYPE.TASK]: i18n.global.t("Form.Task"),
  [RESERVATION_TYPE.PUBLIC_HOLIDAY_AND_OTHERS]: i18n.global.t(
    "Form.PublicHolidaysAndOthers"
  ),
};

export const couponPaymentMethod: any = {
  [COUPON_PAYMENT_METHODS.STOREFRONT]: i18n.global.t(
    "PaymentMethods.storeFront"
  ),
  [COUPON_PAYMENT_METHODS.COUPON_EXCHANGE]: i18n.global.t(
    "PaymentMethods.couponExchange"
  ),
  [COUPON_PAYMENT_METHODS.ADJUSTMENT]: i18n.global.t(
    "PaymentMethods.adjustment"
  ),
  [COUPON_PAYMENT_METHODS.DATA_MIGRATION]: i18n.global.t(
    "PaymentMethods.dataMigration"
  ),
};

export const memberPaymentMethod: any = {
  [MEMBER_PAYMENT_METHODS.STOREFRONT]: i18n.global.t(
    "PaymentMethods.storeFront"
  ),
  [MEMBER_PAYMENT_METHODS.MEMBERSHIP_FEE_PAY]: i18n.global.t(
    "PaymentMethods.memberShipFeePay"
  ),
  [MEMBER_PAYMENT_METHODS.ADJUSTMENT]: i18n.global.t(
    "PaymentMethods.adjustment"
  ),
  [COUPON_PAYMENT_METHODS.DATA_MIGRATION]: i18n.global.t(
    "PaymentMethods.dataMigration"
  ),
};

export const reservationStatus = {
  [RESERVATION_STATUS.NOT_CONFIRMED]: i18n.global.t("Form.Pending"),
  [RESERVATION_STATUS.CONFIRMED]: i18n.global.t("Form.Finalized"),
  [RESERVATION_STATUS.RECEPTION]: i18n.global.t("Form.Accepted"),
  [RESERVATION_STATUS.TOTAL]: i18n.global.t("Form.Total"),
  [RESERVATION_STATUS.ACCOUNTED]: i18n.global.t("Form.Accounted"),
  [RESERVATION_STATUS.CANCEL]: i18n.global.t("Form.Cancelled"),
};

export const reservationRoutes: any = {
  [RESERVATION_ROUTE.TELEPHONE]: i18n.global.t("Form.Telephone"),
  [RESERVATION_ROUTE.LINE]: i18n.global.t("Form.Line"),
  [RESERVATION_ROUTE.WALK_IN]: i18n.global.t("Form.WalkIn"),
  [RESERVATION_ROUTE.AFTER_TREATMENT]: i18n.global.t("Form.AfterTreatment"),
  [RESERVATION_ROUTE.EMAIL]: i18n.global.t("Form.Email"),
};

export const symptoms: any = {
  [SYMPTOMS.DAILY_PAIN]: i18n.global.t("Form.EverydayPain"),
  [SYMPTOMS.TRAFFIC_ACCIDENT]: i18n.global.t("Form.TrafficAccident"),
};

export const consultationClassifications = {
  [CONSULTATION_CLASSIFICATION.FIRST_VISIT]: i18n.global.t("Form.FirstVisit"),
  [CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT]: i18n.global.t(
    "Form.OngoingTreatment"
  ),
  [CONSULTATION_CLASSIFICATION.RE_EXAMINATION]:
    i18n.global.t("Form.ReExamination"),
  [CONSULTATION_CLASSIFICATION.CONFIRMATION_REQUIRED]:
    i18n.global.t("Form.ToConfirm"),
};

export const cancellationTypes = {
  [CANCELLATION_TYPE.NO_CONTACT]: i18n.global.t("Form.NoContact"),
  [CANCELLATION_TYPE.CONTACTED]: i18n.global.t("Form.Contacted"),
  [CANCELLATION_TYPE.AFTER_RECEPTION]: i18n.global.t("Form.AfterReception"),
  [CANCELLATION_TYPE.COURTYARDS_ARE_ALL_CLOSED]: i18n.global.t("Form.Closed"),
};

export const taskStatus = {
  [TASK_STATUS.UNFINISHED]: i18n.global.t("Form.Unfinished"),
  [TASK_STATUS.COMPLETE]: i18n.global.t("Form.Completion"),
  [TASK_STATUS.CANCEL]: i18n.global.t("Form.Cancel"),
};

export const timeFrame: any = {
  [TIME_FRAME.FIFTEEN]: 15,
  [TIME_FRAME.THIRTY]: 30,
  [TIME_FRAME.SIXTY]: 60,
  // [TIME_FRAME.SIXTY]: 60,
  // [TIME_FRAME.SEVENTY_FIVE]: 75,
  // [TIME_FRAME.NINTY]: 90,
  // [TIME_FRAME.HUNDRED_FIVE]: 105,
  // [TIME_FRAME.HUNDRED_TWENTY]: 120,
};

export const locations: Array<{ key: number; name: string }> = [
  {
    key: 1,
    name: i18n.global.t("Policy.Location.Left"),
  },
  {
    key: 2,
    name: i18n.global.t("Policy.Location.Right"),
  },
  {
    key: 3,
    name: i18n.global.t("Policy.Location.Both"),
  },
];

export const stages: Array<{ key: STAGE; name: string }> = [
  {
    key: STAGE.STAGE_FIVE,
    name: i18n.global.t("Treatment.StageFive"),
  },
  {
    key: STAGE.STAGE_FOUR,
    name: i18n.global.t("Treatment.StageFour"),
  },
  {
    key: STAGE.STAGE_THREE,
    name: i18n.global.t("Treatment.StageThree"),
  },
  {
    key: STAGE.STAGE_TWO,
    name: i18n.global.t("Treatment.StageTwo"),
  },
  {
    key: STAGE.STAGE_ONE,
    name: i18n.global.t("Treatment.StageOne"),
  },

  {
    key: STAGE.STAGE_ZERO,
    name: i18n.global.t("Treatment.StageZero"),
  },
];

export const calendarTexts = {
  PUBLIC_HOLIDAY: i18n.global.t("Calendar.PublicHoliday"),
};
