
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { fetch, remove, store as post } from "@/core/api/api-service";
import { paginate } from "@/core/utils";
import moment from "moment";

export default defineComponent({
  name: "Accounts",
  setup() {
    const route = useRoute();
    const toast = useToast();
    const customerNumber = ref("");
    const accountData = ref([]);
    let pagination = ref();
    let currentPage = ref();
    let status = ref({
      remaining: "",
      totalPurchase: "",
      totalUses: "",
    });
    let paginationButton = ref([{ id: 0, buttonName: 1, active: false }]);

    onMounted(async () => {
      const customerID = await (route.params.id as string).split("=?")?.[1];
      customerNumber.value = customerID;
      getAccountsData(customerID);
      getStatusFor(customerID);
    });
    const getStatusFor = (customerNumberFromRoute: any) => {
      const customerNumber = customerNumberFromRoute;
      fetch(
        `api/v1/account-receivables-status?customerNumber=${customerNumber}`
      ).then((res: any) => {
        const response = res?.data?.data;
        status.value = response;
      });
    };
    const getAccountsData = (customerNumberFromRoute: any) => {
      const customerNumber = customerNumberFromRoute;
      const page = `&page=${1}`;
      fetch(
        `api/v1/account-receivables?customerNumber=${customerNumber}${page}`
      ).then((res: any) => {
        const customerSales = res?.data?.data;
        const paginationData = res?.data?.meta?.pagination;
        currentPage.value = res?.data?.meta?.pagination?.current_page;
        pagination.value = {
          ...paginate(
            paginationData.total,
            paginationData.current_page,
            paginationData.per_page,
            3
          ),
          previous: paginationData.links?.previous,
          next: paginationData.links?.next,
        };
        accountData.value = customerSales;
      });
    };
    const changePage = (urlData: any) => {
      const url = urlData;
      fetch(`${url}`).then((res) => {
        const response = res?.data?.data;
        accountData.value = response;
        const paginationData = res?.data?.meta?.pagination;
        currentPage.value = res?.data?.meta?.pagination?.current_page;
        pagination.value = {
          ...paginate(
            paginationData.total,
            paginationData.current_page,
            paginationData.per_page,
            3
          ),
          previous: paginationData.links?.previous,
          next: paginationData.links?.next,
        };
      });
    };
    const onPaginationChange = (selectedButton: any) => {
      fetch(
        `account-receivables?customerNumber=${customerNumber.value}&page=${selectedButton}`
      ).then((res) => {
        const response = res?.data?.data;
        accountData.value = response;
        const paginationData = res?.data?.meta?.pagination;
        currentPage.value = res?.data?.meta?.pagination?.current_page;
        pagination.value = {
          ...paginate(
            paginationData.total,
            paginationData.current_page,
            paginationData.per_page,
            3
          ),
          previous: paginationData.links?.previous,
          next: paginationData.links?.next,
        };
      });
    };
    const convertToMMDD = (date: any) => {
      return moment(date, "YYYY-MM-DD").format("YYYY/MM/DD");
    };
    return {
      convertToMMDD,
      onPaginationChange,
      changePage,
      status,
      accountData,
      pagination,
      paginationButton,
    };
  },
});
