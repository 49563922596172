
import { defineComponent, PropType, ref, watch, watchEffect } from 'vue';
import Multiselect from '@vueform/multiselect';
import { taskStatus } from '@/core/store/constants';
import { TASK_STATUS } from '@/core/store/enums';
import { TableSortBy } from '@/core/interface';
import tickIcon from '@/assets/icons/check-solid.svg';
import moment from 'moment';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: 'TaskListTable',
  components: {
    Multiselect
  },
  emits: ['sortByChange', 'filterTasksByPractitioner', 'updateStatus'],
  props: {
    filterFromSearch: {
      type: Boolean
    },
    filterFromTable: {
      type: Boolean
    },
    sortBy: {
      type: Object as PropType<TableSortBy>
    },
    taskList: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const tasks = ref(props.taskList);
    const router = useRouter();
    const route = useRoute();
    const selectedStatus = ref('');
    const selectTask = ref<boolean>(false);
    const selectedSort = ref('');
    const sortByObj: any = ref(props.sortBy);
    const practitionerList = ref<Array<any>>([]);
    const practitionerOptions = ref<Array<any>>([]);
    const selectedPractitioner = ref('ALL');

    watchEffect(() => {
      if (props.sortBy) {
        sortByObj.value = props.sortBy;
      }
    });

    watch(
      () => props.taskList,
      (taskList, prevTaskList) => {
        if (taskList) {
          practitionerList.value = [];
          tasks.value = [...taskList].map((task: any) => {
            practitionerList.value.push(task.practitioner);
            return {
              ...task,
              checked: false
            };
          });
          buildPractitionerOptions();
        }
      }
    );

    const buildPractitionerOptions = () => {
      if (props.filterFromSearch) {
        practitionerOptions.value = [];
        practitionerOptions.value.push({
          name: 'ALL',
          value: 'ALL',
          icon: tickIcon
        });
        practitionerList.value.forEach((practitioner: any) => {
          if (
            practitionerOptions.value.findIndex((pract: any) => pract?.id === practitioner?.id) ===
            -1
          ) {
            practitionerOptions.value.push({
              id: practitioner?.id,
              name: practitioner?.name,
              value: practitioner?.id,
              icon: tickIcon
            });
          }
        });

        selectedPractitioner.value = 'ALL';
      }
    };

    const check = () => {
      tasks.value.forEach((task: any) => {
        task.checked = selectTask.value;
      });
    };

    const formatTime = (time: string) => {
      return time ? moment(time, 'HH:mm:ss').format('HH:mm') : '';
    };

    const formatDate = (date: any) => {
      return moment(date, 'YYYY-MM-DD').format('YYYY/MM/DD');
    };

    const applyChanges = () => {
      if (selectedStatus.value) {
        const taskIds = tasks.value
          .filter((task: any) => task.checked)
          .map((task: any) => {
            return task.id;
          });
        emit('updateStatus', { taskIds, status: selectedStatus.value });
      }
    };

    const checkTask = (task: any) => {
      if (!task.checked && selectTask) {
        selectTask.value = false;
      }
    };

    const sortByClick = (sortByKey: string) => {
      selectedSort.value = sortByKey;
      for (var key in sortByObj.value) {
        if (key === sortByKey) {
          sortByObj.value[key] = !sortByObj.value[key];
        } else {
          sortByObj.value[key] = false;
        }
      }
      emit('sortByChange', sortByObj.value);
    };

    const filterTasksByPractitioner = (practitioner: any) => {
      emit('filterTasksByPractitioner', practitioner);
    };

    const taskScheduleToReservation = async (dataFromRecord: any, type: string, event: any) => {
      router.push({
        name: 'ReservationRegistration',
        params: {
          isFromTask: dataFromRecord?.status === 3 ? 'true' : 'false',
          isFromCancel: 'false',
          isFromEdit: 'true',
          // hasMedicalRecord: dataFromRecord?.medicalRecord?.id ? 'true' : 'false',
          id: dataFromRecord?.id,
          date: dataFromRecord?.date,
          startHour: moment(dataFromRecord.startingTime, 'HH:mm:ss').format('HH:mm'),
          endHour: moment(dataFromRecord.endingTime, 'HH:mm:ss').format('HH:mm') || '',
          store: dataFromRecord?.store?.id || '',
          practitioner: dataFromRecord.practitioner?.id || '',
          reservation_type: 2,
          // reservation_status: dataFromRecord.status,
          // same_person_confirmation: dataFromRecord?.samePersonConfirmed ? 1 : 0,
          task_type: dataFromRecord?.taskType?.id,
          // customerNumber: dataFromRecord?.customer?.customerNumber,
          absence_type_id: dataFromRecord?.absenceType?.id,
          status: dataFromRecord?.status,
          detail: dataFromRecord?.detail,
          management_memo: dataFromRecord?.details,
          block: dataFromRecord?.block ? '1' : '0'
        },
        query: { type: 'reserve' }
      });
    };

    return {
      taskScheduleToReservation,
      taskStatus,
      //
      tasks,
      selectedStatus,
      selectedPractitioner,
      selectTask,
      selectedSort,
      //
      sortByObj,
      statusOptions: [
        {
          value: TASK_STATUS.UNFINISHED,
          name: `${taskStatus[TASK_STATUS.UNFINISHED]}`,
          selected: false,
          icon: tickIcon
        },
        {
          value: TASK_STATUS.COMPLETE,
          name: `${taskStatus[TASK_STATUS.COMPLETE]}`,
          selected: false,
          icon: tickIcon
        },
        {
          value: TASK_STATUS.CANCEL,
          name: `${taskStatus[TASK_STATUS.CANCEL]}`,
          selected: false,
          icon: tickIcon
        }
      ],
      practitionerOptions,
      //
      filterTasksByPractitioner,
      sortByClick,
      formatTime,
      formatDate,
      check,
      checkTask,
      applyChanges
    };
  }
});
