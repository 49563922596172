import { fetch } from "./api-service";

export const getFutureReservations = (
  customerNumber: string,
  afterDate: string
): Promise<Array<any>> => {
  return new Promise((resolve, reject) => {
    fetch(
      `/api/v1/reservations?customerNumberExact=${customerNumber}&reservationAfterDate=${afterDate}`
    )
      .then((res) => {
        const reservationList = res.data.data;
        resolve(reservationList);
      })
      .catch((err) => reject(err));
  });
};
