
import { defineComponent, ref, onMounted, computed } from 'vue';
import Modal from '@/components/modals/Modal.vue';
import FileInput from '@/components/fileInput/FileInput.vue';
import { store as post, remove, fetch } from '@/core/api/api-service';
import { Inquire } from '@/core/interface';
import { CONSULTATION_CLASSIFICATION } from '../../core/store/enums';
import moment from 'moment';
import { POSITION, useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

export default defineComponent({
  name: 'Inquire',
  components: {
    Modal,
    FileInput
  },
  props: {
    reservation: {
      type: Object || null,
      required: true
    },
    medicalRecord: {
      type: Object || null,
      required: true
    },
    previousReservation: {
      type: Object,
      required: false
    },
    previousMedicalRecord: {
      type: Object,
      required: false
    }
  },
  emits: ['updateReservation'],
  setup(props, { emit }) {
    moment.locale('ja');
    const toast = useToast();
    const reservationData = ref(
      props.reservation.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
        ? props.previousReservation
        : props.reservation
    );
    const createdData = ref('');
    const medicalRecordData = ref(
      props.reservation.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
        ? props.previousMedicalRecord
        : props.medicalRecord
    );

    const selectedFile = ref<any>(null);
    const isFileSelected = ref(false);
    const showSubmittedFileView = ref(false);
    const formSubmitted = ref<any>(false);
    const inquire = ref<Inquire | null>(null);
    const errors = ref('');
    const selectedFiles = (files: any) => {
      selectedFile.value = files[0];
      isFileSelected.value = !!selectedFile.value;
    };

     const convertBase64ToImageUrl = (base64: any) =>{
        const contentType = "image/png";
        const byteCharacters = atob(
            base64.substr(`data:${contentType};base64,`.length)
        );
        const byteArrays = [];
        for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
            const slice = byteCharacters.slice(offset, offset + 1024);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: contentType });
        const blobUrl = URL.createObjectURL(blob);
        return blobUrl;
    }
    const register = () => {
      errors.value = '';
      if (formSubmitted.value) {
        return;
      }

      // formSubmitted.value = true;
      let inquireUrl = inquire?.value?.id ? `inquiries/${inquire?.value?.id}` : 'inquiries';

      let formData = new FormData();
      formData.append('medical_record_id', medicalRecordData.value?.id);
      formData.append('image', selectedFile.value);
      if (inquire?.value?.id) {
        formData.append('_method', 'PUT');
      }

      post(`api/v1/${inquireUrl}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((res) => {
          if (res.status === 200) {
            const inquireResData = res.data.data;
            inquire.value = inquireResData;
            showSubmittedFileView.value = true;
            formSubmitted.value = true;
            window.scrollTo(0, 0);
            toast.success('登録しました', {
              timeout: 2000,
              position: POSITION.BOTTOM_RIGHT
            });
            emit('updateReservation', true);
          }
          // formSubmitted.value = false;
        })
        .catch((err) => {
          formSubmitted.value = false;
          errors.value = err.response.data?.errors?.[0]?.title;
        });
    };

    const removeInquire = () => {
      remove(`/api/v1/inquiries/${inquire.value?.id}`).then((res) => {
        if (res.status === 200) {
          window.scrollTo(0, 0);
          toast.success('削除しました', {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT
          });
          inquire.value = null;
          showSubmittedFileView.value = false;
          formSubmitted.value = false;
          emit('updateReservation', true);
        }
      });
    };

    onMounted(() => {
      if (
        reservationData.value?.consultationClassification !==
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
      ) {
        inquire.value = medicalRecordData.value?.inquiry;
        if (inquire.value) {
          showSubmittedFileView.value = true;
        }
      } else {
        fetchInquiry();
      }
    });

    const getMomentDate = (date: string) => {
      return moment.utc(date || new Date()).format('LL (dd)');
    };

    const fetchInquiry = async () => {
      const medicalRecordResponse = await fetch(
        `api/v1/medical-records/${medicalRecordData.value?.id}`
      );

      if (medicalRecordResponse.status === 200) {
        if (medicalRecordResponse?.data?.data) {
          const medicalRecordData = medicalRecordResponse.data.data;
          if (medicalRecordData.inquiry) {
            inquire.value = medicalRecordData.inquiry;
            showSubmittedFileView.value = true;
          }
        }
      }
    };

    const isFormFieldDisabled = computed(() => {
      return (
        props.reservation.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
      );
    });

    return {
      createdData,
      CONSULTATION_CLASSIFICATION,
      //
      errors,
      selectedFile,
      inquire,
      isFileSelected,
      showSubmittedFileView,
      isFormFieldDisabled,
      formSubmitted,
      selectedFiles,
      getMomentDate,
      convertBase64ToImageUrl,
      removeInquire,
      register
    };
  }
});
