import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelRadio as _vModelRadio, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a7d6344"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-screen" }
const _hoisted_2 = { class: "page-title" }
const _hoisted_3 = ["placeholder"]
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = ["placeholder"]
const _hoisted_6 = { class: "d-flex flex-wrap justify-content-between" }
const _hoisted_7 = ["checked"]
const _hoisted_8 = {
  class: "form-check-label",
  for: "Male"
}
const _hoisted_9 = ["checked"]
const _hoisted_10 = {
  class: "form-check-label",
  for: "Female"
}
const _hoisted_11 = ["placeholder"]
const _hoisted_12 = { class: "note text-danger fw-bold" }
const _hoisted_13 = {
  class: "btn btn-primary d-block mx-auto search-button",
  href: "#"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("IdLinkage.Title")), 1),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("IdLinkage.CustomerNumber")), 1),
    _createElementVNode("input", {
      type: "text",
      class: "form-control",
      placeholder: _ctx.$t('IdLinkage.PleaseFillIn')
    }, null, 8, _hoisted_3),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("IdLinkage.Name")), 1),
    _createElementVNode("input", {
      type: "text",
      class: "form-control",
      placeholder: _ctx.$t('IdLinkage.ExampleName')
    }, null, 8, _hoisted_4),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("IdLinkage.Birthday")), 1),
    _createElementVNode("input", {
      type: "text",
      class: "form-control",
      placeholder: _ctx.$t('IdLinkage.ExampleDate')
    }, null, 8, _hoisted_5),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("IdLinkage.Gender")), 1),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", {
        class: _normalizeClass(["form-check", { selected: _ctx.gender === 'Male' }]),
        for: "first"
      }, [
        _createElementVNode("input", {
          class: "form-check-input",
          type: "radio",
          checked: true ? _ctx.gender === 'Male' : false
        }, null, 8, _hoisted_7),
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          name: "gender",
          value: "Male",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.gender) = $event)),
          class: "hidden-radio",
          id: "Male"
        }, null, 512), [
          [_vModelRadio, _ctx.gender]
        ]),
        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t("IdLinkage.Male")), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["form-check", { selected: _ctx.gender === 'Female' }]),
        for: "first"
      }, [
        _createElementVNode("input", {
          class: "form-check-input",
          type: "radio",
          checked: true ? _ctx.gender === 'Female' : false
        }, null, 8, _hoisted_9),
        _withDirectives(_createElementVNode("input", {
          type: "radio",
          name: "gender",
          value: "Female",
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.gender) = $event)),
          class: "hidden-radio",
          id: "Female"
        }, null, 512), [
          [_vModelRadio, _ctx.gender]
        ]),
        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("IdLinkage.Female")), 1)
      ], 2)
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.$t("IdLinkage.MobileNumber")), 1),
    _createElementVNode("input", {
      type: "text",
      class: "form-control",
      placeholder: _ctx.$t('IdLinkage.PleaseFillIn')
    }, null, 8, _hoisted_11),
    _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t("IdLinkage.Note")), 1),
    _createElementVNode("a", _hoisted_13, _toDisplayString(_ctx.$t("IdLinkage.ToTheNext")), 1)
  ]))
}