
import { defineComponent, onMounted, ref, computed } from 'vue';
import Multiselect from '@vueform/multiselect';
import { CauseSource, Complaint } from '@/core/interface';
import { locations } from '@/core/store/constants';
import { fetch, remove, store as post } from '@/core/api/api-service';
import moment from 'moment';
import Modal from '@/components/modals/Modal.vue';
import { CONSULTATION_CLASSIFICATION } from '../../core/store/enums';
import { POSITION, useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { convertToFullWidthKana } from '@/core/utils';

export default defineComponent({
  name: 'PolicyForm',
  components: {
    Multiselect,
    Modal
  },
  props: {
    reservation: {
      type: Object || null,
      required: true
    },
    medicalRecord: {
      type: Object || null,
      required: true
    },
    previousReservation: {
      type: Object,
      required: false
    },
    previousMedicalRecord: {
      type: Object,
      required: false
    },
    disableAtFirstLoad: {
      type: Boolean
    }
  },
  emits: ['updateReservation'],
  setup(props, { emit }) {
    const toast = useToast();
    const disableNow = ref<boolean>(props.disableAtFirstLoad);
    const reservationData = ref(
      props.reservation.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
        ? props.previousReservation
        : props.reservation
    );
    const medicalRecordData = ref(
      props.reservation.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
        ? props.previousMedicalRecord
        : props.medicalRecord
    );

    let parts: any[] = [];
    const complaints = ref<Array<any>>([]);
    const selectedComplaint = ref<Complaint | null>(null);
    const selectedComplaintIndex = ref<number>(0);
    const selectedTab = ref(0);
    const showNewComplaint = ref(false);
    const disableCreateNewButton = ref(false);
    const newbuttonClicked = ref(false);
    const partOptions = ref(parts);
    const formSubmitted = ref<boolean>(false);
    const formErrors = ref<Array<any>>([]);
    const muscleErrors = ref<Array<any>>([]);
    const formDeletionSubmitted = ref<boolean>(false);

    const locationOptions = locations.map((location: { key: number; name: string }) => {
      return {
        value: location.key,
        name: location.name,
        selected: false,
        icon: require('@/assets/icons/check-solid.svg')
      };
    });

    const selectPolicyTab = (complaint: Complaint, complaintIndex: number) => {
      selectedTab.value = complaintIndex;
    };
    let isValidated = ref(false);
    const registerPolicy = async (complaint: Complaint, complaintIndex: number) => {
      formErrors.value = [];
      muscleErrors.value = [];
      if (formSubmitted.value) {
        return;
      }
      isValidated.value = true;
      formSubmitted.value = true;
      const medicalRecordId = props?.medicalRecord?.id;
      const serialNumber = complaintIndex + 1;
      const chiefComplaint = complaint.mainComplaint;
      const causeAction = complaint.causeAction;
      const causeActionDescription = complaint.causeActionDescription;
      const practitionerComment = complaint.whatYouSee;
      const causes = complaint.causeSources;

      causes.forEach((cause) => {
        if (!cause.location || !cause.part) {
          formErrors.value.push({ title: 'Error', message: 'Required' });
          isValidated.value = false;
        }
      });

      causes.forEach(async (cause, causeIndex) => {
        if (cause?.muscles.length === 0) {
          muscleErrors.value.push({
            id: muscleErrors.value.length + 1,
            title: 'Error',
            message: 'Required'
          });
        }
      });

      if (formErrors.value.length > 0 || muscleErrors.value.length > 0) {
        formSubmitted.value = false;
        return;
      }

      let formData = new FormData();
      let policyUrl = '/api/v1/policies';
      if (complaint?.id) {
        formData.append('_method', 'PUT');
        policyUrl += `/${complaint?.id}`;
      } else {
        formData.append('serial_number', String(serialNumber));
      }
      formData.append('medical_record_id', medicalRecordId);
      formData.append('chief_complaint', chiefComplaint);
      formData.append('cause_action', causeAction);
      formData.append('cause_action_description', causeActionDescription);
      formData.append('practitioner_comment', practitionerComment);
      if (formErrors.value.length === 0 && muscleErrors.value.length === 0) {
        const policiesRes = await post(policyUrl, formData);
        if (policiesRes.status === 200) {
          const policyResponseData = policiesRes.data.data;
          complaints.value[complaintIndex].id = policyResponseData.id;

          let causesFormData = new FormData();
          causesFormData.append('policy_id', policyResponseData.id);
          causes.forEach(async (cause, causeIndex) => {
            causesFormData.append('location[]', cause.location);
            causesFormData.append('part[]', cause.part);
            cause.muscles.forEach((muscle) => {
              causesFormData.append(`muscles[${causeIndex}][]`, String(muscle));
            });
          });
          return await post('/api/v1/causes', causesFormData)
            .then((causesResponse) => {
              if (causesResponse.status === 200) {
                disableCreateNewButton.value = false;
                newbuttonClicked.value = false;
                window.scrollTo(0, 0);
                if (complaint?.id) {
                  toast.success('更新しました', {
                    timeout: 2000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                } else {
                  toast.success('登録しました', {
                    timeout: 2000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                }
                formSubmitted.value = false;
              }
              emit('updateReservation', true);
            })
            .catch((err) => {
              formSubmitted.value = false;
              disableCreateNewButton.value = true;
            });
        }
      }
    };

    const addCauseSource = (complaint: Complaint, index: number) => {
      complaints.value[index].causeSources.push({
        location: '',
        part: '',
        muscles: [],
        muscleOptions: []
      });
    };

    const disableButton = (value: any) => {
      disableCreateNewButton.value = true;
    };

    const onPartSelect = (partId: number, complaintIndex: number, causeIndex: number) => {
      if (partId) {
        const muscleOptions = getMuscleOptionsFromPartId(partId);
        complaints.value[complaintIndex].causeSources[causeIndex].muscleOptions = muscleOptions;
        complaints.value[complaintIndex].causeSources.forEach((muscles: any) => {
          muscles.muscles = [];
        });
      }
    };

    const getMuscleOptionsFromPartId = (partId: number) => {
      const part = parts.filter((part) => part.id === partId)[0];
      return part ? part.muscles : [];
    };

    const removeCauseSource = (
      complaint: Complaint,
      causeSource: CauseSource,
      complaintIndex: number,
      causeSourceIndex: number
    ) => {
      if (complaints.value[complaintIndex].causeSources.length > 1) {
        complaints.value[complaintIndex].causeSources.splice(causeSourceIndex, 1);
      }
    };

    const createNewPolicy = () => {
      complaints.value.push({
        date: moment(),
        mainComplaint: '',
        causeAction: 'ExcessiveMovement',
        causeActionDescription: '',
        whatYouSee: '',
        causeSources: [
          {
            location: '',
            part: '',
            muscles: [],
            muscleOptions: []
          }
        ]
      });
      newbuttonClicked.value = true;
      disableCreateNewButton.value = true;
      showNewComplaint.value = true;
      selectedTab.value = complaints.value.length - 1;
    };

    const removePolicy = async () => {
      const toast = useToast();
      if (formDeletionSubmitted.value) {
        return;
      }
      formDeletionSubmitted.value = true;
      if (selectedComplaint.value && selectedComplaint.value?.id) {
        remove(`/api/v1/policies/${selectedComplaint.value?.id}`)
          .then((res) => {
            if (res.status === 200) {
              const data = res.data.data;
              complaints.value.splice(selectedComplaintIndex.value, 1);
              if (selectedComplaintIndex.value == 0) {
                selectedTab.value = selectedComplaintIndex.value;
              } else {
                selectedTab.value = selectedComplaintIndex.value - 1;
              }
              formDeletionSubmitted.value = false;
              if (complaints.value.length === 1 && selectedComplaintIndex.value !== 0) {
                disableCreateNewButton.value = false;
              } else {
                let hasPolicy = complaints.value.filter((el) => {
                  return el.mainComplaint === '';
                });
                if (hasPolicy.length > 0) {
                  disableCreateNewButton.value = true;
                } else {
                  disableCreateNewButton.value = false;
                }
              }
            }
          })
          .catch((err) => {
            toast.error('施術が登録されているため削除できません', {
              timeout: 2000,
              position: POSITION.BOTTOM_RIGHT
            });
            formDeletionSubmitted.value = false;
          });
      } else {
        complaints.value.splice(selectedComplaintIndex.value, 1);
        if (selectedComplaintIndex.value == 0) {
          selectedTab.value = selectedComplaintIndex.value;
        } else {
          selectedTab.value = selectedComplaintIndex.value - 1;
        }
        formDeletionSubmitted.value = false;
        if (complaints.value.length === 1 && selectedComplaintIndex.value !== 0) {
          disableCreateNewButton.value = false;
        } else {
          let hasPolicy = complaints.value.filter((el) => {
            return el.mainComplaint === '';
          });
          if (hasPolicy.length > 0) {
            disableCreateNewButton.value = true;
          } else {
            disableCreateNewButton.value = false;
          }
        }
      }
    };

    const selectPolicy = async (complaint: Complaint, index: number) => {
      selectedComplaint.value = complaint;
      selectedComplaintIndex.value = index;
    };

    onMounted(() => {
      buildPolicyForms();
    });

    const fetchParts = () => {
      return fetch('api/v1/parts').then((res) => {
        parts = res?.data?.data;
        if (parts) {
          partOptions.value = parts
            .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            .map((part: any) => {
              return {
                value: part.id,
                name: part.name,
                selected: false,
                icon: require('@/assets/icons/check-solid.svg')
              };
            });
        }
      });
    };

    const deActivate = (data: any) => {
      if (data !== null) {
        disableCreateNewButton.value = true;
      } else {
        disableCreateNewButton.value = false;
      }
    };

    const buildPolicyForms = () => {
      fetchParts().then(() => {
        if (
          reservationData.value?.consulatationClassification !==
          CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
        ) {
          fetchPolicies();
        } else {
          buildPolicy(medicalRecordData.value?.policy || []);
        }
      });
    };

    const fetchPolicies = async () => {
      const policyRes = await fetch(
        `api/v1/policies?medicalRecordId=${medicalRecordData.value?.id}`
      );
      if (policyRes.status === 200) {
        if (policyRes?.data?.data.length == 0) {
          disableCreateNewButton.value = true;
        }
        const policyResData = policyRes?.data?.data;
        buildPolicy(policyResData);
      }
    };

    const buildPolicy = (policyResData: any) => {
      if (policyResData.length > 0) {
        policyResData.forEach((policy: any) => {
          let causes: Array<CauseSource> = [];
          if (policy?.causes && policy.causes.length > 0) {
            policy.causes.forEach((cause: any) => {
              causes.push({
                location: cause.locationPart,
                part: cause.part.id,
                muscles: cause?.causeDetail?.map((causeDetail: any) => causeDetail.muscleId) || [],
                muscleOptions: getMuscleOptionsFromPartId(cause.part.id) || []
              });
            });
          }
          const policyData = {
            id: policy.id,
            date: moment(policy.createdDate, 'YYYY-MM-DD'),
            mainComplaint: policy.chiefComplaint,
            causeAction: policy.causeAction,
            causeActionDescription: policy.causeActionDescription,
            whatYouSee: policy.practitionerComment,
            causeSources: causes,
            createdBy: policy.createdBy,
            updatedBy: policy.updatedBy
          };

          complaints.value.push(policyData);
          selectedTab.value = 0;
        });
      } else {
        complaints.value.push({
          date: moment(),
          mainComplaint: '',
          causeAction: 'ExcessiveMovement',
          causeActionDescription: '',
          whatYouSee: '',
          causeSources: [
            {
              location: '',
              part: '',
              muscles: [],
              muscleOptions: []
            }
          ]
        });
      }
      selectedTab.value = complaints.value.length - 1;
      disableNow.value = false;
    };

    const isFormFieldDisabled = computed(() => {
      return false; // enable create NewPolicy button for continuous users
      // return (
      //   props.reservation.consultationClassification ===
      //   CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
      // );
    });

    return {
      convertToFullWidthKana,
      muscleErrors,
      selectedTab,
      showNewComplaint,
      disableCreateNewButton,
      newbuttonClicked,
      complaints,
      locationOptions,
      partOptions,
      deActivate,
      formSubmitted,
      formDeletionSubmitted,
      formErrors,
      selectedComplaint,
      selectedComplaintIndex,
      isFormFieldDisabled,
      selectPolicyTab,
      createNewPolicy,
      registerPolicy,
      addCauseSource,
      removeCauseSource,
      onPartSelect,
      selectPolicy,
      disableNow,
      disableButton,
      removePolicy,
      isValidated
    };
  }
});
