<template>
  <Modal id="backModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
        <div class="modal-body">
          <p class="title text-center">
            {{ $t("BackModal.Title") }}
          </p>
          <div class="d-flex justify-content-between mb-2">
            <button class="btn btn-secondary" data-bs-dismiss="modal">
              {{ $t("BackModal.Cancel") }}
            </button>
            <button
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="handleClick"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from "vue";
import Modal from "./Modal.vue";

export default defineComponent({
  name: "BackConfirmationModal",
  components: { Modal },
  setup(props, { emit }) {
    const handleClick = () => {
      emit("goBack");
    };

    return {
      handleClick,
    };
  },
});
</script>

<style lang="scss" scoped>
.modal-dialog {
  max-width: 404px;

  p.title {
    font-size: 16px;
    padding-top: 8px;
    margin-bottom: 32px;
    color: #444;
  }

  .btn-secondary {
    width: 128px;
    border-color: #888;
  }
}
</style>
