
import { defineComponent, ref, onMounted } from "vue";
import TaskListTable from "../components/tables/TaskListTable.vue";
import TaskListSearch from "../components/search-cards/TaskListSearch.vue";
import { fetch, store as post } from "@/core/api/api-service";
import { TableSortBy } from "@/core/interface";
import { POSITION, useToast } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { paginate } from "@/core/utils";
import moment from "moment";
import { store } from "@/core/store";

export default defineComponent({
  name: "TaskSchedule",
  components: {
    TaskListTable,
    TaskListSearch,
  },
  setup() {
    const toast = useToast();
    const searchFields = ref({
      taskStartDate: moment().format("YYYY-MM-DD"),
      taskEndDate: moment().format("YYYY-MM-DD"),
      taskStatusUnfinished: true,
      taskStatusComplete: true,
      taskStatusCancel: true,
      store: store.getters?.user?.currentAssignment[0]?.storeId,
      practitioner: "",
      taskType: "",
    });
    const sortBy = ref<TableSortBy>({
      date: false,
      time: false,
      store: false,
      practitioner: false,
      taskType: false,
      status: false,
    });
    const stores = ref([]);
    const practitioners = ref([]);
    const taskList = ref<Array<any>>([]);
    const taskTypes = ref<Array<any>>([]);
    const currentPage = ref<number>(1);
    const filterFromSearch = ref<boolean>(true);
    const filterFromTable = ref<boolean>(false);
    const selectedPractitioner = ref<number>();
    const paginationButton = ref([{ id: 0, buttonName: 1, active: false }]);
    const pagination = ref();
    const sortData = ref({ field: "", sortBy: "" });

    const handleTaskSearch = (searchFields: any) => {
      filterFromSearch.value = true;
      filterFromTable.value = false;
      searchFields.value = searchFields;
      performTaskListSearch(1);
    };

    const performTaskListSearch = (
      pageNumber?: number,
      practitionerId?: number
    ) => {
      const {
        taskStartDate,
        taskEndDate,
        taskStatusUnfinished,
        taskStatusComplete,
        taskStatusCancel,
        store,
        practitioner,
        taskType,
      } = searchFields.value;

      const pId = practitionerId ? practitionerId : practitioner;

      let taskUrl = `/api/v1/tasks/index-data?taskDateFrom=${
        taskStartDate || ""
      }&taskDateTo=${taskEndDate || ""}&store=${store || ""}&practitioner=${
        pId || ""
      }&taskType=${taskType || ""}`;
      taskUrl += `&taskUnfinished=${taskStatusUnfinished ? 1 : 0}`;
      taskUrl += `&taskComplete=${taskStatusComplete ? 1 : 0}`;
      taskUrl += `&taskCancel=${taskStatusCancel ? 1 : 0}`;
      if (sortBy.value.date) {
        taskUrl += `&field=date&sort=ASC`;
      }
      if (sortBy.value.time) {
        taskUrl += `&field=time&sort=ASC`;
      }
      if (sortBy.value.store) {
        taskUrl += `&field=store&sort=ASC`;
      }
      if (sortBy.value.practitioner) {
        taskUrl += `&field=practitioner&sort=ASC`;
      }
      if (sortBy.value.taskType) {
        taskUrl += `&field=taskType&sort=ASC`;
      }
      if (sortBy.value.status) {
        taskUrl += `&field=status&sort=ASC`;
      }

      taskUrl += `&page=${pageNumber ? pageNumber : currentPage.value}`;

      fetchTaskList(taskUrl);
    };

    const fetchTaskList = (taskUrl: string) => {
      fetch(taskUrl).then((res) => {
        const paginationData = res?.data?.meta?.pagination;
        currentPage.value = res?.data?.meta?.pagination?.current_page;
        pagination.value = {
          ...paginate(
            paginationData.total,
            paginationData.current_page,
            paginationData.per_page,
            3
          ),
          previous: paginationData.links?.previous,
          next: paginationData.links?.next,
        };

        taskList.value = res.data.data;
      });
    };

    const changePage = (url: string) => {
      if (url) {
        fetchTaskList(url);
      }
    };

    const onPaginationChange = (page: number) => {
      performTaskListSearch(page);
    };

    const sortByChange = (sortBy: any) => {
      sortBy.value = sortBy;
      performTaskListSearch();
    };

    onMounted(() => {
      performTaskListSearch();

      const getStores = () => {
        fetch(`api/v1/getStores`).then((res) => {
          if (res?.data?.data) {
            const storeRes = res.data.data;
            stores.value = storeRes;
          }
        });
      };

      const getEmployee = () => {
        fetch(`api/v1/storeEmployee`).then((res) => {
          const practionerRes = res.data.data;
          practitioners.value = practionerRes;
        });
      };
      const getTaskTypes = () => {
        fetch(`api/v1/getTaskType`).then((res: any) => {
          const taskRes = res.data.data;
          taskTypes.value = taskRes;
        });
      };

      getStores();
      getEmployee();
      getTaskTypes();
    });

    const updateStatus = (data: { taskIds: Array<number>; status: number }) => {
      post("api/v1/tasks/change-status", {
        taskIds: data.taskIds,
        status: data.status,
      })
        .then((res) => {
          if (res.status === 200) {
            window.scrollTo(0, 0);
            toast.success("更新しました", {
              timeout: 2000,
              position: POSITION.BOTTOM_LEFT,
            });
            performTaskListSearch(
              currentPage.value,
              selectedPractitioner.value
            );
          }
        })
        .catch((err) => {
          // toast.error(`${err.response.data?.errors[0]?.title}`, {
          toast.error("行を選択してください", {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT
          });
        });
    };

    const handlePractitonerSearch = (practitioner: any) => {
      filterFromSearch.value = false;
      filterFromTable.value = true;
      selectedPractitioner.value = practitioner !== "ALL" ? practitioner : "";
      performTaskListSearch(1, selectedPractitioner.value);
    };

    return {
      searchFields,
      taskList,
      pagination,
      paginationButton,
      sortData,
      sortBy,
      stores,
      practitioners,
      taskTypes,
      filterFromSearch,
      filterFromTable,
      updateStatus,
      handlePractitonerSearch,
      changePage,
      onPaginationChange,
      //
      handleTaskSearch,
      sortByChange,
    };
  },
});
