
import { defineComponent } from "vue";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "TodayList",
  components: {
    Multiselect,
  },
  data() {
    return {
      taskTypeOptions: [
        {
          value: "南区本院",
          name: "南区本院",
        },
        {
          value: "久我の杜院",
          name: "久我の杜院",
        },
        {
          value: "伏見桃山院",
          name: "伏見桃山院",
        },
      ],
    };
  },
});
