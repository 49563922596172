
import { defineComponent } from "vue";

export default defineComponent({
  name: "IdLinkage",
  components: {},
  data() {
    return {
      gender: "",
    };
  },
});
