
import { defineComponent } from 'vue';
import { reservationStatus, cancellationTypes, reservationRoutes } from '@/core/store/constants';
import { CONSULTATION_CLASSIFICATION, SYMPTOMS, RESERVATION_ROUTE } from '@/core/store/enums';
import moment from 'moment';

moment.locale('ja');

export default defineComponent({
  name: 'ReservationReview',
  props: {
    reservation: {
      required: true
    }
  },
  setup(props) {
    const formatDate = (date: string) => moment.utc(date).format('LL (dd)');

    const formatTime = (time: string) => moment.utc(time, 'HH:mm:s').format('HH:mm');
    const formatRequiredTime = (time: string) => moment.duration(time).asMinutes();

    return {
      formatRequiredTime,
      reservationStatus,
      cancellationTypes,
      reservationRoutes,
      //
      CONSULTATION_CLASSIFICATION,
      SYMPTOMS,
      RESERVATION_ROUTE,
      //
      formatDate,
      formatTime
    };
  }
});
