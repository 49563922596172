
import { defineComponent } from "vue";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "ReservationStoreSelection",
  components: {
    Multiselect,
  },
  data() {
    return {
      visitHistory: "First",
      symptom: "",
      area: "",
      taskTypeOptions: [
        {
          value: "南区本院",
          name: "南区本院",
          icon: require("../assets/icons/check-solid.svg"),
        },
        {
          value: "久我の杜院",
          name: "久我の杜院",
          icon: require("../assets/icons/check-solid.svg"),
        },
        {
          value: "伏見桃山院",
          name: "伏見桃山院",
          icon: require("../assets/icons/check-solid.svg"),
        },
      ],
    };
  },
});
