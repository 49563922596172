
import { defineComponent, ref, onMounted, onUnmounted } from "vue";
import { store } from "@/core/store";
import { useRouter } from "vue-router";
import { fetch, store as post } from "@/core/api/api-service";
import { Notification } from "@/core/interface";

export default defineComponent({
  setup() {
    const router = useRouter();
    let notificationInterval: any = null;
    let notifications: any = ref([]);
    let displayNotification = ref(false);
    let displayUserDropdown = ref(false);
    let user = ref();

    onMounted(() => {
      store.dispatch("getEmployeeProfile").then(() => {
        user.value = store.getters.user;
        setUpNotification();
      });
    });

    onUnmounted(() => {
      // clearNotificationInterval();
    });

    const goToLogin = () => {
      store.dispatch("logout").then(() => {
        // clearNotificationInterval();
        router.push({ name: "Login" });
      });
    };

    const setUpNotification = () => {
      fetchNotifications();
      // notificationInterval = setInterval(() => fetchNotifications(), 5000);
    };

    // const clearNotificationInterval = () => {
    //   notificationInterval && clearInterval(notificationInterval);
    // };

    const getUnreadNotificationCount = (): number => {
      return notifications.value.filter(
        (notification: { readStatus: boolean }) => !notification.readStatus
      ).length;
    };

    const fetchNotifications = () => {
      fetch("/api/v1/notifications", {
        headers: {
          practitionerId: user.value?.id,
        },
      }).then((res) => {
        if (res.status === 200) {
          if (res.data.data && res.data.data.length > 0) {
            notifications.value = res.data.data?.map(
              (notification: Notification) => {
                const { messageStatus, messageDetail } =
                  getNotificationStatusAndMessage(notification.message);
                return {
                  ...notification,
                  messageStatus,
                  messageDetail,
                };
              }
            );
          } else {
            notifications.value = [];
          }
        }
      });
    };

    const markNotificationAsRead = (notification: any) => {
      const formData = new FormData();
      formData.append("_method", "PUT");
      formData.append("read_status", "1");
      post(`/api/v1/notices/${notification.id}`, formData).then((res) => {
        if (res.status === 200) {
          // clearNotificationInterval();
          setUpNotification();
        }
      });
    };

    const getNotificationStatusAndMessage = (
      notificationMessage: string
    ): { messageStatus: string; messageDetail: string } => {
      const messageSplit = notificationMessage.split(/：/);
      return {
        messageStatus: messageSplit?.shift() || "",
        messageDetail: messageSplit?.join("：") || "",
      };
    };

    const notificationClick = () => {
      displayNotification.value = !displayNotification.value;
      if (displayNotification.value) {
        fetchNotifications();
      }
    };

    const displayReservation = async (notification: any) => {
      await store.dispatch(
        "setReservationCalendarStoreId",
        notification.storeId
      );
      await store.dispatch(
        "setReservationCalendarStoreDate",
        notification.reservationDate
      );
      displayNotification.value = false;
      router.push({
        name: "ReservationCalendar",
        query: {
          id: notification.reservationId,
          date: notification.reservationDate,
        },
      });
    };

    return {
      user,
      displayNotification,
      displayReservation,
      displayUserDropdown,
      notifications,
      notificationClick,
      //
      markNotificationAsRead,
      getUnreadNotificationCount,
      goToLogin,
    };
  },
});
