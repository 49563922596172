
import {defineComponent, ref} from 'vue';
// import { Modal as BootstrapModal } from "bootstrap";
import moment from 'moment';
import DatePicker from '../components/DatePicker.vue';
import 'vue3-date-time-picker/dist/main.css';
import Modal from '../components/modals/Modal.vue';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
import i18n from '../i18n';
import {
  CANCELLATION_TYPE,
  CONSULTATION_CLASSIFICATION,
  PUBLIC_STATUS,
  RESERVATION_ROUTE,
  RESERVATION_STATUS,
  RESERVATION_TYPE,
  SAME_PERSON_CONFIRMATION,
  SYMPTOMS,
  TASK_STATUS,
  TIME_FRAME
} from '@/core/store/enums';
import {timeFrame} from '@/core/store/constants';
import {fetch} from '@/core/api/api-service';
import {toFullwidthKana} from 'japanese-string-utils';
import {paginate, remove_duplicates_es6} from '@/core/utils';
import {POSITION, useToast} from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {createTemoraryTransaction} from '@/core/api/transaction-service';

const diffMonths = (lastVisitDate:moment.Moment, reservationDate:moment.Moment) => {
  const last = Number(lastVisitDate.format('YYYY'));
  const reservation = Number(reservationDate.format('YYYY'));
  const diffYYYY = reservation - last;

  const lastM = Number(lastVisitDate.format('M'));
  const reservationM = Number(reservationDate.format('M'));

  // 年の差分が1なら12を足して月の差分を算出
  if (diffYYYY === 1) { return reservationM + 12 - lastM; }
  // 年の差分が0ならそのまま月の差分を算出
  if (diffYYYY === 0) { return reservationM - lastM; }
  // 年の差分が2以上なら固定値3を返す
  return 3
}

const elapsedMonths = (lastVisitDate:moment.Moment, reservationDate:moment.Moment) => {
  // console.log(`lastVisitDate:${lastVisitDate}`);
  // console.log(`reservationDate:${reservationDate}`);

  if(lastVisitDate == null || lastVisitDate == undefined ) return 3;
  if(reservationDate == null || reservationDate == undefined ) return 3;


  const elapsedMonths = diffMonths(lastVisitDate, reservationDate);

  if (elapsedMonths > 2) { return elapsedMonths; }

  if (lastVisitDate.date() <= reservationDate.date()) {
    return elapsedMonths;
  }

  return elapsedMonths > 0 ? elapsedMonths - 1 : elapsedMonths;
}

export default defineComponent({
  name: 'ReservationRegistration',
  components: {
    DatePicker,
    Modal,
    Multiselect,
    Loading
  },
  data() {
    return {
      isCustomerLoading: false,
      disableReservationRoute: false,
      disableReservationandTelephone:
        this.$route.params?.consultation_classification === '1' ||
        this.$route.params?.consultation_classification === undefined
          ? false
          : true,
      disablePractioner: false,
      isToday: false,
      redirect: this.$route.params.redirect || '',
      hasMedicalRecord: this.$route.params.hasMedicalRecord || false,
      isFromSecondBackButton:
        this.$store.getters.getReservationCalendarSecondBackButton === true ? true : false,
      isFromEdit: this.$route.params.isFromEdit || false,
      isLoading: false,
      isSignupButtonDisabled: true,
      TIME_FRAME: TIME_FRAME,
      timeFrame: timeFrame,
      firstInputOptions: ['09:00', '10:00'],
      secondInputOptions: ['09:00', '10:00'],
      customer: null,
      isAccupunture: false,
      isInsurance: false,
      trafficOrIndustrialTreatmentAvailable: false,
      RESERVATION_TYPE: RESERVATION_TYPE,
      RESERVATION_STATUS: RESERVATION_STATUS,
      CONSULTATION_CLASSIFICATION: CONSULTATION_CLASSIFICATION,
      SYMPTOMS: SYMPTOMS,
      SAME_PERSON_CONFIRMATION: SAME_PERSON_CONFIRMATION,
      RESERVATION_ROUTE: RESERVATION_ROUTE,
      CANCELLATION_TYPE: CANCELLATION_TYPE,
      TASK_STATUS: TASK_STATUS,
      PUBLIC_STATUS: PUBLIC_STATUS,
      previousPractitionerId: this.$route.params?.prevPractitionerID || '',
      registration: {
        reservation_id: this.$route.params.id || '',
        date: this.$route.params.date ? this.$route.params.date : moment().format('YYYY-MM-DD'),
        starting_time: this.$route.params.startHour || '',
        ending_time: this.$route.params.endHour || '',
        store_id: this.$route.params.store || '',
        practitioner_id: this.$route.params.practitioner || '',
        reservation_type: Number(this.$route.params?.reservation_type) || RESERVATION_TYPE.RESERVE,
        reservation_status: this.$route.params?.reservation_status
          ? this.$route.params?.reservation_status
          : this.$route.query.type === 'reservation-memo'
          ? RESERVATION_STATUS.NOT_CONFIRMED
          : RESERVATION_STATUS.CONFIRMED,
        customer_number: this.$route.params?.customerNumber || '',
        customerNameField: '',
        reservation_person: this.$route.params.reservation_person || '',
        cancellation_type:
          this.$route.params?.isFromCancel === 'false' ? '' : this.$route.params?.isFromCancel,
        fromTodayRoute: this.$route.params?.isFromToday,
        telephone_number: this.$route.params.phone || '',
        consultation_classification: this.$route.params?.consultation_classification
          ? this.$route.params.consultation_classification
          : this.$route.params.next_time === '1'
          ? CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
          : CONSULTATION_CLASSIFICATION.FIRST_VISIT,
        treatment_menu_id: this.$route.params.treatment_menu_id || '',
        is_blocked: '1',
        symptom: this.$route.params.symptom || SYMPTOMS.DAILY_PAIN,
        management_memo: this.$route.params.management_memo || '',
        same_person_confirmation:
          this.$route.params.same_person_confirmation || SAME_PERSON_CONFIRMATION.CONFIRMED,
        reservation_route: this.$route.params?.reservationRoute
          ? this.$route.params.reservationRoute
          : this.$route.params.next_time === '1'
          ? RESERVATION_ROUTE.AFTER_TREATMENT
          : RESERVATION_ROUTE.TELEPHONE,
        task_type_id: this.$route.params.task_type || '',
        // block: this.$route.params.block || '0',
        block: '1',
        status:
          Number(this.$route.params?.reservation_type) === 2
            ? this.$route.params.status
            : TASK_STATUS.UNFINISHED,
        publicStatus:
          Number(this.$route.params?.reservation_type) === 3
            ? this.$route.params.status
            : PUBLIC_STATUS.CONFIRM,
        detail: this.$route.params?.detail || '',
        absence_type_id: this.$route.params?.absence_type_id || '',
        details: this.$route.params.detail ? this.$route.params.detail : '',
        line_id: this.$route.params?.lineId || ''
      },
      storeOptions: [],
      employeeData: [],
      practitionerOptions: [],
      treatmentApiRes: [],
      treatmentOptions: [{ value: '', name: '', icon: '', color: '' }],
      taskTypeList: [],
      taskTypeOptions: [
        {
          endTime: '',
          icon: '',
          name: '',
          requiredTime: null,
          startTime: '',
          isBlock: false,
          timeZoneDivision: '',
          value: ''
        }
      ],
      errors: [{ title: '', message: '' }],
      holidayTypeOptions: [
        {
          blockEndTime: '',
          blockStartTime: '',
          blockType: null,
          icon: '',
          name: '',
          requiredTime: undefined,
          value: ''
        }
      ],
      showCalendar: false,
      showCustomerList: false,
      // customers: customers,
      customers: [],
      // pagination:{},
      currentPage: ref(),
      pagination: ref(),
      customerSearch: {
        customerNumber: '',
        customerName: '',
        phoneNumber: ''
      }
    };
  },
  mounted() {
    this.getStores();
    this.getTreatment();
    this.getTaskTypes();
    this.getHoliday();
    this.getEmployee();
    this.getTime(this.registration.date);
    this.setStore();
    this.reservationStatusChange(this.registration.reservation_status);
    this.checkToday(this.registration.date);
    // this.checkCustomerSearch();
  },
  unmounted() {
    this.$store.dispatch('setSecondBackButton', false);
    this.$store.dispatch('setSecondBackId', 'undefined');
  },
  methods: {
    // checkCustomerSearch(){
    //   if(this.registration.consultation_classification !== 1){
    //     this.isSignupButtonDisabled = false
    //   }else{
    //     this.isSignupButtonDisabled = true
    //   }
    // },
    changePage(urlData: any) {
      this.isCustomerLoading = true;
      const url = urlData;
      fetch(`${url}`)
        .then((res) => {
          const response = res?.data?.data;
          this.customers = response;
          const paginationData = res?.data?.meta?.pagination;
          this.currentPage = res?.data?.meta?.pagination?.current_page;
          this.pagination = {
            ...paginate(
              paginationData.total,
              paginationData.current_page,
              paginationData.per_page,
              3
            ),
            previous: paginationData.links?.previous,
            next: paginationData.links?.next
          };
          this.isCustomerLoading = false;
        })
        .catch((err) => {
          this.isCustomerLoading = false;
        });
    },
    onPaginationChange(selectedButton: any) {
      this.isCustomerLoading = true;
      const pageNumber = selectedButton ? `&page=${selectedButton}` : '';
      const customerName = this.customerSearch.customerName;
      const customerNumber = this.customerSearch.customerNumber;
      const phoneNumber = this.customerSearch.phoneNumber;
      fetch(
        `api/v1/customers/search?customerName=${customerName}&customerNumber=${customerNumber}&phoneNumber=${phoneNumber}${pageNumber}`
      )
        .then((res) => {
          const response = res?.data?.data;
          this.customers = response;
          this.pagination = res?.data?.meta?.pagination;
          const paginationData = res?.data?.meta?.pagination;
          this.currentPage = res?.data?.meta?.pagination?.current_page;
          this.pagination = {
            ...paginate(
              paginationData.total,
              paginationData.current_page,
              paginationData.per_page,
              3
            ),
            previous: paginationData.links?.previous,
            next: paginationData.links?.next
          };
          this.isCustomerLoading = false;
        })
        .catch((err) => {
          this.isCustomerLoading = false;
        });
    },
    onTreatmentClick() {
      if (
        this.registration.treatment_menu_id &&
        this.registration.reservation_type === RESERVATION_TYPE.RESERVE
      ) {
        const currentTreamtment: any = this.treatmentOptions.filter(
          (prSt: any) => prSt.value == this.registration.treatment_menu_id
        );
        var end = moment(`${this.registration.ending_time}`, 'hh:mm');
        var startTime = moment(`${this.registration.starting_time}`, 'hh:mm');
        var currentTreatmentTime = currentTreamtment?.[0]?.requiredTime;
        var convertingMinutes = currentTreatmentTime?.split(':'); // split it at the colons
        var minutes = +convertingMinutes[0] * 60 + +convertingMinutes[1];
        var duration = moment.duration(end.diff(startTime)).asMinutes();
        if (duration !== Number(minutes)) {
          let endTime = moment(this.registration.starting_time, 'hh:mm').add(
            Number(minutes),
            'minutes'
          );
          if (endTime.isValid()) {
            if (this.registration.starting_time) {
              const filteredFirstTimeExist: any = this.secondInputOptions.filter(
                (trDataTreatment: any) =>
                  moment(trDataTreatment, 'HH:mm').format('HH:mm') ===
                  moment(endTime, 'hh:mm').format('HH:mm')
              );

              if (filteredFirstTimeExist.length > 0) {
                this.registration.ending_time = endTime.format('HH:mm');
              } else {
                this.registration.ending_time = '';
              }
            } else {
              this.registration.ending_time = '';
              this.registration.starting_time = '';
            }
          }
        }
      }
    },
    validateBlankSpace(event: any, fieldType: any) {
      if (event && fieldType == 'reservationRegistration') {
        this.registration.reservation_person = event.target.value.trim();
      }
      if (event && fieldType == 'management_memo') {
        this.registration.management_memo = event.target.value.trim();
        this.registration.management_memo = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'telephone_number') {
        this.registration.telephone_number = event.target.value.trim();
      }
      if (event && fieldType == 'detail') {
        this.registration.detail = event.target.value.trim();
        this.registration.detail = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'details') {
        this.registration.details = event.target.value.trim();
        this.registration.details = toFullwidthKana(`${event.target.value.trim()}`);
      }
    },
    handleDateNumber(evt: any) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      evt.preventDefault();
    },
    handleNumber(evt: any) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 46) {
        evt.preventDefault();
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      } else {
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    },

    setStore() {
      if (this.registration.store_id) {
        this.$store.dispatch('setReservationCalendarStoreId', this.registration.store_id);
      }
    },
    setDate() {
      if (this.registration.date) {
        this.$store.dispatch('setReservationCalendarStoreDate', this.registration.date);
      }
    },
    filterErrors(fieldName: any) {
      if (this.errors.length > 0) {
        const errorMessage = this.errors.filter((x: { title: any }) => x.title === fieldName);
        return errorMessage[0]?.message;
      }
    },
    filterErrorColor(fieldName: any) {
      if (this.errors.length > 0) {
        const errorMessage = this.errors.filter((x: { title: any }) => x.title === fieldName);
        if (errorMessage && errorMessage.length > 0) {
          return true;
        } else if (errorMessage.length === 0) {
          return false;
        }
      }
    },
    async getTime(date?: any) {
      if (this.registration.date && this.registration.store_id) {
        var check = moment(date, 'YYYY/MM/DD');
        var day = check.format('d');
        const storeResponse = await axios(`${process.env.VUE_APP_BASE_API_URL}/api/v1/getStores`, {
          headers: { Authorization: 'Bearer ' + this.$store.getters.token }
        });
        if (storeResponse.status === 200) {
          const storeRes = storeResponse.data.data;
          const filteredStoreById: any = storeRes.filter(
            (store: any) => store.id == this.registration.store_id
          )[0];
          if (
            filteredStoreById &&
            filteredStoreById?.storeBusinessHours &&
            filteredStoreById?.storeBusinessHours.filter(
              (businesshours: any) => businesshours.day == day
            )[0] &&
            filteredStoreById?.timeFrame
          ) {
            const filteredBusinessHour = filteredStoreById?.storeBusinessHours.filter(
              (businesshours: any) => businesshours.day == day
            )[0];

            if (filteredBusinessHour?.isRegularHoliday === true) {
              this.setBusinessTime();
            } else {
              this.setBusinessTime(
                filteredBusinessHour?.openingTime,
                filteredBusinessHour?.closingTime,
                filteredBusinessHour?.morningEndTime,
                filteredBusinessHour?.afternoonStartTime,
                timeFrame[filteredStoreById.timeFrame]
              );
            }
          } else {
            this.setBusinessTime();
          }
        }
      } else {
        this.setBusinessTime();
      }
      this.setDate();
    },
    setBusinessTime(
      startTime = '09:30:00',
      endTime = '21:00:00',
      breakStartTime = '12:00:00',
      breakEndTime = '15:30:00',
      timeFrame = 15
    ) {
      let todayStart = this.setMomentTime(startTime);
      let todayEnd = this.setMomentTime(endTime);
      let todayBreakStart = this.setMomentTime(breakStartTime);
      let todayBreakEnd = this.setMomentTime(breakEndTime);
      let items = [];
      for (
        let time = todayStart;
        time.diff(todayEnd, 'minutes') <= 0;
        time.add(timeFrame, 'minutes')
      ) {
        items.push(time.format('HH:mm'));
        // if (
        //   !time.isBetween(todayBreakStart, todayBreakEnd) ||
        //   (time.hour() === todayBreakEnd.hour() &&
        //     time.minute() === todayBreakEnd.minute())
        // ) {
        //   items.push(time.format("HH:mm"));
        // }
      }
      const secondInput: any = items.filter((item, index) => index !== 0);
      const firstInput: any = items.filter((element, index) => index < items.length - 1);
      if (this.$route.params.startHour) {
        const startSelected = this.$route.params.startHour;
        firstInput.push(`${startSelected}`);
      }
      if (this.$route.params.endHour) {
        const endSelected = this.$route.params.endHour;
        secondInput.push(`${endSelected}`);
      }
      const filteredDuplicateFirst: any = remove_duplicates_es6(firstInput);
      const filteredDuplicateSecond: any = remove_duplicates_es6(secondInput);
      this.firstInputOptions = filteredDuplicateFirst;
      this.secondInputOptions = filteredDuplicateSecond;
      if (this.registration.reservation_type == 1) {
        if (this.$route.params.isFromEdit !== 'true') {
          this.onTreatmentClick();
        }
      } else if (this.registration.reservation_type == 2) {
        if (this.$route.params.isFromEdit !== 'true') {
          this.taskSelectedTimeRequired();
        }
      } else {
        this.absenceSelectedTimeRequired();
      }
    },
    setMomentTime(time: any) {
      return moment().set({
        hour: moment(time, 'HH:mm').get('hour'),
        minute: moment(time, 'HH:mm').get('minute')
      });
    },
    taskSelected(taskId: string) {
      const task: any = this.taskTypeList.filter((task: any) => task.id == taskId)[0];
      this.registration.block = task.isBlock ? '1' : '0';

      this.taskSelectedTimeRequired();
    },
    taskSelectedTimeRequired() {
      if (
        this.registration.task_type_id &&
        this.registration.reservation_type === RESERVATION_TYPE.TASK
      ) {
        const currentTaskType: any = this.taskTypeOptions.filter(
          (prSt: any) => prSt.value == this.registration.task_type_id
        );
        if (currentTaskType) {
          if (currentTaskType[0]?.timeZoneDivision === '2') {
            const firstInput = this.firstInputOptions;
            const secondInput = this.secondInputOptions;

            const filteredFirstTimeExist: any = firstInput.filter(
              (trData: any) =>
                moment(trData).format('HH:mm') ===
                moment(currentTaskType[0]?.startTime).format('HH:mm')
            );
            const filteredSecondTimeExist: any = secondInput.filter(
              (trData1: any) =>
                moment(trData1).format('HH:mm') ===
                moment(currentTaskType[0]?.endTime).format('HH:mm')
            );

            const filteredDuplicateFirst: any = remove_duplicates_es6(firstInput);
            const filteredDuplicateSecond: any = remove_duplicates_es6(secondInput);
            this.firstInputOptions = filteredDuplicateFirst;
            this.secondInputOptions = filteredDuplicateSecond;
            if (filteredFirstTimeExist) {
              if (filteredFirstTimeExist.length > 0) {
                this.registration.starting_time = moment(
                  currentTaskType[0]?.startTime,
                  'hh:mm:ss'
                ).format('HH:mm');
              } else {
                this.registration.starting_time = '';
              }
            }

            if (filteredSecondTimeExist) {
              if (filteredSecondTimeExist.length > 0) {
                this.registration.ending_time = moment(
                  currentTaskType[0]?.endTime,
                  'hh:mm:ss'
                ).format('HH:mm');
              } else {
                this.registration.ending_time = '';
              }
            }
          } else if (currentTaskType[0]?.timeZoneDivision === '3') {
            var end = moment(`${this.registration.ending_time}`, 'hh:mm');
            var startTime = moment(`${this.registration.starting_time}`, 'hh:mm');
            var currentTreatmentTime = currentTaskType?.[0]?.requiredTime;
            var convertingMinutes = currentTreatmentTime?.split(':'); // split it at the colons
            var minutes = +convertingMinutes[0] * 60 + +convertingMinutes[1];
            var duration = moment.duration(end.diff(startTime)).asMinutes();
            if (duration !== Number(minutes)) {
              let endTime = moment(this.registration.starting_time, 'hh:mm').add(
                Number(minutes),
                'minutes'
              );
              if (endTime.isValid()) {
                const firstInput = this.firstInputOptions;
                const secondInput = this.secondInputOptions;

                const filteredFirstTimeExist: any = secondInput.filter(
                  (trData2: any) =>
                    moment(trData2, 'HH:mm').format('HH:mm') ===
                    moment(endTime, 'hh:mm:ss').format('HH:mm')
                );

                // firstInput.push(
                //   `${moment(endTime, "hh:mm:ss").format("HH:mm")}`
                // );
                // secondInput.push(
                //   `${moment(endTime, "hh:mm:ss").format("HH:mm")}`
                // );
                const filteredDuplicateFirst: any = remove_duplicates_es6(firstInput);
                const filteredDuplicateSecond: any = remove_duplicates_es6(secondInput);
                this.firstInputOptions = filteredDuplicateFirst;
                this.secondInputOptions = filteredDuplicateSecond;
                if (filteredDuplicateFirst) {
                  if (filteredFirstTimeExist.length > 0) {
                    this.registration.ending_time = endTime.format('HH:mm');
                  } else {
                    this.registration.ending_time = '';
                  }
                }
              }
            }
          } else if (currentTaskType[0]?.timeZoneDivision === '1') {
            const firstInput = this.firstInputOptions;
            const secondInput = this.secondInputOptions;

            const filteredFirstTimeExist: any = firstInput.filter(
              (trData: any) =>
                moment(trData).format('HH:mm') ===
                moment(this.firstInputOptions?.[0]).format('HH:mm')
            );
            const filteredSecondTimeExist: any = secondInput.filter(
              (trData4: any) =>
                moment(trData4).format('HH:mm') ===
                moment(this.secondInputOptions[this.secondInputOptions.length - 1]).format('HH:mm')
            );
            if (filteredFirstTimeExist.length > 0) {
              this.registration.starting_time = this.firstInputOptions?.[0];
            } else {
              this.registration.starting_time = '';
            }
            if (filteredSecondTimeExist.length > 0) {
              this.registration.ending_time =
                this.secondInputOptions[this.secondInputOptions.length - 1];
            } else {
              this.registration.ending_time = '';
            }
          } else {
            return false;
          }
        }
      }
    },
    absenceSelectedTimeRequired() {
      if (
        this.registration.absence_type_id &&
        this.registration.reservation_type === RESERVATION_TYPE.PUBLIC_HOLIDAY_AND_OTHERS
      ) {
        const currentTaskType: any = this.holidayTypeOptions.filter(
          (prSt: any) => prSt.value == this.registration.absence_type_id
        );
        if (currentTaskType[0]?.blockType === 3) {
          if (this.registration.starting_time) {
            var end = moment(`${this.registration.ending_time}`, 'hh:mm');
            var startTime = moment(`${this.registration.starting_time}`, 'hh:mm');
            var currentTreatmentTime = currentTaskType?.[0]?.requiredTime;
            var convertingMinutes = currentTreatmentTime?.split(':'); // split it at the colons
            var minutes = +convertingMinutes[0] * 60 + +convertingMinutes[1];
            var duration = moment.duration(end.diff(startTime)).asMinutes();
            if (duration !== Number(minutes)) {
              let endTime = moment(this.registration.starting_time, 'hh:mm').add(
                Number(minutes),
                'minutes'
              );
              if (endTime.isValid()) {
                const filteredFirstTimeExist = this.secondInputOptions.filter(
                  (trData4: any) =>
                    moment(trData4, 'HH:mm').format('HH:mm') ===
                    moment(endTime, 'hh:mm').format('HH:mm')
                );

                if (filteredFirstTimeExist.length > 0) {
                  this.isFromEdit
                    ? (this.registration.ending_time = this.$route.params.endHour)
                    : (this.registration.ending_time = endTime.format('HH:mm'));
                } else {
                  this.registration.ending_time = '';
                }
              }
            }
          } else {
            this.registration.starting_time = '';
            this.registration.ending_time = '';
          }
        } else if (currentTaskType[0]?.blockType === 1) {
          const firstInput = this.firstInputOptions;
          const secondInput = this.secondInputOptions;

          const filteredFirstTimeExist: any = firstInput.filter(
            (trData: any) =>
              moment(trData).format('HH:mm') === moment(this.firstInputOptions?.[0]).format('HH:mm')
          );
          const filteredSecondTimeExist: any = secondInput.filter(
            (trData: any) =>
              moment(trData).format('HH:mm') ===
              moment(this.secondInputOptions[this.secondInputOptions.length - 1]).format('HH:mm')
          );

          if (filteredFirstTimeExist.length > 0) {
            this.isFromEdit
              ? (this.registration.starting_time = this.$route.params.startHour)
              : (this.registration.starting_time = this.firstInputOptions?.[0]);
          } else {
            this.registration.starting_time = '';
          }
          if (filteredSecondTimeExist.length > 0) {
            this.isFromEdit
              ? (this.registration.ending_time = this.$route.params.endHour)
              : (this.registration.ending_time =
                  this.secondInputOptions[this.secondInputOptions.length - 1]);
          } else {
            this.registration.ending_time = '';
          }
        } else {
          const filteredFirstTimeExist: any = this.firstInputOptions.filter(
            (trData1: any) =>
              moment(trData1, 'HH:mm').format('HH:mm') ===
              moment(currentTaskType[0]?.blockStartTime, 'hh:mm:ss').format('HH:mm')
          );
          const filteredSecondTimeExist: any = this.secondInputOptions.filter(
            (trData: any) =>
              moment(trData, 'HH:mm').format('HH:mm') ===
              moment(currentTaskType[0]?.blockEndTime, 'hh:mm:ss').format('HH:mm')
          );
          if (filteredFirstTimeExist.length > 0) {
            this.isFromEdit
              ? (this.registration.starting_time = this.$route.params.startHour)
              : (this.registration.starting_time = moment(
                  currentTaskType[0]?.blockStartTime,
                  'hh:mm:ss'
                ).format('HH:mm'));
          } else {
            this.registration.starting_time = '';
          }
          if (filteredSecondTimeExist.length > 0) {
            this.isFromEdit
              ? (this.registration.ending_time = this.$route.params.endHour)
              : (this.registration.ending_time = moment(
                  currentTaskType[0]?.blockEndTime,
                  'hh:mm:ss'
                ).format('HH:mm'));
          } else {
            this.registration.ending_time = '';
          }
        }
      }
    },
    getStores() {
      fetch(`api/v1/getStores`).then((res) => {
        if (res) {
          const storeRes = res.data.data;
          this.storeOptions = storeRes
            .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            .map((store: any) => {
              return {
                value: store.id,
                name: store.storeName,
                icon: require('../assets/icons/check-solid.svg'),
                storeBusinessHours: store.storeBusinessHours,
                timeFrame: store.timeFrame
              };
            });
        }
      });
    },
    getEmployee() {
      this.practitionerOptions = [];
      const calendarDate: any =
        this.registration.date === undefined || this.registration.date === 'undefined'
          ? moment().format('YYYY-MM-DD')
          : this.registration.date;
      let reservationUrl = `?date=${calendarDate}`;
      fetch(`api/v1/storeEmployee${reservationUrl}`)
        // fetch(`api/v1/storeEmployee`)
        .then((res) => {
          const practionerRes = res.data.data;
          this.employeeData = practionerRes;
          return practionerRes;
        })
        .then((employeeData: any) => {
          // if (this.registration.store_id) {
          const filteredPractitionerWithStoreId: any = employeeData.filter(
            (prSt: any) => prSt.storeId == this.registration.store_id
          );
          let resourcesFiltered = [];
          const calendarTime = this.registration.date;
          resourcesFiltered = filteredPractitionerWithStoreId?.[0].employeeInfo.filter(
            (emp: any) =>
              moment(`${emp?.employeeEndDate}`).isSameOrAfter(`${calendarTime}`, 'day') &&
              moment(`${emp?.employeeStartDate}`).isSameOrBefore(`${calendarTime}`, 'day')
          );
          if (resourcesFiltered.length > 0) {
            let resources = resourcesFiltered.map((employeeData: any, index: number) => {
              return {
                value: employeeData?.employeeId,
                name: employeeData?.employeeName,
                icon: require('../assets/icons/check-solid.svg'),
                displayOrder: employeeData?.displayOrder
              };
            });
            const sortedResources = resources.sort((a: any, b: any) => {
              return a.displayOrder > b.displayOrder ? 0 : a.displayOrder < b.displayOrder ? -1 : 1;
            });

            this.practitionerOptions = sortedResources;
          } else {
            this.practitionerOptions = [];
          }
          // }
        });
    },
    getTreatment() {
      fetch(`api/v1/getTreatmentMenu`)
        .then((res) => {
          const TreatRes = res.data.data;

          this.treatmentApiRes = TreatRes;
        })
        .then(() => {
          if (this.registration.consultation_classification) {
            this.consultationChange(this.registration.consultation_classification);
          }
        });
    },
    getTaskTypes() {
      fetch(`api/v1/getTaskType`).then((res: any) => {
        const taskRes = res.data.data;
        this.taskTypeList = taskRes;
        this.taskTypeOptions = taskRes

          .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
          .map((task: any) => {
            return {
              value: task?.id,
              name: task?.name,
              icon: require('../assets/icons/check-solid.svg'),
              requiredTime: task?.timeRequired,
              isBlock: task?.isBlock,
              timeZoneDivision: task?.timeZoneDivision,
              startTime: task?.startTime,
              endTime: task?.endTime
            };
          });
      });
    },
    getHoliday() {
      fetch(`api/v1/getAbsenceType`).then((res: any) => {
        const holidayRes = res.data.data;
        this.holidayTypeOptions = holidayRes
          .sort((a: any, b: any) => a.order - b.order)
          .map((holiday: any) => {
            return {
              value: holiday.id,
              name: holiday.name,
              icon: require('../assets/icons/check-solid.svg'),
              requiredTime: holiday?.requiredTime,
              blockType: holiday?.blockType,
              blockStartTime: holiday?.blockStartTime,
              blockEndTime: holiday?.blockEndTime
            };
          });
      });
    },
    searchCustomer(customerNumber = '', customerName = '', phoneNumber = '') {
      this.isCustomerLoading = true;
      const page = `&page=${1}`;
      fetch(
        `api/v1/customers/search?customerName=${customerName}&customerNumber=${customerNumber}&phoneNumber=${phoneNumber}${page}`
      )
        .then((response) => {
          const customerRes = response.data.data;
          const paginationRes = response.data.meta?.pagination;
          this.customers = customerRes;
          const paginationData = response?.data?.meta?.pagination;
          this.currentPage = response?.data?.meta?.pagination?.current_page;
          this.pagination = {
            ...paginate(
              paginationData.total,
              paginationData.current_page,
              paginationData.per_page,
              3
            ),
            previous: paginationData.links?.previous,
            next: paginationData.links?.next
          };
          this.isCustomerLoading = false;
        })
        .catch((err) => (this.isCustomerLoading = false));
    },
    onCancel() {
      this.$router.back();
    },
    goToReservationSecondBackDetails(e: any) {
      const toast = useToast();

      this.errors = [];
      if (!this.registration.date) {
        this.errors.push({
          title: 'date',
          message: `${i18n.global.t('Errors.E061')}`
        });
      }

      if (!this.registration.starting_time) {
        this.errors.push({
          title: 'starting_time',
          message: `${i18n.global.t('Errors.E061')}`
        });
      }
      if (!this.registration.ending_time) {
        this.errors.push({
          title: 'ending_time',
          message: `${i18n.global.t('Errors.E061')}`
        });
      }
      if (!this.registration.store_id) {
        this.errors.push({
          title: 'store_id',
          message: `${i18n.global.t('Errors.E061')}`
        });
      }

      if (this.registration.reservation_type === 1) {
        if (this.registration.starting_time && this.registration.ending_time) {
          let startingTime1 = this.registration.starting_time;
          let endingTime2 = this.registration.ending_time;
          if (moment(`${startingTime1}`, 'HH:mm').isAfter(moment(`${endingTime2}`, 'HH:mm'))) {
            this.errors.push({
              title: 'ending_time',
              message: '終了時刻が無効'
            });
            window.scrollTo(0, 0);
          }
        }

        let reservationStatusCheck = this.registration.reservation_status;
        reservationStatusCheck = reservationStatusCheck.toString();
        if (!this.registration.practitioner_id && reservationStatusCheck !== '1') {
          this.errors.push({
            title: 'practitioner_id',
            message: `${i18n.global.t('Errors.E061')}`
          });
          window.scrollTo(0, 0);
        }
        if (
          !this.registration.customer_number &&
          Number(this.registration.consultation_classification) !== 1
        ) {
          this.errors.push({
            title: 'customerNameField',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "お客様番号は必須です",
          });
          window.scrollTo(0, 0);
        }
        if (!this.registration.reservation_person) {
          this.errors.push({
            title: 'reservation_person',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "予約者は必須です",
          });
        }

        if (!this.registration.telephone_number) {
          this.errors.push({
            title: 'telephone_number',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "電話番号は必須です",
          });
        }
        if (this.registration.telephone_number) {
          const getFirstCharacterInNumber = this.registration.telephone_number;
          var numberPattern1 = /^[0-9]+$/g;
          const getValue1: any = this.registration.telephone_number;
          const test: any = numberPattern1.test(getValue1);

          if (test === true) {
            const day = getFirstCharacterInNumber.toString().charAt(0) === '0' ? true : false;
            if (day === true) {
              switch (day === true) {
                case day === true && getFirstCharacterInNumber.length === 10:
                  break;
                case day === true && getFirstCharacterInNumber.length === 11:
                  break;
                default:
                  this.errors.push({
                    title: 'telephone_number',
                    message: `${i18n.global.t('Errors.E060')}`
                  });
              }
            }

            if (getFirstCharacterInNumber.toString().charAt(0) !== '0') {
              this.errors.push({
                title: 'telephone_number',
                message: `形式違い`
              });
            }
          } else {
            this.errors.push({
              title: 'telephone_number',
              message: `${i18n.global.t('Errors.E063')}`
            });
          }
        }

        if (this.registration.management_memo && this.registration.management_memo.length > 1000) {
          this.errors.push({
            title: 'management_memo',
            message: '管理メモは登録できる桁数を超えています'
          });
        }
        if (
          !this.registration.treatment_menu_id &&
          this.registration.consultation_classification !== 4
        ) {
          this.errors.push({
            title: 'treatment_menu_id',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "施術メニューは必須です",
          });
        }
        if (this.registration.reservation_person.length > 255) {
          this.errors.push({
            title: 'reservation_person',
            message: '予約者は登録できる桁数を超えています'
          });
        }

        if (this.errors.length === 0) {
          const getCustomerNumber = this.registration.customer_number.toString();
          let getCustomerID: any;
          if (
            this.$store.getters.reservationCalendarSecondBackId !== undefined ||
            this.$store.getters.reservationCalendarSecondBackId !== 'undefined'
          ) {
            getCustomerID = this.$store.getters.reservationCalendarSecondBackId;
          }
          // const getCustomerStoreID = this.registration.store_id.toString();
          const getCustomerStoreID = this.$store.getters?.user?.currentAssignment?.[0]?.storeId; //sending storeId of current user
          const getCustomerPractionerID = this.previousPractitionerId.toString();

          let urlRegistrationType1 = this.registration.reservation_id
            ? `reservations/${this.registration.reservation_id}`
            : `reservations`;
          this.isLoading = true;
          this.postAPICall(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/${urlRegistrationType1}`,
            this.registration.reservation_type
          )
            .then((response: any) => {
              createTemoraryTransaction(
                getCustomerNumber,
                getCustomerStoreID,
                'reservation',
                getCustomerID,
                getCustomerPractionerID
              )
                .then((res) => {
                  this.isLoading = false;
                  // Added record to temporary sales = 仮売上高にレコードを追加
                  toast.success('レジにデータ送信完了', {
                    //Added record to temporary sales
                    timeout: 3000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                })
                .catch((err) => {
                  toast.error(`${err.response.data?.errors?.[0]?.title}`, {
                    timeout: 2000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                  this.isLoading = false;
                });
              window.scrollTo(0, 0);
              this.isLoading = false;
              if (this.registration.reservation_id) {
                toast.success('更新しました.', {
                  timeout: 2000,
                  position: POSITION.BOTTOM_RIGHT
                });
              } else {
                toast.success('登録しました', {
                  timeout: 2000,
                  position: POSITION.BOTTOM_RIGHT
                });
              }
              if (this.redirect == 'ReservationDetail') {
                this.$router.push({
                  name: 'ReservationDetail',
                  params: {
                    id: this.registration?.reservation_id
                  }
                });
              } else {
                this.$router.push({ name: 'ReservationCalendar' });
              }
            })
            .catch((err) => {
              this.isLoading = false;
              if (err.response?.status === 422) {
                const errorsResponse = err.response.data?.errors;
                let errorsHere: any = [];
                errorsResponse.forEach((errorItems: any, errorIndex: any) => {
                  errorsHere.push({
                    title: errorItems.source[0].split('":"')?.[1],
                    message: errorItems.detail
                  });
                });
                if (errorsResponse.length > 0) {
                  this.errors = errorsHere;
                }
              } else {
                this.errors = [
                  {
                    title: 'form',
                    message: err.response.data?.errors?.[0]?.title
                  }
                ];
              }
            });
        }
      }
    },
    goToReservationDetails(e: any) {
      // this.isLoading = true;
      const toast = useToast();

      this.errors = [];
      if (!this.registration.date) {
        this.errors.push({
          title: 'date',
          // message: "日付は必須です",
          message: `${i18n.global.t('Errors.E061')}`
        });
        window.scrollTo(0, 0);
      }

      if (!this.registration.starting_time) {
        this.errors.push({
          title: 'starting_time',
          message: `${i18n.global.t('Errors.E061')}`
          // message: "開始は必須です",
        });
        window.scrollTo(0, 0);
      }
      if (!this.registration.ending_time) {
        this.errors.push({
          title: 'ending_time',
          // message: "終了は必須です",
          message: `${i18n.global.t('Errors.E061')}`
        });
        window.scrollTo(0, 0);
      }
      if (!this.registration.store_id) {
        this.errors.push({
          title: 'store_id',
          // message: "店舗は必須です",
          message: `${i18n.global.t('Errors.E061')}`
        });
        window.scrollTo(0, 0);
      }

      // if (this.registration.telephone_number) {
      //   if (!Number(this.registration.telephone_number)) {
      //     this.errors.push({
      //       title: "telephone_number",
      //       message: `${i18n.global.t("Errors.E063")}`,
      //     });
      //   }
      // }

      if (this.registration.reservation_type === 1) {
        if (this.registration.starting_time && this.registration.ending_time) {
          let startingTime1 = this.registration.starting_time;
          let endingTime2 = this.registration.ending_time;
          if (moment(`${startingTime1}`, 'HH:mm').isAfter(moment(`${endingTime2}`, 'HH:mm'))) {
            this.errors.push({
              title: 'ending_time',
              message: '終了時刻が無効'
            });
            window.scrollTo(0, 0);
          }
        }

        let reservationStatusCheck = this.registration.reservation_status;
        reservationStatusCheck = reservationStatusCheck.toString();
        if (
          !this.registration.practitioner_id &&
          reservationStatusCheck !== '1' &&
          !this.$route.params?.isFromCancel
        ) {
          this.errors.push({
            title: 'practitioner_id',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "施術者は必須です",
          });
          window.scrollTo(0, 0);
        }
        if (
          !this.registration.customer_number &&
          Number(this.registration.consultation_classification) !== 1
        ) {
          this.errors.push({
            title: 'customerNameField',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "お客様番号は必須です",
          });
        }
        if (!this.registration.reservation_person) {
          this.errors.push({
            title: 'reservation_person',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "予約者は必須です",
          });
        }

        if (!this.registration.telephone_number) {
          this.errors.push({
            title: 'telephone_number',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "電話番号は必須です",
          });
        }
        if (this.registration.telephone_number) {
          const getFirstCharacterInNumber = this.registration.telephone_number;
          var numberPattern1 = /^[0-9]+$/g;
          const getValue1: any = this.registration.telephone_number;
          const test: any = numberPattern1.test(getValue1);

          if (test === true) {
            const day = getFirstCharacterInNumber.toString().charAt(0) === '0' ? true : false;
            if (day === true) {
              switch (day === true) {
                case day === true && getFirstCharacterInNumber.length === 10:
                  break;
                case day === true && getFirstCharacterInNumber.length === 11:
                  // this.errors.push({
                  //   title: "telephone_number",
                  //   message: `${i18n.global.t("Errors.E060")}`,
                  // });
                  break;
                default:
                  this.errors.push({
                    title: 'telephone_number',
                    message: `${i18n.global.t('Errors.E060')}`
                  });
              }
            }

            //  if (getFirstCharacterInNumber.toString().charAt(0) === "0" && getFirstCharacterInNumber.length === 10 ) {
            // return false
            //  }
            //  else if ( getFirstCharacterInNumber.toString().charAt(0) === "0" && getFirstCharacterInNumber.length === 11 ) {
            //    return false
            //  }else if ( getFirstCharacterInNumber.toString().charAt(0) !== "0") {
            //    this.errors.push({
            //      title: "telephone_number",
            //      message: `形式違い`,
            //    });
            //  }
            //  else{
            //    this.errors.push({
            //      title: "telephone_number",
            //      message: `${i18n.global.t("Errors.E060")}`,
            //    });
            //
            //  }

            // if (getFirstCharacterInNumber.toString().charAt(0) !== "0") {
            //   this.errors.push({
            //     title: "telephone_number",
            //     message: `形式違い`,
            //   });
            // }
            if (getFirstCharacterInNumber.toString().charAt(0) !== '0') {
              this.errors.push({
                title: 'telephone_number',
                message: `形式違い`
              });
            }
          } else {
            this.errors.push({
              title: 'telephone_number',
              message: `${i18n.global.t('Errors.E063')}`
            });
          }
        }

        if (this.registration.management_memo && this.registration.management_memo.length > 1000) {
          this.errors.push({
            title: 'management_memo',
            message: '管理メモは登録できる桁数を超えています'
          });
        }
        if (
          !this.registration.treatment_menu_id &&
          this.registration.consultation_classification !== 4
        ) {
          this.errors.push({
            title: 'treatment_menu_id',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "施術メニューは必須です",
          });
        }
        if (this.registration.reservation_person.length > 255) {
          this.errors.push({
            title: 'reservation_person',
            message: '予約者は登録できる桁数を超えています'
          });
        }

        if (this.errors.length === 0) {
          let urlRegistrationType1 = this.registration.reservation_id
            ? `reservations/${this.registration.reservation_id}`
            : `reservations`;
          this.isLoading = true;
          const res = this.postAPICall(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/${urlRegistrationType1}`,
            this.registration.reservation_type
          )
            .then(async (response) => {
              if (this.errors.length === 0) {
                window.scrollTo(0, 0);
                if (
                  this.registration.consultation_classification ===
                  CONSULTATION_CLASSIFICATION.RE_EXAMINATION
                ) {
                  this.isLoading = true;
                  let formData = new FormData();
                  formData.append('_method', 'PUT');
                  formData.append('no_of_hospital_visits', '1');
                  await axios
                    .post(
                      `${process.env.VUE_APP_BASE_API_URL}/api/v1/customers/${this.registration.customer_number}`,
                      formData,
                      {
                        headers: {
                          Authorization: 'Bearer ' + this.$store.getters.token
                        }
                      }
                    )
                    .then((response) => {
                      this.isLoading = false;
                      // return response.data.message;
                      // console.log(response.data.message);
                    })
                    .catch((err) => {
                      this.isLoading = false;
                      // console.log(err);
                    });
                    //.finally(() => (this.isLoading = false));
                }
                this.isLoading = false;
                if (this.registration.reservation_id) {
                  toast.success('更新しました.', {
                    timeout: 2000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                } else {
                  toast.success('登録しました', {
                    timeout: 2000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                }
                if (this.redirect == 'ReservationDetail') {
                  this.$router.push({
                    name: 'ReservationDetail',
                    params: {
                      id: this.registration?.reservation_id
                    }
                  });
                } else {
                  if (
                    this.registration.cancellation_type &&
                    this.registration.fromTodayRoute === 'true'
                  ) {
                    this.$router.push({
                      path: 'reservation-list?type=today',
                      query: { type: 'today' }
                    });
                  } else if (
                    this.registration.cancellation_type &&
                    this.registration.fromTodayRoute == 'false'
                  ) {
                    this.$router.push({ path: 'reservation-list' });
                  } else {
                    this.$router.push({ name: 'ReservationCalendar' });
                  }
                }
              }
            })
            .catch((err) => {
              this.isLoading = false;
              if (err.response?.status === 422) {
                const errorsResponse = err.response.data?.errors;
                let errorsHere: any = [];
                errorsResponse.forEach((errorItems: any, errorIndex: any) => {
                  errorsHere.push({
                    title: errorItems.source[0].split('":"')?.[1],
                    message: errorItems.detail
                  });
                });
                if (errorsResponse.length > 0) {
                  this.errors = errorsHere;
                }
              } else {
                this.errors = [
                  {
                    title: 'form',
                    message: err.response.data?.errors?.[0]?.title
                  }
                ];
              }
            });
        }
      } else if (this.registration.reservation_type === 2) {
        // this.taskSelectedTimeRequired();
        if (this.registration.starting_time && this.registration.ending_time) {
          let startingTime1 = this.registration.starting_time;
          let endingTime2 = this.registration.ending_time;
          if (moment(`${startingTime1}`, 'HH:mm').isAfter(moment(`${endingTime2}`, 'HH:mm'))) {
            this.errors.push({
              title: 'ending_time',
              message: '終了時刻が無効'
            });
            window.scrollTo(0, 0);
          }
        }
        if (!this.registration.practitioner_id) {
          this.errors.push({
            title: 'practitioner_id',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "施術者は必須です",
          });
          window.scrollTo(0, 0);
        }
        if (this.registration.detail && this.registration.detail.length > 1000) {
          this.errors.push({
            title: 'detail',
            message: '詳細は登録できる桁数を超えています'
          });
        }
        if (!this.registration.task_type_id) {
          this.errors.push({
            title: 'task_type_id',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "タスク種別は必須です",
          });
        }
        if (this.errors.length === 0) {
          let urlRegistrationType2 = this.registration.reservation_id
            ? `tasks/${this.registration.reservation_id}`
            : `tasks`;
          this.isLoading = true;
          const res = this.postAPICall(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/${urlRegistrationType2}`,
            this.registration.reservation_type
          )
            .then((response) => {
              this.isLoading = false;
              if (this.errors.length === 0) {
                if (this.registration.reservation_id) {
                  toast.success('更新しました.', {
                    timeout: 2000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                } else {
                  toast.success('登録しました.', {
                    timeout: 2000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                }
                window.scrollTo(0, 0);
                if (this.redirect == 'ReservationDetail') {
                  this.$router.push({
                    name: 'ReservationDetail',
                    params: {
                      id: this.registration?.reservation_id
                    }
                  });
                } else {
                  if (
                    this.registration.cancellation_type &&
                    this.registration.fromTodayRoute === 'true'
                  ) {
                    this.$router.push({
                      path: 'reservation-list?type=today',
                      query: { type: 'today' }
                    });
                  } else if (
                    this.registration.cancellation_type &&
                    this.registration.fromTodayRoute == 'false'
                  ) {
                    this.$router.push({ path: 'reservation-list' });
                  } else {
                    this.$router.push({ name: 'ReservationCalendar' });
                  }
                }
              }
            })
            .catch((err) => {
              this.isLoading = false;
              if (err.response?.status === 422) {
                const errorsResponse = err.response.data?.errors;
                let errorsHere: any = [];
                errorsResponse.forEach((errorItems: any, errorIndex: any) => {
                  errorsHere.push({
                    title: errorItems.source[0].split('":"')?.[1],
                    message: errorItems.detail
                  });
                });
                if (errorsResponse.length > 0) {
                  this.errors = errorsHere;
                }
              } else {
                this.errors = [
                  {
                    title: 'form',
                    message: err.response.data?.errors?.[0]?.title
                  }
                ];
              }
            });
        }
      } else {
        if (this.registration.starting_time && this.registration.ending_time) {
          let startingTime1 = this.registration.starting_time;
          let endingTime2 = this.registration.ending_time;
          if (moment(`${startingTime1}`, 'HH:mm').isAfter(moment(`${endingTime2}`, 'HH:mm'))) {
            this.errors.push({
              title: 'ending_time',
              message: '終了時刻が無効'
            });
            window.scrollTo(0, 0);
          }
        }

        if (!this.registration.practitioner_id) {
          this.errors.push({
            title: 'practitioner_id',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "施術者は必須です",
          });
          window.scrollTo(0, 0);
        }
        if (!this.registration.absence_type_id) {
          this.errors.push({
            title: 'absence_type_id',
            message: `${i18n.global.t('Errors.E061')}`
            // message: "公休種別は必須です",
          });
        }
        if (this.registration.detail && this.registration.detail.length > 30000) {
          this.errors.push({
            title: 'detail',
            message: '詳細は登録できる桁数を超えています'
          });
        }
        if (this.errors.length === 0) {
          let urlRegistrationType3 = this.registration.reservation_id
            ? `public-holidays/${this.registration.reservation_id}`
            : `public-holidays`;
          this.isLoading = true;
          const res = this.postAPICall(
            `${process.env.VUE_APP_BASE_API_URL}/api/v1/${urlRegistrationType3}`,
            this.registration.reservation_type
          )
            .then((response) => {
              this.isLoading = false;
              if (this.errors.length === 0) {
                if (this.registration.reservation_id) {
                  toast.success('更新しました', {
                    timeout: 2000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                } else {
                  toast.success('登録しました', {
                    timeout: 2000,
                    position: POSITION.BOTTOM_RIGHT
                  });
                }

                window.scrollTo(0, 0);
                if (this.redirect == 'ReservationDetail') {
                  this.$router.push({
                    name: 'ReservationDetail',
                    params: {
                      id: this.registration?.reservation_id
                    }
                  });
                } else {
                  if (
                    this.registration.cancellation_type &&
                    this.registration.fromTodayRoute === 'true'
                  ) {
                    this.$router.push({
                      path: 'reservation-list?type=today',
                      query: { type: 'today' }
                    });
                  } else if (
                    this.registration.cancellation_type &&
                    this.registration.fromTodayRoute == 'false'
                  ) {
                    this.$router.push({ path: 'reservation-list' });
                  } else {
                    this.$router.push({ name: 'ReservationCalendar' });
                  }
                }
              }
            })
            .catch((err) => {
              this.isLoading = false;
              if (err.response?.status === 422) {
                const errorsResponse = err.response.data?.errors;
                let errorsHere: any = [];
                errorsResponse.forEach((errorItems: any, errorIndex: any) => {
                  errorsHere.push({
                    title: errorItems.source[0].split('":"')?.[1],
                    message: errorItems.detail
                  });
                });
                if (errorsResponse.length > 0) {
                  this.errors = errorsHere;
                }
              } else {
                this.errors = [
                  {
                    title: 'form',
                    message: err.response.data?.errors?.[0]?.title
                  }
                ];
              }
            });
        }
      }
    },
    async postAPICall(url: string, type: number) {
      let formData: any = this.registration;
      if (this.registration.reservation_id) {
        formData._method = 'PUT';
      }
      if (this.registration?.reservation_person) {
        formData.reservation_person = toFullwidthKana(`${this.registration?.reservation_person}`);
      }

      if (Number(this.registration.reservation_type) === 3) {
        formData.status = this.registration.publicStatus;
      }
      if (Number(this.registration.reservation_type) === 2) {
        formData.status = this.registration.status;
      }
      if (Number(this.registration.reservation_type) === 3) {
        const public1: any = Boolean(this.registration.publicStatus) === true ? 1 : 0;
        formData.publicStatus as any;
        formData.publicStatus = public1;
        formData.status = public1;
      }

      const filteredFirstTimeExist: any = this.firstInputOptions.filter(
        (trData2: any) => trData2 === this.registration?.starting_time
      );

      const filteredSecondTimeExist: any = this.secondInputOptions.filter(
        (trData1: any) => trData1 === this.registration?.ending_time
      );
      if (filteredFirstTimeExist.length === 0) {
        this.registration.starting_time = '';
        this.errors.push({
          title: 'starting_time',
          message: `${i18n.global.t('Errors.E061')}`
          // message: "お客様番号は必須です",
        });
        window.scrollTo(0, 0);
      }
      if (filteredSecondTimeExist.length === 0) {
        this.registration.ending_time = '';
        this.errors.push({
          title: 'ending_time',
          message: `${i18n.global.t('Errors.E061')}`
          // message: "お客様番号は必須です",
        });
      }
      // if (
      //   this.registration.treatment_menu_id &&
      //   this.registration.starting_time &&
      //   this.registration.ending_time &&
      //   this.registration.reservation_type === 1
      // ) {
      //   const currentTreamtment: any = this.treatmentOptions.filter(
      //     (prSt: any) => prSt.value == this.registration.treatment_menu_id
      //   );
      //   var end = moment(`${this.registration.ending_time}`, "hh:mm");
      //   var startTime = moment(`${this.registration.starting_time}`, "hh:mm");
      //   var currentTreatmentTime = currentTreamtment?.[0]?.requiredTime;
      //   var convertingMinutes = currentTreatmentTime.split(":"); // split it at the colons
      //   var minutes = +convertingMinutes[0] * 60 + +convertingMinutes[1];
      //   var duration = moment.duration(end.diff(startTime)).asMinutes();
      //   if (duration !== Number(minutes)) {
      //     let endTime = moment(this.registration.starting_time, "hh:mm").add(
      //       Number(minutes),
      //       "minutes"
      //     );
      //     formData.ending_time = endTime.format("HH:mm");
      //   }
      // }
      if (formData.ending_time) {
        const endTimeSorted: any = this.secondInputOptions.sort(
          (a: any, b: any) => parseInt(a?.split(':')?.[0]) - parseInt(b?.split(':')?.[0])
        );
        let endingTime = formData.ending_time;
        if (
          moment(`${endingTime}`, 'HH:mm').isAfter(
            moment(`${endTimeSorted[endTimeSorted.length - 1]}`, 'HH:mm')
          )
        )
          this.errors.push({
            title: 'ending_time',
            message: '終了時刻が無効'
          });
        window.scrollTo(0, 0);
      }
      // formData.ending_time = this.registration.ending_time as
      //   | string
      //   | string[]
      //   | { hours: number; minutes: number };
      // formData.ending_time = `${("0" + formData.ending_time.hours)
      //   .slice(-2)
      //   .toString()}:${("0" + formData.ending_time.minutes)
      //   .slice(-2)
      //   .toString()}`;
      // formData.starting_time = `${("0" + formData.starting_time.hours)
      //   .slice(-2)
      //   .toString()}:${("0" + formData.starting_time.minutes)
      //   .slice(-2)
      //   .toString()}`;
      // if (type === 2) {
      //   this.registration.block = "0";
      // } else {
      //   this.registration.block = "1";
      // }
      if (Number(this.registration.reservation_status) === 1) {
        formData.determine_the_time_of_day = null;
      } else {
        if (Number(this.registration.reservation_status) !== 3) {
          formData.determine_the_time_of_day = moment().format('YYYY-MM-DD HH:mm:ss');
        }
      }
      if (this.errors.length === 0) {
        return await axios.post(url, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$store.getters.token,
            'Content-Type': 'application/json'
          }
        });
      }
    },

    toggleCustomerList(customerInfo: any) {
      if (
        customerInfo.customerName.length <= 0 &&
        customerInfo.customerNumber.length <= 0 &&
        customerInfo.phoneNumber.length <= 0
      ) {
        this.showCustomerList = false;
        const toast = useToast();
        toast.error(`検索条件項目が未入力です`, {
          timeout: 2000,
          position: POSITION.BOTTOM_RIGHT
        });
      } else {
        this.showCustomerList = true;
        this.searchCustomer(
          this.customerSearch.customerNumber,
          this.customerSearch.customerName,
          this.customerSearch.phoneNumber
        );
      }
    },
    nameSelected(customer: any) {
      const toast = useToast();
      if (customer?.lastVisitDate) {
        // const momentMonth = moment(this.registration?.date).diff(
        //   `${customer?.lastVisitDate}`,
        //   'month',
        //   true
        // );
        const momentMonth = elapsedMonths(moment(customer?.lastVisitDate), moment(this.registration?.date));
        let conditionMet = false;
        if (
          momentMonth >= 1 &&
          (customer?.baoyeTreatmentIncludes === true ||
            customer?.trafficOrIndustrialTreatmentAvailable === true)
        ) {
          this.registration.consultation_classification = 3;
          conditionMet = true;
        } else if (
          momentMonth < 1 &&
          (customer?.baoyeTreatmentIncludes === true ||
            customer?.trafficOrIndustrialTreatmentAvailable === true)
        ) {
          this.registration.consultation_classification = 2;
          conditionMet = true;
        } else if (
          momentMonth >= 2 &&
          (customer?.baoyeTreatmentIncludes === false ||
            customer?.trafficOrIndustrialTreatmentAvailable === false)
        ) {
          this.registration.consultation_classification = 3;
          conditionMet = true;
        } else if (
          momentMonth < 2 &&
          (customer?.baoyeTreatmentIncludes === false ||
            customer?.trafficOrIndustrialTreatmentAvailable === false)
        ) {
          this.registration.consultation_classification = 2;
          conditionMet = true;
        } else if (
          momentMonth > -2 &&
          (customer?.baoyeTreatmentIncludes === false ||
            customer?.trafficOrIndustrialTreatmentAvailable === false)
        ) {
          this.registration.consultation_classification = 2;
          conditionMet = true;
        } else {
          conditionMet = false;
        }
        if (conditionMet === true) {
          this.treatmentOptions = [];
          this.isAccupunture = customer?.withAcupunture === true ? true : false;
          this.isInsurance = customer?.baoyeTreatmentIncludes === true ? true : false;
          this.trafficOrIndustrialTreatmentAvailable =
            customer?.trafficOrIndustrialTreatmentAvailable === true ? true : false;

          let id: any = null;
          if (
            customer?.baoyeTreatmentIncludes === true &&
            customer?.trafficOrIndustrialTreatmentAvailable === false &&
            customer?.withAcupunture === false
          ) {
            id = 4;
          } else if (
            customer?.trafficOrIndustrialTreatmentAvailable === true &&
            customer?.baoyeTreatmentIncludes === false &&
            customer?.withAcupunture === false
          ) {
            id = 2;
          } else if (
            customer?.withAcupunture === true &&
            customer?.trafficOrIndustrialTreatmentAvailable === false &&
            customer?.baoyeTreatmentIncludes === false
          ) {
            id = 3;
          } else {
            id = 1;
          }
          const filteredTreatment1: any = this.treatmentApiRes.filter(
            (trData: any) =>
              trData.consultationClassification == this.registration.consultation_classification
          );
          if (filteredTreatment1.length > 0) {
            // const filteredTreatment: any = this.treatmentApiRes.filter(
            //     (trData: any) =>
            //         trData.consultationClassification == filteredTreatment1?.[0]?.consultationClassification
            // );
            let resources = filteredTreatment1
              .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
              .map((treatData: any) => {
                return {
                  value: treatData?.id,
                  name: treatData?.name,
                  icon: require('../assets/icons/check-solid.svg'),
                  displayOrder: treatData?.displayOrder,
                  color: treatData.backgroundColor ? treatData.backgroundColor.toLowerCase() : '',
                  consultationClassification: treatData.consultationClassification,
                  requiredTime: treatData?.requiredTime
                };
              });
            const filteredTreatmentWithSelectedId: any = resources.filter(
              (trData: any) => trData.displayOrder == id
            );

            this.treatmentOptions = resources;
            if (filteredTreatmentWithSelectedId.length > 0) {
              this.registration.treatment_menu_id = filteredTreatmentWithSelectedId?.[0]?.value;
              this.onTreatmentClick();
            } else {
              this.registration.treatment_menu_id = resources?.[0]?.value;
              this.onTreatmentClick();
            }
          } else {
            this.registration.treatment_menu_id = '';
            this.treatmentOptions = [];
          }
          toast.success('受診区分を自動で設定しました', {
            timeout: 3000,
            position: POSITION.BOTTOM_RIGHT
          });
        }
      }
      this.registration.symptom = customer?.symptom ? customer.symptom : SYMPTOMS.DAILY_PAIN;
      this.customer = customer;
      if (customer?.surname && customer?.name) {
        this.registration.reservation_person = customer?.surname + ' ' + customer?.name;
      } else {
        this.registration.reservation_person = customer?.surname;
      }
      this.registration.telephone_number = customer?.cellphoneNumber
        ? customer?.cellphoneNumber
        : customer?.telephoneNumber;
      this.registration.customer_number = customer.customerNumber;
      // this.registration.management_memo = customer?.lineId;
      this.registration.line_id = customer?.lineId;
      this.registration.customerNameField = customer.customerNumber;
      this.customerSearch.customerNumber = '';
      this.customerSearch.customerName = '';
      this.customerSearch.phoneNumber = '';
      this.showCustomerList = false;
      (this.$refs.myDiv as any).click();
    },

    taskAndtreatmnetClick() {
      if (Number(this.registration.reservation_type) == 1) {
        this.onTreatmentClick();
      } else if (Number(this.registration.reservation_type) == 2) {
        this.taskSelectedTimeRequired();
      } else {
        this.absenceSelectedTimeRequired();
      }
    },
    storeSelect() {
      this.practitionerOptions = [];
      const calendarDate: any =
        this.registration.date === undefined || this.registration.date === 'undefined'
          ? moment().format('YYYY-MM-DD')
          : this.registration.date;
      let reservationUrl = `?date=${calendarDate}`;
      fetch(`api/v1/storeEmployee${reservationUrl}`)
        // fetch(`api/v1/storeEmployee`)
        .then((res) => {
          const practionerRes = res.data.data;
          this.employeeData = practionerRes;
          return practionerRes;
        })
        .then((employeeData: any) => {
          if (this.registration.store_id) {
            const filteredPractitionerWithStoreId: any = employeeData.filter(
              (prSt: any) => prSt.storeId == this.registration.store_id
            );
            let resourcesFiltered = [];
            const calendarTime = this.registration.date;
            resourcesFiltered = filteredPractitionerWithStoreId?.[0].employeeInfo.filter(
              (emp: any) =>
                moment(`${emp?.employeeEndDate}`).isSameOrAfter(`${calendarTime}`, 'day') &&
                moment(`${emp?.employeeStartDate}`).isSameOrBefore(`${calendarTime}`, 'day')
            );
            if (resourcesFiltered.length > 0) {
              let resources = resourcesFiltered.map((employeeData: any, index: number) => {
                return {
                  value: employeeData?.employeeId,
                  name: employeeData?.employeeName,
                  icon: require('../assets/icons/check-solid.svg'),
                  displayOrder: employeeData?.displayOrder
                };
              });
              const sortedResources = resources.sort((a: any, b: any) => {
                return a.displayOrder > b.displayOrder
                  ? 0
                  : a.displayOrder < b.displayOrder
                  ? -1
                  : 1;
              });
              if (this.practitionerOptions) {
                this.practitionerOptions = [];
                this.registration.practitioner_id = '';
                if (this.registration.store_id) {
                  const employeeData = this.employeeData;
                  const filteredPractitionerWithStoreId: any = employeeData.filter(
                    (prSt: any) => prSt.storeId == this.registration.store_id
                  );
                  let resourcesFiltered = [];
                  const calendarTime = this.registration.date;
                  resourcesFiltered = filteredPractitionerWithStoreId[0].employeeInfo.filter(
                    (emp: any) =>
                      moment(`${emp?.employeeEndDate}`).isSameOrAfter(`${calendarTime}`, 'day') &&
                      moment(`${emp?.employeeStartDate}`).isSameOrBefore(`${calendarTime}`, 'day')
                  );
                  if (resourcesFiltered.length > 0) {
                    let resources = resourcesFiltered.map((employeeData: any, index: number) => {
                      return {
                        value: employeeData?.employeeId,
                        name: employeeData?.employeeName,
                        icon: require('../assets/icons/check-solid.svg'),
                        displayOrder: employeeData.displayOrder
                      };
                    });
                    const sortedResources = resources.sort((a: any, b: any) => {
                      return a.displayOrder > b.displayOrder
                        ? 0
                        : a.displayOrder < b.displayOrder
                        ? -1
                        : 1;
                    });
                    this.practitionerOptions = sortedResources;
                    this.getTime(this.registration.date);
                  } else {
                    this.practitionerOptions = [];
                  }
                }
              }
              // this.practitionerOptions = resources;
            } else {
              this.practitionerOptions = [];
            }
          }
        });
      this.setStore();
    },
    reservationTypeChange(event: any) {
      this.registration.treatment_menu_id = '';
      // this.registration.starting_time = "";
      // this.registration.ending_time = "";
      this.getTime(this.registration.date);
      if (event == 1) {
        this.registration.treatment_menu_id = this.treatmentOptions[0].value;
        this.onTreatmentClick();
        if (event == 1 && this.registration.reservation_status == 1) {
          // this.registration.detail = "";
          this.disablePractioner = true;
        } else {
          // this.registration.detail = "";
          this.disablePractioner = false;
        }
      } else if (event == 2) {
        this.disablePractioner = false;
        let tasktypeOptions = this.taskTypeOptions[0].value?.toString();
        let taskIsBlock = this.taskTypeOptions[0];
        this.registration.task_type_id = tasktypeOptions;
        this.registration.block = taskIsBlock.isBlock ? '1' : '0';
        this.taskSelectedTimeRequired();
      } else {
        this.disablePractioner = false;
        this.registration.absence_type_id = this.holidayTypeOptions[0]?.value;
        this.absenceSelectedTimeRequired();
      }
    },
    reservationStatusChange(event: any) {
      if (event == RESERVATION_STATUS.NOT_CONFIRMED) {
        this.registration.practitioner_id = '';
        this.disablePractioner = true;
      } else {
        this.disablePractioner = false;
      }
    },
    resetValueonClick(event: any) {
      this.registration.treatment_menu_id = '';
      this.$route.params.treatment_menu_id = '';
      this.disableReservationRoute = false;
      this.treatmentOptions = [];
      this.registration.reservation_person = '';
      this.registration.telephone_number = '';
      this.registration.customer_number = '';
      if (event === '1') {
        this.registration.reservation_person = '';
        this.registration.telephone_number = '';
        this.registration.customer_number = '';
        this.registration.customerNameField = '';
        this.disableReservationandTelephone = false;
        const filteredTreatment: any = this.treatmentApiRes.filter(
          (trData: any) => trData.consultationClassification == event
        );
        if (filteredTreatment.length > 0) {
          let resources = filteredTreatment
            .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            .map((treatData: any) => {
              return {
                value: treatData.id,
                name: treatData.name,
                icon: require('../assets/icons/check-solid.svg'),
                color: treatData.backgroundColor ? treatData.backgroundColor.toLowerCase() : '',
                consultationClassification: treatData.consultationClassification,
                requiredTime: treatData?.requiredTime
              };
            });
          this.treatmentOptions = resources;
          this.registration.treatment_menu_id = resources[0]?.value;
          this.onTreatmentClick();
        } else {
          this.registration.treatment_menu_id = '';
          this.treatmentOptions = [];
        }
      } else if (event == '2') {
        this.disableReservationRoute = true;
        this.registration.reservation_route = RESERVATION_ROUTE.AFTER_TREATMENT;
        this.disableReservationandTelephone = true;
        const filteredTreatment: any = this.treatmentApiRes.filter(
          (trData: any) => trData.consultationClassification == event
        );
        if (filteredTreatment.length > 0) {
          let resources = filteredTreatment
            .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            .map((treatData: any) => {
              return {
                value: treatData.id,
                name: treatData.name,
                icon: require('../assets/icons/check-solid.svg'),
                color: treatData.backgroundColor ? treatData.backgroundColor.toLowerCase() : '',
                consultationClassification: treatData.consultationClassification,
                requiredTime: treatData?.requiredTime
              };
            });
          this.treatmentOptions = resources;
          this.registration.treatment_menu_id = resources[0]?.value;
          this.onTreatmentClick();
        } else {
          this.registration.treatment_menu_id = '';
          this.treatmentOptions = [];
        }
      } else {
        this.disableReservationandTelephone = true;
        const filteredTreatment: any = this.treatmentApiRes.filter(
          (trData: any) => trData.consultationClassification == event
        );
        if (filteredTreatment.length > 0) {
          let resources = filteredTreatment
            .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            .map((treatData: any) => {
              return {
                value: treatData.id,
                name: treatData.name,
                icon: require('../assets/icons/check-solid.svg'),
                color: treatData.backgroundColor ? treatData.backgroundColor.toLowerCase() : '',
                consultationClassification: treatData.consultationClassification,
                requiredTime: treatData?.requiredTime
              };
            });
          this.treatmentOptions = resources;
          this.registration.treatment_menu_id = resources[0]?.value;
          this.onTreatmentClick();
        } else {
          this.registration.treatment_menu_id = '';
          this.treatmentOptions = [];
        }
      }
    },
    consultationChange(event: any) {
      this.registration.consultation_classification = event;
      if (event == '2') {
        this.disableReservationRoute = true;
        this.registration.reservation_route = RESERVATION_ROUTE.AFTER_TREATMENT;
      }
      !this.$route.params.treatment_menu_id
        ? (this.registration.treatment_menu_id = '')
        : (this.registration.treatment_menu_id = this.$route.params.treatment_menu_id);
      this.treatmentOptions = [];
      // if (event === "1") {
      //   this.registration.reservation_person = "";
      //   this.registration.telephone_number = "";
      //   this.registration.customer_number = "";
      //   this.registration.customerNameField = "";
      // }

      const filteredTreatment: any = this.treatmentApiRes.filter(
        (trData: any) =>
          trData.consultationClassification == this.registration.consultation_classification
      );
      if (filteredTreatment.length > 0) {
        let resources = filteredTreatment
          .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
          .map((treatData: any) => {
            return {
              value: treatData.id,
              name: treatData.name,
              icon: require('../assets/icons/check-solid.svg'),
              color: treatData.backgroundColor ? treatData.backgroundColor.toLowerCase() : '',
              consultationClassification: treatData.consultationClassification,
              requiredTime: treatData?.requiredTime
            };
          });
        this.treatmentOptions = resources;
        if (!this.$route.params.treatment_menu_id) {
          this.registration.treatment_menu_id = resources[0]?.value;
        }
      } else {
        this.registration.treatment_menu_id = '';
        this.treatmentOptions = [];
      }
    },
    checkToday(date?: string | string[]) {
      if (date === moment().format('YYYY-MM-DD')) {
        this.isToday = true;
      } else {
        this.isToday = false;
      }
    }
  },
  computed: {
    isDisabled() {
      if (Number(this.registration.consultation_classification) === 1) {
        return true;
      } else {
        return false;
      }
    },
    isDisableReservationandTelephone() {
      if (this.disableReservationandTelephone == true) {
        return true;
      } else {
        return false;
      }
    },
    isPractionerDisabled() {
      if (this.disablePractioner == true) {
        return true;
      } else {
        return false;
      }
    },
    isSubmitDisabled() {
      if (this.isLoading === true) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    'registration.date': function (newValue, oldValue) {
      this.checkToday(newValue);
    }
  }
});
