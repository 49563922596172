
import { defineComponent, onMounted, ref } from 'vue';
import { fetch } from '@/core/api/api-service';
import { paginate } from '@/core/utils';
import { useRoute } from 'vue-router';
export default defineComponent({
  name: 'Treatment',
  setup(props) {
    const route = useRoute();
    let selectedSalesTab = ref('1');
    const treatmentData = ref([]);
    let pagination = ref();
    let currentPage = ref();
    let activeIndex = ref(0);
    const customerNumber = ref('');

    onMounted( () => {
      const customerID = (route.params.id as string).split('=?')?.[1];
      customerNumber.value = customerID;
      getSalesData(customerID);
    });
    const getSalesData = (customerNumberFromRoute: any) => {
      const customerNumber = customerNumberFromRoute;
      const page = `&page=${1}`;
      fetch(`api/v1/treatments?customerNumber=${customerNumber}${page}`).then((res) => {
        const treatment = res?.data?.data;
        const paginationData = res?.data?.meta?.pagination;
        currentPage.value = res?.data?.meta?.pagination?.current_page;
        pagination.value = {
          ...paginate(
            paginationData.total,
            paginationData.current_page,
            paginationData.per_page,
            3
          ),
          previous: paginationData.links?.previous,
          next: paginationData.links?.next
        };
        treatmentData.value = treatment;
      });
    };
    const changePage = (urlData: any) => {
      const url = urlData;
      fetch(`${url}`).then((res) => {
        const response = res?.data?.data;
        treatmentData.value = response;
        const paginationData = res?.data?.meta?.pagination;
        currentPage.value = res?.data?.meta?.pagination?.current_page;
        pagination.value = {
          ...paginate(
            paginationData.total,
            paginationData.current_page,
            paginationData.per_page,
            3
          ),
          previous: paginationData.links?.previous,
          next: paginationData.links?.next
        };
      });
    };
    const onPaginationChange = (selectedButton: any) => {
      const urlForCustomer = customerNumber.value;
      const selectedTab = selectedSalesTab.value;
      fetch(`api/v1/treatments?customerNumber=${urlForCustomer}&page=${selectedButton}`).then(
        (res) => {
          const response = res?.data?.data;
          treatmentData.value = response;
          const paginationData = res?.data?.meta?.pagination;
          currentPage.value = res?.data?.meta?.pagination?.current_page;
          pagination.value = {
            ...paginate(
              paginationData.total,
              paginationData.current_page,
              paginationData.per_page,
              3
            ),
            previous: paginationData.links?.previous,
            next: paginationData.links?.next
          };
        }
      );
    };

    return {
      pagination,
      selectedSalesTab,
      treatmentData,
      changePage,
      onPaginationChange
    };
  }
});
