<template>
  <div
    class="alert alert-danger mt-1 display-flex align-items-center"
    role="alert"
  >
    <img src="../../src/assets/icons/error.png" class="icon-warning-sign" />
    <span class="ms-2">
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomError",
  props: {
    errorMessage: {
      required: true,
      type: String,
    },
  },
});
</script>

<style lang="scss" scoped>
.icon-warning-sign {
  max-width: 35px;
}
</style>
