
import { defineComponent, onMounted, ref, watchEffect, watch } from 'vue';
import ReservationListTable from '../components/tables/ReservationListTable.vue';
import BackConfirmationModal from '../components/modals/BackConfirmationModal.vue';
import TodayListSearchB from '../components/search-cards/TodayListSearchB.vue';
import { useRoute, useRouter } from 'vue-router';
import { fetch, store as post } from '@/core/api/api-service';

import {
  CANCELLATION_TYPE,
  CONSULTATION_CLASSIFICATION,
  RESERVATION_TYPE,
  RESERVATION_STATUS,
  SAME_PERSON_CONFIRMATION,
  TASK_STATUS,
  SYMPTOMS,
  RESERVATION_ROUTE,
  TIME_FRAME
} from '@/core/store/enums';
import { reservationStatus } from '@/core/store/constants';
import { store } from '@/core/store';
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { POSITION, useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { getCurrentDivision, paginate } from '@/core/utils';
import * as bootstrap from 'bootstrap';
import check from '@/assets/icons/check-solid.svg';

export default defineComponent({
  name: 'ReservationList',
  components: {
    BackConfirmationModal,
    ReservationListTable,
    TodayListSearchB,
    Loading
  },
  setup: function () {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    let menuName = ref();
    let userData = ref();
    let practionData = ref();
    const selectTask = ref<boolean>(false);
    const fromCustomerPage = ref<boolean>(false);
    let practionerData = ref([]);
    let isReservationLoading = ref(false);
    let customerListData = ref([]);
    let currentPage = ref();
    let pagination = ref();
    let practitionerOptions = ref();
    let activeIndex = ref(0);
    let paginationButton = ref([{ id: 0, buttonName: 1 as any, active: false }]);
    let sortData = ref({ field: 'reservationDateTime', sortBy: 'DESC' });
    let fieldHereSearch = ref({
      reservationStatusMultiple: {
        ACCOUNTED:
          route?.query?.type === 'history' || route?.query?.type === 'today'
            ? true
            : route.params.store
            ? true
            : false,
        CANCEL: route?.query?.type === 'today' ? true : false,
        CONFIRMED:
          route?.query?.type === undefined
            ? true
            : route?.query?.type === 'history' || route?.query?.type === 'today'
            ? true
            : false,
        NOT_CONFIRMED:
          route?.query?.type === undefined
            ? true
            : route?.query?.type === 'history' || route?.query?.type === 'today'
            ? true
            : false,
        RECEPTION:
          route?.query?.type === 'history' || route?.query?.type === 'today' ? true : false,
        TOTAL: route?.query?.type === 'history' || route?.query?.type === 'today' ? true : false
      },
      consultationClassification: '',
      reservationStatus: '',
      practitioner: '',
      treatment_menu_id: '',
      symptom: '',
      medicalRecord: false,
      reservationDate2:
        route?.query?.type === 'today'
          ? moment().format('YYYY-MM-DD')
          : route?.query?.type === 'history'
          ? moment().subtract(1, 'day').format('YYYY-MM-DD')
          : '',
      reservationDate1:
        route?.query?.type === 'today'
          ? moment().format('YYYY-MM-DD')
          : route?.query?.type === 'history'
          ? ''
          : moment().add(1, 'day').format('YYYY-MM-DD'),

      baoyeTreatmentHas: '',
      birthMonth: '',
      customerName: '',
      customerNumber: '',
      lastVisitDate: '',
      noOfHospitalVisitFrom: '',
      noOfHospitalVisiTo: '',
      phoneNumber: '',
      reservationDateFrom: '',
      reservationRegDateTo: '',
      reservationRegDateFrom: '',
      reviewWB: '',
      store: '',
      trafficOrIndustrialTreatmentAvailable: '',
      reservationRoute: 0
    });

    onMounted(async () => {
      menuName.value = route?.query?.type ? route?.query?.type : '';
      checkRoute();
      store.dispatch('setFromCustomer', false);
      await onMountSearch('');
    });

    watch(
      () => route?.query,
      function () {
        menuName.value = route?.query?.type ? route?.query?.type : '';

        if (router && route) {
          onMountSearch('');
        }
      },
      {}
    );

    const onMountSearch = async (data:any) => {
      sortData.value.sortBy = route?.query?.type !== 'history' ? 'ASC' : 'DESC';
      isReservationLoading.value = true;
      if(data === 'noReload'){
        setTimeout(() =>{
          isReservationLoading.value = false;
        }, 1000)
        return;
      }

      fetch(`api/v1/storeEmployee`)
        .then((res) => {
          const practionerRes = res.data.data;
          practionData.value = practionerRes;
          return practionerRes;
        })
        .then(async () => {
          const storeID = route?.params?.store
            ? route?.params?.store
            : store?.getters?.user?.currentAssignment[0]?.storeId === undefined
            ? ''
            : store?.getters?.user?.currentAssignment[0]?.storeId
            ? store?.getters?.user?.currentAssignment[0]?.storeId
            : '';
          if (storeID) {
            const filteredPractitionerWithStoreId: any = practionData.value.filter(
              (prSt: any) => prSt.storeId == storeID
            );

            if (filteredPractitionerWithStoreId.length > 0) {
              let resources = filteredPractitionerWithStoreId[0].employeeInfo.map(
                (employeeData: any, index: number) => {
                  return {
                    value: employeeData?.employeeId,
                    name: employeeData?.employeeName,
                    icon: check,
                    employeeEndDate: employeeData?.employeeEndDate,
                    employeeStartDate: employeeData?.employeeStartDate
                  };
                }
              );
              const empDateAndTime: any = resources.filter(
                (emp: any) =>
                  moment(`${emp?.employeeEndDate}`).isSameOrAfter(
                    `${moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')}`,
                    'day'
                  ) &&
                  moment(`${emp?.employeeStartDate}`).isSameOrBefore(
                    `${moment(moment().format('YYYY-MM-DD'), 'YYYY-MM-DD')}`,
                    'day'
                  )
              );
              practitionerOptions.value = empDateAndTime;
            } else {
              practitionerOptions.value = [];
            }
          }
          const sortByreservationDate =
            route?.query?.type !== 'history'
              ? `&sort=ASC&field=reservationDateTime`
              : `&sort=DESC&field=reservationDateTime`;
          const reservationDateFrom =
            (await route?.query?.type) === 'today'
              ? `&reservationDateFrom=${moment().format('YYYY-MM-DD')}`
              : route?.query?.type === 'history'
              ? ''
              : `&reservationDateFrom=${moment().add(1, 'day').format('YYYY-MM-DD')}`;
          const reservationDateTo =
            (await route?.query?.type) === 'today'
              ? `&reservationDateTo=${moment().format('YYYY-MM-DD')}`
              : route?.query?.type === 'history'
              ? `&reservationDateTo=${moment().subtract(1, 'day').format('YYYY-MM-DD')}`
              : '';
          // const practitioner = route.params.store
          //   ? ""
          //   : practitionerOptions.value?.[0]?.value
          //   ? `&practitioner=${practitionerOptions.value?.[0]?.value}`
          //   : "";
          fieldHereSearch.value.store = (await route.params.store)
            ? `${route.params.store}`
            : store?.getters?.user?.currentAssignment[0]?.storeId
            ? `${store?.getters?.user?.currentAssignment[0]?.storeId}`
            : '';

          fieldHereSearch.value.reservationStatusMultiple = {
            ACCOUNTED:
              route?.query?.type === 'history' || route?.query?.type === 'today'
                ? true
                : route.params.store
                ? true
                : false,
            CANCEL: route?.query?.type === 'today' ? true : false,
            CONFIRMED:
              route?.query?.type === undefined
                ? true
                : route?.query?.type === 'history' || route?.query?.type === 'today'
                ? true
                : false,
            NOT_CONFIRMED:
              route?.query?.type === undefined
                ? true
                : route?.query?.type === 'history' || route?.query?.type === 'today'
                ? true
                : false,
            RECEPTION:
              route?.query?.type === 'history' || route?.query?.type === 'today' ? true : false,
            TOTAL: route?.query?.type === 'history' || route?.query?.type === 'today' ? true : false
          };
          fieldHereSearch.value.reservationDate2 =
            route?.query?.type === 'today'
              ? moment().format('YYYY-MM-DD')
              : route?.query?.type === 'history'
              ? moment().subtract(1, 'day').format('YYYY-MM-DD')
              : '';
          fieldHereSearch.value.reservationDate1 =
            route?.query?.type === 'today'
              ? moment().format('YYYY-MM-DD')
              : route?.query?.type === 'history'
              ? ''
              : moment().add(1, 'day').format('YYYY-MM-DD');
          const storeUrl = (await route.params.store)
            ? `&store=${route.params.store}`
            : store?.getters?.user?.currentAssignment[0]?.storeId
            ? `&store=${store?.getters?.user?.currentAssignment[0]?.storeId}`
            : '';
          const phoneNumber = (await route.params.phoneNumber)
            ? `&phoneNumber=${route.params.phoneNumber}`
            : '';
          const customerName = (await route.params.customerName)
            ? `&customerName=${route.params.customerName}`
            : '';
          const customerNumber = (await route.params.customerNumber)
            ? `&customerNumber=${route.params.customerNumber}`
            : '';

          const reservationStatusACCOUNTED =
            route?.query?.type === 'history' || route?.query?.type === 'today'
              ? `&reservationStatusAccounted=${true}`
              : '';

          const reservationStatusCONFIRMED =
            route?.query?.type === undefined ||
            route?.query?.type === 'history' ||
            route?.query?.type === 'today'
              ? `&reservationStatusSure=${true}`
              : '';
          const reservationStatusNOT_CONFIRMED =
            route?.query?.type === undefined ||
            route?.query?.type === 'history' ||
            route?.query?.type === 'today'
              ? `&reservationStatusMemo=${true}`
              : '';
          const reservationStatusRECEPTION =
            route?.query?.type === 'history' || route?.query?.type === 'today'
              ? `&reservationStatusReception=${true}`
              : '';

          const reservationStatusTOTAL =
            route?.query?.type === 'history' || route?.query?.type === 'today'
              ? `&reservationStatusTotal=${true}`
              : '';

          const reservationStatusCANCEL =
            route?.query?.type === 'today' ? `&reservationStatusCancel=${true}` : '';
          const consultationClassification = fieldHereSearch.value.consultationClassification
            ? `&consultationClassification=${fieldHereSearch.value.consultationClassification}`
            : '';
          const page = `&page=${1}`;
          let customerUrl = `index-data${storeUrl.trim()}${phoneNumber.trim()}${customerName
            .replace(/ +/g, '')
            .trim()}${customerNumber.trim()}${reservationDateFrom.trim()}${reservationDateTo.trim()}${sortByreservationDate}${page}${reservationStatusTOTAL}${reservationStatusRECEPTION}${reservationStatusNOT_CONFIRMED}${reservationStatusCONFIRMED}${reservationStatusACCOUNTED}${reservationStatusCANCEL}${consultationClassification}`;
          let customerUrlSplit =
            customerUrl.split('&').length == 0 ? `index-data` : customerUrl.replace(/&/, '?');
          isReservationLoading.value = true;
          await fetch(`api/v1/reservations/${customerUrlSplit}`)
            .then((res) => {
              const response = res?.data?.data;
              customerListData.value = response;
              const paginationData = res?.data?.meta?.pagination;
              currentPage.value = res?.data?.meta?.pagination?.current_page;
              pagination.value = {
                ...paginate(
                  paginationData.total,
                  paginationData.current_page,
                  paginationData.per_page,
                  3
                ),
                previous: paginationData.links?.previous,
                next: paginationData.links?.next
              };
              isReservationLoading.value = false;
            })
            .catch((err) => (isReservationLoading.value = false));
        });
    };

    const openBackModal = () => {
      const backModalElement = document.getElementById('backModal') as HTMLElement;
      let backModal = bootstrap.Modal.getInstance(backModalElement);
      if (!backModal) {
        backModal = new bootstrap.Modal(backModalElement);
      }
      backModal.toggle();
    };

    const onReturn = () => {
      router.back();
    };

    const checkRoute = () => {
      fromCustomerPage.value = store.getters.fromCustomer;
    };

    const getCustomerList = (customer: any, type: any) => {
      isReservationLoading.value = true;
      if (type === 'fieldSearch') {
        fieldHereSearch.value = customer;
        const sortingParam = sortData.value.field
          ? `&sort=${sortData.value.sortBy}&field=${sortData.value.field}`
          : '';
        const store = customer.store && customer.store !== '0' ? `&store=${customer.store}` : '';
        const practitioner =
          customer.practitioner && customer.practitioner !== '0'
            ? `&practitioner=${customer.practitioner}`
            : '';

        const reservationRegDateFrom = customer.reservationRegDateFrom
          ? `&reservationRegDateFrom=${customer.reservationRegDateFrom}`
          : '';
        const consultationClassification = customer.consultationClassification
          ? `&consultationClassification=${customer.consultationClassification}`
          : '';

        const reservationRegDateTo = customer.reservationRegDateTo
          ? `&reservationRegDateTo=${customer.reservationRegDateTo}`
          : '';
        const noOfHospitalVisitFrom = customer.noOfHospitalVisitFrom
          ? `&noOfHospitalVisitFrom=${customer.noOfHospitalVisitFrom}`
          : '';
        const noOfHospitalVisiTo = customer.noOfHospitalVisiTo
          ? `&noOfHospitalVisiTo=${customer.noOfHospitalVisiTo}`
          : '';
        const reservationDateFrom = customer.reservationDate1
          ? `&reservationDateFrom=${customer.reservationDate1}`
          : '';
        const reservationDateTo = customer.reservationDate2
          ? `&reservationDateTo=${customer.reservationDate2}`
          : '';
        const phoneNumber = customer.phoneNumber ? `&phoneNumber=${customer.phoneNumber}` : '';
        const customerName = customer.customerName ? `&customerName=${customer.customerName}` : '';
        const customerNumber = customer.customerNumber
          ? `&customerNumber=${customer.customerNumber}`
          : '';
        const medicalRecord = customer?.medicalRecord
          ? `&medicalRecord=${customer?.medicalRecord}`
          : '';
        const symptom = customer?.symptom ? `&symptom=${customer?.symptom}` : '';
        const treatmentMenu = customer?.treatment_menu_id
          ? `&treatmentMenu=${customer?.treatment_menu_id}`
          : '&treatmentMenu=';
        const reservationRoute =
          customer.reservationRoute && customer.reservationRoute !== 0
            ? `&reservationRoute=${customer.reservationRoute}`
            : '&reservationRoute=';

        const reservationStatusACCOUNTED =
          customer.reservationStatusMultiple?.ACCOUNTED === true
            ? `&reservationStatusAccounted=${customer.reservationStatusMultiple?.ACCOUNTED}`
            : '';
        const reservationStatusCANCEL =
          customer.reservationStatusMultiple?.CANCEL === true
            ? `&reservationStatusCancel=${customer.reservationStatusMultiple?.CANCEL}`
            : '';
        const reservationStatusCONFIRMED =
          customer.reservationStatusMultiple?.CONFIRMED === true
            ? `&reservationStatusSure=${customer.reservationStatusMultiple?.CONFIRMED}`
            : '';
        const reservationStatusNOT_CONFIRMED =
          customer.reservationStatusMultiple?.NOT_CONFIRMED === true
            ? `&reservationStatusMemo=${customer.reservationStatusMultiple?.NOT_CONFIRMED}`
            : '';
        const reservationStatusRECEPTION =
          customer.reservationStatusMultiple?.RECEPTION === true
            ? `&reservationStatusReception=${customer.reservationStatusMultiple?.RECEPTION}`
            : '';

        const reservationStatusTOTAL =
          customer.reservationStatusMultiple?.TOTAL === true
            ? `&reservationStatusTotal=${customer.reservationStatusMultiple?.TOTAL}`
            : '';

        const page = `&page=${1}`;
        let customerUrl = `index-data${reservationDateFrom.trim()}${reservationDateTo.trim()}${store.trim()}${reservationStatusTOTAL.trim()}${customerName.trim()}${customerNumber.trim()}${phoneNumber.trim()}${practitioner.trim()}${reservationRegDateFrom.trim()}${reservationRegDateTo.trim()}${noOfHospitalVisitFrom.trim()}${noOfHospitalVisiTo.trim()}${medicalRecord.trim()}${symptom.trim()}${treatmentMenu.trim()}${reservationRoute.trim()}${sortingParam.trim()}${reservationStatusRECEPTION}${reservationStatusACCOUNTED}${reservationStatusCANCEL}${reservationStatusCONFIRMED}${reservationStatusNOT_CONFIRMED}${consultationClassification}${page}`;
        let customerUrlSplit =
          customerUrl.split('&').length == 0 ? `index-data` : customerUrl.replace(/&/, '?');
        fetch(`api/v1/reservations/${customerUrlSplit}`)
          .then((res) => {
            const response = res?.data?.data;
            customerListData.value = response;
            const paginationData = res?.data?.meta?.pagination;
            currentPage.value = res?.data?.meta?.pagination?.current_page;
            pagination.value = {
              ...paginate(
                paginationData.total,
                paginationData.current_page,
                paginationData.per_page,
                3
              ),
              previous: paginationData.links?.previous,
              next: paginationData.links?.next
            };
            isReservationLoading.value = false;
          })
          .catch((err) => (isReservationLoading.value = false));
      } else {
        sortData.value = customer;
        const page = `&page=${1}`;
        const sortingParam1 = sortData.value.field
          ? `&sort=${sortData.value.sortBy}&field=${sortData.value.field}`
          : '';
        const store =
          fieldHereSearch.value.store && fieldHereSearch.value.store !== '0'
            ? `&store=${fieldHereSearch.value.store}`
            : '';
        const practitioner =
          fieldHereSearch.value.practitioner && fieldHereSearch.value.practitioner !== '0'
            ? `&practitioner=${fieldHereSearch.value.practitioner}`
            : '';

        const reservationRegDateFrom = fieldHereSearch.value.reservationRegDateFrom
          ? `&reservationRegDateFrom=${fieldHereSearch.value.reservationRegDateFrom}`
          : '';

        const reservationRegDateTo = fieldHereSearch.value.reservationRegDateTo
          ? `&reservationRegDateTo=${fieldHereSearch.value.reservationRegDateTo}`
          : '';

        const noOfHospitalVisitFrom = fieldHereSearch.value.noOfHospitalVisitFrom
          ? `&noOfHospitalVisitFrom=${fieldHereSearch.value.noOfHospitalVisitFrom}`
          : '';
        const noOfHospitalVisiTo = fieldHereSearch.value.noOfHospitalVisiTo
          ? `&noOfHospitalVisiTo=${fieldHereSearch.value.noOfHospitalVisiTo}`
          : '';
        const reservationDateFrom = fieldHereSearch.value.reservationDate1
          ? `&reservationDateFrom=${fieldHereSearch.value.reservationDate1}`
          : '';
        const reservationDateTo = fieldHereSearch.value.reservationDate2
          ? `&reservationDateTo=${fieldHereSearch.value.reservationDate2}`
          : '';

        const phoneNumber = fieldHereSearch.value.phoneNumber
          ? `&phoneNumber=${fieldHereSearch.value.phoneNumber}`
          : '';
        const customerName = fieldHereSearch.value.customerName
          ? `&customerName=${fieldHereSearch.value.customerName}`
          : '';
        const customerNumber = fieldHereSearch.value.customerNumber
          ? `&customerNumber=${fieldHereSearch.value.customerNumber}`
          : '';
        const medicalRecord = fieldHereSearch.value?.medicalRecord
          ? `&medicalRecord=${fieldHereSearch.value?.medicalRecord}`
          : '';
        const symptom = fieldHereSearch.value?.symptom
          ? `&symptom=${fieldHereSearch.value?.symptom}`
          : '';
        const treatmentMenu = fieldHereSearch.value?.treatment_menu_id
          ? `&treatmentMenu=${fieldHereSearch.value?.treatment_menu_id}`
          : '';
        const reservationRoute =
          fieldHereSearch.value.reservationRoute && fieldHereSearch.value.reservationRoute !== 0
            ? `&reservationRoute=${fieldHereSearch.value.reservationRoute}`
            : '';
        const reservationStatusACCOUNTED =
          fieldHereSearch.value.reservationStatusMultiple?.ACCOUNTED === true
            ? `&reservationStatusAccounted=${fieldHereSearch.value.reservationStatusMultiple?.ACCOUNTED}`
            : '';
        const reservationStatusCANCEL =
          fieldHereSearch.value.reservationStatusMultiple?.CANCEL === true
            ? `&reservationStatusCancel=${fieldHereSearch.value.reservationStatusMultiple?.CANCEL}`
            : '';
        const reservationStatusCONFIRMED =
          fieldHereSearch.value.reservationStatusMultiple?.CONFIRMED === true
            ? `&reservationStatusSure=${fieldHereSearch.value.reservationStatusMultiple?.CONFIRMED}`
            : '';
        const reservationStatusNOT_CONFIRMED =
          fieldHereSearch.value.reservationStatusMultiple?.NOT_CONFIRMED === true
            ? `&reservationStatusMemo=${fieldHereSearch.value.reservationStatusMultiple?.NOT_CONFIRMED}`
            : '';
        const reservationStatusRECEPTION =
          fieldHereSearch.value.reservationStatusMultiple?.RECEPTION === true
            ? `&reservationStatusReception=${fieldHereSearch.value.reservationStatusMultiple?.RECEPTION}`
            : '';
        const reservationStatusTOTAL =
          fieldHereSearch.value.reservationStatusMultiple?.TOTAL === true
            ? `&reservationStatusTotal=${
                fieldHereSearch.value.reservationStatusMultiple?.TOTAL === true
              }`
            : '';

        const consultationClassification = fieldHereSearch.value.consultationClassification
          ? `&consultationClassification=${fieldHereSearch.value.consultationClassification}`
          : '';
        let customerUrl = `index-data${store}${customerName}${customerNumber}${reservationStatusTOTAL}${reservationStatusACCOUNTED}${reservationStatusCANCEL}${reservationStatusNOT_CONFIRMED}${reservationStatusRECEPTION}${reservationStatusCONFIRMED}${phoneNumber}${practitioner}${reservationRegDateFrom}${reservationRegDateTo}${noOfHospitalVisiTo}${noOfHospitalVisitFrom}${reservationDateFrom}${reservationDateTo}${medicalRecord}${symptom}${treatmentMenu}${reservationRoute}${sortingParam1}${consultationClassification}${page}`;
        let customerUrlSplit =
          customerUrl.split('?').length == 0 ? `customers` : customerUrl.replace(/&/, '?');
        fetch(`api/v1/reservations/${customerUrlSplit}`)
          .then((res) => {
            const response = res?.data?.data;
            customerListData.value = response;
            const paginationData = res?.data?.meta?.pagination;
            currentPage.value = res?.data?.meta?.pagination?.current_page;
            pagination.value = {
              ...paginate(
                paginationData.total,
                paginationData.current_page,
                paginationData.per_page,
                3
              ),
              previous: paginationData.links?.previous,
              next: paginationData.links?.next
            };
            isReservationLoading.value = false;
          })
          .catch((err) => (isReservationLoading.value = false));
      }
    };

    const changePage = (urlData: any) => {
      isReservationLoading.value = true;
      const url = urlData;
      fetch(`${url}`)
        .then((res) => {
          const response = res?.data?.data;
          customerListData.value = response;
          const paginationData = res?.data?.meta?.pagination;
          currentPage.value = res?.data?.meta?.pagination?.current_page;
          pagination.value = {
            ...paginate(
              paginationData.total,
              paginationData.current_page,
              paginationData.per_page,
              3
            ),
            previous: paginationData.links?.previous,
            next: paginationData.links?.next
          };

          isReservationLoading.value = false;
        })
        .catch((err) => (isReservationLoading.value = false));
    };

    const onPaginationChange = (selectedButton: any) => {
      isReservationLoading.value = true;
      const pageNumber = selectedButton ? `&page=${selectedButton}` : '';
      const sortingParam = sortData.value.field
        ? `&sort=${sortData.value.sortBy}&field=${sortData.value.field}`
        : '';
      const store =
        fieldHereSearch.value.store && fieldHereSearch.value.store != '0'
          ? `&store=${fieldHereSearch.value.store}`
          : '';
      const practitioner =
        fieldHereSearch.value.practitioner && fieldHereSearch.value.practitioner !== '0'
          ? `&practitioner=${fieldHereSearch.value.practitioner}`
          : '';
      const reservationRegDateFrom = fieldHereSearch.value.reservationDateFrom
        ? `&reservationRegDateFrom=${fieldHereSearch.value.reservationDateFrom}`
        : '';
      const reservationRegDateTo = fieldHereSearch.value.reservationRegDateTo
        ? `&reservationRegDateTo=${fieldHereSearch.value.reservationRegDateTo}`
        : '';

      const noOfHospitalVisitFrom = fieldHereSearch.value.noOfHospitalVisitFrom
        ? `&noOfHospitalVisitFrom=${fieldHereSearch.value.noOfHospitalVisitFrom}`
        : '';
      const noOfHospitalVisiTo = fieldHereSearch.value.noOfHospitalVisiTo
        ? `&noOfHospitalVisiTo=${fieldHereSearch.value.noOfHospitalVisiTo}`
        : '';
      const reservationDateFrom = fieldHereSearch.value.reservationDate1
        ? `&reservationDateFrom=${fieldHereSearch.value.reservationDate1}`
        : '';
      const reservationDateTo = fieldHereSearch.value.reservationDate2
        ? `&reservationDateTo=${fieldHereSearch.value.reservationDate2}`
        : '';

      const phoneNumber = fieldHereSearch.value.phoneNumber
        ? `&phoneNumber=${fieldHereSearch.value.phoneNumber}`
        : '';
      const customerName = fieldHereSearch.value.customerName
        ? `&customerName=${fieldHereSearch.value.customerName}`
        : '';
      const customerNumber = fieldHereSearch.value.customerNumber
        ? `&customerNumber=${fieldHereSearch.value.customerNumber}`
        : '';
      const medicalRecord = fieldHereSearch.value?.medicalRecord
        ? `&medicalRecord=${fieldHereSearch.value?.medicalRecord}`
        : '';
      const symptom = fieldHereSearch.value?.symptom
        ? `&symptom=${fieldHereSearch.value?.symptom}`
        : '';
      const treatmentMenu = fieldHereSearch.value?.treatment_menu_id
        ? `&treatmentMenu=${fieldHereSearch.value?.treatment_menu_id}`
        : '';
      const consultationClassification = fieldHereSearch.value.consultationClassification
        ? `&consultationClassification=${fieldHereSearch.value.consultationClassification}`
        : '';
      const reservationRoute =
        fieldHereSearch.value.reservationRoute && fieldHereSearch.value.reservationRoute !== 0
          ? `&reservationRoute=${fieldHereSearch.value.reservationRoute}`
          : '';
      const reservationStatusACCOUNTED =
        fieldHereSearch.value.reservationStatusMultiple?.ACCOUNTED === true
          ? `&reservationStatusAccounted=${fieldHereSearch.value.reservationStatusMultiple?.ACCOUNTED}`
          : '';
      const reservationStatusCANCEL =
        fieldHereSearch.value.reservationStatusMultiple?.CANCEL === true
          ? `&reservationStatusCancel=${fieldHereSearch.value.reservationStatusMultiple?.CANCEL}`
          : '';
      const reservationStatusCONFIRMED =
        fieldHereSearch.value.reservationStatusMultiple?.CONFIRMED === true
          ? `&reservationStatusSure=${fieldHereSearch.value.reservationStatusMultiple?.CONFIRMED}`
          : '';
      const reservationStatusNOT_CONFIRMED =
        fieldHereSearch.value.reservationStatusMultiple?.NOT_CONFIRMED === true
          ? `&reservationStatusMemo=${fieldHereSearch.value.reservationStatusMultiple?.NOT_CONFIRMED}`
          : '';
      const reservationStatusRECEPTION =
        fieldHereSearch.value.reservationStatusMultiple?.RECEPTION === true
          ? `&reservationStatusReception=${fieldHereSearch.value.reservationStatusMultiple?.RECEPTION}`
          : '';
      const reservationStatusTOTAL =
        fieldHereSearch.value.reservationStatusMultiple?.TOTAL === true
          ? `&reservationStatusTotal=${
              fieldHereSearch.value.reservationStatusMultiple?.TOTAL === true
            }`
          : '';
      fetch(
        `api/v1/reservations/index-data?${store}${customerName}${customerNumber}${phoneNumber}${practitioner}${reservationRegDateFrom}${reservationRegDateTo}${noOfHospitalVisitFrom}${noOfHospitalVisiTo}${reservationDateFrom}${reservationDateTo}${medicalRecord}${symptom}${treatmentMenu}${reservationRoute}${sortingParam}${pageNumber}${reservationStatusACCOUNTED}${reservationStatusCANCEL}${reservationStatusCONFIRMED}${reservationStatusNOT_CONFIRMED}${reservationStatusRECEPTION}${reservationStatusTOTAL}${consultationClassification}`
      )
        .then((res) => {
          const response = res?.data?.data;
          customerListData.value = response;
          const paginationData = res?.data?.meta?.pagination;
          currentPage.value = res?.data?.meta?.pagination?.current_page;
          pagination.value = {
            ...paginate(
              paginationData.total,
              paginationData.current_page,
              paginationData.per_page,
              3
            ),
            previous: paginationData.links?.previous,
            next: paginationData.links?.next
          };
          isReservationLoading.value = false;
        })
        .catch((err) => (isReservationLoading.value = false));
    };

    const updateStatus = (data: { taskIds: Array<number>; status: number }) => {
      if (data?.taskIds.length > 0) {
        post('api/v1/reservations/change-status', {
          reservationIds: data.taskIds,
          cancellationType: data.status
        })
          .then((res) => {
            if (res.status === 200) {
              onMountSearch('');
              window.scrollTo(0, 0);
              toast.success('キャンセルしました', {
                timeout: 2000,
                position: POSITION.BOTTOM_RIGHT
              });
              selectTask.value = false;
            }
          })
          .catch((err) => {
            toast.error(`${err.response.data?.errors[0]?.title}`, {
              timeout: 2000,
              position: POSITION.BOTTOM_RIGHT
            });
            selectTask.value = false;
            onMountSearch('noReload');
          });
      } else {
        selectTask.value = false;
        window.scrollTo(0, 0);
        toast.error('行を選択してください', {
          timeout: 2000,
          position: POSITION.BOTTOM_RIGHT
        });
      }
    };

    const handlePractitonerSearch = (practitioner: any) => {
      const page = `&page=${1}`;
      const sortingParam1 = sortData.value.field
        ? `&sort=${sortData.value.sortBy}&field=${sortData.value.field}`
        : '';
      const store =
        fieldHereSearch.value.store && fieldHereSearch.value.store != '0'
          ? `&store=${fieldHereSearch.value.store}`
          : '';

      const reservationRegDateFrom = fieldHereSearch.value.reservationRegDateFrom
        ? `&reservationRegDateFrom=${fieldHereSearch.value.reservationRegDateFrom}`
        : '';

      const reservationRegDateTo = fieldHereSearch.value.reservationRegDateTo
        ? `&reservationRegDateTo=${fieldHereSearch.value.reservationRegDateTo}`
        : '';

      const noOfHospitalVisitFrom = fieldHereSearch.value.noOfHospitalVisitFrom
        ? `&noOfHospitalVisitFrom=${fieldHereSearch.value.noOfHospitalVisitFrom}`
        : '';
      const noOfHospitalVisiTo = fieldHereSearch.value.noOfHospitalVisiTo
        ? `&noOfHospitalVisiTo=${fieldHereSearch.value.noOfHospitalVisiTo}`
        : '';
      const reservationDateFrom = fieldHereSearch.value.reservationDate1
        ? `&reservationDateFrom=${fieldHereSearch.value.reservationDate1}`
        : '';
      const reservationDateTo = fieldHereSearch.value.reservationDate2
        ? `&reservationDateTo=${fieldHereSearch.value.reservationDate2}`
        : '';

      const phoneNumber = fieldHereSearch.value.phoneNumber
        ? `&phoneNumber=${fieldHereSearch.value.phoneNumber}`
        : '';
      const customerName = fieldHereSearch.value.customerName
        ? `&customerName=${fieldHereSearch.value.customerName}`
        : '';
      const customerNumber = fieldHereSearch.value.customerNumber
        ? `&customerNumber=${fieldHereSearch.value.customerNumber}`
        : '';
      const medicalRecord = fieldHereSearch.value?.medicalRecord
        ? `&medicalRecord=${fieldHereSearch.value?.medicalRecord}`
        : '';
      const symptom = fieldHereSearch.value?.symptom
        ? `&symptom=${fieldHereSearch.value?.symptom}`
        : '';
      const treatmentMenu = fieldHereSearch.value?.treatment_menu_id
        ? `&treatmentMenu=${fieldHereSearch.value?.treatment_menu_id}`
        : '';
      const reservationRoute =
        fieldHereSearch.value.reservationRoute && fieldHereSearch.value.reservationRoute !== 0
          ? `&reservationRoute=${fieldHereSearch.value.reservationRoute}`
          : '';
      const reservationStatusACCOUNTED =
        fieldHereSearch.value.reservationStatusMultiple?.ACCOUNTED === true
          ? `&reservationStatusAccounted=${fieldHereSearch.value.reservationStatusMultiple?.ACCOUNTED}`
          : '';
      const reservationStatusCANCEL =
        fieldHereSearch.value.reservationStatusMultiple?.CANCEL === true
          ? `&reservationStatusCancel=${fieldHereSearch.value.reservationStatusMultiple?.CANCEL}`
          : '';
      const reservationStatusCONFIRMED =
        fieldHereSearch.value.reservationStatusMultiple?.CONFIRMED === true
          ? `&reservationStatusSure=${fieldHereSearch.value.reservationStatusMultiple?.CONFIRMED}`
          : '';
      const reservationStatusNOT_CONFIRMED =
        fieldHereSearch.value.reservationStatusMultiple?.NOT_CONFIRMED === true
          ? `&reservationStatusMemo=${fieldHereSearch.value.reservationStatusMultiple?.NOT_CONFIRMED}`
          : '';
      const reservationStatusRECEPTION =
        fieldHereSearch.value.reservationStatusMultiple?.RECEPTION === true
          ? `&reservationStatusReception=${fieldHereSearch.value.reservationStatusMultiple?.RECEPTION}`
          : '';
      const reservationStatusTOTAL =
        fieldHereSearch.value.reservationStatusMultiple?.TOTAL === true
          ? `&reservationStatusTotal=${
              fieldHereSearch.value.reservationStatusMultiple?.TOTAL === true
            }`
          : '';

      const consultationClassification = fieldHereSearch.value.consultationClassification
        ? `&consultationClassification=${fieldHereSearch.value.consultationClassification}`
        : '';
      isReservationLoading.value = true;
      const practitionerUrl =
        practitioner && practitioner !== 'ALL' ? `&practitioner=${practitioner}` : '';
      fetch(
        `api/v1/reservations/index-data?${practitionerUrl}${page}${sortingParam1}${store}${reservationRegDateFrom}${reservationRegDateTo}${noOfHospitalVisitFrom}${noOfHospitalVisiTo}${reservationDateFrom}${reservationDateTo}${phoneNumber}${customerName}${customerNumber}${medicalRecord}${symptom}${treatmentMenu}${reservationRoute}${reservationStatusACCOUNTED}${reservationStatusCANCEL}${consultationClassification}${reservationStatusTOTAL}${reservationStatusRECEPTION}${reservationStatusNOT_CONFIRMED}${reservationStatusCONFIRMED}`
      )
        .then((res) => {
          const response = res?.data?.data;
          customerListData.value = response;
          const paginationData = res?.data?.meta?.pagination;
          currentPage.value = res?.data?.meta?.pagination?.current_page;
          pagination.value = {
            ...paginate(
              paginationData.total,
              paginationData.current_page,
              paginationData.per_page,
              3
            ),
            previous: paginationData.links?.previous,
            next: paginationData.links?.next
          };
          isReservationLoading.value = false;
        })
        .catch((err) => (isReservationLoading.value = false));
    };
    const getPractioner = (practioner: any) => {
      if (practioner.length > 0) {
        practionerData.value = practioner;
      }
    };

    return {
      selectTask,
      practionerData,
      fromCustomerPage,
      handlePractitonerSearch,
      updateStatus,
      isReservationLoading,
      menuName,
      onPaginationChange,
      changePage,
      pagination,
      paginationButton,
      reservationStatus,
      RESERVATION_STATUS,
      onReturn,
      openBackModal,
      customerListData,
      getCustomerList,
      getPractioner
    };
  }
});
