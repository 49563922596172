import axios from "axios";
import { store } from "@/core/store";
import { useRouter } from "vue-router";

export const httpBase = () => {
  const router = useRouter();
  const normalHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Bearer " + store.getters.token,
  };

  const api = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_API_URL}`,
    headers: { ...normalHeaders },
    responseType: "json",
  });

  api.interceptors.response.use(
    (response: any) => {
      return response;
    },
    (error) => {
      if (401 === error.response.status) {
        store.dispatch("logout").then(async (response) => {
          // clearNotificationInterval();
          router.push({ name: "Login" });
        });
      }
      if (404 === error.response.status) {
        console.log(error, "404");
      }
      if (500 === error.response.status) {
        // console.log(error,'500')
        // Router.push('/500');
      }
      if (429 === error.response.status) {
        console.log(error, "429");
      }
      return Promise.reject(error);
    }
  );

  return api;
};
