
import { defineComponent, ref, watchEffect, onMounted, watch } from 'vue';
import Multiselect from '@vueform/multiselect';
import { fetch } from '@/core/api/api-service';
import { store } from '@/core/store';
import moment from 'moment';
import DatePicker from '../../components/DatePicker.vue';
import check from '@/assets/icons/check-solid.svg';
import { getCurrentDivision } from '@/core/utils';
import { useI18n } from 'vue-i18n';
import { useToast, POSITION } from 'vue-toastification';
import { ROUTES_FROM } from '@/core/store/enums';

moment.locale('en');
export default defineComponent({
  name: 'CustomerListSearchSearch',
  emits: ['customer'],
  components: {
    Multiselect,
    DatePicker
  },
  setup(props, {emit}) {
    let userData = ref();
    let monthData = ref();
    const i18n = useI18n();
    const toast = useToast();
    let errors: any = ref([]);
    let customer = ref({
      reviewEkiten: '',
      reviewGReview: '',
      reviewWB: '',
      ageFirst: '',
      ageSecond: '',
      store: '',
      customerName: '',
      customerNumber: '',
      phoneNumber: '',
      birthMonth: '',
      noOfHospitalVisit2: '',
      noOfHospitalVisit1: '',
      lastVisitDate: '',
      baoyeTreatmentHas: false,
      trafficOrIndustrialTreatmentAvailable: false,
      remainingCoupon: '',
      accountReceivable: '',
      nonMember: '',
      dmok: '',
      anonymous: '',
      member: false,
      productSalesMember: false,
      unsettledThisMonth: null,
      memberCoupon10TimesOrMore: false,
      memberCouponZero: false,
      memberCouponMinus: false,
      psmUnsettled: null,
      psmNotDelivered: false
    });
    const routeFrom = ref('')

    let storeOptions = ref();
    let showCalendar = ref(false);
    const getRouteFrom = () => {
      return new Promise((resolve, reject) => {
        try {
          const routeFrom = store.getters.getRouteFrom;
          resolve(routeFrom)
        } catch (err) {
          reject(err)
        }
      })
    }
    const patchInputFields = () => {
      if (store.state.routeFrom) {
        getRouteFrom().then((route: any) => {
          routeFrom.value = route;
          if (route == ROUTES_FROM.CUSTOMER_DETAILS || ROUTES_FROM.RESERVATION_HISTORY_LIST) {
            if (localStorage.customerName) {
              customer.value.customerName = localStorage.customerName;
            }
            if (localStorage.reviewEkiten) {
              customer.value.reviewEkiten = localStorage.reviewEkiten;
            }
            if (localStorage.reviewGReview) {
              customer.value.reviewGReview = localStorage.reviewGReview;
            }
            if (localStorage.reviewWB) {
              customer.value.reviewWB = localStorage.reviewWB;
            }
            if (localStorage.ageFirst) {
              customer.value.ageFirst = localStorage.ageFirst;
            }
            if (localStorage.ageSecond) {
              customer.value.ageSecond = localStorage.ageSecond;
            }
            if (localStorage.store) {
              customer.value.store = localStorage.store;
            }
            if (localStorage.customerNumber) {
              customer.value.customerNumber = localStorage.customerNumber;
            }
            if (localStorage.phoneNumber) {
              customer.value.phoneNumber = localStorage.phoneNumber;
            }
            if (localStorage.birthMonth) {
              customer.value.birthMonth = localStorage.birthMonth;
            }
            if (localStorage.noOfHospitalVisit2) {
              customer.value.noOfHospitalVisit2 = localStorage.noOfHospitalVisit2;
            }
            if (localStorage.noOfHospitalVisit1) {
              customer.value.noOfHospitalVisit1 = localStorage.noOfHospitalVisit1;
            }
            if (localStorage.lastVisitDate) {
              customer.value.lastVisitDate = localStorage.lastVisitDate;
            }
            if (localStorage.baoyeTreatmentHas) {
              customer.value.baoyeTreatmentHas = localStorage.baoyeTreatmentHas;
            }
            if (localStorage.trafficOrIndustrialTreatmentAvailable) {
              customer.value.trafficOrIndustrialTreatmentAvailable =
                  localStorage.trafficOrIndustrialTreatmentAvailable;
            }
            if (localStorage.remainingCoupon) {
              customer.value.remainingCoupon = localStorage.remainingCoupon;
            }
            if (localStorage.accountReceivable) {
              customer.value.accountReceivable = localStorage.accountReceivable;
            }
            if (localStorage.nonMember) {
              customer.value.nonMember = localStorage.nonMember;
            }
            if (localStorage.dmok) {
              customer.value.dmok = localStorage.dmok;
            }
            if (localStorage.member) {
              customer.value.member = localStorage.member;
            }
            if (localStorage.productSalesMember) {
              customer.value.productSalesMember = localStorage.productSalesMember;
            }
            if (localStorage.unsettledThisMonth) {
              customer.value.unsettledThisMonth = localStorage.unsettledThisMonth;
            }
            if (localStorage.memberCoupon10TimesOrMore) {
              customer.value.memberCoupon10TimesOrMore = localStorage.memberCoupon10TimesOrMore;
            }
            if (localStorage.memberCouponZero) {
              customer.value.memberCouponZero = localStorage.memberCouponZero;
            }
            if (localStorage.memberCouponMinus) {
              customer.value.memberCouponMinus = localStorage.memberCouponMinus;
            }
            if (localStorage.psmUnsettled) {
              customer.value.psmUnsettled = localStorage.psmUnsettled;
            }
            if (localStorage.psmNotDelivered) {
              customer.value.psmNotDelivered = localStorage.psmNotDelivered;
            }
          }
        }).catch(err => console.error(err)).finally(() =>
            store.dispatch('setRouteFrom', null))
      }
    }
    onMounted(() => {
      // console.log(localStorage.name);
      // if (localStorage.customerName) {
      //   localStorage.customerName = customer.value.customerName;
      // }
      patchInputFields();
      getCurrentUserData();
      getStores();
    });
    const getCurrentUserData = () => {
      fetch(`api/v1/getEmployee`).then((res: any) => {
        if (res) {
          const user = res?.data?.data;
          user.currentEmployeeDivision = getCurrentDivision(user.employeeDivsions);
          userData.value = user;
        }
      });
    };
    watch(customer.value, (newCustomer) => {
      localStorage.setItem('name', newCustomer.customerName);
      localStorage.customerName = newCustomer.customerName;
      localStorage.reviewEkiten = newCustomer.reviewEkiten;
      localStorage.reviewGReview = newCustomer.reviewGReview;
      localStorage.reviewWB = newCustomer.reviewWB;
      localStorage.ageFirst = newCustomer.ageFirst;
      localStorage.ageSecond = newCustomer.ageSecond;
      localStorage.store = newCustomer.store;
      localStorage.customerNumber = newCustomer.customerNumber;
      localStorage.phoneNumber = newCustomer.phoneNumber;
      localStorage.birthMonth = newCustomer.birthMonth;
      localStorage.noOfHospitalVisit2 = newCustomer.noOfHospitalVisit2;
      localStorage.noOfHospitalVisit1 = newCustomer.noOfHospitalVisit1;
      localStorage.lastVisitDate = newCustomer.lastVisitDate;
      localStorage.baoyeTreatmentHas = newCustomer.baoyeTreatmentHas;
      localStorage.trafficOrIndustrialTreatmentAvailable =
        newCustomer.trafficOrIndustrialTreatmentAvailable;
      localStorage.remainingCoupon = newCustomer.remainingCoupon;
      localStorage.accountReceivable = newCustomer.accountReceivable;
      localStorage.nonMember = newCustomer.nonMember;
      localStorage.dmok = newCustomer.dmok;
      localStorage.anonymous = newCustomer.anonymous;
      localStorage.member = newCustomer.member;
      localStorage.productSalesMember = newCustomer.productSalesMember;
      localStorage.unsettledThisMonth = newCustomer.unsettledThisMonth;
      localStorage.memberCoupon10TimesOrMore = newCustomer.memberCoupon10TimesOrMore;
      localStorage.memberCouponZero = newCustomer.memberCouponZero;
      localStorage.memberCouponMinus = newCustomer.memberCouponMinus;
      localStorage.psmUnsettled = newCustomer.psmUnsettled;
      localStorage.psmNotDelivered = newCustomer.psmNotDelivered;
      console.log(newCustomer);
    });
    const getStores = () => {
      fetch(`api/v1/getStores`).then((res) => {
        if (res) {
          const storeRes = res.data.data;

          storeOptions.value = storeRes
            .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            .map((store: any) => {
              return {
                value: store.id,
                name: store.storeName,
                storeBusinessHours: store.storeBusinessHours,
                timeFrame: store.timeFrame,
                icon: check
              };
            });
          storeOptions.value.unshift({ value: '', icon: check });
        }
      });
    };

    const getMONTHS = () => {
      const months: any = [];
      const dateStart = moment(1980 - 1 - 1);
      const dateEnd = moment(1980 - 1 - 1).add(11, 'month');
      while (dateEnd.diff(dateStart, 'months') >= 0) {
        months.push({
          value: dateStart.format('M'),
          name: moment(dateStart).locale('ja').format('MMMM')
        });
        dateStart.add(1, 'month');
      }

      monthData.value = months;
      return months;
    };
    getMONTHS();

    const OnSearch = async () => {
      errors.value = [];

      let formData: any = customer?.value;

      if (formData.noOfHospitalVisit1 && formData.noOfHospitalVisit2) {
        if (Number(formData.noOfHospitalVisit1) > Number(formData.noOfHospitalVisit2)) {
          errors.value.push({
            title: 'noOfHospitalVisit1',
            message: i18n.t('Errors.E063')
          });
        }
      }
      if (formData.phoneNumber) {
        const getFirstCharacterInNumberTelephone = formData.phoneNumber;
        if (
          getFirstCharacterInNumberTelephone.toString().charAt(0) !== '0' &&
          getFirstCharacterInNumberTelephone.length < 10
        ) {
          errors.value.push({
            title: 'phoneNumber',
            message: `形式違い`
          });
        }
      }
      if (errors.value.length === 0) {
        if (
          !formData.accountReceivable &&
          !formData.ageFirst &&
          !formData.ageSecond &&
          !formData.anonymous &&
          !formData.baoyeTreatmentHas &&
          !formData.birthMonth &&
          !formData.customerName &&
          !formData.customerNumber &&
          !formData.dmok &&
          !formData.lastVisitDate &&
          !formData.member &&
          !formData.memberCoupon10TimesOrMore &&
          !formData.memberCouponMinus &&
          !formData.memberCouponZero &&
          !formData.noOfHospitalVisit1 &&
          !formData.noOfHospitalVisit2 &&
          !formData.nonMember &&
          !formData.phoneNumber &&
          !formData.productSalesMember &&
          !formData.psmNotDelivered &&
          formData.psmUnsettled === null &&
          !formData.remainingCoupon &&
          !formData.reviewEkiten &&
          !formData.reviewGReview &&
          formData.store === null &&
          !formData.reviewWB &&
          !formData.trafficOrIndustrialTreatmentAvailable &&
          formData.unsettledThisMonth === null
        ) {
          toast.error(i18n.t('Errors.E112'), {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT
          });
        } else {
          emit('customer', formData);
        }
      }
    };

    const handleNumber = (evt: any) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 46) {
        evt.preventDefault();
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      } else {
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    };

    const handleNumberRegex = (evt: any, type: any) => {
      const getValue = evt?.target.value;
      var numberPattern = /^[0-9]+$/g;
      const test = numberPattern.test(getValue);

      if (type === 'customer.phoneNumber') {
        if (test === true) {
          customer.value.phoneNumber = getValue;
        } else {
          customer.value.phoneNumber = '';
        }
      } else if (type === 'customer.customerNumber') {
        if (test === true) {
          customer.value.customerNumber = getValue;
        } else {
          customer.value.customerNumber = '';
        }
      } else if (type === 'customer.ageFirst') {
        if (test === true) {
          customer.value.ageFirst = getValue;
        } else {
          customer.value.ageFirst = '';
        }
      } else if (type === 'customer.ageSecond') {
        if (test === true) {
          customer.value.ageSecond = getValue;
        } else {
          customer.value.ageSecond = '';
        }
      } else if (type === 'customer.noOfHospitalVisit1') {
        if (test === true) {
          customer.value.noOfHospitalVisit1 = getValue;
        } else {
          customer.value.noOfHospitalVisit1 = '';
        }
      } else if (type === 'customer.noOfHospitalVisit2') {
        if (test === true) {
          customer.value.noOfHospitalVisit2 = getValue;
        } else {
          customer.value.noOfHospitalVisit2 = '';
        }
      } else if (type === 'customer.lastVisitDate') {
        const isValid = customer.value.lastVisitDate;
        const check = moment(isValid, 'YYYY-MM-DD', true).isValid();
        if (check === true) {
          customer.value.lastVisitDate = getValue;
        } else {
          customer.value.lastVisitDate = '';
        }
      }
    };

    const filterErrorColor = (fieldName: any) => {
      if (errors.value.length > 0) {
        const errorMessage = errors.value.filter((x: { title: any }) => x.title === fieldName);
        if (errorMessage && errorMessage.length > 0) {
          return true;
        } else if (errorMessage.length === 0) {
          return false;
        }
      }
    };

    const filterErrors = (fieldName: any) => {
      if (errors.value.length > 0) {
        const errorMessage = errors.value.filter((x: { title: any }) => x.title === fieldName);
        return errorMessage[0]?.message;
      }
    };

    return {
      filterErrorColor,
      filterErrors,
      handleNumberRegex,
      handleNumber,
      showCalendar,
      monthData,
      OnSearch,
      storeOptions,
      customer,

      taskTypeOptions: [
        {
          value: '南区本院',
          name: '南区本院'
          // icon: require("../assets/icons/check-solid.svg"),
        },
        {
          value: '久我の杜院',
          name: '久我の杜院'
          // icon: require("../assets/icons/check-solid.svg"),
        },
        {
          value: '伏見桃山院',
          name: '伏見桃山院'
          // icon: require("../assets/icons/check-solid.svg"),
        }
      ]
    };
  }
});
