import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1af93c52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "custom-tabs" }
const _hoisted_2 = { class: "d-flex align-items-end" }
const _hoisted_3 = { class: "table-container" }
const _hoisted_4 = { class: "table table-striped" }
const _hoisted_5 = { style: {"width":"100px"} }
const _hoisted_6 = { style: {"width":"210px"} }
const _hoisted_7 = { style: {"width":"210px"} }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = {
  key: 1,
  class: "pagination d-flex justify-content-center"
}
const _hoisted_10 = ["disabled"]
const _hoisted_11 = ["onClick"]
const _hoisted_12 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("ul", _hoisted_2, [
        _createElementVNode("li", {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onChangeTab('1'))),
          class: _normalizeClass({ active: this.selectedSalesTab === '1' })
        }, _toDisplayString(_ctx.$t("CustomerDetails.WarmPlus")), 3),
        _createElementVNode("li", {
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onChangeTab('2'))),
          class: _normalizeClass({ active: this.selectedSalesTab === '2' })
        }, _toDisplayString(_ctx.$t("CustomerDetails.Vitamins")), 3)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("table", _hoisted_4, [
        _createElementVNode("thead", null, [
          _createElementVNode("tr", null, [
            _createElementVNode("th", _hoisted_5, _toDisplayString(_ctx.$t("CustomerDetails.Table.Date")), 1),
            _createElementVNode("th", _hoisted_6, _toDisplayString(_ctx.$t("CustomerDetails.Table.MethodPayment")), 1),
            _createElementVNode("th", _hoisted_7, _toDisplayString(_ctx.$t("CustomerDetails.Table.ProductMenu")), 1),
            _createElementVNode("th", null, _toDisplayString(_ctx.$t("CustomerDetails.Table.DeliveryDate")), 1)
          ])
        ]),
        _createElementVNode("tbody", null, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.salesData, (selectedSalesData, i) => {
            return (_openBlock(), _createElementBlock("tr", { key: i }, [
              _createElementVNode("td", null, _toDisplayString(_ctx.convertToMMDD(selectedSalesData?.settlementDate)), 1),
              _createElementVNode("td", null, _toDisplayString(selectedSalesData?.paymentMethod), 1),
              _createElementVNode("td", null, _toDisplayString(selectedSalesData?.courseName), 1),
              _createElementVNode("td", null, _toDisplayString(_ctx.convertToMMDD(selectedSalesData?.deliveryDate)), 1)
            ]))
          }), 128))
        ])
      ]),
      (_ctx.salesData.length === 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString("該当するデータがありません")))
        : _createCommentVNode("", true),
      (_ctx.salesData.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("button", {
              disabled: !_ctx.pagination?.previous,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.changePage(_ctx.pagination?.previous)))
            }, " « ", 8, _hoisted_10),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pagination?.pages, (page, i) => {
              return (_openBlock(), _createElementBlock("button", {
                key: i,
                class: _normalizeClass([_ctx.pagination?.currentPage === page ? 'active' : '']),
                onClick: ($event: any) => (_ctx.onPaginationChange(page))
              }, _toDisplayString(page), 11, _hoisted_11))
            }), 128)),
            _createElementVNode("button", {
              disabled: !_ctx.pagination?.next,
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.changePage(_ctx.pagination?.next)))
            }, " » ", 8, _hoisted_12)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}