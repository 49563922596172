
import { defineComponent, onMounted, ref } from "vue";
import { fetch } from "@/core/api/api-service";
import { paginate } from "@/core/utils";
import moment from "moment";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Sales",

  setup(props) {
    const route = useRoute();
    let selectedSalesTab = ref("1");
    const salesData = ref([]);
    let pagination = ref();
    let currentPage = ref();
    let activeIndex = ref(0);
    let paginationButton = ref([{ id: 0, buttonName: 1, active: false }]);

    onMounted(async () => {
      getSalesData(selectedSalesTab.value);
    });
    const getSalesData = (selectedSalesTab: any) => {
      const page = `&page=${1}`;
      const selectedTab = selectedSalesTab;
      const customerID = (route.params.id as string).split("=?")?.[1];
      fetch(
        `api/v1/monthly-product-sales-member-details?code=${selectedTab}${page}&customerNumber=${customerID}`
      ).then((res) => {
        const customerSales = res?.data?.data;
        salesData.value = customerSales;

        const paginationData = res?.data?.meta?.pagination;
        currentPage.value = res?.data?.meta?.pagination?.current_page;
        pagination.value = {
          ...paginate(
            paginationData.total,
            paginationData.current_page,
            paginationData.per_page,
            3
          ),
          previous: paginationData.links?.previous,
          next: paginationData.links?.next,
        };
      });
    };
    const changePage = (urlData: any) => {
      const url = urlData;
      fetch(`${url}`).then((res) => {
        const response = res?.data?.data;
        salesData.value = response;
        const paginationData = res?.data?.meta?.pagination;
        currentPage.value = res?.data?.meta?.pagination?.current_page;
        pagination.value = {
          ...paginate(
            paginationData.total,
            paginationData.current_page,
            paginationData.per_page,
            3
          ),
          previous: paginationData.links?.previous,
          next: paginationData.links?.next,
        };
      });
    };
    const onPaginationChange = (selectedButton: any) => {
      const selectedTab = selectedSalesTab.value;
      fetch(
        `api/v1/monthly-product-sales-member-details?code=${selectedTab}&page=${selectedButton}`
      ).then((res) => {
        const response = res?.data?.data;
        salesData.value = response;
        const paginationData = res?.data?.meta?.pagination;
        currentPage.value = res?.data?.meta?.pagination?.current_page;
        pagination.value = {
          ...paginate(
            paginationData.total,
            paginationData.current_page,
            paginationData.per_page,
            3
          ),
          previous: paginationData.links?.previous,
          next: paginationData.links?.next,
        };
      });
    };
    const onChangeTab = (tabSelected: any) => {
      salesData.value = [];
      selectedSalesTab.value = tabSelected;
      getSalesData(tabSelected);
    };
    const convertToMMDD = (date: any) => {
      return moment(date, "YYYY-MM-DD").format("YYYY/MM/DD");
    };

    return {
      convertToMMDD,
      onPaginationChange,
      changePage,
      pagination,
      paginationButton,
      onChangeTab,
      selectedSalesTab,
      salesData,
    };
  },
});
