
import { defineComponent } from "vue";
import SymptomsForm from "../components/SymptomsForm.vue";

export default defineComponent({
  name: "ReservationHistory",
  components: {
    SymptomsForm,
  },
});
