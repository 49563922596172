import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-716cddd8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wrapper position-relative" }
const _hoisted_2 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header, {
      class: _normalizeClass([_ctx.isPreliminary ? 'd-none' : ''])
    }, null, 8, ["class"]),
    _createVNode(_component_Sidebar, {
      class: _normalizeClass([_ctx.isPreliminary ? 'd-none' : ''])
    }, null, 8, ["class"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ]))
}