
import { defineComponent, ref, onMounted } from 'vue';
import Multiselect from '@vueform/multiselect';
import MpGraph from '../modals/MpGraph.vue';
import { TodaySymptom, Treatment, Trp } from '@/core/interface';
import { locations, stages } from '@/core/store/constants';
import { store } from '@/core/store';
import { fetch, remove, store as post } from '@/core/api/api-service';
import axios from 'axios';
import i18n from '@/i18n';
import Modal from '@/components/modals/Modal.vue';
import { CONSULTATION_CLASSIFICATION } from '@/core/store/enums';
import { POSITION, useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { convertToFullWidthKana } from '@/core/utils';

export default defineComponent({
  name: 'Treatment',
  props: {
    reservation: {
      type: Object || null,
      required: true
    },
    medicalRecord: {
      type: Object || null,
      required: true
    },
    previousReservation: {
      type: Object,
      required: false
    },
    previousMedicalRecord: {
      type: Object,
      required: false
    }
  },
  components: {
    Multiselect,
    Modal,
    MpGraph
  },
  emits: ['updateReservation'],
  setup(props, { emit }) {
    const toast = useToast();
    const reservationData = ref(
      props.reservation.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
        ? props.previousReservation
        : props.reservation
    );
    const oldMedicalForContinious = ref(props.previousMedicalRecord);
    //   const medicalRecordData = ref(
    //       props.reservation.consultationClassification ===
    //       CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
    //           ? props.previousMedicalRecord
    //           : props.medicalRecord
    // );
    const medicalRecordData = ref(props.medicalRecord);
    let parts: any[] = [];
    let senses: any[] = [];
    const showMpGraph = ref<boolean>(false);
    const disableCreateNewButton = ref(false);
    const formSubmitted = ref<boolean>(false);
    const formDeletionSubmitted = ref<boolean>(false);
    const treatments = ref<Array<Treatment>>([]);
    const selectedTreatment = ref<Treatment | null>(null);
    const selectedTreatmentIndex = ref<number>(0);
    const partOptions = ref<Array<any>>([]);
    const selectedTab = ref(0);
    const policies = ref<Array<any>>([]);
    const complaintOptions = ref<Array<any>>([]);
    const senseOptions = ref<Array<any>>([]);
    const formErrors = ref<Array<any>>([]);
    const muscleErrors = ref<Array<any>>([]);
    const keepErrors = ref<Array<any>>([]);
    const locationOptions = locations.map((location: { key: number; name: string }) => {
      return {
        value: location.key,
        name: location.name,
        selected: false,
        icon: require('@/assets/icons/check-solid.svg')
      };
    });
    const stageOptions = stages
      .sort((a, b) => b.key - a.key)
      .map((stage: { key: number; name: string }) => {
        return {
          value: stage.key,
          name: stage.name,
          selected: false,
          icon: require('@/assets/icons/check-solid.svg')
        };
      });

    onMounted(async () => {
      await fetchParts();
      await fetchSenses();
      await fetchPoliciesAndTreatments();
    });

    const fetchParts = () => {
      return fetch('api/v1/parts').then((res) => {
        parts = res?.data?.data;
        if (parts) {
          partOptions.value = parts
            .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            .map((part: any) => {
              return {
                value: part.id,
                name: part.name,
                selected: false,
                icon: require('@/assets/icons/check-solid.svg')
              };
            });
        }
      });
    };

    const fetchSenses = () => {
      return fetch('api/v1/getSense').then((res) => {
        senses = res?.data?.data;
        if (senses) {
          senseOptions.value = senses
            .sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            .map((sense: any) => {
              return {
                value: sense.id,
                name: sense.name,
                icon: require('@/assets/icons/check-solid.svg')
              };
            });
        }
      });
    };

    const fetchPoliciesAndTreatments = async () => {
      if (
        props.reservation.consultationClassification !==
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
      ) {
        const medicalRecordResponse = await axios(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/medical-records/${medicalRecordData.value?.id}`,
          {
            headers: { Authorization: 'Bearer ' + store.getters.token }
          }
        );

        if (medicalRecordResponse.status === 200) {
          if (medicalRecordResponse?.data?.data) {
            const medicalRecordData = medicalRecordResponse.data.data;
            if (medicalRecordData.policy) {
              policies.value = medicalRecordData.policy;
              complaintOptions.value = policies.value.map((policy, index) => {
                return {
                  value: policy.id,
                  name: `${i18n.global.t('Policy.MainComplaint')} ${index + 1}`,
                  selected: false,
                  icon: require('@/assets/icons/check-solid.svg')
                };
              });
            }
            if (medicalRecordData.treatment && medicalRecordData.treatment.length > 0) {
              disableCreateNewButton.value = false;
              medicalRecordData.treatment.forEach((treatment: any) => {
                if (props.reservation.id === treatment.reservationId) {
                  treatments.value.push({
                    ...treatment,
                    todaySymptoms: treatment.todaysSymptoms?.map((todaysSymptom: any) => {
                      return {
                        location: todaysSymptom.location,
                        part: todaysSymptom.part.id,
                        sense: todaysSymptom.sense.id
                      };
                    }),
                    trpList: treatment.trps.map((trp: any) => {
                      return {
                        location: trp.location,
                        part: trp.part.id,
                        muscles: buildMuscleFromTrpDetails(trp?.trpDetails),
                        muscleOptions: getMuscleOptionsFromPartId(trp.part.id) || []
                      };
                    }),
                    mainComplaint: treatment.policy.id,
                    kCastFixed: treatment?.kCastFixation == 1 ? true : false,
                    kBandageExchange: treatment?.kChangesOfBandages == 1 ? true : false,
                    kNumberOfCircles: treatment?.kNoOfCircles ? String(treatment.kNoOfCircles) : '',
                    kNumberOfTapes: treatment?.kNoOfTapes ? String(treatment.kNoOfTapes) : '',
                    kNumberOfZeros: treatment?.kNoOfZeros ? String(treatment.kNoOfZeros) : '',
                    mNeckAndShoulder: treatment?.mNeckAndShoulder ? 'mNeckAndShoulder' : false,
                    mWUpperLimbZone: treatment?.mWUpperLimbZone ? 'mWUpperLimbZone' : false,
                    mLowerBack: treatment?.mLowerBack ? 'mLowerBack' : false,
                    mLumbarAbdomen: treatment?.mLumbarAbdomen ? 'mLumbarAbdomen' : false,
                    mLowerBackAndLowerLimbs: treatment?.mLowerBackAndLowerLimbs
                      ? 'mLowerBackAndLowerLimbs'
                      : false,
                    mUpperLimbBand: treatment?.mUpperLimbBand ? 'mUpperLimbBand' : false,
                    mLowerLimbTreatment: treatment?.mLowerLimbTreatment
                      ? 'mLowerLimbTreatment'
                      : false,
                    mWLowerLimbTreatment: treatment?.mWLowerLimbTreatment
                      ? 'mWLowerLimbTreatment'
                      : false
                  });
                }
              });
            } else {
              disableCreateNewButton.value = true;
              treatments.value.push({
                mainComplaint: '',
                progressFromLastTime: '',
                stage: '',
                todaySymptoms: [
                  {
                    location: '',
                    part: '',
                    sense: ''
                  }
                ],
                trpList: [
                  {
                    location: '',
                    part: '',
                    muscles: [],
                    muscleOptions: []
                  }
                ],
                mNeckAndShoulder: false,
                mLowerBack: false,
                mLumbarAbdomen: false,
                mLowerBackAndLowerLimbs: false,
                mUpperLimbBand: false,
                mWUpperLimbZone: false,
                mLowerLimbTreatment: false,
                mWLowerLimbTreatment: false,
                uHv3: false,
                uHv4: false,
                uHv5: false,
                uAcpAcupunture: false,
                uSetTheNeedle: false,
                uPulse: false,
                isKNumberOfCircles: false,
                isKNumberOfTapes: false,
                isKNumberOfZeros: false,
                kNumberOfCircles: '',
                kNumberOfTapes: '',
                kNumberOfZeros: '',
                kCastFixed: false,
                kBandageExchange: false,
                aWarmingTesting: false,
                aWarmingBooklet: false,
                aStretchingBooklet: false,
                aEquiten: false,
                aGoogleMapReview: false,
                aWhiteBoard: false,
                insuranceTreatment: false,
                trafficAccident: false
              });
              selectedTab.value = treatments.value.length - 1;
            }
          }
        }
      } else {
        const medicalRecordResponse = await axios(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/medical-records/${oldMedicalForContinious.value?.id}`,
          {
            headers: { Authorization: 'Bearer ' + store.getters.token }
          }
        );

        if (medicalRecordResponse.status === 200) {
          if (medicalRecordResponse?.data?.data) {
            const medicalRecordData = medicalRecordResponse.data.data;
            if (medicalRecordData.policy) {
              policies.value = medicalRecordData.policy;
              complaintOptions.value = policies.value.map((policy, index) => {
                return {
                  value: policy.id,
                  name: `${i18n.global.t('Policy.MainComplaint')} ${index + 1}`,
                  selected: false,
                  icon: require('@/assets/icons/check-solid.svg')
                };
              });
            }
          }
        }

        if (medicalRecordData.value.treatment && medicalRecordData.value.treatment.length > 0) {
          disableCreateNewButton.value = false;
        } else {
          disableCreateNewButton.value = true;
        }

        treatments.value = [];

        medicalRecordData.value.treatment.forEach((treatment: any) => {
          if (props.reservation.id === treatment.reservationId) {
            treatments.value.push({
              ...treatment,
              todaySymptoms: treatment.todaysSymptoms?.map((todaysSymptom: any) => {
                return {
                  location: todaysSymptom.location,
                  part: todaysSymptom.part.id,
                  sense: todaysSymptom.sense.id
                };
              }),
              trpList: treatment.trps.map((trp: any) => {
                return {
                  location: trp.location,
                  part: trp.part.id,
                  muscles: buildMuscleFromTrpDetails(trp?.trpDetails),
                  muscleOptions: getMuscleOptionsFromPartId(trp.part.id) || []
                };
              }),
              mainComplaint: treatment.policy.id,
              kCastFixed: treatment?.kCastFixation == 1 ? true : false,
              kBandageExchange: treatment?.kChangesOfBandages == 1 ? true : false,
              kNumberOfCircles: treatment?.kNoOfCircles ? String(treatment.kNoOfCircles) : '',
              kNumberOfTapes: treatment?.kNoOfTapes ? String(treatment.kNoOfTapes) : '',
              kNumberOfZeros: treatment?.kNoOfZeros ? String(treatment.kNoOfZeros) : '',
              mNeckAndShoulder: treatment?.mNeckAndShoulder ? 'mNeckAndShoulder' : false,
              mWUpperLimbZone: treatment?.mWUpperLimbZone ? 'mWUpperLimbZone' : false,
              mLowerBack: treatment?.mLowerBack ? 'mLowerBack' : false,
              mLumbarAbdomen: treatment?.mLumbarAbdomen ? 'mLumbarAbdomen' : false,
              mLowerBackAndLowerLimbs: treatment?.mLowerBackAndLowerLimbs
                ? 'mLowerBackAndLowerLimbs'
                : false,
              mUpperLimbBand: treatment?.mUpperLimbBand ? 'mUpperLimbBand' : false,
              mLowerLimbTreatment: treatment?.mLowerLimbTreatment ? 'mLowerLimbTreatment' : false,
              mWLowerLimbTreatment: treatment?.mWLowerLimbTreatment ? 'mWLowerLimbTreatment' : false
            });
          }
        });

        // add a treatment form when theres no treatments at the start
      }
      if (treatments.value.length === 0) {
        treatments.value.push({
          mainComplaint: '',
          progressFromLastTime: '',
          stage: '',
          todaySymptoms: [
            {
              location: '',
              part: '',
              sense: ''
            }
          ],
          trpList: [
            {
              location: '',
              part: '',
              muscles: [],
              muscleOptions: []
            }
          ],
          mNeckAndShoulder: false,
          mLowerBack: false,
          mLumbarAbdomen: false,
          mLowerBackAndLowerLimbs: false,
          mUpperLimbBand: false,
          mWUpperLimbZone: false,
          mLowerLimbTreatment: false,
          mWLowerLimbTreatment: false,
          uHv3: false,
          uHv4: false,
          uHv5: false,
          uAcpAcupunture: false,
          uSetTheNeedle: false,
          uPulse: false,
          isKNumberOfCircles: false,
          isKNumberOfTapes: false,
          isKNumberOfZeros: false,
          kNumberOfCircles: '',
          kNumberOfTapes: '',
          kNumberOfZeros: '',
          kCastFixed: false,
          kBandageExchange: false,
          aWarmingTesting: false,
          aWarmingBooklet: false,
          aStretchingBooklet: false,
          aEquiten: false,
          aGoogleMapReview: false,
          aWhiteBoard: false,
          insuranceTreatment: false,
          trafficAccident: false
        });
      }

      selectedTab.value = treatments.value.length - 1;
    };

    const buildMuscleFromTrpDetails = (trpDetails: any): Array<number> => {
      let muscles: Array<number> = [];
      if (trpDetails) {
        trpDetails.forEach((trpDetail: any) => {
          if (trpDetail.muscle && trpDetail.muscle?.id) {
            muscles.push(trpDetail.muscle.id);
          }
        });
      }
      return muscles;
    };

    const clearError = () => {
      formErrors.value = [];
      muscleErrors.value = [];
      keepErrors.value = [];
    };

    const clearMainMenu = (index: number) => {
      treatments.value[index].mNeckAndShoulder = false;
      treatments.value[index].mWUpperLimbZone = false;
      treatments.value[index].mLowerBack = false;
      treatments.value[index].mLumbarAbdomen = false;
      treatments.value[index].mLowerBackAndLowerLimbs = false;
      treatments.value[index].mUpperLimbBand = false;
      treatments.value[index].mLowerLimbTreatment = false;
      treatments.value[index].mWLowerLimbTreatment = false;
    };

    const onPartSelect = (partId: number, treatmentIndex: number, trpIndex: number) => {
      if (partId) {
        // treatments.value[treatmentIndex].todaySymptoms[trpIndex].part = partId.toString(); //not changing todays symptom section when trp section is changed
        const muscleOptions = getMuscleOptionsFromPartId(partId);
        // treatments.value[treatmentIndex].trpList[trpIndex].muscles = [];
        treatments.value[treatmentIndex].trpList[trpIndex].muscleOptions = muscleOptions;
      }
    };

    const onTrpLocationSelect = (trpLocation: number, treatmentIndex: number, trpIndex: number) => {
      if (trpLocation) {
        // treatments.value[treatmentIndex].todaySymptoms[trpIndex].location = trpLocation.toString();//not changing todays symptom section when trp section is changed
      }
    };

    const onTodaySymptomLocationSelect = (
      location: number,
      treatmentIndex: number,
      todaySymptomIndex: number
    ) => {
      if (location && todaySymptomIndex == 0) {
        treatments.value[treatmentIndex].trpList[todaySymptomIndex].location = location.toString();
      }
    };

    const onTodaySymptomPartSelect = (
      todaySymptomsPart: number,
      treatmentIndex: number,
      todaySymptomIndex: number
    ) => {
      if (todaySymptomsPart && todaySymptomIndex == 0) {
        treatments.value[treatmentIndex].trpList[todaySymptomIndex].part =
          todaySymptomsPart.toString();
        const muscleOptions = getMuscleOptionsFromPartId(todaySymptomsPart);
        treatments.value[treatmentIndex].trpList[todaySymptomIndex].muscleOptions = muscleOptions;
        treatments.value[treatmentIndex].trpList.forEach((trp) => {
          trp.muscles = [];
        });
      }
    };

    const addTodaySymptom = (treatmentIndex: number) => {
      treatments.value[treatmentIndex].todaySymptoms.push({
        location: '',
        part: '',
        sense: ''
      });
      // treatments.value[treatmentIndex].trpList.push({
      //   location: '',
      //   part: '',
      //   muscles: [],
      //   muscleOptions: []
      // }); // not adding trp when symptoms are added
    };

    const update = (radioItem: string, index: number) => {
      // debugger;
      // const treatmentData : any = treatments.value[index];
      if (radioItem === 'mNeckAndShoulder') {
        treatments.value[index].mLowerBack = false;
        treatments.value[index].mLumbarAbdomen = false;
        treatments.value[index].mLowerBackAndLowerLimbs = false;
        treatments.value[index].mWUpperLimbZone = false;
        treatments.value[index].mUpperLimbBand = false;
        treatments.value[index].mLowerLimbTreatment = false;
        treatments.value[index].mWLowerLimbTreatment = false;
      } else if (radioItem == 'mLowerBack') {
        treatments.value[index].mNeckAndShoulder = false;
        treatments.value[index].mLumbarAbdomen = false;
        treatments.value[index].mLowerBackAndLowerLimbs = false;
        treatments.value[index].mWUpperLimbZone = false;
        treatments.value[index].mUpperLimbBand = false;
        treatments.value[index].mLowerLimbTreatment = false;
        treatments.value[index].mWLowerLimbTreatment = false;
      } else if (radioItem == 'mLumbarAbdomen') {
        treatments.value[index].mNeckAndShoulder = false;
        treatments.value[index].mLowerBack = false;
        treatments.value[index].mLowerBackAndLowerLimbs = false;
        treatments.value[index].mWUpperLimbZone = false;
        treatments.value[index].mUpperLimbBand = false;
        treatments.value[index].mLowerLimbTreatment = false;
        treatments.value[index].mWLowerLimbTreatment = false;
      } else if (radioItem == 'mLowerBackAndLowerLimbs') {
        treatments.value[index].mNeckAndShoulder = false;
        treatments.value[index].mLowerBack = false;
        treatments.value[index].mLumbarAbdomen = false;
        treatments.value[index].mWUpperLimbZone = false;
        treatments.value[index].mUpperLimbBand = false;
        treatments.value[index].mLowerLimbTreatment = false;
        treatments.value[index].mWLowerLimbTreatment = false;
      } else if (radioItem == 'mUpperLimbBand') {
        treatments.value[index].mNeckAndShoulder = false;
        treatments.value[index].mLowerBack = false;
        treatments.value[index].mLumbarAbdomen = false;
        treatments.value[index].mWUpperLimbZone = false;
        treatments.value[index].mLowerBackAndLowerLimbs = false;
        treatments.value[index].mLowerLimbTreatment = false;
        treatments.value[index].mWLowerLimbTreatment = false;
      } else if (radioItem == 'mWUpperLimbZone') {
        treatments.value[index].mNeckAndShoulder = false;
        treatments.value[index].mLowerBack = false;
        treatments.value[index].mLumbarAbdomen = false;
        treatments.value[index].mUpperLimbBand = false;
        treatments.value[index].mLowerBackAndLowerLimbs = false;
        treatments.value[index].mLowerLimbTreatment = false;
        treatments.value[index].mWLowerLimbTreatment = false;
      } else if (radioItem == 'mLowerLimbTreatment') {
        treatments.value[index].mNeckAndShoulder = false;
        treatments.value[index].mLowerBack = false;
        treatments.value[index].mLumbarAbdomen = false;
        treatments.value[index].mUpperLimbBand = false;
        treatments.value[index].mLowerBackAndLowerLimbs = false;
        treatments.value[index].mWUpperLimbZone = false;
        treatments.value[index].mWLowerLimbTreatment = false;
      } else if (radioItem == 'mWLowerLimbTreatment') {
        treatments.value[index].mNeckAndShoulder = false;
        treatments.value[index].mLowerBack = false;
        treatments.value[index].mLumbarAbdomen = false;
        treatments.value[index].mUpperLimbBand = false;
        treatments.value[index].mLowerBackAndLowerLimbs = false;
        treatments.value[index].mWUpperLimbZone = false;
        treatments.value[index].mLowerLimbTreatment = false;
      }
    };

    const removeTodaySymptom = (
      treatment: Treatment,
      todaySymptom: TodaySymptom,
      treatmentIndex: number,
      todaySymptomIndex: number
    ) => {
      if (treatments.value[treatmentIndex].todaySymptoms.length > 1) {
        treatments.value[treatmentIndex].todaySymptoms.splice(todaySymptomIndex, 1);
      }
      // if (treatments.value[treatmentIndex].trpList.length > 1) {
      //   treatments.value[treatmentIndex].trpList.splice(todaySymptomIndex, 1);
      // }
    };

    const addTrp = (treatmentIndex: number) => {
      treatments.value[treatmentIndex].trpList.push({
        location: '',
        part: '',
        muscles: [],
        muscleOptions: []
      });
      // treatments.value[treatmentIndex].todaySymptoms.push({ add trp wont add above todaysymptoms
      //   location: '',
      //   part: '',
      //   sense: ''
      // });
    };

    const removeTrp = (
      treatment: Treatment,
      trp: Trp,
      treatmentIndex: number,
      trpIndex: number
    ) => {
      if (treatments.value[treatmentIndex].trpList.length > 1) {
        treatments.value[treatmentIndex].trpList.splice(trpIndex, 1);
      }
      // if (treatments.value[treatmentIndex].todaySymptoms.length > 1) { //removing trp will not remove todays symtoms
      //   treatments.value[treatmentIndex].todaySymptoms.splice(trpIndex, 1);
      // }
    };

    const getMuscleOptionsFromPartId = (partId: number) => {
      const part = parts.filter((part) => part.id === partId)[0];
      return part ? part.muscles : [];
    };

    const toggleMpGraph = () => {
      showMpGraph.value = !showMpGraph.value;
    };
    let isValidated = ref(false);
    const registerTreatment = async (treatment: Treatment, treatmentIndex: number) => {
      isValidated.value = true;
      formErrors.value = [];
      muscleErrors.value = [];
      keepErrors.value = [];
      if (formSubmitted.value) {
        return;
      }
      formSubmitted.value = true;

      if (!treatment.mainComplaint) {
        // formErrors.value.push({ title: "Error", message: "Required" });
        keepErrors.value.push({
          title: 'mainComplaint',
          message: `${i18n.global.t('Required')}`
        });
      }

      if (treatment.stage === null || typeof treatment.stage === 'string') {
        formErrors.value.push({ title: 'Error', message: 'Required' });
        isValidated.value = false;
      }

      if (
        typeof treatment.mNeckAndShoulder === 'string' ||
        typeof treatment.mWUpperLimbZone === 'string' ||
        typeof treatment.mLowerBack === 'string' ||
        typeof treatment.mLumbarAbdomen === 'string' ||
        typeof treatment.mLowerBackAndLowerLimbs === 'string' ||
        typeof treatment.mUpperLimbBand === 'string' ||
        typeof treatment.mWLowerLimbTreatment === 'string' ||
        typeof treatment.mLowerLimbTreatment === 'string'
      ) {
        treatment.trpList.forEach((trp) => {
          if (!trp.part) {
            formErrors.value.push({ title: 'trpPart', message: 'Required' });
          }
        });
        treatment.trpList.forEach((trp) => {
          if (!trp.location) {
            formErrors.value.push({ title: 'trpLocation', message: 'Required' });
          }
        });
        treatment.trpList.forEach(async (trp, trpIndex) => {
          if (trp?.muscles.length === 0) {
            muscleErrors.value.push({ title: 'Error', message: 'Required' });
          }
        });

        treatment.trpList.forEach((trp) => {
          if (trp.muscles.length > 0) {
            if (
              typeof treatment.mNeckAndShoulder !== 'string' &&
              typeof treatment.mWUpperLimbZone !== 'string' &&
              typeof treatment.mLowerBack !== 'string' &&
              typeof treatment.mLumbarAbdomen !== 'string' &&
              typeof treatment.mLowerBackAndLowerLimbs !== 'string' &&
              typeof treatment.mUpperLimbBand !== 'string' &&
              typeof treatment.mWLowerLimbTreatment !== 'string' &&
              typeof treatment.mLowerLimbTreatment !== 'string'
            ) {
              formErrors.value.push({
                title: 'mainMenu',
                message: `${i18n.global.t('Errors.E061')}`
              });
            }
          }
        });
      }

      treatment.trpList.forEach((trp) => {
        if (trp.part === null) {
          //if part is deselected make the muscle selection part empty as well
          trp.muscles = [];
        }
        if (trp.part || trp.location || trp.muscles.length > 0) {
          if (
            typeof treatment.mNeckAndShoulder !== 'string' &&
            typeof treatment.mWUpperLimbZone !== 'string' &&
            typeof treatment.mLowerBack !== 'string' &&
            typeof treatment.mLumbarAbdomen !== 'string' &&
            typeof treatment.mLowerBackAndLowerLimbs !== 'string' &&
            typeof treatment.mUpperLimbBand !== 'string' &&
            typeof treatment.mWLowerLimbTreatment !== 'string' &&
            typeof treatment.mLowerLimbTreatment !== 'string'
          ) {
            toast.error(i18n.global.t('Errors.E056'), {
              timeout: 2000,
              position: POSITION.BOTTOM_RIGHT
            });
            // return;
            formErrors.value.push({
              title: 'mainMenu',
              message: `${i18n.global.t('Errors.E056')}`
            });
          }
        }
      });

      // if (treatment.trpList.length > 0) {
      //   if (typeof treatment.mNeckAndShoulder !== 'string' && typeof treatment.mLowerBack !== 'string' && typeof treatment.mLumbarAbdomen !== 'string' && typeof treatment.mLowerBackAndLowerLimbs !== 'string' && typeof treatment.mUpperLimbBand !== 'string' && typeof treatment.mWLowerLimbTreatment !== 'string' && typeof treatment.mLowerLimbTreatment !== 'string') {
      //     formErrors.value.push({
      //       title: 'mainMenu',
      //       message: `${i18n.global.t('Errors.E061')}`
      //     })
      //   }
      // }
      treatment.todaySymptoms.forEach((symptom) => {
        if (!symptom.location || !symptom.part || !symptom.sense) {
          formErrors.value.push({ title: 'Error', message: 'Required' });
        }
      });
      // if (!treatment.kNumberOfCircles) {
      //   keepErrors.value.push({
      //     title: 'kNumberOfCircles',
      //     message: `${i18n.global.t('Errors.E061')}`
      //   });
      // }
      if (treatment.kNumberOfCircles) {
        validationEffect('kNumberOfCircles', treatment.kNumberOfCircles);
      }

      // if (!treatment.kNumberOfTapes) {
      //   keepErrors.value.push({
      //     title: 'kNumberOfTapes',
      //     message: `${i18n.global.t('Errors.E061')}`
      //   });
      // }
      if (treatment.kNumberOfTapes) {
        validationEffect('kNumberOfTapes', treatment.kNumberOfTapes);
      }
      // if (!treatment.kNumberOfZeros) {
      //   keepErrors.value.push({
      //     title: 'kNumberOfZeros',
      //     message: `${i18n.global.t('Errors.E061')}`
      //   });
      // }
      if (treatment.kNumberOfZeros) {
        validationEffect('kNumberOfZeros', treatment.kNumberOfZeros);
      }

      if (
        !treatment.mNeckAndShoulder &&
        !treatment.mWUpperLimbZone &&
        !treatment.mLowerBack &&
        !treatment.mLumbarAbdomen &&
        !treatment.mLowerBackAndLowerLimbs &&
        !treatment.mUpperLimbBand &&
        !treatment.mWLowerLimbTreatment &&
        !treatment.mLowerLimbTreatment &&
        !treatment.uHv3 &&
        !treatment.uHv4 &&
        !treatment.uHv5 &&
        !treatment.uAcpAcupunture &&
        !treatment.uSetTheNeedle &&
        !treatment.kNumberOfCircles &&
        !treatment.kNumberOfCircles &&
        !treatment.kNumberOfTapes &&
        !treatment.kCastFixed &&
        !treatment.kBandageExchange
      ) {
        toast.error(i18n.global.t('Errors.E055'), {
          timeout: 2000,
          position: POSITION.BOTTOM_RIGHT
        });
        formSubmitted.value = false;
        return;
      }

      if (
        formErrors.value.length > 0 ||
        muscleErrors.value.length > 0 ||
        keepErrors.value.length > 0
      ) {
        formSubmitted.value = false;
        return;
      }

      let formData = new FormData();
      let treatmentUrl = '/api/v1/treatments';
      if (treatment?.id) {
        formData.append('_method', 'PUT');
        treatmentUrl += `/${treatment?.id}`;
      }

      formData.append('medical_record_id', medicalRecordData.value?.id);
      // formData.append("reservation_id", reservationData.value?.id);
      formData.append('reservation_id', props.reservation?.id);
      formData.append('policy_id', treatment.mainComplaint);
      formData.append('progress_from_last_time', treatment.progressFromLastTime);
      formData.append('stage', treatment.stage);
      //
      formData.append(
        'm_neck_and_shoulder',
        typeof treatment.mNeckAndShoulder === 'string' ? '1' : '0'
      );
      formData.append('lower_back', typeof treatment.mLowerBack === 'string' ? '1' : '0');
      formData.append('lumbar_abdomen', typeof treatment.mLumbarAbdomen === 'string' ? '1' : '0');
      formData.append(
        'lower_back_and_lower_limb',
        typeof treatment.mLowerBackAndLowerLimbs === 'string' ? '1' : '0'
      );
      formData.append('upper_limb_band', typeof treatment.mUpperLimbBand === 'string' ? '1' : '0');
      formData.append(
        'w_upper_limb_zone',
        typeof treatment.mWUpperLimbZone === 'string' ? '1' : '0'
      );
      formData.append(
        'lower_limb_treatment',
        typeof treatment.mLowerLimbTreatment === 'string' ? '1' : '0'
      );
      formData.append(
        'w_lower_limb_treatment',
        typeof treatment.mWLowerLimbTreatment === 'string' ? '1' : '0'
      );
      // formData.append('m_girdle', treatment.mGirdle ? '1' : '0');
      // formData.append('m_w_shoulder_joint', treatment.mWShoulderJoint ? '1' : '0');
      // formData.append('m_w_forearm', treatment.mWForearm ? '1' : '0');
      // formData.append('m_w_lower_leg', treatment.mWLowerLeg ? '1' : '0');
      // formData.append('m_lateral_hip', treatment.mLateralHip ? '1' : '0');
      //
      formData.append('u_hv3', treatment.uHv3 ? '1' : '0');
      formData.append('u_hv4', treatment.uHv4 ? '1' : '0');
      formData.append('u_hv5', treatment.uHv5 ? '1' : '0');
      formData.append('u_acp_acupunture', treatment.uAcpAcupunture ? '1' : '0');
      formData.append('u_set_the_needle', treatment.uSetTheNeedle ? '1' : '0');
      formData.append('u_pulse', treatment.uPulse ? '1' : '0');
      //
      formData.append(
        'k_no_of_circles',
        treatment.kNumberOfCircles ? treatment.kNumberOfCircles : ''
      );
      formData.append('k_no_of_tapes', treatment.kNumberOfTapes ? treatment.kNumberOfTapes : '');
      formData.append('k_no_of_zeros', treatment.kNumberOfZeros ? treatment.kNumberOfZeros : '');
      //
      formData.append('k_cast_fixation', treatment.kCastFixed ? '1' : '0');
      formData.append('k_changes_of_bandages', treatment.kBandageExchange ? '1' : '0');
      //
      formData.append('a_warming_tasting', treatment.aWarmingTesting ? '1' : '0');
      formData.append('a_warming_booklet', treatment.aWarmingBooklet ? '1' : '0');
      formData.append('a_stretching_booklet', treatment.aStretchingBooklet ? '1' : '0');
      formData.append('a_equiten', treatment.aEquiten ? '1' : '0');
      formData.append('a_google_map_review', treatment.aGoogleMapReview ? '1' : '0');
      formData.append('a_white_board', treatment.aWhiteBoard ? '1' : '0');
      //
      formData.append('insurance_treatment', treatment.insuranceTreatment ? '1' : '0');
      formData.append('traffic_accident', treatment.trafficAccident ? '1' : '0');
      //
      treatment.todaySymptoms.forEach(async (symptom, symptomIndex) => {
        formData.append(`s_location[]`, symptom.location);
        formData.append(`s_part_id[]`, symptom.part);
        formData.append(`s_sense_id[]`, symptom.sense);
      });
      //
      treatment.trpList.forEach(async (trp, trpIndex) => {
        formData.append(`t_location[]`, trp.location);
        formData.append(`t_part_id[]`, trp.part);
        trp.muscles.forEach((muscle) => {
          formData.append(`t_muscle_id[${trpIndex}][]`, String(muscle));
        });
      });

      return await post(treatmentUrl, formData)
        .then((treatmentRes) => {
          if (treatmentRes.status === 200) {
            window.scrollTo(0, 0);
            disableCreateNewButton.value = false;
            treatments.value[treatmentIndex].id = treatmentRes.data.data.id;
            if (treatment?.id) {
              toast.success('更新しました', {
                timeout: 2000,
                position: POSITION.BOTTOM_RIGHT
              });
            } else {
              toast.success('登録しました', {
                timeout: 2000,
                position: POSITION.BOTTOM_RIGHT
              });
            }
            formSubmitted.value = false;
            emit('updateReservation', true);
          }
        })
        .catch((err) => {
          formSubmitted.value = false;
          disableCreateNewButton.value = true;
        });
    };

    const selectTreatment = (treatment: Treatment, treatmentIndex: number) => {
      selectedTreatment.value = treatment;
      selectedTreatmentIndex.value = treatmentIndex;
    };

    const removeTreatment = () => {
      if (formDeletionSubmitted.value) {
        return;
      }
      formDeletionSubmitted.value = true;
      if (selectedTreatment.value?.id) {
        remove(`/api/v1/treatments/${selectedTreatment.value?.id}`).then((res) => {
          if (res.status === 200) {
            toast.success('削除しました', {
              timeout: 2000,
              position: POSITION.BOTTOM_RIGHT
            });
            const data = res.data.data;
            treatments.value.splice(selectedTreatmentIndex.value, 1);
            formDeletionSubmitted.value = false;
            if (treatments.value.length === 1 && selectedTreatmentIndex.value !== 0) {
              disableCreateNewButton.value = false;
            } else {
              let hasTreatment = treatments.value.filter((el) => {
                return el.progressFromLastTime === '';
              });
              if (hasTreatment.length > 0) {
                disableCreateNewButton.value = true;
              } else {
                disableCreateNewButton.value = false;
              }
            }
            selectedTab.value =
              treatments.value.length - 1 < selectedTreatmentIndex.value
                ? selectedTreatmentIndex.value - 1
                : selectedTreatmentIndex.value;
            emit('updateReservation', true);
          }
        });
      } else {
        treatments.value.splice(selectedTreatmentIndex.value, 1);
        formDeletionSubmitted.value = false;
        if (treatments.value.length === 1 && selectedTreatmentIndex.value !== 0) {
          disableCreateNewButton.value = false;
        } else {
          let hasTreatment = treatments.value.filter((el) => {
            return el.progressFromLastTime === '';
          });
          if (hasTreatment.length > 0) {
            disableCreateNewButton.value = true;
          } else {
            disableCreateNewButton.value = false;
          }
        }
        selectedTab.value =
          treatments.value.length - 1 < selectedTreatmentIndex.value
            ? selectedTreatmentIndex.value - 1
            : selectedTreatmentIndex.value;
      }
    };

    const createNewTreatment = () => {
      clearError();
      disableCreateNewButton.value = true;
      treatments.value.push({
        mainComplaint: '',
        progressFromLastTime: '',
        stage: '',
        todaySymptoms: [
          {
            location: '',
            part: '',
            sense: ''
          }
        ],
        trpList: [
          {
            location: '',
            part: '',
            muscles: [],
            muscleOptions: []
          }
        ],
        mNeckAndShoulder: false,
        mLowerBack: false,
        mLumbarAbdomen: false,
        mLowerBackAndLowerLimbs: false,
        mUpperLimbBand: false,
        mWUpperLimbZone: false,
        mLowerLimbTreatment: false,
        mWLowerLimbTreatment: false,
        uHv3: false,
        uHv4: false,
        uHv5: false,
        uAcpAcupunture: false,
        uSetTheNeedle: false,
        uPulse: false,
        isKNumberOfCircles: false,
        isKNumberOfTapes: false,
        isKNumberOfZeros: false,
        kNumberOfCircles: '',
        kNumberOfTapes: '',
        kNumberOfZeros: '',
        kCastFixed: false,
        kBandageExchange: false,
        aWarmingTesting: false,
        aWarmingBooklet: false,
        aStretchingBooklet: false,
        aEquiten: false,
        aGoogleMapReview: false,
        aWhiteBoard: false,
        insuranceTreatment: false,
        trafficAccident: false
      });
      selectedTab.value = treatments.value.length - 1;
    };

    const filterErrorColor = (fieldName: any, location: any) => {
      if (keepErrors.value.length > 0) {
        const errorMessage = keepErrors.value.filter((x) => x.title === fieldName);
        if (errorMessage && errorMessage.length > 0) {
          return true;
        } else if (errorMessage.length === 0) {
          return false;
        }
      } else if (formErrors.value.length > 0) {
        const errorMessage = formErrors.value.filter((x) => x.title === fieldName);
        if (errorMessage && errorMessage.length > 0 && !location) {
          return true;
        } else if (errorMessage.length === 0) {
          return false;
        }
      }
    };

    const filterErrors = (data: any) => {
      if (keepErrors.value.length > 0) {
        const errorMessage = keepErrors.value.filter((x) => x.title === data);
        return errorMessage[0]?.message;
      } else if (formErrors.value && formErrors.value.length > 0) {
        const errorMessage = formErrors.value.filter((x) => x.title === data);
        return errorMessage[0]?.message;
      }
    };
    const validationEffect = (data: any, value: any) => {
      // keepErrors.value = [];
      if (data === 'kNumberOfCircles') {
        // treatments.value.forEach(async (trp, trpIndex) => {
        const getValue1 = value;
        var numberPattern1 = /^[0-9]+$/g;
        const test = numberPattern1.test(getValue1);
        if (test === true) {
          switch (true) {
            case Number(getValue1) == 0:
              keepErrors.value.push({
                title: 'kNumberOfCircles',
                message: `${i18n.global.t('Errors.E063')}`
              });
              break;
            case Number(getValue1) == 1:
              break;
            case Number(getValue1) == 2:
              break;
            case Number(getValue1) == 3:
              break;
            case Number(getValue1) == 4:
              break;
            case Number(getValue1) == 5:
              break;
            case Number(getValue1) == 6:
              break;
            case Number(getValue1) == 7:
              break;
            case Number(getValue1) == 8:
              break;
            case Number(getValue1) == 9:
              break;
            case Number(getValue1) == 10:
              break;
            default:
              keepErrors.value.push({
                title: 'kNumberOfCircles',
                message: `${i18n.global.t('Errors.E060')}`
              });
          }
        } else {
          keepErrors.value.push({
            title: 'kNumberOfCircles',
            message: `${i18n.global.t('Errors.E063')}`
            // message: `${i18n.global.t('Errors.E063')}`
          });
        }
        // });
      } else if (data === 'kNumberOfTapes') {
        // treatments.value.forEach(async (trp, trpIndex) => {
        const getValue1 = value;
        var numberPattern2 = /^[0-9]+$/g;
        const test = numberPattern2.test(getValue1);
        if (test === true) {
          switch (true) {
            case Number(getValue1) == 0:
              keepErrors.value.push({
                title: 'kNumberOfTapes',
                message: `${i18n.global.t('Errors.E063')}`
              });
              break;
            case Number(getValue1) == 1:
              break;
            case Number(getValue1) == 2:
              break;
            case Number(getValue1) == 3:
              break;
            case Number(getValue1) == 4:
              break;
            case Number(getValue1) == 5:
              break;
            default:
              keepErrors.value.push({
                title: 'kNumberOfTapes',
                message: `${i18n.global.t('Errors.E060')}`
              });
          }
        } else {
          keepErrors.value.push({
            title: 'kNumberOfTapes',
            // message: `${i18n.global.t('Errors.E063')}`
            message: `${i18n.global.t('Errors.E063')}`
          });
        }
        // });
      } else if (data === 'kNumberOfZeros') {
        // treatments.value.forEach(async (trp, trpIndex) => {
        const getValue1 = value;
        var numberPattern3 = /^[0-9]+$/g;
        const test = numberPattern3.test(getValue1);
        if (test === true) {
          switch (true) {
            case Number(getValue1) == 0:
              keepErrors.value.push({
                title: 'kNumberOfZeros',
                message: `${i18n.global.t('Errors.E063')}`
              });
              break;
            case Number(getValue1) == 1:
              break;
            case Number(getValue1) == 2:
              break;
            case Number(getValue1) == 3:
              break;
            case Number(getValue1) == 4:
              break;
            case Number(getValue1) == 5:
              break;
            default:
              keepErrors.value.push({
                title: 'kNumberOfZeros',
                message: `${i18n.global.t('Errors.E060')}`
              });
          }
        } else {
          keepErrors.value.push({
            title: 'kNumberOfZeros',
            message: `${i18n.global.t('Errors.E063')}`
            // message: `${i18n.global.t('Errors.E063')}`
          });
        }
        // });
      }
    };

    return {
      filterErrorColor,
      filterErrors,
      validationEffect,
      convertToFullWidthKana,
      onTrpLocationSelect,
      onTodaySymptomPartSelect,
      onTodaySymptomLocationSelect,
      muscleErrors,
      reservationData,
      selectedTab,
      treatments,
      locationOptions,
      partOptions,
      complaintOptions,
      disableCreateNewButton,
      stageOptions,
      senseOptions,
      policies,
      formSubmitted,
      formDeletionSubmitted,
      showMpGraph,
      formErrors,
      createNewTreatment,
      selectTreatment,
      registerTreatment,
      isValidated,
      removeTreatment,
      addTodaySymptom,
      removeTodaySymptom,
      onPartSelect,
      addTrp,
      removeTrp,
      toggleMpGraph,
      update,
      clearError,
      clearMainMenu
    };
  }
});
