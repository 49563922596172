import { AxiosResponse } from "axios";
import { fetch } from "./api-service";

export const fetchPostalCode = (postalCode: string): Promise<AxiosResponse> => {
  return new Promise((resolve, reject) => {
    fetch(`/api/v1/postal-codes/${postalCode}`)
      .then((res) => resolve(res))
      .catch((err) => reject(err));
  });
};
