
import { defineComponent } from "vue";
import PersonalInformation from "../components/PersonalInformation.vue";

export default defineComponent({
  name: "PreliminaryExaminaionSlip",
  components: {
    PersonalInformation,
  },
});
