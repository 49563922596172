import { AxiosResponse } from "axios";
import { store as post } from "./api-service";
import { store } from "@/core/store";

export const createTemoraryTransaction = (
  customerNumber: string,
  storeId: string,
  fromPage: "reservation" | "customer",
  reservationId?: string,
  practitionerId?: string
): Promise<AxiosResponse> => {
  const formData = new FormData();
  formData.append("customer_number", customerNumber);
  reservationId && formData.append("reservation_id", reservationId);
  formData.append("store_id", storeId);
  practitionerId && formData.append("practitioner_id", practitionerId);
  formData.append("employee_id", store.getters.user.id);

  return new Promise((resolve, reject) => {
    if (customerNumber) {
      post(`/api/v1/provisional-sales?from=${fromPage}`, formData)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    } else {
      reject();
    }
  });
};
