import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../assets/images/card.svg'
import _imports_1 from '../assets/icons/salesMemberFlag.svg'


const _withScopeId = n => (_pushScopeId("data-v-9fc8b654"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "details-card position-relative"
}
const _hoisted_2 = { class: "d-flex align-items-end justify-content-center" }
const _hoisted_3 = {
  key: 0,
  class: "visit-button white-box font-18"
}
const _hoisted_4 = {
  key: 1,
  class: "card-image me-2",
  src: _imports_0,
  alt: "123reservation"
}
const _hoisted_5 = {
  key: 2,
  class: "card-image me-2",
  src: _imports_1,
  alt: "123reservation"
}
const _hoisted_6 = {
  key: 4,
  style: {"color":"#444444 !important"},
  class: "name fw-bold text-primary font-18"
}
const _hoisted_7 = { class: "respect" }
const _hoisted_8 = { class: "form-check" }
const _hoisted_9 = ["checked"]
const _hoisted_10 = {
  class: "form-check-label fw-normal",
  for: "HVNotPossible"
}
const _hoisted_11 = { class: "form-check" }
const _hoisted_12 = ["checked"]
const _hoisted_13 = {
  class: "form-check-label fw-normal",
  for: "NoAcupuncture"
}
const _hoisted_14 = { class: "form-check" }
const _hoisted_15 = ["checked"]
const _hoisted_16 = {
  class: "form-check-label fw-normal",
  for: "MetalHas"
}
const _hoisted_17 = { class: "buttons-container d-flex justify-content-center" }
const _hoisted_18 = { class: "white-box" }
const _hoisted_19 = { class: "fw-bold" }
const _hoisted_20 = { class: "number" }
const _hoisted_21 = { class: "text-black" }
const _hoisted_22 = { class: "last-text" }
const _hoisted_23 = { class: "white-box first-box" }
const _hoisted_24 = { class: "fw-bold" }
const _hoisted_25 = { class: "number" }
const _hoisted_26 = { class: "text-black" }
const _hoisted_27 = { class: "last-text" }
const _hoisted_28 = { class: "white-box" }
const _hoisted_29 = { class: "fw-bold" }
const _hoisted_30 = { class: "number" }
const _hoisted_31 = { class: "text-black" }
const _hoisted_32 = { class: "last-text" }
const _hoisted_33 = { class: "white-box" }
const _hoisted_34 = { class: "fw-bold" }
const _hoisted_35 = { class: "number" }
const _hoisted_36 = { class: "text-black" }
const _hoisted_37 = { class: "last-text" }
const _hoisted_38 = {
  key: 0,
  class: "last-visit-date-text position-absolute"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.reservation)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.reservation?.id)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.consultationClassifications[_ctx.reservation.consultationClassification]), 1))
            : _createCommentVNode("", true),
          (_ctx.reservation.customer?.membershipFlag)
            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
            : _createCommentVNode("", true),
          (_ctx.reservation.customer?.salesMemberFlag)
            ? (_openBlock(), _createElementBlock("img", _hoisted_5))
            : _createCommentVNode("", true),
          (_ctx.reservation.customer?.customerNumber)
            ? (_openBlock(), _createElementBlock("span", {
                key: 3,
                class: "name fw-bold text-primary font-18",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.redirectToCustomerDetail(_ctx.reservation))),
                style: {"cursor":"pointer"}
              }, [
                _createElementVNode("u", null, _toDisplayString(_ctx.reservation.customer?.customerNumber) + _toDisplayString(_ctx.reservation?.customer && _ctx.reservation?.customer?.surname && _ctx.reservation?.customer?.name
              ? ' ' + _ctx.reservation.customer?.surname + ' ' + _ctx.reservation.customer?.name
              : _ctx.reservation?.customer?.surname 
              ? ' ' + _ctx.reservation?.customer.surname
              : ' ' + _ctx.reservation.reservationPerson), 1)
              ]))
            : _createCommentVNode("", true),
          (!_ctx.reservation.customer?.customerNumber)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.reservation.customer?.customerNumber) + " " + _toDisplayString(_ctx.reservation.customer?.surname && _ctx.reservation.customer?.name
            ? _ctx.reservation.customer.surname + ' ' + _ctx.reservation.customer.name
            : _ctx.reservation.reservationPerson), 1))
            : _createCommentVNode("", true),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('ReservationDetails.Mr')), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("input", {
              class: "form-check-input",
              type: "checkbox",
              value: "block",
              id: "HVNotPossible",
              checked: _ctx.reservation?.customer?.hvNotPossible,
              disabled: ""
            }, null, 8, _hoisted_9),
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t('ReservationDetails.HVNotPossible')), 1)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("input", {
              class: "form-check-input",
              type: "checkbox",
              value: "block",
              id: "NoAcupuncture",
              checked: _ctx.reservation?.customer?.noAcupunture,
              disabled: ""
            }, null, 8, _hoisted_12),
            _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('ReservationDetails.NoAcupuncture')), 1)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("input", {
              class: "form-check-input",
              type: "checkbox",
              value: "block",
              id: "MetalHas",
              checked: _ctx.reservation?.customer?.metalHas,
              disabled: ""
            }, null, 8, _hoisted_15),
            _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t('ReservationDetails.MetalHas')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t('ReservationDetails.RemnantCoupons')) + ":", 1),
            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.reservation?.customer?.remainingCoupon ? _ctx.reservation?.customer?.remainingCoupon : '-'), 1),
            _createElementVNode("span", _hoisted_21, [
              _createElementVNode("span", _hoisted_22, _toDisplayString(_ctx.$t('ReservationDetails.Sheet')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("span", _hoisted_24, _toDisplayString(_ctx.$t('ReservationDetails.DisabledMemberCoupon')) + ":", 1),
            _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.reservation?.customer?.memberRemainingCoupon
              ? _ctx.reservation?.customer?.memberRemainingCoupon
              : '-'), 1),
            _createElementVNode("span", _hoisted_26, [
              _createElementVNode("span", _hoisted_27, _toDisplayString(_ctx.$t('ReservationDetails.Sheet')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("span", _hoisted_29, _toDisplayString(_ctx.$t('ReservationDetails.AccountsReceivable')) + ":", 1),
            _createElementVNode("span", _hoisted_30, _toDisplayString(_ctx.reservation?.customer?.accountsReceivable
              ? parseInt(_ctx.reservation?.customer?.accountsReceivable).toLocaleString()
              : '-'), 1),
            _createElementVNode("span", _hoisted_31, [
              _createElementVNode("span", _hoisted_32, _toDisplayString(_ctx.$t('ReservationDetails.Circle')), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_33, [
            _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.$t('ReservationDetails.NoOfVisits')), 1),
            _createElementVNode("span", _hoisted_35, _toDisplayString(_ctx.reservation?.customer?.noOfHospitalVisits
              ? _ctx.reservation?.customer?.noOfHospitalVisits + 1
              : '1'), 1),
            _createElementVNode("span", _hoisted_36, [
              _createElementVNode("span", _hoisted_37, _toDisplayString(_ctx.$t('ReservationDetails.Return')), 1)
            ])
          ])
        ]),
        (_ctx.reservation?.customer?.customerNumber)
          ? (_openBlock(), _createElementBlock("div", _hoisted_38, " (" + _toDisplayString(`${_ctx.$t('ReservationDetails.LastVisitDate')} ${_ctx.getMomentJpDate(
          _ctx.reservation?.customer?.lastVisitDate
        )}`) + ") ", 1))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}