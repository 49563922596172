
import { computed, defineComponent, ref, watchEffect } from 'vue';
import { fetch, update, store as post, remove } from '@/core/api/api-service';
import Modal from '@/components/modals/Modal.vue';
import FileInput from '@/components/fileInput/FileInput.vue';
import { store } from '@/core/store';
import { useRouter } from 'vue-router';
import { SYMPTOMS, CONSULTATION_CLASSIFICATION } from '@/core/store/enums';
import { POSITION, useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { convertToFullWidthKana } from '@/core/utils';

export default defineComponent({
  name: 'PreliminaryExamination',
  components: {
    Modal,
    FileInput
  },
  props: {
    reservation: {
      type: Object,
      required: true
    },
    medicalRecord: {
      type: Object,
      required: true
    },
    previousReservation: {
      type: Object,
      required: false
    },
    previousMedicalRecord: {
      type: Object,
      required: false
    }
  },
  emits: ['updateReservation'],
  setup(props, { emit }) {
    const router = useRouter();
    const toast = useToast();
    const {
      getters: { registrationSteps }
    } = store;
    const reservationData = ref(
      props.reservation.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
        ? props.previousReservation
        : props.reservation
    );

    const preliminaryExamination = ref(
      props.reservation.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
        ? props.previousMedicalRecord?.preliminaryExamination
        : props.medicalRecord?.preliminaryExamination
    );

    // for step 3 questions
    const questionnaireExaminationUnparsed = ref(
      props.reservation.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
        ? props.previousMedicalRecord?.questionnaire?.questionAnswer
        : props.medicalRecord?.questionnaire?.questionAnswer
    );

    const questionnaireExamination = ref();

    let PreliminaryExaminationID = ref(preliminaryExamination.value?.id);
    const customerData = ref(registrationSteps || undefined);
    const isFirstFrontSelected = ref<boolean>(false);
    const isSecondFileSelected = ref<boolean>(false);
    const isDeleted = ref<boolean>(true);
    let selectedTab = ref(
      props.reservation.symptom === SYMPTOMS.DAILY_PAIN
        ? SYMPTOMS.DAILY_PAIN
        : SYMPTOMS.TRAFFIC_ACCIDENT
    );
    let fileFront = ref<any>(null);
    let fileBack = ref<any>(null);

    const convertBase64ToImageUrl = (base64: any) => {
      const contentType = 'image/png';
      const byteCharacters = atob(base64.substr(`data:${contentType};base64,`.length));
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
        const slice = byteCharacters.slice(offset, offset + 1024);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      const blobUrl = URL.createObjectURL(blob);
      return blobUrl;
    };
    let frontImageUrl = ref<any>('');
    let backImageUrl = ref<any>('');
    if (
      preliminaryExamination.value?.trafficAccidentFrontImage !== undefined &&
      preliminaryExamination.value?.trafficAccidentBackImage !== undefined &&
      preliminaryExamination.value?.trafficAccidentFrontImage !== null &&
      preliminaryExamination.value?.trafficAccidentBackImage !== null
    ) {
      frontImageUrl = ref<any>(
        convertBase64ToImageUrl(preliminaryExamination.value?.trafficAccidentFrontImage)
      );
      backImageUrl = ref<any>(
        convertBase64ToImageUrl(preliminaryExamination.value?.trafficAccidentBackImage)
      );
    } else {
      frontImageUrl = ref<any>('');
      backImageUrl = ref<any>('');
    }
    let showSubmittedFileView = ref(
      !!preliminaryExamination.value?.trafficAccidentFrontImage &&
        !!preliminaryExamination.value?.trafficAccidentBackImage
    );
    let showSubmittedFileModelView = ref(false);

    const firstFileSelected = (files: any) => {
      fileFront.value = files[0];
      isFirstFrontSelected.value = true;
    };
    const secondFileSelected = (files: any) => {
      fileBack.value = files[0];
      isSecondFileSelected.value = true;
    };

    const updateQuestionnaire = () => {
      if (questionnaireExaminationUnparsed.value) {
        questionnaireExamination.value = JSON.parse(questionnaireExaminationUnparsed.value);
      }
    };

    watchEffect(() => {
      if (preliminaryExamination.value) {
        showSubmittedFileModelView.value =
          preliminaryExamination.value?.trafficAccidentFrontImage ||
          preliminaryExamination.value?.trafficAccidentBackImage
            ? true
            : false;
        updateQuestionnaire();
      }
    });

    const register = () => {
      let formData = new FormData();
      formData.append('medical_record_id', props.reservation.medicalRecord.id);
      formData.append('type', props.reservation.symptom);
      formData.append('traffic_accident_front_image', fileFront.value);
      formData.append('traffic_accident_back_image', fileBack.value);
      if (PreliminaryExaminationID.value) {
        formData.append('_method', 'PUT');
      }
      const urlForUpdatingFile = PreliminaryExaminationID.value
        ? `preliminary-examinations/${PreliminaryExaminationID.value}`
        : 'preliminary-examinations';
      post(`/api/v1/${urlForUpdatingFile}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then((res) => {
        PreliminaryExaminationID.value = res.data.data.id;
        showSubmittedFileView.value =
          res?.data?.data &&
          res.data.data.trafficAccidentFrontImage &&
          res.data.data.trafficAccidentBackImage;
        if (showSubmittedFileView.value) {
          frontImageUrl.value = convertBase64ToImageUrl(res.data.data.trafficAccidentFrontImage);
          backImageUrl.value = convertBase64ToImageUrl(res.data.data.trafficAccidentBackImage);
        }

        showSubmittedFileModelView.value = true;

        emit('updateReservation', true);
      });
    };

    const goToTab = (tab: SYMPTOMS) => {
      if (
        (props.reservation.symptom == SYMPTOMS.DAILY_PAIN && tab === SYMPTOMS.DAILY_PAIN) ||
        (props.reservation.symptom == SYMPTOMS.TRAFFIC_ACCIDENT &&
          tab === SYMPTOMS.TRAFFIC_ACCIDENT)
      ) {
        selectedTab.value = tab;
      }
    };

    const createNew = (buttonType: string) => {
      if (buttonType === 'create') {
        if (props.reservation.consultationClassification === 3) {
          let customerDataOnOngoing = {
            katakana_name: reservationData.value?.customer?.katakanaName,
            kana_surname: reservationData.value?.customer?.kanaSurname,
            name: reservationData.value?.customer?.name,
            surname: reservationData.value?.customer?.surname,
            gender: reservationData.value?.customer?.gender,
            dob: reservationData.value?.customer?.dob,
            postal_code: reservationData.value?.customer?.postalCode,
            city: reservationData.value?.customer?.city,
            prefectures: reservationData.value?.customer?.prefectures,
            name_of_buildings: reservationData.value?.customer?.nameofBuilding,
            cellphone_number: reservationData.value?.customer?.cellphoneNumber,
            telephone_number: reservationData.value?.customer?.telephoneNumber,
            profession: reservationData.value?.customer?.profession,
            age: reservationData.value?.customer?.age
          };

          Promise.all([
            store.dispatch('setReservationRegistrationPageType', 'edit'),
            store.dispatch('setReservation', props.reservation),
            store.dispatch('setStepCustomerRegistration', customerDataOnOngoing)
          ]).then(() => {
            router.push({
              name: 'PreliminaryExaminationSteps'
            });
          });
        } else {
          store.dispatch('resetRegistrationSteps', {});
          // this.showQuestions = true;
          Promise.all([
            store.dispatch('setReservationRegistrationPageType', buttonType),
            store.dispatch('setReservation', props.reservation)
          ]).then(() => {
            router.push({
              name: 'PreliminaryExaminationSteps'
            });
          });
        }
      } else {
        if (reservationData.value?.reservationStatus === 3) {
          let customerDataOnOngoing = {
            katakana_name: reservationData.value?.customer?.katakanaName,
            kana_surname: reservationData.value?.customer?.kanaSurname,
            name: reservationData.value?.customer?.name,
            surname: reservationData.value?.customer?.surname,
            gender: reservationData.value?.customer?.gender,
            dob: reservationData.value?.customer?.dob,
            postal_code: reservationData.value?.customer?.postalCode,
            city: reservationData.value?.customer?.city,
            prefectures: reservationData.value?.customer?.prefectures,
            name_of_buildings: reservationData.value?.customer?.nameofBuilding,
            cellphone_number: reservationData.value?.customer?.cellphoneNumber,
            telephone_number: reservationData.value?.customer?.telephoneNumber,
            profession: reservationData.value?.customer?.profession,
            age: reservationData.value?.customer?.age
          };

          Promise.all([
            store.dispatch('setReservationRegistrationPageType', buttonType),
            store.dispatch('setReservation', props.reservation),
            store.dispatch('setStepCustomerRegistration', customerDataOnOngoing)
          ]).then(() => {
            router.push({
              name: 'PreliminaryExaminationSteps'
            });
          });
        } else {
          Promise.all([
            store.dispatch('setReservationRegistrationPageType', buttonType),
            store.dispatch('setReservation', props.reservation)
          ]).then(() => {
            router.push({
              name: 'PreliminaryExaminationSteps'
            });
          });
        }
      }
    };

    const ontrafficAccident = () => {
      const consultationClassification = props.reservation.consultationClassification;
      if (reservationData?.value?.customer === null) {
        store.dispatch('resetTrafficAccidentRegistration', {}).then(() => {
          router.push({
            name: 'CustomerDetails',
            params: {
              id: 'new-customer-traffic-accident',
              userData: `${reservationData?.value?.id}`,
              storeID: `${reservationData?.value?.store?.id}`,
              smaregiID: `${reservationData?.value?.store?.smaregiId}`,
              MedicalID: `?medical_record_id=${reservationData?.value?.medicalRecord?.id}`,
              consultationClassification: `${consultationClassification}`,
              updateMedicalID: `${reservationData?.value?.medicalRecord?.id}`,
              // customerNumberFromList: `?customerNumber=${reservationData?.value?.customer?.customerNumber}`,
              symptom: 2
            }
          });
        });
      } else {
        store
          .dispatch('setTrafficAccidentRegistration', reservationData?.value?.customer)
          .then(() => {
            router.push({
              name: 'CustomerDetails',
              params: {
                id: `edit-customer-traffic-accident?id=${reservationData?.value?.customer?.customerNumber}`,
                userData: `${reservationData?.value?.id}`,
                storeID: `${reservationData?.value?.store?.id}`,
                smaregiID: `${reservationData?.value?.store?.smaregiId}`,
                MedicalID: `?medical_record_id=${reservationData?.value?.medicalRecord?.id}`,
                consultationClassification: `${consultationClassification}`,
                customerNumberFromList: `?customerNumber=${reservationData?.value?.customer?.customerNumber}`,
                symptom: `${'false'}`
              }
            });
          });
      }
    };

    const handlePreliminaryExaminationEdit = () => {
      createPreliminaryExamination();
    };

    const createPreliminaryExamination = () => {
      return post(`${process.env.VUE_APP_BASE_API_URL}/api/v1/preliminary-examinations`, {
        medical_record_id: reservationData.value?.medicalRecord.id,
        type: reservationData.value?.symptom,
        format_id: preliminaryExamination.value.formatId,
        format_version: preliminaryExamination.value.formatVersion,
        symptom_answer: JSON.stringify(preliminaryExamination.value.symptom_answer)
      });
    };

    const deletePreliminary = () => {
      const UrlId = PreliminaryExaminationID?.value;
      let deletedSymptomQuestions = customerData?.value;
      deletedSymptomQuestions.symptomQuestions = null;
      remove(`api/v1/preliminary-examinations/${UrlId}`)
        .then((res) => {
          if (res?.status === 200) {
            window.scrollTo(0, 0);
            toast.success('削除しました', {
              timeout: 2000,
              position: POSITION.BOTTOM_RIGHT
            });
            store.dispatch('resetQuestionnaireSteps', { deletedSymptomQuestions }).then(() => {
              isDeleted.value = false;
            });

            showSubmittedFileModelView.value = false;
            emit('updateReservation', true);
          }
        })
        .catch((err) => {
          toast.error('施術が登録されているため削除できません', {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT
          });
          showSubmittedFileModelView.value = false;
        });
    };

    const isDeletedDisabled = computed(() => {
      let disabled;
      if (isDeleted.value === true) {
        disabled = true;
      } else {
        disabled = false;
      }
      return disabled;
    });

    const isFormFieldDisabled = computed(() => {
      return (
        props.reservation.consultationClassification ===
        CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
      );
    });

    const isReservationHasCustomer = computed(() => {
      let disabled;
      // () && reservationData.value?.reservationStatus !== 3;
      if (!!props.reservation?.customer || !!props.medicalRecord?.customer) {
        if (props.reservation.consultationClassification === 3) {
          disabled = false;
        } else {
          disabled = true;
        }
      } else {
        disabled = false;
      }
      return disabled;
    });

    const photographingPermission = computed(() => {

      //return props.medicalRecord.questionnaire.photographingPermission;
      if( props.medicalRecord?.questionnaire?.photographingPermission == null){
         return null;
      }else{
         return props.medicalRecord.questionnaire.photographingPermission;
      }
    });

    const parking = computed(() => {
      type Question = { id: string; answer: string; question: string };
      if (props.medicalRecord?.questionnaire?.questionAnswer) {
        const questionnaire = JSON.parse(props.medicalRecord.questionnaire.questionAnswer);
        console.log(questionnaire)
        if (Number(questionnaire.formatVer) < 1.2) return null;

        const questions: Question[] = questionnaire.contents;

        if (!questions) return null;
        const parking = questions.find((question) => question.id == 'q3');

        if (parking) {
          console.log(parking?.answer);
          return parking.answer;
        }
      }

      return null;
    });

    return {
      photographingPermission,
      parking,
      showSubmittedFileModelView,
      convertToFullWidthKana,
      ontrafficAccident,
      SYMPTOMS,
      CONSULTATION_CLASSIFICATION,
      reservationData,
      preliminaryExamination,
      //
      isDeletedDisabled,
      isFormFieldDisabled,
      convertBase64ToImageUrl,
      isFirstFrontSelected,
      isSecondFileSelected,
      showSubmittedFileView,
      showQuestions: false,
      yearSpan: '',
      monthSpan: '',
      daySpan: '',
      selectedTab,
      deletePreliminary,
      fileFront,
      fileBack,
      //
      frontImageUrl,
      backImageUrl,
      createNew,
      isReservationHasCustomer,
      register,
      secondFileSelected,
      firstFileSelected,
      goToTab,
      handlePreliminaryExaminationEdit,
      questionnaireExamination
    };
  },
  methods: {}
});
