
import { defineComponent, onMounted, ref, toRefs } from 'vue';
import { store } from '@/core/store';
import { fetch, store as post } from '@/core/api/api-service';
import { POSITION, useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import { useRouter } from 'vue-router';
import { toFullwidthKana } from 'japanese-string-utils';
import { CONSULTATION_CLASSIFICATION } from '@/core/store/enums';

export default defineComponent({
  name: 'Personal',
  props: {
    reservation: {
      type: Object || null,
      required: true
    }
  },
  emits: ['personalUpdate'],
  setup(props, { emit }) {
    const toast = useToast();
    // const personalInfo = ref(props.reservation.consultationClassification ===
    //     CONSULTATION_CLASSIFICATION.CONTINUOUS_TREATMENT
    //     ? '':
    //     props?.reservation?.customer?.personalInfo || ""
    // );
    const personalInfo = ref(props?.reservation?.customer?.personalInfo || '');

    const router = useRouter();
    const isCustomerDetailAvailable = ref(props?.reservation?.customer);
    const personalID = ref(props?.reservation);
    const personalDetailsUpdate = () => {
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('personal_info', personalInfo?.value);
      return post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/customers/${props?.reservation?.customer?.customerNumber}`,
        formData
      ).then((res) => {
        window.scrollTo(0, 0);
        toast.success('更新しました', {
          timeout: 2000,
          position: POSITION.BOTTOM_LEFT
        });
        fetchInquiry();
      });
    };
    const fetchInquiry = () => {
      emit('personalUpdate', true);
    };
    const validateBlankSpace = (event: any, fieldType: any) => {
      if (event && fieldType == 'personalInfo') {
        personalInfo.value = event.target.value.trim();
        personalInfo.value = toFullwidthKana(`${event.target.value.trim()}`);
      }
    };

    return {
      validateBlankSpace,
      isCustomerDetailAvailable,
      personalDetailsUpdate,
      personalInfo
    };
  }
});
