import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: _ctx.id,
    class: _normalizeClass(_ctx.modalClass ? `modal fade ${_ctx.modalClass}` : `modal fade`),
    ref: _ctx.id,
    tabindex: "-1",
    "data-bs-backdrop": "static",
    "data-bs-keyboard": "false",
    "aria-hidden": "true"
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 10, _hoisted_1))
}