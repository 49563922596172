<template>
  <div class="full-details">
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.CustomerNumber') }}
        </p>
      </div>
      <div class="col-4">
        <span>{{ `${customerNumber ? customerNumber : '-'}` }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">Line ID</p>
      </div>
      <div class="col-4">
        <span>{{ customerLineID ? customerLineID : '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title required">
          {{ $t('CustomerDetails.Details.RegisteredStores') }}
        </p>
      </div>
      <div class="col-4">
        <!-- <input
          type="text"
          :class="[
            filterErrorColor('registered_store') ? 'error' : '',
            'form-control',
          ]"
          v-model="customer.registered_store"
        /> -->
        <Multiselect
            v-model="customer.registered_store"
            :canDeselect="false"
            :class="[filterErrorColor('registered_store') ? 'error' : '']"
            :options="storeOptions"
            :placeholder="$t('Form.Placeholder')"
            label="name"
        >
          <!-- v-model="registration.store_id"
          :class="[filterErrorColor('store_id') ? 'error' : '', '']"
          @select="storeSelect()" -->
          <template v-slot:option="{ option }" track-by="name">
            {{ option.name }}
            <img :src="option.icon" alt="storeSelection" class="multi-select-option-icon"/>
          </template>
        </Multiselect>

        <span v-if="filterErrorColor('registered_store')" class="error-box" role="alert">
          {{ filterErrors('registered_store') }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title required">
          {{ $t('CustomerDetails.Details.YourName') }}
        </p>
      </div>
      <div class="col d-flex">
        <div class="d-flex flex-column">
          <input
              v-model="customer.surname"
              :class="[filterErrorColor('surname') ? 'error' : '', 'form-control me-4']"
              :disabled="disabledFields"
              placeholder="例：山田"
              type="text"
              @change="validateBlankSpace($event, 'surname')"
          />
          <!-- @paste="$event.preventDefault()" -->
          <div style="margin-top: 6px">
            <span v-if="filterErrorColor('surname')" class="error-box" role="alert">
              {{ filterErrors('surname') }}
            </span>
          </div>
        </div>
        <div class="d-flex flex-column">
          <input
              v-model="customer.name"
              :class="[filterErrorColor('name') ? 'error' : '', 'form-control']"
              :disabled="disabledFields"
              placeholder="例：太郎"
              type="text"
              @change="validateBlankSpace($event, 'name')"
          />
          <!-- @paste="$event.preventDefault()" -->
          <div style="margin-top: 6px">
            <span v-if="filterErrorColor('name')" class="error-box" role="alert">
              {{ filterErrors('name') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title required">
          {{ $t('CustomerDetails.Details.Phonetic') }}
        </p>
      </div>
      <div class="col d-flex">
        <div class="d-flex flex-column">
          <input
              v-model="customer.kana_surname"
              :class="[filterErrorColor('kana_surname') ? 'error' : '', 'form-control me-4']"
              :disabled="disabledFields"
              placeholder="例：ヤマダ"
              type="text"
              @change="validateBlankSpace($event, 'kana_surname')"
          />
          <!-- @paste="$event.preventDefault()" -->
          <div style="margin-top: 6px">
            <span v-if="filterErrorColor('kana_surname')" class="error-box" role="alert">
              {{ filterErrors('kana_surname') }}
            </span>
          </div>
        </div>
        <div class="d-flex flex-column">
          <input
              v-model="customer.katakana_name"
              :class="[filterErrorColor('katakana_name') ? 'error' : '', 'form-control']"
              :disabled="disabledFields"
              placeholder="例：タロウ"
              type="text"
              @change="validateBlankSpace($event, 'katakana_name')"
          />
          <!-- @paste="$event.preventDefault()" -->
          <div style="margin-top: 6px">
            <span v-if="filterErrorColor('katakana_name')" class="error-box" role="alert">
              {{ filterErrors('katakana_name') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t("CustomerDetails.Details.CustomerName") }}
        </p>
      </div>
      <div class="col-8">
        <input type="text" class="form-control" placeholder="例：山田" />
        <input type="text" class="form-control" placeholder="例：太郎" />
      </div>
    </div> -->
    <div class="row">
      <div class="col-3">
        <p class="form-title required">
          {{ $t('CustomerDetails.Details.Phone') }}
        </p>
        <p class="small-text text-end">
          {{ $t('CustomerDetails.Details.NoHyphen') }}
        </p>
      </div>
      <div class="col">
        <div>
          <span class="fw-bold me-2">{{ $t('CustomerDetails.Details.Mobile') }}</span>
          <input
              v-model="customer.cellphone_number"
              :class="[filterErrorColor('cellphone_number') ? 'error' : '', 'form-control me-4']"
              :disabled="disabledFields"
              :placeholder="$t('Form.Placeholder')"
              type="text"
          />
          <span class="fw-bold me-2">or</span>
          <span class="fw-bold me-2">{{ $t('CustomerDetails.Details.Home') }}</span>
          <input
              v-model="customer.telephone_number"
              :class="[filterErrorColor('telephone_number') ? 'error' : '', 'form-control me-4']"
              :disabled="disabledFields"
              :placeholder="$t('Form.Placeholder')"
              type="text"
          />
          <div class="ms-2 ps-1" style="margin-top: 6px">
            <span v-if="filterErrorColor('telephone_number')" class="error-box ms-4" role="alert">
              {{ filterErrors('telephone_number') }}
            </span>
          </div>
        </div>
        <div class="ms-2 ps-1" style="margin-top: 6px">
          <span v-if="filterErrorColor('cellphone_number')" class="error-box ms-4" role="alert">
            {{ filterErrors('cellphone_number') }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.EmailAddress') }}
        </p>
      </div>
      <div class="col-4">
        <input
            v-model="customer.email"
            :class="[filterErrorColor('email') ? 'error' : '', 'form-control me-4']"
            :disabled="disabledFields"
            :placeholder="$t('Form.Placeholder')"
            class="form-control"
            type="text"
            @change="validateBlankSpace($event, 'email')"
        />
        <div class="ms-2 ps-1" style="margin-top: 6px">
          <span v-if="filterErrorColor('email')" class="error-box ms-4" role="alert">
            {{ filterErrors('email') }}
          </span>
        </div>
        <!-- @paste="$event.preventDefault()" -->
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title required">
          {{ $t('CustomerDetails.Details.DoB') }}
        </p>
      </div>
      <div class="col date-selector">
        <div class="">
          <div class="d-flex flex-column">
            <select
                v-model="customer.dobYear"
                :class="[filterErrorColor('year') ? 'error' : '', 'custom-select']"
                :disabled="disabledFields"
                :placeholder="$t('Ticket.Step1.Select')"
                label="name"
                required
            >
              <option :value="0" disabled hidden>
                {{ $t('Ticket.Step1.Select') }}
              </option>
              <option v-for="(item, index) in yearOptions" v-bind:key="index" :value="item.value">
                {{ item.name }}
              </option>
            </select>
            <div v-if="filterErrorColor('year')" class="mt-2 pt-1">
              <span class="error-box d-block ms-0" role="alert" style="margin-top: -12px">
                {{ filterErrors('year') }}
              </span>
            </div>
          </div>
          <span class="fw-bold">{{ $t('Ticket.Step1.Year') }}</span>
        </div>
        <div class="">
          <div class="d-flex flex-column">
            <select
                v-model="customer.dobMonth"
                :class="[filterErrorColor('month') ? 'error' : '', 'custom-select']"
                :disabled="!customer.dobYear || customer.dobYear === '0' || disabledFields"
                :placeholder="$t('Ticket.Step1.Select')"
                label="name"
                required
            >
              <option :value="0" disabled hidden>
                {{ $t('Ticket.Step1.Select') }}
              </option>
              <option v-for="(item, index) in monthOptions" v-bind:key="index" :value="item.value">
                {{ item.name }}
              </option>
            </select>
            <div v-if="filterErrorColor('month')" class="mt-2 pt-1">
              <span class="error-box d-block ms-0" role="alert" style="margin-top: -12px">
                {{ filterErrors('month') }}
              </span>
            </div>
          </div>
          <span class="fw-bold">{{ $t('Ticket.Step1.Month') }}</span>
        </div>
        <div class="me-4">
          <div class="d-flex flex-column">
            <select
                v-model="customer.dobDay"
                :class="[filterErrorColor('dob') ? 'error' : '', 'custom-select']"
                :disabled="!customer.dobMonth || customer.dobMonth === '0' || disabledFields"
                :placeholder="$t('Ticket.Step1.Select')"
                label="name"
                required
            >
              <option :value="0" disabled hidden>
                {{ $t('Ticket.Step1.Select') }}
              </option>
              <option v-for="(item, index) in daysOption" v-bind:key="index" :value="item.value">
                {{ item.name }}
              </option>
            </select>
            <div v-if="filterErrorColor('day')" class="mt-2 pt-1">
              <span class="error-box d-block ms-0" role="alert" style="margin-top: -12px">
                {{ filterErrors('day') }}
              </span>
            </div>
          </div>
          <span class="fw-bold">{{ $t('Ticket.Step1.Day') }}</span>
        </div>
        <div class="">
          <span class="fw-bold">
            年齢
            <u>{{ calculateAgeField }}</u>
            歳
          </span>
        </div>
      </div>
      <div v-if="filterErrorColor('dob')" class="row mb-0">
        <div class="col-3"></div>
        <div class="col">
          <span class="error-box d-block" role="alert" style="margin-top: -12px">
            {{ filterErrors('dob') }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title required">{{ $t('Ticket.Step1.Gender') }}</p>
      </div>
      <div class="col">
        <div class="form-check">
          <input
              id="man"
              v-model="customer.gender"
              :checked="true"
              :disabled="disabledFields"
              class="form-check-input"
              name="gender"
              type="radio"
              value="1"
          />
          <label class="form-check-label" for="man">
            {{ $t('Ticket.Step1.Man') }}
          </label>
        </div>
        <div class="form-check">
          <input
              id="woman"
              v-model="customer.gender"
              :disabled="disabledFields"
              class="form-check-input"
              name="gender"
              type="radio"
              value="2"
          />
          <label class="form-check-label" for="woman">
            {{ $t('Ticket.Step1.Woman') }}
          </label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title required">
          {{ $t('CustomerDetails.Details.ZipCode') }}
        </p>
      </div>
      <div class="col d-flex">
        <div>
          <input
              v-model="customer.postal_code"
              :class="[filterErrorColor('postal_code') ? 'error' : '', 'form-control']"
              :disabled="disabledFields"
              :placeholder="$t('Form.Placeholder')"
              type="text"
              @blur="handlePostalcodeRegex($event)"
              @change="clearFieldsOnPostalCodeChange"
              @focus="removeError('postal_code')"
              @keyup="checkPostalCodeValid(customer.postal_code)"
              @paste="$event.preventDefault()"
          />
          <div style="margin-top: 6px">
            <span v-if="filterErrorColor('postal_code')" class="error-box" role="alert">
              {{ filterErrors('postal_code') }}
            </span>
          </div>
        </div>
        <button
            :disabled="!customer.postal_code"
            class="btn btn-primary search-btn customer-registration"
            @click="searchPostalCode"
        >
          {{ $t('Ticket.Step1.AddressSearch') }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title required">
          {{ $t('CustomerDetails.Details.Prefecture') }}
        </p>
      </div>
      <div class="col-4">
        <div>
          <input
              v-model="customer.prefectures"
              :class="[filterErrorColor('prefectures') ? 'error' : '', 'form-control']"
              :disabled="disabledFields"
              :placeholder="$t('Form.Placeholder')"
              type="text"
              @change="validateBlankSpace($event, 'prefectures')"
          />
          <div style="margin-top: 6px">
            <span v-if="filterErrorColor('prefectures')" class="error-box" role="alert">
              {{ filterErrors('prefectures') }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title required">
          {{ $t('CustomerDetails.Details.CityAndStreet') }}
        </p>
      </div>
      <div class="col">
        <Multiselect
            v-if="hasMultipleAddresses"
            v-model="customer.city"
            :class="[filterErrorColor('city') ? 'error' : '', 'form-control']"
            :options="multipleCityAddressArr"
            placeholder="（選択してください）"
        ></Multiselect>
        <input
            v-else
            v-model="customer.city"
            :class="[filterErrorColor('city') ? 'error' : '', 'form-control long-input']"
            :disabled="disabledFields"
            :placeholder="$t('Form.Placeholder')"
            type="text"
            @change="validateBlankSpace($event, 'city')"
        />
        <div style="margin-top: 6px">
          <span v-if="filterErrorColor('city')" class="error-box" role="alert">
            {{ filterErrors('city') }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">{{ $t('CustomerDetails.Details.Building') }}</p>
      </div>
      <div class="col">
        <input
            v-model="customer.name_of_buildings"
            :disabled="disabledFields"
            :placeholder="$t('Form.Placeholder')"
            class="form-control long-input"
            type="text"
            @change="validateBlankSpace($event, 'name_of_buildings')"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3 textarea-title">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.PersonalInformation') }}
        </p>
      </div>
      <div class="col">
        <textarea
            v-model="customer.personal_info"
            :disabled="disabledFields"
            :placeholder="$t('Form.Placeholder')"
            class="form-control long-input"
            type="text"
            @change="validateBlankSpace($event, 'personal_info')"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">{{ $t('Form.ManagementMemo') }}</p>
      </div>
      <div class="col">
        <input
            v-model="customer.management_note"
            :disabled="disabledFields"
            :placeholder="$t('Form.Placeholder')"
            class="form-control long-input"
            type="text"
            @change="validateBlankSpace($event, 'management_note')"
        />
      </div>
    </div>
    <hr/>

    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.NoOfVisit') }}
        </p>
      </div>
      <div class="col">
        <span>{{ customer.no_of_hospital_visits || '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.FirstVisitDate') }}
        </p>
      </div>
      <div class="col">
        <span>{{ customer.first_visit_date || '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.lastVisitDate') }}
        </p>
      </div>
      <div class="col">
        <span>{{ customer.last_visit_date || '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.ReexaminationDate') }}
        </p>
      </div>
      <div class="col">
        <span>{{ customer.revisit_date || '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.InchargeFirstVisit') }}
        </p>
      </div>
      <div class="col">
        <span>{{ customer.first_examination_staff_name || '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.InchargeReexamination') }}
        </p>
      </div>
      <div class="col">
        <span>{{ customer.re_examination_staff_name || '-' }}</span>
      </div>
    </div>

    <hr/>

    <div class="row">
      <div class="col-3">
        <p class="form-title">{{ $t('CustomerDetails.Details.NotAtAll') }}</p>
      </div>
      <div class="col">
        <input
            id="taskChecked"
            :checked="customer.no_acupunture"
            :disabled="disabledFields"
            :v-model="customer.no_acupunture"
            class="form-check-input"
            type="checkbox"
            @click="checkboxChange('no_acupunture', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.HVNotAllowed') }}
        </p>
      </div>
      <div class="col">
        <input
            id="hv_not_possible"
            :checked="customer.hv_not_possible"
            :disabled="disabledFields"
            :v-model="customer.hv_not_possible"
            class="form-check-input"
            type="checkbox"
            @click="checkboxChange('hv_not_possible', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">{{ $t('CustomerDetails.Details.Metals') }}</p>
      </div>
      <div class="col">
        <input
            :checked="customer.metal_has"
            :disabled="disabledFields"
            :v-model="customer.metal_has"
            class="form-check-input"
            type="checkbox"
            @click="checkboxChange('metal_has', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.MetalParts') }}
        </p>
      </div>
      <div class="col">
        <input
            v-model="customer.metal_parts"
            :disabled="disabledFields"
            :placeholder="$t('Form.Placeholder')"
            class="form-control"
            type="text"
        />
      </div>
    </div>

    <hr/>

    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.InsuranceAvailable') }}
        </p>
      </div>
      <div class="col">
        <input
            id="baoyeTreatmentIncludes"
            :checked="customer.baoye_treatment_includes"
            :disabled="disabledFields"
            :v-model="customer.baoye_treatment_includes"
            class="form-check-input"
            type="checkbox"
            @click="checkboxChange('baoyeTreatmentIncludes', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.Compensation') }}
        </p>
      </div>
      <div class="col">
        <input
            id="trafficOrIndustrialTreatmentAvailable"
            :checked="customer.traffic_or_industrial_treatment_available"
            :disabled="disabledFields"
            :v-model="customer.traffic_or_industrial_treatment_available"
            class="form-check-input"
            type="checkbox"
            @click="checkboxChange('trafficOrIndustrialTreatmentAvailable', $event)"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.AcupunctureAvailable') }}
        </p>
      </div>
      <div class="col">
        <input
            id="with_acupunture"
            :checked="customer.with_acupunture"
            :disabled="disabledFields"
            :v-model="customer.with_acupunture"
            class="form-check-input"
            type="checkbox"
            @click="checkboxChange('with_acupunture', $event)"
        />
      </div>
    </div>

    <hr/>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.MemberRemainingCoupons') }}
        </p>
      </div>
      <div class="col">
        <span>{{ customer.member_remaining_coupon || '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.RemainingCoupons') }}
        </p>
      </div>
      <div class="col">
        <span>{{ customer.remaining_coupon || '-' }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.RemainingAccounts') }}
        </p>
      </div>
      <div class="col">
        <span>{{ customer.accounts_receivable || '-' }}</span>
      </div>
    </div>

    <hr/>

    <div>
      <div class="row">
        <div class="col-3">
          <p class="form-title">
            {{ $t('CustomerDetails.Details.MonthlyMembershipDate') }}
          </p>
        </div>
        <div class="col">
          <span>{{ customer.monthly_membership_enrollment_date || '-' }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <p class="form-title">
            {{ $t('CustomerDetails.Details.memberSettlementDate') }}
          </p>
        </div>
        <div class="col">
          <span>
            {{
              customer.monthly_member_latest_settlement_date
                  ? customer.monthly_member_latest_settlement_date
                  : `-`
            }}
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <p class="form-title">
            {{ $t('CustomerDetails.Details.MonthlyTemporaryRecess') }}
          </p>
        </div>
        <div class="col">
          <div class="date-selector">
            <div class="date-field">
              <input
                  id="monthlyTemporaryRecess"
                  v-model="customer.monthly_member_close_date"
                  :class="[filterErrorColor('date') ? 'error' : '', 'form-control']"
                  autocomplete="off"
                  placeholder="年/月/日"
                  type="text"
                  @focus="showCalendar3 = true"
                  @keypress="handleDateNumber($event)"
                  @paste="$event.preventDefault()"
              />
              <div class="icon">
                <img alt="123reservation" src="../assets/icons/calendar.svg"/>
              </div>
            </div>
            <div
                v-if="this.showCalendar3 == true"
                :class="{
                'datepicker-overlay': true,
                display: this.showCalendar3
              }"
                @click="this.showCalendar3 = !this.showCalendar3"
            ></div>
            <DatePicker
                v-if="showCalendar3"
                type="default"
                @selectedDate="
                (selectedDate) => {
                  customer.monthly_member_close_date = selectedDate;
                  this.showCalendar2 = false;
                  //  this.getTime(selectedDate, 'set');
                  //  this.storeSelect();
                }
              "
            />
          </div>
          <!-- <span>{{ customer.monthly_member_close_date || "-" }}</span> -->
        </div>
      </div>
    </div>

    <hr/>

    <template
        v-for="(monthlyProductSalesItem, monthlyProductSalesIndex) of monthlyProductSales"
        :key="monthlyProductSalesIndex"
    >
      <div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.MembershipType') }}
            </p>
          </div>
          <div class="col">
            <span>
              <!-- {{
                monthlyProductSalesItem?.membershipType
                  ? eachSalesItem?.membershipType === 1
                    ? `温感Plus`
                    : `トリガーポイントビタミン`
                  : `-`
              }} -->
              {{
                monthlyProductSalesItem?.membershipType
                    ? monthlyProductSalesItem?.membershipType === 1
                        ? '温感Plus'
                        : 'トリガーポイントビタミン'
                    : '-'
              }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.MembershipDate') }}
            </p>
          </div>
          <div class="col">
            <!-- <span>{{ customer.monthly_membership_enrollment_date || "-" }}</span> -->
            <span>
              {{
                monthlyProductSalesItem?.membershipDate
                    ? monthlyProductSalesItem?.membershipDate
                    : `-`
              }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.MembershipSuspensionDate') }}
            </p>
          </div>
          <div class="col">
            <div class="date-selector">
              <!-- <span> -->
              <!-- {{
                monthlyProductSalesItem?.memberSuspensionDate
                  ? monthlyProductSalesItem?.memberSuspensionDate
                  : `-`
              }} -->

              <!-- </span> -->
              <div class="date-field">
                <input
                    id="registrationDate"
                    v-model="monthlyProductSalesItem.memberSuspensionDate"
                    :class="[filterErrorColor('date') ? 'error' : '', 'form-control']"
                    autocomplete="off"
                    placeholder="年/月/日"
                    type="text"
                    @focus="dynamicfocus(monthlyProductSalesItem)"
                    @keypress="handleDateNumber($event)"
                    @paste="$event.preventDefault()"
                />
                <div class="icon">
                  <img alt="123reservation" src="../assets/icons/calendar.svg"/>
                </div>
              </div>
              <div
                  v-if="monthlyProductSalesItem.show == true"
                  :class="{
                  'datepicker-overlay': true,
                  display: monthlyProductSalesItem.show
                }"
                  @click="dynamicClick(monthlyProductSalesItem)"
              ></div>
              <DatePicker
                  v-if="monthlyProductSalesItem.show"
                  type="default"
                  @selectedDate="
                  (selectedDate) => {
                    monthlyProductSalesItem.memberSuspensionDate = selectedDate;
                    monthlyProductSalesItem.show = false;
                    // this.getTime(selectedDate, 'set');
                    //this.storeSelect();
                  }
                "
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.SalesSettleSales') }}
            </p>
          </div>
          <div class="col">
            <span>
              {{
                monthlyProductSalesItem?.memberLatestSettlementDate
                    ? monthlyProductSalesItem?.memberLatestSettlementDate
                    : `-`
              }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.memberDeliveryDate') }}
            </p>
          </div>
          <div class="col">
            <span>
              {{
                monthlyProductSalesItem?.memberLatestDeliveryDate
                    ? monthlyProductSalesItem?.memberLatestDeliveryDate
                    : `-`
              }}
            </span>
          </div>
        </div>
      </div>
    </template>

    <div v-if="monthlyProductSales.length === 0">
      <div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.MembershipType') }}
            </p>
          </div>
          <div class="col">
            <span>{{ `-` }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.MembershipDate') }}
            </p>
          </div>
          <div class="col">
            <!-- <span>{{ customer.monthly_membership_enrollment_date || "-" }}</span> -->
            <span>{{ `-` }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.MembershipSuspensionDate') }}
            </p>
          </div>
          <div class="col">
            <div class="date-selector">
              <div class="date-field">
                <input
                    id="registrationDate1"
                    :class="[filterErrorColor('date') ? 'error' : '', 'form-control']"
                    :disabled="true"
                    autocomplete="off"
                    placeholder="年/月/日"
                    type="text"
                    @focus="showCalendar2 = true"
                    @keypress="handleDateNumber($event)"
                    @paste="$event.preventDefault()"
                />
                <div class="icon">
                  <img alt="123reservation" src="../assets/icons/calendar.svg"/>
                </div>
              </div>
              <div
                  v-if="this.showCalendar2 == true"
                  :class="{
                  'datepicker-overlay': true,
                  display: this.showCalendar2
                }"
                  @click="this.showCalendar2 = !this.showCalendar2"
              ></div>
              <DatePicker
                  v-if="showCalendar2"
                  type="default"
                  @selectedDate="
                  (selectedDate) => {
                    registration.date = selectedDate;
                    this.showCalendar2 = false;
                    this.getTime(selectedDate, 'set');
                    this.storeSelect();
                  }
                "
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.SalesSettleSales') }}
            </p>
          </div>
          <div class="col">
            <span>
              {{ `-` }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.memberDeliveryDate') }}
            </p>
          </div>
          <div class="col">
            <span>{{ `-` }}</span>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <template v-for="(eachSalesItem, eachSalesIndex) of eachSales" :key="eachSalesIndex">
      <div>
        <div :id="index" class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.ProductSalesByType') }}
            </p>
          </div>
          <div class="col">
            <span>
              {{
                eachSalesItem.productSalesType
                    ? eachSalesItem.productSalesType === 1
                        ? `温感Plus`
                        : `トリガーポイントビタミン`
                    : '-'
              }}
            </span>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.FirstPurchaseDate') }}
            </p>
          </div>
          <div class="col">
            <span>
              {{
                eachSalesItem?.productSalesFirstPurchaseDate
                    ? eachSalesItem?.productSalesFirstPurchaseDate
                    : '-'
              }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.LatestPurchaseDate') }}
            </p>
          </div>
          <div class="col">
            <span>
              {{
                eachSalesItem?.productSalesLatestPurchaseDate
                    ? eachSalesItem?.productSalesLatestPurchaseDate
                    : '-'
              }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.EstimatedDate') }}
            </p>
          </div>
          <div class="col">
            <span>
              {{
                eachSalesItem?.expectedProductSalesDigestionDate
                    ? eachSalesItem?.expectedProductSalesDigestionDate
                    : '-'
              }}
            </span>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <p class="form-title">
              {{ $t('CustomerDetails.Details.NextAlertDate') }}
            </p>
          </div>
          <div class="col">
            <span>
              {{
                eachSalesItem?.productSalesNextAlertDate
                    ? eachSalesItem?.productSalesNextAlertDate
                    : '-'
              }}
            </span>
          </div>
        </div>
      </div>
    </template>

    <div v-if="eachSales.length === 0" id="noLength">
      <div :id="index" class="row">
        <div class="col-3">
          <p class="form-title">
            {{ $t('CustomerDetails.Details.ProductSalesByType') }}
          </p>
        </div>
        <div class="col">
          <span>{{ '-' }}</span>
        </div>
      </div>

      <div class="row">
        <div class="col-3">
          <p class="form-title">
            {{ $t('CustomerDetails.Details.FirstPurchaseDate') }}
          </p>
        </div>
        <div class="col">
          <span>{{ '-' }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <p class="form-title">
            {{ $t('CustomerDetails.Details.LatestPurchaseDate') }}
          </p>
        </div>
        <div class="col">
          <span>{{ '-' }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <p class="form-title">
            {{ $t('CustomerDetails.Details.EstimatedDate') }}
          </p>
        </div>
        <div class="col">
          <span>{{ '-' }}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-3">
          <p class="form-title">
            {{ $t('CustomerDetails.Details.NextAlertDate') }}
          </p>
        </div>
        <div class="col">
          <span>{{ '-' }}</span>
        </div>
      </div>
    </div>

    <hr/>

    <div class="row">
      <div class="col-3">
        <p class="form-title">{{ $t('CustomerDetails.Details.EReview') }}</p>
      </div>
      <div class="col">
        <input
            v-model="customer.review_equiten"
            :disabled="disabledFields"
            class="form-check-input"
            type="checkbox"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.GReview') }}
        </p>
      </div>
      <div class="col">
        <input
            v-model="customer.review_g_review"
            :disabled="disabledFields"
            class="form-check-input"
            type="checkbox"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">{{ $t('CustomerDetails.Details.WBReview') }}</p>
      </div>
      <div class="col">
        <input
            v-model="customer.review_wb"
            :disabled="disabledFields"
            class="form-check-input"
            type="checkbox"
        />
      </div>
    </div>

    <hr/>
    <div class="row">
      <div class="col-3">
        <p class="form-title">{{ $t('CustomerDetails.Details.Occupation') }}</p>
      </div>
      <div class="col">
        <input
            v-model="customer.profession"
            :disabled="disabledFields"
            :placeholder="$t('Form.Placeholder')"
            class="form-control"
            type="text"
            @change="validateBlankSpace($event, 'profession')"
        />

        <!-- @paste="$event.preventDefault()" -->
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p id="otherInflow1Label" class="form-title">
          {{ $t('CustomerDetails.Details.InflowPath') }}
        </p>
      </div>
      <div class="col">
        <select
            v-if="isTrafficAccidentCustomer"
            id="otherInflow1"
            v-model="customer.inflowPath"
            :class="[filterErrorColor('inflowPath') ? 'error' : '', 'custom-select']"
            :disabled="disabledFields"
            label="name"
            required
            @change="checkdisableFunction($event)"
        >
          <option disabled value="">
            {{ $t('Form.Placeholder') }}
          </option>
          <option
              v-for="(item, index) in trafficAccidentInflowOptions"
              v-bind:key="index"
              :value="item.value"
          >
            {{ item.name }}
          </option>
        </select>
        <select
            v-else
            id="otherInflow1"
            v-model="customer.inflowPath"
            :class="[filterErrorColor('inflowPath') ? 'error' : '', 'custom-select']"
            :disabled="disabledFields"
            label="name"
            required
            @change="checkdisableFunction($event)"
        >
          <option disabled value="">
            {{ $t('Form.Placeholder') }}
          </option>
          <option v-for="(item, index) in inflowOptions" v-bind:key="index" :value="item.value">
            {{ item.name }}
          </option>
        </select>
        <div v-if="filterErrorColor('inflowPath')" style="margin-top: 6px">
          <span class="error-box ms-0" role="alert">
            {{ filterErrors('inflowPath') }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.SearcTerms') }}
        </p>
      </div>
      <div class="col">
        <input
            id="searchTerms"
            v-model="customer.searchTerms"
            :class="[
            filterErrorColor('searchTerms') || showError ? 'error' : '',
            'form-control me-4'
          ]"
            :disabled="checkDisable"
            :placeholder="$t('Form.Placeholder')"
            type="text"
            @change="validateBlankSpace($event, 'searchTerms')"
            @keyup="searchTermEnter($event)"
        />
        <div style="margin-top: 6px">
          <span v-if="filterErrorColor('searchTerms')" class="error-box" role="alert">
            {{ filterErrors('searchTerms') }}
          </span>
        </div>
        <div style="margin-top: 6px">
          <span v-if="showError" class="error-box" role="alert">
            {{ $t('Errors.E060') }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.OtherInflowPath') }}
        </p>
      </div>
      <div class="col">
        <input
            id="otherInflowPath2"
            v-model="customer.otherInflowPath"
            :class="[
            filterErrorColor('otherInflowPath') || showErrorInflow ? 'error' : '',
            'form-control me-4'
          ]"
            :disabled="disabledFields || disableOtherInflowRoutes"
            :placeholder="$t('Form.Placeholder')"
            type="text"
            @change="validateBlankSpace($event, 'otherInflowPath')"
            @keyup="inflowTermEnter($event)"
        />
        <div v-if="filterErrorColor('otherInflowPath')" style="margin-top: 6px">
          <span class="error-box ms-0" role="alert" style="margin-top: -12px">
            {{ filterErrors('otherInflowPath') }}
          </span>
        </div>
        <div v-if="showErrorInflow" style="margin-top: 6px">
          <span class="error-box ms-0" role="alert" style="margin-top: -12px">
            {{ $t('Errors.E060') }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-3">
        <p class="form-title">
          {{ $t('CustomerDetails.Details.Notification') }}
        </p>
      </div>
      <div class="col">
        <!-- <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="customer.emailPermission"
            :disabled="disabledFields"
          />
        </div> -->
        <div class="form-check d-inline-block">
          <input
              id="emailPermissionYes"
              v-model="customer.emailPermission"
              :disabled="disabledFields"
              :value="1"
              class="form-check-input"
              type="radio"
          />
          <label class="form-check-label" for="emailPermissionYes">はい</label>
        </div>
        <div class="form-check d-inline-block">
          <input
              id="flexRadioDefault2No"
              v-model="customer.emailPermission"
              :disabled="disabledFields"
              :value="0"
              class="form-check-input"
              type="radio"
          />
          <label class="form-check-label" for="flexRadioDefault2No">いいえ</label>
        </div>
      </div>
    </div>

    <div
        v-if="
        (customerQuestionnaireExists === CUSTOMER_QUESTIONNAIRE_EXISTS.QUESTIONNAIRE ||
          customerQuestionnaireExists === CUSTOMER_QUESTIONNAIRE_EXISTS.NO_QUESTIONNAIRE_PRESENT) &&
        isSypmtomOne === 1
      "
        class="row"
    >
      <div class="col-3">
        <p class="form-title">{{ $t('CustomerDetails.Details.Shooting') }}</p>
      </div>
      <div class="col">
        <div class="form-check d-inline-block">
          <input
              id="shootingPermissionYes"
              v-model="customer.shootingPermission"
              :value="1"
              class="form-check-input"
              type="radio"
          />
          <label class="form-check-label" for="shootingPermissionYes">許可</label>
        </div>
        <div class="form-check d-inline-block">
          <input
              id="shootingPermissionNo"
              v-model="customer.shootingPermission"
              :value="0"
              class="form-check-input"
              type="radio"
          />
          <label class="form-check-label" for="shootingPermissionNo">不許可</label>
        </div>
        <!-- <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="customer.shootingPermission"
            :disabled="disabledFields"
          />
        </div> -->
      </div>
    </div>

    <hr/>
    <span v-if="filterErrorColor('form')" class="error-box" role="alert">
      {{ filterErrors('form') }}
    </span>
    <!-- <hr /> -->
    <div class="buttons-container mt-40">
      <button
          v-if="customerRegistered"
          class="btn btn-secondary"
          data-bs-target="#deletionModal"
          data-bs-toggle="modal"
      >
        {{ $t('TodayList.Delete') }}
      </button>
      <button v-if="!customerRegistered" class="btn btn-secondary" @click="goBack">
        {{ $t('TodayListTable.Status.cancel') }}
      </button>
      <button :disabled="disabledFields" class="btn btn-primary" @click="OnSubmit">
        {{ $t('Policy.Registration') }}
      </button>
    </div>
  </div>

  <Modal id="deletionModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button aria-label="Close" class="btn-close" data-bs-dismiss="modal" type="button"></button>
        <div class="modal-header">
          <h4>{{ $t('DeletionModal.title') }}</h4>
        </div>
        <div class="modal-body">
          <div class="d-flex"></div>
          <div class="d-flex mt-40 justify-content-center">
            <button
                class="btn btn-secondary"
                data-bs-dismiss="modal"
                type="button"
                @click="remove1"
            >
              {{ $t('DeletionModal.deletion') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import {computed, defineComponent, onMounted, ref, watchEffect} from 'vue';
import {onBeforeRouteLeave, useRoute, useRouter} from 'vue-router';
import {store} from '@/core/store';
import moment from 'moment';
import {fetch, remove, store as post} from '@/core/api/api-service';
import Multiselect from '@vueform/multiselect';
import Modal from '@/components/modals/Modal.vue';
import {POSITION, useToast} from 'vue-toastification';
import {fetchPostalCode} from '@/core/api/postal-code-service';
import 'vue-toastification/dist/index.css';
import DatePicker from '../components/DatePicker.vue';
import check from '@/assets/icons/check-solid.svg';
import axios from 'axios';
import {useI18n} from 'vue-i18n';
import {toFullwidthKana} from 'japanese-string-utils';
import {CUSTOMER_QUESTIONNAIRE_EXISTS} from '@/core/store/enums';

export default defineComponent({
  name: 'CustomerDetail',
  components: {Modal, DatePicker, Multiselect},
  emits: ['updateNext'],
  setup(props, {emit}) {
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const i18n = useI18n();
    let showError = ref(false);
    let showErrorInflow = ref(false);
    const storeOptions = ref([]);
    let checkDisable = ref(false);
    let disableOtherInflowRoutes = ref(false);
    let errors = ref([]);
    const {
      getters: {getTrafficAccidentRegistration}
    } = store;
    const customerData = ref(getTrafficAccidentRegistration || undefined);
    const customerQuestionnaireExists = ref(CUSTOMER_QUESTIONNAIRE_EXISTS.NO_QUESTIONNAIRE_PRESENT);
    let questionTrafficAccident = ref([]);
    let monthlyProductSales = ref([]);
    let eachSales = ref([]);
    const showCalendar = ref(false);
    let customerRegistered = ref(false);
    const showCalendar2 = ref(false);
    const showCalendar3 = ref(false);
    let customerEdit = ref([]);
    let customerNumber = ref();
    let customerLineID = ref();
    let test = true;
    let custNumberFromParams;
    let multipleCityAddressArr = ref([]);
    let hasMultipleAddresses = ref(false);
    const postalCodeWithoutHyphen = ref('');
    let customer = ref({
      first_examination_staff: '',
      first_examination_staff_name: '',
      re_examination_staff: '',
      re_examination_staff_name: '',
      shootingPermission: '',
      emailPermission: '',
      otherInflowPath: '',
      searchTerms: '',
      inflowPath: '',
      review_wb: false,
      review_g_review: false,
      review_equiten: false,
      no_of_hospital_visits: '2',
      registered_store: '',
      katakana_name: '',
      kana_surname: '',
      name: '',
      surname: '',
      gender: 1,
      dob: '',
      dobYear: '0',
      dobMonth: '0',
      dobDay: '0',
      postal_code: '',
      city: '',
      prefectures: '',
      name_of_buildings: '',
      management_note: '',
      personal_info: '',
      email: '',
      telephone_number: '',
      first_visit_date: '',
      last_visit_date: '',
      revisit_date: '',
      cellphone_number: '',
      homeNumber: '',
      profession: '',
      age: '',
      hv_not_possible: false,
      no_acupunture: false,
      metal_has: true,
      metal_parts: '',
      with_acupunture: false,
      baoye_treatment_includes: false,
      traffic_or_industrial_treatment_available: false,
      member_remaining_coupon: '',
      remaining_coupon: '',
      accounts_receivable: '',
      monthly_membership_enrollment_date: '',
      monthly_member_close_date: '',
      monthly_member_latest_settlement_date: ''
    });

    let trafficAccidentInflowOptions = ref([
      {
        value: '1',
        name: '知人より',
        selected: false
      },
      {
        value: '2',
        name: '家族から',
        selected: false
      },
      {
        value: '3',
        name: '会社の同僚',
        selected: false
      },
      {
        value: '4',
        name: '当院のホームページ',
        selected: false
      },
      {
        value: '5',
        name: 'Googleマップ',
        selected: false
      },
      {
        value: '6',
        name: '外観通りがかり',
        selected: false
      },
      {
        value: '7',
        name: '現在通院中',
        selected: false
      },
      {
        value: '8',
        name: '以前通院していた',
        selected: false
      },
      {
        value: '9',
        name: 'その他',
        selected: false
      }
    ]);
    let inflowOptions = ref([
      {
        value: '1',
        name: '知人より',
        selected: false
      },
      {
        value: '2',
        name: '家族から',
        selected: false
      },
      {
        value: '3',
        name: '会社の同僚',
        selected: false
      },
      {
        value: '4',
        name: 'チラシ',
        selected: false
      },
      {
        value: '5',
        name: '当院のホームページ',
        selected: false
      },
      {
        value: '6',
        name: 'Googleマップ',
        selected: false
      },
      {
        value: '7',
        name: 'エキテン',
        selected: false
      },
      {
        value: '8',
        name: 'Facebook',
        selected: false
      },
      {
        value: '9',
        name: 'ホットペッパー',
        selected: false
      },
      {
        value: '10',
        name: 'その他オンライン',
        selected: false
      },
      {
        value: '11',
        name: '外観 通りがかり 看板を見て',
        selected: false
      },
      {
        value: '12',
        name: 'その他',
        selected: false
      }
    ]);
    let newReservationDetailReviewQuestions = {};
    let reservationDetailReviewQuestions = {
      themeName: 'survey',
      formatVer: '1.0',
      contents: [
        {
          id: 'q1',
          question: '当院に来院する決め手となったものを一つお選びください',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: '1',
              text: '知人より',
              children: {
                text: 'お名前',
                required: 'false',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '2',
              text: '家族から'
            },
            {
              value: '3',
              text: '会社の同僚'
            },
            {
              value: '4',
              text: 'チラシ'
            },
            {
              value: '5',
              text: '当院のホームページ',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '6',
              text: 'Googleマップ',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '7',
              text: 'エキテン',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '8',
              text: 'Facebook',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '9',
              text: 'ホットペッパー',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '10',
              text: 'その他オンライン',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '11',
              text: '外観　通りがかり　看板を見て'
            },
            {
              value: '12',
              text: 'その他',
              children: {
                anserType: 'text',
                answer: '',
                required: 'true'
              }
            }
          ]
        },
        {
          id: 'q2',
          question: 'ハガキなどでご連絡を差し上げてもよろしいでしょうか？',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: 'true',
              text: 'はい'
            },
            {
              value: 'false',
              text: 'いいえ'
            }
          ]
        }
      ]
    };
    const customerID = route.params.userData;
    let smaregiStore, latestSettlementDate;
    let isSypmtomOne = ref(1);
    const isTrafficAccidentCustomer = ref(false);
    if (
        route.params.id.includes('new-customer-traffic-accident') ||
        route.params.id.includes('edit-customer-traffic-accident')
    ) {
      isTrafficAccidentCustomer.value = true;
    }
    if (route.params.id.includes('new-customer-traffic-accident')) {
      //When params sent from redirect funciton call to this page
      smaregiStore = route.params.storeID; // taking storeId rather than smaregId
      isSypmtomOne = parseInt(route.params.symptom);
    } else {
      //When page is refreshed get id smargeId from api call
      let id = route.params.id.split('=')?.[1];
      if (id.includes('?')) {
        id = id.replace(/\?/g, '');
      }
      fetch(`api/v1/customers/${id}`).then(async (res) => {
        const customerResponse = await res?.data?.data;
        latestSettlementDate = customerResponse?.monthlyMemberLatestSettlementDate;
        smaregiStore = customerResponse?.store?.id; // taking storeId rather than smaregId
        isSypmtomOne = customerResponse?.symptom;
      });
    }

    const MedicalID = route.params.MedicalID;
    const UpdateMedicalID = route.params.updateMedicalID;
    const customerNumberFromList = route.params.customerNumberFromList;
    const consultationClassification = route.params.consultationClassification;

    let yearData = ref([]);

    const searchTermEnter = (value) => {
      if (value.target.value.length > 100) {
        showError.value = true;
      } else {
        showError.value = false;
      }
    };

    const inflowTermEnter = (value) => {
      if (value.target.value.length > 100) {
        showErrorInflow.value = true;
      } else {
        showErrorInflow.value = false;
      }
    };

    const YEARS = () => {
      const numYears = 102;
      const years = [];
      const start = moment();
      const months = Array.from({length: numYears}, (_, i) =>
          moment(start.subtract(1, 'year')).format('YYYY')
      ).reverse();
      if (months.length > 0) {
        months.forEach((dates) => {
          years.push({
            value: dates,
            name: dates
          });
        });
        yearData.value = years;
      }
    };
    const daysOption = computed(() => {
      let daysCalculated;
      if (
          customer.value.dobMonth &&
          Number(customer.value.dobMonth) !== 0 &&
          customer.value.dobYear
      ) {
        const numberOfDays =
            customer.value.dobMonth && customer.value.dobYear
                ? moment(
                    `${customer.value.dobYear}-${customer.value.dobMonth}`,
                    'YYYY-MM'
                )?.daysInMonth()
                : '';
        let daysArray =
            numberOfDays &&
            Array.from({length: numberOfDays}, (v, i) => {
              return {
                value: i < 9 ? ('0' + Number(i + 1)).toString() : Number(i + 1).toString(),
                name: i < 9 ? ('0' + Number(i + 1)).toString() : Number(i + 1).toString(),
                selected: false,
                icon: require('@/assets/icons/check-solid.svg')
              };
            });
        daysCalculated = (daysArray && daysArray.length) > 0 ? daysArray : [];
        return daysCalculated;
      } else {
        daysCalculated = '-';
      }
      return daysCalculated;
    });

    const handleDateNumber = (evt) => {
      evt = evt ? evt : window.event;
      evt.preventDefault();
    };

    onBeforeRouteLeave((to, from, next) => {
      store.dispatch('setRouteFrom', from.name);
      next()
    });
    onMounted(async () => {
      getStores();
      if (MedicalID) {
        fetch(`api/v1/search-questionnaire-traffic-accident${MedicalID}`).then(async (res) => {
          const response = await res?.data?.data[0];
          questionTrafficAccident.value = response;
        });
      }
      if (route.params.id.includes('customer-traffic-accident')) {
        custNumberFromParams = `?customerNumber=${route.params.id.split('=')[1]}`;
      } else {
        custNumberFromParams = `?customerNumber=${route.params.id.split('?')[2]}`;
      }
      if (
          customerNumberFromList ||
          route.params.id.split('=')[1] !== undefined ||
          route.params.id.split('=')[2] !== undefined
      ) {
        customerRegistered.value = true;
        let endpoints = [
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/search-questionnaire-traffic-accident${
              customerNumberFromList ? customerNumberFromList : custNumberFromParams
          }`,
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/questionnaires-search${
              customerNumberFromList ? customerNumberFromList : custNumberFromParams
          }`,
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/monthly-product-sales${
              customerNumberFromList ? customerNumberFromList : custNumberFromParams
          }`,
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/each-sales${
              customerNumberFromList ? customerNumberFromList : custNumberFromParams
          }`
        ];
        Promise.all(
            endpoints.map((endpoint) =>
                axios.get(endpoint, {
                  headers: {Authorization: 'Bearer ' + store.getters.token}
                })
            )
        ).then(
            axios.spread(async (...allData) => {
              if (allData?.[0]?.data?.data.length > 0) {
                const response = await allData?.[0]?.data?.data[0];
                questionTrafficAccident.value = response;
                if (
                    response.inflowRouteClassification == 2 ||
                    response.inflowRouteClassification == 3 ||
                    response.inflowRouteClassification == 4 ||
                    response.inflowRouteClassification == 11 ||
                    response.inflowRouteClassification == 12
                ) {
                  checkDisable.value = true;
                }
                if (response.inflowRouteClassification == 12) {
                  disableOtherInflowRoutes.value = false;
                } else {
                  disableOtherInflowRoutes.value = true;
                }
                customerQuestionnaireExists.value =
                    CUSTOMER_QUESTIONNAIRE_EXISTS.TRAFFIC_ACCIDENT_QUESTIONNAIRE;
              } else if (allData?.[1]?.data?.data.length > 0) {
                const response = await allData?.[1]?.data?.data[0];
                questionTrafficAccident.value = response;
                if (
                    response.inflowRouteClassification == 2 ||
                    response.inflowRouteClassification == 3 ||
                    response.inflowRouteClassification == 4 ||
                    response.inflowRouteClassification == 11 ||
                    response.inflowRouteClassification == 12
                ) {
                  checkDisable.value = true;
                }
                if (response.inflowRouteClassification == 12) {
                  disableOtherInflowRoutes.value = false;
                } else {
                  disableOtherInflowRoutes.value = true;
                }
                customerQuestionnaireExists.value = CUSTOMER_QUESTIONNAIRE_EXISTS.QUESTIONNAIRE;
              } else {
                customerQuestionnaireExists.value =
                    CUSTOMER_QUESTIONNAIRE_EXISTS.NO_QUESTIONNAIRE_PRESENT;
              }
              if (allData?.[2]?.data?.data.length > 0) {
                const response2 = await allData?.[2]?.data?.data;
                monthlyProductSales.value = response2;
              }
              if (allData?.[3]?.data?.data.length > 0) {
                const response3 = await allData?.[3]?.data?.data;
                eachSales.value = response3;
              }
            })
        );
      }
      if (route.params.id.split('?')?.[0] === 'edit') {
        const id = await route.params.id.split('?')?.[2];
        fetch(`api/v1/customers/${id}`).then(async (res) => {
          const customerResponse = await res?.data?.data;
          customerEdit.value = customerResponse;
          customerNumber.value = customerResponse?.customerNumber;
          isSypmtomOne = customerResponse?.symptom;
        });
      }
    });

    const getStores = () => {
      fetch(`api/v1/getStores`).then((res) => {
        if (res) {
          const storeRes = res.data.data;
          storeOptions.value = storeRes
              .sort((a, b) => a.displayOrder - b.displayOrder)
              .map((store1) => {
                return {
                  value: store1.id,
                  name: store1.storeName,
                  storeBusinessHours: store1.storeBusinessHours,
                  timeFrame: store1.timeFrame,
                  icon: check
                };
              });
        }
      });
    };

    YEARS();
    watchEffect(() => {
      (customerNumber.value =
          route?.params?.id?.split('?')?.[0] === 'edit'
              ? customerEdit?.value?.customerNumber
              : route?.params?.id === 'new-customer-traffic-accident'
                  ? '-'
                  : customerData?.value?.customerNumber),
          (customerLineID.value =
              route?.params?.id?.split('?')?.[0] === 'edit'
                  ? customerEdit?.value?.lineId
                  : route?.params?.id === 'new-customer-traffic-accident'
                      ? '-'
                      : customerData?.value?.lineId),
          (customer.value.no_of_hospital_visits =
              route?.params?.id?.split('?')?.[0] === 'edit'
                  ? customerEdit?.value?.noOfHospitalVisits
                  : route?.params?.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.noOfHospitalVisits),
          (customer.value.revisit_date =
              route?.params?.id?.split('?')?.[0] === 'edit'
                  ? customerEdit?.value?.revisitDate
                  : route?.params?.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.revisitDate),
          (customer.value.first_examination_staff =
              route?.params?.id?.split('?')?.[0] === 'edit'
                  ? customerEdit?.value?.firstExaminationStaff?.id
                  : route?.params?.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.firstExaminationStaff?.name),
          (customer.value.first_examination_staff_name =
              route?.params?.id?.split('?')?.[0] === 'edit'
                  ? customerEdit?.value?.firstExaminationStaff?.name
                  : route?.params?.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.firstExaminationStaff?.name),
          (customer.value.re_examination_staff =
              route?.params?.id?.split('?')?.[0] === 'edit'
                  ? customerEdit?.value?.reExaminationStaff?.id
                  : route?.params?.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.reExaminationStaff?.name),
          (customer.value.re_examination_staff_name =
              route?.params?.id?.split('?')?.[0] === 'edit'
                  ? customerEdit?.value?.reExaminationStaff?.name
                  : route?.params?.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.reExaminationStaff?.name),
          (customer.value.last_visit_date =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.lastVisitDate
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.lastVisitDate),
          (customer.value.first_visit_date =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.firstVisitDate
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.firstVisitDate),
          (customer.value.registered_store =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.store?.id
                  : smaregiStore),
          (customer.value.katakana_name =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.katakanaName
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.katakanaName),
          (customer.value.kana_surname =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.kanaSurname
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.kanaSurname),
          (customer.value.name =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.name
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.name),
          (customer.value.surname =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.surname
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.surname),
          (customer.value.gender =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.gender
                  : route.params.id === 'new-customer-traffic-accident'
                      ? '1'
                      : customerData?.value?.gender),
          (customer.value.dobYear =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit?.value?.dob?.split('-')[0]
                  : route.params.id === 'new-customer-traffic-accident'
                      ? '1980'
                      : customerData?.value?.dob?.split('-')[0]),
          (customer.value.dobMonth =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit?.value?.dob?.split('-')[1]
                  : route.params.id === 'new-customer-traffic-accident'
                      ? '0'
                      : customerData?.value?.dob?.split('-')[1]),
          (customer.value.dobDay =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit?.value?.dob?.split('-')[2].toString()
                  : route.params.id === 'new-customer-traffic-accident'
                      ? '0'
                      : customerData?.value?.dob?.split('-')[2].toString()),
          (customer.value.postal_code =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.postalCode
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.postalCode),
          (customer.value.city =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.city
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.city),
          (customer.value.prefectures =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.prefectures
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.prefectures),
          (customer.value.name_of_buildings =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.nameofBuilding
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.nameofBuilding),
          (customer.value.management_note =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.managementNote
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.managementNote),
          (customer.value.personal_info =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.personalInfo // when reload, get value from api where the field is personalInfo rather than personal_info
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.personalInfo),
          (customer.value.email =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.email
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.email),
          (customer.value.telephone_number =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.telephoneNumber
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.telephoneNumber),
          (customer.value.cellphone_number =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.cellphoneNumber
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.cellphoneNumber),
          (customer.value.monthly_member_close_date =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.monthlyMemberCloseDate
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.monthlyMemberCloseDate),
          (customer.value.profession =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.profession
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.profession),
          (customer.value.age = ''),
          (customer.value.hv_not_possible =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.hvNotPossible
                  : route.params.id === 'new-customer-traffic-accident'
                      ? false
                      : customerData?.value?.hvNotPossible),
          (customer.value.no_acupunture =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.noAcupunture
                  : route.params.id === 'new-customer-traffic-accident'
                      ? false
                      : customerData?.value?.noAcupunture),
          (customer.value.metal_has =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.metalHas
                  : route.params.id === 'new-customer-traffic-accident'
                      ? false
                      : customerData?.value?.metalHas),
          (customer.value.metal_parts =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.metalParts
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.metalParts),
          (customer.value.with_acupunture =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.withAcupunture
                  : route.params.id === 'new-customer-traffic-accident'
                      ? false
                      : customerData?.value?.withAcupunture),
          (customer.value.baoye_treatment_includes =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.baoyeTreatmentIncludes
                  : route.params.id === 'new-customer-traffic-accident'
                      ? false
                      : customerData?.value?.baoyeTreatmentIncludes),
          (customer.value.traffic_or_industrial_treatment_available =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.trafficOrIndustrialTreatmentAvailable
                  : route.params.id === 'new-customer-traffic-accident'
                      ? false
                      : customerData?.value?.trafficOrIndustrialTreatmentAvailable),
          (customer.value.member_remaining_coupon =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.memberRemainingCoupon
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.memberRemainingCoupon),
          (customer.value.monthly_membership_enrollment_date =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.monthlyMembershipEnrollmentDate
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.monthlyMembershipEnrollmentDate),
          (customer.value.monthly_member_close_date =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.monthlyMemberCloseDate
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.monthlyMemberCloseDate),
          (customer.value.monthly_member_latest_settlement_date =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.monthlyMemberLatestSettlementDate
                      ? customerEdit.value.monthlyMemberLatestSettlementDate
                      : null
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.monthlyMemberLatestSettlementDate
                          ? moment(
                              customerData?.value?.monthlyMemberLatestSettlementDate,
                              'YYYY-MM-DD HH:mm:ss'
                          ).format('YYYY-MM-DD')
                          : null),
          (customer.value.remaining_coupon =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.remainingCoupon
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.remainingCoupon),
          (customer.value.accounts_receivable =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.accountsReceivable
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : customerData?.value?.accountsReceivable),
          (customer.value.review_wb =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.reviewWB
                  : route.params.id === 'new-customer-traffic-accident'
                      ? false
                      : customerData?.value?.reviewWB),
          (customer.value.review_g_review =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.reviewGReview
                  : route.params.id === 'new-customer-traffic-accident'
                      ? false
                      : customerData?.value?.reviewGReview),
          (customer.value.review_equiten =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? customerEdit.value.reviewEquiten
                  : route.params.id === 'new-customer-traffic-accident'
                      ? false
                      : customerData?.value?.reviewEquiten),
          (customer.value.otherInflowPath =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? questionTrafficAccident?.value?.otherInflowRoute || ''
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : questionTrafficAccident?.value?.otherInflowRoute || ''),
          (customer.value.inflowPath =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? questionTrafficAccident?.value?.inflowRouteClassification || ''
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : questionTrafficAccident?.value?.inflowRouteClassification || ''),
          (customer.value.searchTerms =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? questionTrafficAccident?.value?.searchKeyword || ''
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : questionTrafficAccident?.value?.searchKeyword || ''),
          (customer.value.shootingPermission =
              route.params.id?.split('?')?.[0] === 'edit'
                  ? questionTrafficAccident?.value?.photographingPermission === true
                      ? 1
                      : questionTrafficAccident?.value?.photographingPermission === false
                          ? 0
                          : ''
                  : route.params.id === 'new-customer-traffic-accident'
                      ? ''
                      : questionTrafficAccident?.value?.photographingPermission
                          ? 1
                          : 0 || ''),
          (customer.value.emailPermission =
              route.params.id === 'new-customer-traffic-accident'
                  ? ''
                  : customerEdit.value?.notificationEmailPermission
                      ? 1
                      : 0);
    });

    const remove1 = () => {
      const UrlId = customerNumber?.value;
      let questionnaireUrl = questionTrafficAccident.value?.id;
      // remove(`api/v1/questionnaires/${questionnaireUrl}`)
      //   .then(async (res) => {
      remove(`api/v1/customers/${UrlId}`)
          .then(async (res) => {
            if (res) {
              window.scrollTo(0, 0);
              toast.success('削除しました', {
                timeout: 2000,
                position: POSITION.BOTTOM_RIGHT
              });
              router.push({
                name: 'CustomerList'
              });
            }
            // emit("updateNext", "true");
          })
          .catch((err) => {
            if (err.response?.status === 400) {
              toast.error(`${err.response.data?.errors?.[0]?.title}`, {
                timeout: 2000,
                position: POSITION.BOTTOM_RIGHT
              });
            }
          });
      // })
      // .catch((err) => {
      //   if (err.response?.status === 400) {
      //     toast.error(`${err.response.data?.errors?.[0]?.title}`, {
      //       timeout: 2000,
      //       position: POSITION.BOTTOM_RIGHT,
      //     });
      //   }
      // });
    };

    const handleNumberRegex = (evt, type) => {
      const getValue = evt?.target.value;
      var numberPattern = /^[0-9]+$/g;

      const test = numberPattern.test(getValue);

      if (type === 'customer.postal_code') {
        if (test === true) {
          customer.value.postal_code = getValue;
        } else {
          customer.value.postal_code = '';
        }
      } else if (type === 'customer.telephone_number') {
        if (test === true) {
          customer.value.telephone_number = getValue;
        } else {
          customer.value.telephone_number = '';
        }
      } else if (type === 'customer.cellphone_number') {
        if (test === true) {
          customer.value.cellphone_number = getValue;
        } else {
          customer.value.cellphone_number = '';
        }
      }
    };

    const handleNumber = (evt) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode === 46) {
        evt.preventDefault();
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      } else {
        if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
          evt.preventDefault();
        } else {
          return true;
        }
      }
    };

    const calAge = (date) => {
      var diff_ms = Date.now() - date.getTime();
      var age_dt = new Date(diff_ms);
      return Math.abs(age_dt.getUTCFullYear() - 1970);
    };

    const goBack = () => {
      router.back();
    };

    const filterErrorColor = (fieldName) => {
      if (errors.value.length > 0) {
        const errorMessage = errors.value.filter((x) => x.title === fieldName);
        if (errorMessage && errorMessage.length > 0) {
          return true;
        } else if (errorMessage.length === 0) {
          return false;
        }
      }
    };
    const filterErrors = (data) => {
      if (errors.value.length > 0) {
        const errorMessage = errors.value.filter((x) => x.title === data);
        return errorMessage[0]?.message;
      }
    };

    const OnSubmit = async () => {
      errors.value = [];
      if (postalCodeWithoutHyphen.value) {
        customer.value.postal_code = postalCodeWithoutHyphen.value;
      }
      let formData = customer?.value;
      formData.monthlyProductSale = monthlyProductSales.value.map((e) => {
        return {
          id: e.id,
          suspensionDate: e.memberSuspensionDate
        };
      });
      if (formData.baoye_treatment_includes === true) {
        formData.baoye_treatment_includes = 1;
        // delete formData.baoyeTreatmentIncludes;
      } else {
        formData.baoye_treatment_includes = 0;
        // delete formData.baoyeTreatmentIncludes;
        // formData.baoyeTreatmentIncludes = 0;
      }
      if (formData.traffic_or_industrial_treatment_available === true) {
        formData.traffic_or_industrial_treatment_available = 1;
        // delete formData.tr;
      } else {
        formData.traffic_or_industrial_treatment_available = 0;
        // delete formData.traffic_or_industrial_treatment_available;
      }

      if (formData.with_acupunture === true) {
        formData.with_acupunture = 1;
      } else {
        formData.with_acupunture = 0;
      }
      const questainoireData = {};
      questainoireData.search_keyword = customer?.value?.searchTerms;
      questainoireData.inflow_route_classification = customer?.value?.inflowPath;
      questainoireData.other_inflow_route = customer?.value?.otherInflowPath;
      questainoireData.notification_email_permission =
          customer?.value?.emailPermission === true || customer?.value?.emailPermission === false
              ? customer?.value?.emailPermission
              : undefined;
      questainoireData.photographing_permission =
          customer?.value?.shootingPermission === 0 || customer?.value?.shootingPermission === 1
              ? customer?.value?.shootingPermission
              : undefined;
      questainoireData.medical_record_id =
          MedicalID?.split('=')?.[1] ||
          questionTrafficAccident.value?.medicalRecord?.id ||
          questionTrafficAccident.value?.medicalRecordId;
      questainoireData.customer_number = customerNumber.value;

      formData.dob = `${customer?.value?.dobYear}-${customer?.value?.dobMonth}-${customer?.value?.dobDay}`;
      formData.age = calAge(
          new Date(
              Number(customer?.value?.dobYear),
              Number(customer?.value?.dobMonth),
              Number(customer?.value?.dobDay)
          )
      );
      //   delete formData.dobDay;
      // delete formData.dobMonth;
      // delete formData.dobYear;
      // delete formData.searchTerms;
      // delete formData.inflowPath;
      // delete formData.otherInflowPath;

      if (!formData.city) {
        errors.value.push({
          title: 'city',
          message: '必須'
        });
      }

      if (formData.dob) {
        let dobYear = formData.dob.split('-')[0];
        let dobMonth = formData.dob.split('-')[1];
        let dobDay = formData.dob.split('-')[2];
        if (dobYear === '0' || dobYear == 'undefined') {
          errors.value.push({
            title: 'year',
            message: '必須'
          });
        }
        if (dobMonth === '0' || dobMonth == 'undefined') {
          errors.value.push({
            title: 'month',
            message: '必須'
          });
        }
        if (dobDay === '0' || dobDay == 'undefined') {
          errors.value.push({
            title: 'day',
            message: '必須'
          });
        }
      }

      if (!formData.prefectures) {
        errors.value.push({
          title: 'prefectures',
          message: '必須'
        });
      }
      if (!formData.katakana_name) {
        errors.value.push({
          title: 'katakana_name',
          message: '必須'
        });
      }

      if (formData.katakana_name) {
        const getValue1 = formData.katakana_name;
        var numberPattern3 = /^[ァ-ヾ]*$/g;

        const found = getValue1.match(numberPattern3);
        var firstItem2 = Array.isArray(found) ? found.shift() : [];
        if (!firstItem2 || firstItem2.length === 0) {
          errors.value.push({
            title: 'katakana_name',
            message: i18n.t('Errors.E063')
          });
        }
        if (firstItem2 && formData.katakana_name.length > 82) {
          errors.value.push({
            title: 'katakana_name',
            message: i18n.t('Errors.E060')
          });
        }
      }
      if (!formData.kana_surname) {
        errors.value.push({
          title: 'kana_surname',
          message: '必須'
        });
      }
      if (formData.kana_surname) {
        const getValue1 = formData.kana_surname;
        var numberPattern = /^[ァ-ヾ]*$/g;
        const found = getValue1.match(numberPattern);
        var firstItem = Array.isArray(found) ? found.shift() : [];
        if (!firstItem || firstItem.length === 0) {
          errors.value.push({
            title: 'kana_surname',
            message: i18n.t('Errors.E063')
          });
        }
        if (firstItem2 && formData.kana_surname.length > 82) {
          errors.value.push({
            title: 'kana_surname',
            message: i18n.t('Errors.E060')
          });
        }
      }

      if (!formData.name) {
        errors.value.push({
          title: 'name',
          message: '必須'
        });
      }
      if (!formData.surname) {
        errors.value.push({
          title: 'surname',
          message: '必須'
        });
      }
      if (!formData.registered_store) {
        errors.value.push({
          title: 'registered_store',
          message: '必須'
        });
      }
      if (!formData.inflowPath) {
        errors.value.push({
          title: 'inflowPath',
          message: '必須'
        });
      }
      if (formData.inflowPath === '12' && !formData.otherInflowPath) {
        errors.value.push({
          title: 'otherInflowPath',
          message: '必須'
        });
      }

      if (
          parseInt(formData.inflowPath) !== 12 &&
          !formData.searchTerms &&
          parseInt(formData.inflowPath) !== 2 &&
          parseInt(formData.inflowPath) !== 1 &&
          parseInt(formData.inflowPath) !== 3 &&
          parseInt(formData.inflowPath) !== 4 &&
          parseInt(formData.inflowPath) !== 11
      ) {
        errors.value.push({
          title: 'searchTerms',
          message: '必須'
        });
      }

      if (formData.searchTerms.length > 100) {
        showError.value = false;
        errors.value.push({
          title: 'searchTerms',
          message: '桁数超過'
        });
      }

      if (formData.otherInflowPath.length > 100) {
        showErrorInflow.value = false;
        errors.value.push({
          title: 'otherInflowPath',
          message: '桁数超過'
        });
      }

      if (!formData.cellphone_number && !formData.telephone_number) {
        errors.value.push({
          title: 'cellphone_number',
          message: '必須'
        });
        errors.value.push({
          title: 'telephone_number',
          message: '必須'
        });
      }

      if (formData.email) {
        let pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;
        const getEmail = formData.email.toLowerCase();
        const test = pattern.test(getEmail);
        if (test === false) {
          errors.value.push({
            title: 'email',
            message: i18n.t('Errors.E063')
          });
        }
      }

      if (formData.cellphone_number) {
        const getValue1 = formData.cellphone_number;
        var numberPattern1 = /^[0-9]+$/g;

        const test = numberPattern1.test(getValue1);

        if (test === true) {
          const cellphoneNumber0 = getValue1.toString().charAt(0) === '0' ? true : false;
          if (cellphoneNumber0 === true) {
            switch (cellphoneNumber0 === true) {
              case cellphoneNumber0 === true && getValue1.length === 10:
                break;
              case cellphoneNumber0 === true && getValue1.length === 11:
                break;
              default:
                errors.value.push({
                  title: 'telephone_number',
                  message: i18n.t('Errors.E060')
                });
            }
          }

          const getFirstCharacterInNumberTelephone = formData.cellphone_number;
          if (getFirstCharacterInNumberTelephone.toString().charAt(0) !== '0') {
            errors.value.push({
              title: 'cellphone_number',
              message: `形式違い`
            });
          }
        } else {
          errors.value.push({
            title: 'cellphone_number',
            message: i18n.t('Errors.E063')
          });
        }
      }

      if (formData.telephone_number) {
        const getValue1 = formData.telephone_number;
        var numberPattern2 = /^[0-9]+$/g;
        const test = numberPattern2.test(getValue1);
        if (test === true) {
          const cellphoneNumber0 = getValue1.toString().charAt(0) === '0' ? true : false;
          if (cellphoneNumber0 === true) {
            switch (cellphoneNumber0 === true) {
              case cellphoneNumber0 === true && getValue1.length === 10:
                break;
              case cellphoneNumber0 === true && getValue1.length === 11:
                break;
              default:
                errors.value.push({
                  title: 'telephone_number',
                  message: i18n.t('Errors.E060')
                });
            }
          }

          const getFirstCharacterInNumberTelephone = formData.telephone_number;
          if (getFirstCharacterInNumberTelephone.toString().charAt(0) !== '0') {
            errors.value.push({
              title: 'telephone_number',
              message: `形式違い`
            });
          }
        } else {
          errors.value.push({
            title: 'telephone_number',
            message: i18n.t('Errors.E063')
          });
        }
      }

      if (!customer.value.postal_code) {
        errors.value.push({
          title: 'postal_code',
          message: '必須'
        });
      }

      if (customer.value.postal_code) {
        if (Number(customer.value.postal_code.length) !== 7) {
          errors.value.push({
            title: 'postal_code',
            message: i18n.t('Errors.E063')
          });
        }
      }

      formData.photographing_permission = customer?.value?.shootingPermission
          ? customer?.value?.shootingPermission
          : undefined;

      formData.notification_email_permission =
          customer?.value?.emailPermission === 0 || customer?.value?.emailPermission === 1
              ? customer?.value?.emailPermission
              : undefined;

      if (errors.value.length === 0) {
        const urlType =
            route.params.id?.split('?')?.[0] === 'edit'
                ? `customers/${customerEdit?.value?.customerNumber}`
                : customerData?.value
                    ? `customers/${customerData?.value?.customerNumber}`
                    : `customers`;

        let questionnaireUrl =
            customerQuestionnaireExists.value ===
            CUSTOMER_QUESTIONNAIRE_EXISTS.TRAFFIC_ACCIDENT_QUESTIONNAIRE
                ? `questionnaire-traffic-accident`
                : customerQuestionnaireExists.value === CUSTOMER_QUESTIONNAIRE_EXISTS.QUESTIONNAIRE
                    ? `questionnaires`
                    : customer.value.shootingPermission === ''
                        ? `questionnaire-traffic-accident`
                        : `questionnaires`;

        if (customerData?.value) {
          formData._method = 'PUT';
          // questainoireData._method = "PUT";
        }
        if (route.params.id?.split('?')?.[0] === 'edit') {
          formData._method = 'PUT';
          // questainoireData._method = "PUT";
        }

        return post(`${process.env.VUE_APP_BASE_API_URL}/api/v1/${urlType}`, formData)
            .then((res) => {
              var responseFromFirstApiCall = res?.data?.data;
              const getMedicalID = UpdateMedicalID
                  ? UpdateMedicalID
                  : questainoireData.medical_record_id;
              if (getMedicalID) {
                const MedicalDatatoUpdated = new FormData();
                MedicalDatatoUpdated.append('_method', 'PUT');
                MedicalDatatoUpdated.append(
                    'customer_number',
                    res?.data?.data?.customerNumber ? res?.data?.data?.customerNumber : ''
                );
                return post(
                    `${process.env.VUE_APP_BASE_API_URL}/api/v1/medical-records/${getMedicalID}`,
                    MedicalDatatoUpdated
                )
                    .then((res) => {
                      if (!customerData?.value) {
                        if (route.params.id?.split('?')?.[0] !== 'edit') {
                          let customerValue = {customer_number: '', _method: ''};
                          questainoireData.customer_number = responseFromFirstApiCall?.customerNumber;
                          customerValue.customer_number = responseFromFirstApiCall?.customerNumber;
                          customerValue._method = 'PUT';
                          return post(
                              `${process.env.VUE_APP_BASE_API_URL}/api/v1/reservations/updateCustomer/${customerID}`,
                              customerValue
                          )
                              .then((res) => {
                                if (questainoireData.medical_record_id) {
                                  return post(
                                      `${process.env.VUE_APP_BASE_API_URL}/api/v1/${questionnaireUrl}`,
                                      questainoireData
                                  )
                                      .then((res) => {
                                        if (route.params.id?.split('?')?.[0] === 'edit') {
                                          window.scrollTo(0, 0);
                                          toast.success('更新しました', {
                                            timeout: 2000,
                                            position: POSITION.BOTTOM_RIGHT
                                          });
                                          // router.push({
                                          //   name: "CustomerList",
                                          // });
                                        } else {
                                          window.scrollTo(0, 0);
                                          toast.success('登録しました', {
                                            timeout: 2000,
                                            position: POSITION.BOTTOM_RIGHT
                                          });
                                          router.push({
                                            name: 'ReservationDetail',
                                            params: {
                                              id: customerID
                                            }
                                          });
                                        }
                                      })
                                      .catch((err) => {
                                        if (err.response?.status === 422) {
                                          const errorsResponse = err.response.data?.errors;
                                          let errorsHere = [];
                                          errorsResponse.forEach((errorItems, errorIndex) => {
                                            errorsHere.push({
                                              title: errorItems.source[0].split('":"')?.[1],
                                              message: errorItems.detail
                                            });
                                          });
                                          if (errorsResponse.length > 0) {
                                            errors.value = errorsHere;
                                          }
                                        } else {
                                          errors.value = [
                                            {
                                              title: 'form',
                                              message: err.response.data?.errors?.[0]?.title
                                            }
                                          ];
                                        }
                                      });
                                }
                              })
                              .catch((err) => {
                                if (err.response?.status === 422) {
                                  const errorsResponse = err.response.data?.errors;
                                  let errorsHere = [];
                                  errorsResponse.forEach((errorItems, errorIndex) => {
                                    errorsHere.push({
                                      title: errorItems.source[0].split('":"')?.[1],
                                      message: errorItems.detail
                                    });
                                  });
                                  if (errorsResponse.length > 0) {
                                    errors.value = errorsHere;
                                  }
                                } else {
                                  errors.value = [
                                    {
                                      title: 'form',
                                      message: err.response.data?.errors?.[0]?.title
                                    }
                                  ];
                                }
                              });
                        } else if (route.params.id?.split('?')?.[0] === 'edit') {
                          fetch(
                              //custNumberFromParams = "?customerNumber=1001329"
                              `/api/v1/questionnaires-search${
                                  customerNumberFromList ? customerNumberFromList : custNumberFromParams
                              }`
                          ).then((res) => {
                            // console.log({ custNumberFromParams });
                            if (res.status === 200) {
                              /* fetch('/api/v1/questionnaires-search?customerNumber=1001329').then(
                                    // fetch('/api/v1/questionnaire-json').then((res) => {
                                    (response) => {
                                      if (response.status === 200) {
                                        const dataFromAPI = JSON.parse(
                                          response.data.data[0].questionAnswer
                                        );
                                        console.log({ dataFromAPI });
                                      }
                                    }
                                  ); */
                              newReservationDetailReviewQuestions = JSON.parse(
                                  res.data.data[0].questionAnswer
                              );

                              const filterArr =
                                  newReservationDetailReviewQuestions.contents[0].options.map((e) => {
                                    if (e.value !== questainoireData.inflow_route_classification) {
                                      if (e.children) {
                                        e.children.answer = '';
                                      }
                                    } else {
                                      newReservationDetailReviewQuestions.contents[0].answer =
                                          questainoireData.inflow_route_classification;
                                      if (e.children) {
                                        e.children.answer = questainoireData.search_keyword
                                            ? questainoireData.search_keyword
                                            : questainoireData.other_inflow_route;
                                      }
                                    }
                                    return e;
                                  });
                              if (
                                  newReservationDetailReviewQuestions.contents[1].question ===
                                  'サービス向上の為にカウンセリング内容を記録しています'
                              ) {
                                newReservationDetailReviewQuestions.contents[1].answer =
                                    questainoireData.photographing_permission ? 'true' : 'false';
                                if (newReservationDetailReviewQuestions.contents[2]) {
                                  newReservationDetailReviewQuestions.contents[2].answer = customer
                                      ?.value?.emailPermission
                                      ? 'true'
                                      : 'false';
                                }
                              } else {
                                newReservationDetailReviewQuestions.contents[1].answer = customer?.value
                                    ?.emailPermission
                                    ? 'true'
                                    : 'false';
                                if (newReservationDetailReviewQuestions.contents[2]) {
                                  newReservationDetailReviewQuestions.contents[1].answer =
                                      questainoireData.photographing_permission ? 'true' : 'false';
                                }
                              }
                              // questainoireData.question_answer = JSON.stringify(
                              //   newReservationDetailReviewQuestions
                              // );
                              questainoireData._method = 'PUT';

                              return post(
                                  `${process.env.VUE_APP_BASE_API_URL}/api/v1/${questionnaireUrl}/${res.data.data[0].id}`,
                                  questainoireData
                              ).then((res) => {
                                if (route.params.id?.split('?')?.[0] === 'edit') {
                                  store.dispatch(
                                      'setStepReviewQuestions',
                                      newReservationDetailReviewQuestions
                                  );
                                  window.scrollTo(0, 0);
                                  toast.success('更新しました', {
                                    timeout: 2000,
                                    position: POSITION.BOTTOM_RIGHT
                                  });
                                  // router.push({
                                  //   name: "CustomerList",
                                  // });
                                } else {
                                  window.scrollTo(0, 0);
                                  toast.success('登録しました', {
                                    timeout: 2000,
                                    position: POSITION.BOTTOM_RIGHT
                                  });
                                  router.push({
                                    name: 'ReservationDetail',
                                    params: {
                                      id: customerID
                                    }
                                  });
                                }
                              });
                            }
                          });
                        }
                      } else {
                        return post(
                            `${process.env.VUE_APP_BASE_API_URL}/api/v1/${questionnaireUrl}`,
                            questainoireData
                        )
                            .then((responseFromQuestionnarie) => {
                              if (responseFromQuestionnarie) {
                                if (route.params.id?.split('?')?.[0] === 'edit') {
                                  window.scrollTo(0, 0);
                                  toast.success('更新しました', {
                                    timeout: 2000,
                                    position: POSITION.BOTTOM_RIGHT
                                  });
                                  // router.push({
                                  //   name: 'CustomerList'
                                  // });
                                } else {
                                  window.scrollTo(0, 0);
                                  toast.success('登録しました', {
                                    timeout: 2000,
                                    position: POSITION.BOTTOM_RIGHT
                                  });
                                  router.push({
                                    name: 'ReservationDetail',
                                    params: {
                                      id: customerID
                                    }
                                  });
                                }
                              }
                            })
                            .catch((err) => {
                              if (err.response?.status === 422) {
                                const errorsResponse = err.response.data?.errors;
                                let errorsHere = [];
                                errorsResponse.forEach((errorItems, errorIndex) => {
                                  errorsHere.push({
                                    title: errorItems.source[0].split('":"')?.[1],
                                    message: errorItems.detail
                                  });
                                });
                                if (errorsResponse.length > 0) {
                                  errors.value = errorsHere;
                                }
                              } else {
                                errors.value = [
                                  {
                                    title: 'form',
                                    message: err.response.data?.errors?.[0]?.title
                                  }
                                ];
                              }
                            });
                      }
                    })
                    .catch((err) => {
                      console.log('err', err);
                    });
              }

              emit('updateNext', 'true');
            })
            .catch((err) => {
              if (err.response?.status === 422) {
                const errorsResponse = err.response.data?.errors;
                let errorsHere = [];
                errorsResponse.forEach((errorItems, errorIndex) => {
                  errorsHere.push({
                    title: errorItems.source[0].split('":"')?.[1],
                    message: errorItems.detail
                  });
                });
                if (errorsResponse.length > 0) {
                  errors.value = errorsHere;
                }
              } else {
                errors.value = [
                  {
                    title: 'form',
                    message: err.response.data?.errors?.[0]?.title
                  }
                ];
              }
            });
      }
    };
    const disabledFields = computed((value) => {
      let disabled;
      if (consultationClassification == '2') {
        disabled = true;
      } else {
        disabled = false;
      }
      return disabled;
    });

    const calculateAgeField = computed(() => {
      let ageCalculated;
      if (
          Number(customer?.value?.dobYear) &&
          Number(customer?.value?.dobYear) !== 0 &&
          Number(customer?.value?.dobMonth) &&
          Number(customer?.value?.dobMonth)
      ) {
        ageCalculated = calAge(
            new Date(
                Number(customer?.value?.dobYear),
                Number(customer?.value?.dobMonth),
                Number(customer?.value?.dobDay)
            )
        );
      } else {
        ageCalculated = '-';
      }
      return ageCalculated;
    });

    const checkPostalCodeValid = (value) => {
      if (value === '') {
        removeError('postal_code');
      }
    };

    const removeError = (errorTitle) => {
      let indexOfValue = -1;
      errors.value?.some((error, index) => {
        if (error.title === errorTitle) {
          indexOfValue = index;
        }
        return error.title === errorTitle;
      });
      if (indexOfValue > -1) {
        errors.value.splice(indexOfValue, 1);
      }
    };

    const handlePostalcodeRegex = (event) => {
      const postalCodeWithHyphen = event?.target.value;
      postalCodeWithoutHyphen.value = postalCodeWithHyphen.replaceAll('-', '');
      var numberPattern = /^[0-9]+$/g;
      const test = numberPattern.test(postalCodeWithoutHyphen.value);
      if (test) {
        if (postalCodeWithoutHyphen.value.length > 7) {
          errors.value.push({
            title: 'postCode',
            message: i18n.t('Errors.E060')
          });
        } else if (postalCodeWithoutHyphen.value.length < 7) {
          errors.value.push({
            title: 'postCode',
            message: i18n.t('Errors.E063')
          });
        }
      }
    };

    const checkdisableFunction = (event) => {
      if (
          event.target.value == 2 ||
          event.target.value == 3 ||
          event.target.value == 4 ||
          event.target.value == 11 ||
          event.target.value == 12
      ) {
        checkDisable.value = true;
        customer.value.searchTerms = '';
        errors.value.map((i, index) => {
          if (i.title === 'searchTerms') {
            errors.value[index] = [];
          }
        });
      } else {
        checkDisable.value = false;
      }
      if (event.target.value == 12) {
        disableOtherInflowRoutes.value = false;
      } else {
        customer.value.otherInflowPath = '';
        disableOtherInflowRoutes.value = true;
      }
    };

    const searchPostalCode = () => {
      removeError('postal_code');
      let indexOfPostalCode = -1;
      errors.value?.some((error, index) => {
        if (error.title === 'postal_code') {
          indexOfPostalCode = index;
        }
        return error.title === 'postal_code';
      });
      if (indexOfPostalCode > -1) {
        errors.value.splice(indexOfPostalCode, 1);
      }
      fetchPostalCode(postalCodeWithoutHyphen.value)
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data && res.data.data.length > 0) {
              if (res.data.data.length > 1) {
                const postalCodeData = res.data.data;
                // multiplePrefecturesArr.value = [];
                multipleCityAddressArr.value = [];
                hasMultipleAddresses.value = true;
                customer.value.prefectures = postalCodeData[0].prefecture;
                for (const postalCode of postalCodeData) {
                  // multiplePrefecturesArr.value.push(postalCode.prefecture);
                  let city = postalCode.city;
                  if (postalCode.town) {
                    city += ' ' + postalCode.town;
                  }
                  if (postalCode.koaza) {
                    city += ' ' + postalCode.koaza;
                  }
                  if (postalCode.kyoto_street) {
                    city += ' ' + postalCode.kyoto_street;
                  }
                  multipleCityAddressArr.value.push(city);
                  customer.value.city = multipleCityAddressArr.value[0];
                }
              } else {
                hasMultipleAddresses.value = false;
                const postalCodeData = res.data.data[0];
                customer.value.prefectures = postalCodeData.prefecture;
                // Set city
                let city = postalCodeData.city;
                if (postalCodeData.town) {
                  city += ' ' + postalCodeData.town;
                }
                if (postalCodeData.koaza) {
                  city += ' ' + postalCodeData.koaza;
                }
                if (postalCodeData.kyoto_street) {
                  city += ' ' + postalCodeData.kyoto_street;
                }
                customer.value.city = city;
                // Set Building
                let building = postalCodeData.building;
                if (postalCodeData.floor) {
                  building += ' ' + postalCodeData.floor;
                }
                customer.value.name_of_buildings = building;
              }
            } else {
              errors.value.push({
                title: 'postal_code',
                message: i18n.t('Ticket.Step1.InvalidPostalCode')
              });
            }
          }
        })
        .catch((err) => {
          errors.value.push({
            title: 'postal_code',
            message: i18n.t('Ticket.Step1.InvalidPostalCode')
          });
        });
    };

    const validateBlankSpace = (event, fieldType) => {
      if (event && fieldType == 'kana_surname') {
        customer.value.kana_surname = event.target.value.trim();
        customer.value.kana_surname = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'katakana_name') {
        customer.value.katakana_name = event.target.value.trim();
        customer.value.katakana_name = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'name') {
        customer.value.name = event.target.value.trim();
        customer.value.name = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'surname') {
        customer.value.surname = event.target.value.trim();
        customer.value.surname = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'email') {
        customer.value.email = event.target.value.trim();
        customer.value.email = toFullwidthKana(`${event.target.value.trim()}`);
      }

      if (event && fieldType == 'profession') {
        customer.value.profession = event.target.value.trim();
        customer.value.profession = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'management_note') {
        customer.value.management_note = event.target.value.trim();
        customer.value.management_note = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'personal_info') {
        customer.value.personal_info = event.target.value.trim();
        customer.value.personal_info = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'name_of_buildings') {
        customer.value.name_of_buildings = event.target.value.trim();
        customer.value.name_of_buildings = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'city') {
        customer.value.city = event.target.value.trim();
        customer.value.city = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'prefectures') {
        customer.value.prefectures = event.target.value.trim();
        customer.value.prefectures = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'prefectures') {
        customer.value.prefectures = event.target.value.trim();
        customer.value.prefectures = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'searchTerms') {
        customer.value.searchTerms = event.target.value.trim();
        customer.value.searchTerms = toFullwidthKana(`${event.target.value.trim()}`);
      }
      if (event && fieldType == 'otherInflowPath') {
        customer.value.otherInflowPath = event.target.value.trim();
        customer.value.otherInflowPath = toFullwidthKana(`${event.target.value.trim()}`);
      }
    };
    // };
    const changeFormat = (value) => {
      const date = moment(value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD');
      return date;
    };

    const checkboxChange = (event, eventType) => {
      if (event === 'baoyeTreatmentIncludes') {
        if (customer.value.baoye_treatment_includes === true) {
          customer.value.baoye_treatment_includes = false;
        } else {
          customer.value.baoye_treatment_includes = true;
        }
      }
      if (event === 'trafficOrIndustrialTreatmentAvailable') {
        if (customer.value.traffic_or_industrial_treatment_available === true) {
          customer.value.traffic_or_industrial_treatment_available = false;
        } else {
          customer.value.traffic_or_industrial_treatment_available = true;
        }
      }

      if (event === 'with_acupunture') {
        if (customer.value.with_acupunture === true) {
          customer.value.with_acupunture = false;
        } else {
          customer.value.with_acupunture = true;
        }
      }

      if (event === 'metal_has') {
        if (customer.value.metal_has === true) {
          customer.value.metal_has = false;
        } else {
          customer.value.metal_has = true;
        }
      }
      if (event === 'hv_not_possible') {
        if (customer.value.hv_not_possible === true) {
          customer.value.hv_not_possible = false;
        } else {
          customer.value.hv_not_possible = true;
        }
      }
      if (event === 'no_acupunture') {
        if (customer.value.no_acupunture === true) {
          customer.value.no_acupunture = false;
        } else {
          customer.value.no_acupunture = true;
        }
      }
    };

    const dynamicfocus = (monthlyProductSalesItem) => {
      if (monthlyProductSalesItem.show) {
        monthlyProductSalesItem.show = false;
      } else {
        monthlyProductSalesItem.show = true;
      }
    };

    const dynamicClick = (monthlyProductSalesItem) => {
      monthlyProductSalesItem.show = !monthlyProductSalesItem.show;
    };

    const clearFieldsOnPostalCodeChange = () => {
      if (customer.value.city) {
        customer.value.city = '';
      }
      if (customer.value.prefectures) {
        customer.value.prefectures = '';
      }
    };

    return {
      isSypmtomOne,
      changeFormat,
      monthlyProductSales,
      eachSales,
      CUSTOMER_QUESTIONNAIRE_EXISTS,
      customerQuestionnaireExists,
      validateBlankSpace,
      searchTermEnter,
      showError,
      inflowTermEnter,
      showErrorInflow,
      disabledFields,
      inflowOptions,
      trafficAccidentInflowOptions,
      isTrafficAccidentCustomer,
      calculateAgeField,
      customerLineID,
      customerNumber,
      showCalendar,
      showCalendar2,
      showCalendar3,
      customerID,
      handleNumberRegex,
      handleNumber,
      handleDateNumber,
      storeOptions,
      filterErrorColor,
      filterErrors,
      remove1,
      OnSubmit,
      customer,
      checkdisableFunction,
      checkDisable,
      disableOtherInflowRoutes,
      checkPostalCodeValid,
      searchPostalCode,
      yearOptions: yearData,
      customerRegistered,
      monthOptions: [
        {
          value: '01',
          name: '01',
          selected: false
        },
        {
          value: '02',
          name: '02',
          selected: false
        },
        {
          value: '03',
          name: '03',
          selected: false
        },
        {
          value: '04',
          name: '04',
          selected: false
        },
        {
          value: '05',
          name: '05',
          selected: false
        },
        {
          value: '06',
          name: '06',
          selected: false
        },
        {
          value: '07',
          name: '07',
          selected: false
        },
        {
          value: '08',
          name: '08',
          selected: false
        },
        {
          value: '09',
          name: '09',
          selected: false
        },
        {
          value: '10',
          name: '10',
          selected: false
        },
        {
          value: '11',
          name: '11',
          selected: false
        },
        {
          value: '12',
          name: '12',
          selected: false
        }
      ],
      daysOption,
      removeError,
      handlePostalcodeRegex,
      checkboxChange,
      dynamicfocus,
      dynamicClick,
      goBack,
      hasMultipleAddresses,
      multipleCityAddressArr,
      clearFieldsOnPostalCodeChange
    };
  }
});
</script>

<style lang="scss" scoped>
@import '../assets/styles/variable.scss';
@import '../assets/styles/partials/details.scss';
@import '../assets/styles/partials/select.scss';

.full-details {
  margin-top: 24px;
  background: unset;
  padding: 32px 24px;

  .row {
    align-items: center;
    // min-height: 45px;
    margin-bottom: 16px;
  }

  & > .row:first-child {
    min-height: auto;
  }

  .form-control {
    display: inline-block;
    width: 210px;
    height: 45px;
  }

  .col-3 {
    width: 22%;
    margin-right: 44px;
    position: relative;

    .form-title {
      &.required {
        &::after {
          position: absolute;
          right: -34px;
          content: '必須';
          padding: 1px 6px;
          font-size: 12px;
          background-color: #f98585;
          font-weight: normal;
          color: #fff;
          border-radius: 3px;
        }
      }
    }

    .small-text {
      font-size: 10px;
      margin-bottom: 0;
    }
  }

  .col-8 {
    .form-control {
      margin-right: 24px;
    }
  }

  .date-selector {
    display: flex;

    & > div {
      display: flex;
      align-items: center;
      margin-right: 8px;

      span {
        margin-left: 8px;
      }
    }
  }

  .search-btn {
    height: 45px;
    width: 88px;
    margin-left: 8px;
  }

  .form-control.long-input {
    width: 518px;
  }

  .textarea-title {
    align-self: flex-start;
    margin-top: 12px;
  }

  textarea.long-input {
    height: 150px;
    resize: none;
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;

    & > * {
      height: 45px;
      font-size: 16px;
    }

    .btn-secondary {
      width: 128px;
      color: #888;
    }

    .btn-primary {
      width: 240px;
    }
  }
}

::v-deep(.multiselect) {
  min-height: 45px;
  width: 210px;
  margin: 0;
}

::v-deep(.multiselect-option) {
  justify-content: space-between !important;
  font-size: 14px !important;

  img {
    display: none;
  }

  &.is-selected {
    img {
      display: block;
    }
  }
}

::v-deep(.multiselect-dropdown) {
  overflow-y: auto;
}

::v-deep(.multiselect-single-label) {
  font-size: 14px !important;
}

::v-deep(.multiselect-placeholder) {
  font-size: 14px !important;
}

::v-deep(.multiselect-option.is-selected) {
  background: unset;
  color: unset;
}

::v-deep(.multiselect-option.is-selected.is-pointed) {
  background: unset;
  color: unset;
}

::v-deep(.multi-select-option-icon) {
  width: 14px;
}

.error {
  border-color: #fc4848;
}

.error-box {
  // display: inline-block;
  padding: 6px 14px;
  color: #fc4848;
  background-color: #fc484826;
}

.short-input {
  display: flex;
  align-items: center;

  input {
    max-width: 210px;
    margin-right: 24px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.custom-select {
  min-height: 45px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  width: 110px;

  &.error {
    border-color: #fc4848;
  }
}

.date-selector {
  position: relative;

  .date-picker {
    position: absolute;
    top: 45px;
    left: -23px;
    margin-top: 4px;
    background: #fff;
    z-index: 12;

    &:after,
    &:before {
      content: '';
      // border-color: #a9a9a9 transparent transparent transparent;
      // border-style: solid;
      // border-width: 11px;
      border-left: 15px solid transparent;
      border-right: 15px solid transparent;
      position: absolute;
      // box-shadow: 0 0 10px gray;
      // background: white;
      width: 20px;
      height: 20px;
      top: -15px;
      right: 16px;
      transform: rotate(180deg);
    }

    &:after {
      border-top: 15px solid #d2d6de;
      z-index: 1;
      margin-top: -5px;
    }

    &:before {
      border-top: 15px solid white;
      z-index: 3;
      margin-top: -3px;
    }
  }

  .date-field {
    position: relative;

    &:hover {
      cursor: pointer;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 16px;
    background: #e6f6fb;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
}

.datepicker-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 6;
  visibility: hidden;

  &.display {
    visibility: visible;
  }
}
</style>
