
import { defineComponent } from "vue";

export default defineComponent({
  name: "Sidebar",
  data: () => {
    return {
      activeSidebar: false,
    };
  },
  methods: {
    handleSidebarToggle() {
      this.activeSidebar = !this.activeSidebar;
    },
  },
});
