import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, vModelRadio as _vModelRadio, vModelText as _vModelText, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6faa9fab"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "step-title" }
const _hoisted_2 = { class: "full-details preliminary-form white-background" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-2" }
const _hoisted_5 = { class: "form-title" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "mb-3" }
const _hoisted_8 = { class: "fw-bold d-inline-block mb-0" }
const _hoisted_9 = {
  key: 0,
  class: "required"
}
const _hoisted_10 = {
  key: 0,
  class: "form-check"
}
const _hoisted_11 = ["onChange", "required", "value", "id", "onUpdate:modelValue"]
const _hoisted_12 = ["for"]
const _hoisted_13 = {
  key: 1,
  class: "col-2"
}
const _hoisted_14 = { class: "form-check" }
const _hoisted_15 = ["onChange", "value", "id", "name", "onUpdate:modelValue", "required"]
const _hoisted_16 = ["for"]
const _hoisted_17 = ["for"]
const _hoisted_18 = ["required", "disabled", "onKeyup", "onBlur", "onUpdate:modelValue"]
const _hoisted_19 = { style: {"margin-left":"22px"} }
const _hoisted_20 = {
  key: 0,
  class: "required"
}
const _hoisted_21 = { style: {"margin-left":"23px"} }
const _hoisted_22 = {
  key: 0,
  class: "required"
}
const _hoisted_23 = {
  key: 1,
  class: "multiple-checkboxes ms-4 mb-3"
}
const _hoisted_24 = { class: "row" }
const _hoisted_25 = ["required"]
const _hoisted_26 = ["onChange", "value", "id", "onUpdate:modelValue", "disabled", "required"]
const _hoisted_27 = ["for"]
const _hoisted_28 = ["for"]
const _hoisted_29 = ["required", "onKeyup", "onBlur", "disabled", "onUpdate:modelValue"]
const _hoisted_30 = { style: {"margin-left":"22px"} }
const _hoisted_31 = {
  key: 0,
  class: "required"
}
const _hoisted_32 = { style: {"margin-left":"22px"} }
const _hoisted_33 = {
  key: 0,
  class: "required"
}
const _hoisted_34 = { key: 0 }
const _hoisted_35 = { class: "button-container" }
const _hoisted_36 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h2", _hoisted_1, _toDisplayString(_ctx.$t('Ticket.Step2.Title')), 1),
    (_ctx.symptomQuestions)
      ? (_openBlock(), _createElementBlock("form", {
          key: 0,
          onSubmit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showNext()))
        }, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.symptomQuestions.contents, (questionItem, index) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("p", _hoisted_5, _toDisplayString(questionItem.id.toUpperCase()), 1)
                  ]),
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("h6", _hoisted_8, _toDisplayString(questionItem.question), 1),
                      (questionItem.required == 'true')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass({ row: questionItem.style === 'horizontal' })
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questionItem.options, (questionOptionItem, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass({ 'col-3': questionItem.style === 'horizontal' }),
                          key: index
                        }, [
                          (questionItem.anserType === 'checkbox')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _withDirectives(_createElementVNode("input", {
                                  class: "form-check-input",
                                  type: "checkbox",
                                  onChange: ($event: any) => (_ctx.clearCheckBox(questionOptionItem, $event)),
                                  required: 
                        questionItem.required == 'true' && questionItem.answers.length === 0
                      ,
                                  value: questionOptionItem.value,
                                  id: `${questionItem.id}-${questionOptionItem.text}`,
                                  "onUpdate:modelValue": ($event: any) => ((questionItem.answers) = $event)
                                }, null, 40, _hoisted_11), [
                                  [_vModelCheckbox, questionItem.answers]
                                ]),
                                _createElementVNode("label", {
                                  class: "form-check-label",
                                  for: `${questionItem.id}-${questionOptionItem.text}`
                                }, _toDisplayString(questionOptionItem.text), 9, _hoisted_12)
                              ]))
                            : _createCommentVNode("", true),
                          (questionItem.anserType === 'radio')
                            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                _createElementVNode("div", _hoisted_14, [
                                  _withDirectives(_createElementVNode("input", {
                                    class: "form-check-input",
                                    type: "radio",
                                    onChange: ($event: any) => (_ctx.clearRadioField(questionOptionItem, questionItem, $event)),
                                    value: questionOptionItem.value,
                                    id: `${questionItem.id}-${questionOptionItem.text}`,
                                    name: questionItem.id,
                                    "onUpdate:modelValue": ($event: any) => ((questionItem.answer) = $event),
                                    required: questionItem.required == 'true'
                                  }, null, 40, _hoisted_15), [
                                    [_vModelRadio, questionItem.answer]
                                  ]),
                                  _createElementVNode("label", {
                                    class: "form-check-label",
                                    for: `${questionItem.id}-${questionOptionItem.text}`
                                  }, _toDisplayString(questionOptionItem.text), 9, _hoisted_16)
                                ])
                              ]))
                            : _createCommentVNode("", true),
                          (questionOptionItem.children)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                (questionOptionItem.children.anserType === 'text')
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      (questionOptionItem.children.text)
                                        ? (_openBlock(), _createElementBlock("label", {
                                            key: 0,
                                            for: `${questionItem.id}-${questionOptionItem.text}`,
                                            class: "textarea-title"
                                          }, _toDisplayString(questionOptionItem.children.text), 9, _hoisted_17))
                                        : _createCommentVNode("", true),
                                      _withDirectives(_createElementVNode("textarea", {
                                        name: "q1-note",
                                        class: _normalizeClass(["feedback-container", {
                        'border border-danger':
                          (questionItem?.answer === questionOptionItem.value ||
                            questionItem?.answers?.includes(questionOptionItem.value)) &&
                          questionOptionItem.children.required == 'true'
                      }]),
                                        rows: "3",
                                        required: 
                        (questionItem?.answer === questionOptionItem.value ||
                          questionItem?.answers?.includes(questionOptionItem.value)) &&
                        questionOptionItem.children.required == 'true' || _ctx.s
                      ,
                                        disabled: _ctx.checkDisabled(`${questionItem.id}-${questionOptionItem.text}`),
                                        onKeyup: 
                        ($event) => {
                          if (questionItem.anserType === 'radio') {
                            questionItem.answer = questionOptionItem.value;
                          }
                          if (questionItem.anserType === 'checkbox') {
                            if (!questionItem.answers.includes(questionOptionItem.value)) {
                              questionItem.answers.push(questionOptionItem.value);
                            }
                          }
                          questionOptionItem.children.answer = _ctx.convertToFullWidthKana(
                            questionOptionItem.children.answer
                          );
                          if(questionOptionItem.children.answer.length > 100){
                            _ctx.setError()
                          } else {
                            _ctx.resetError()
                          }
                        }
                      ,
                                        onBlur: 
                        () => {
                          questionOptionItem.children.answer =
                            questionOptionItem.children.answer.trim();
                          _ctx.convertToFullWidthKana(questionOptionItem.children.answer);
                        }
                      ,
                                        "onUpdate:modelValue": ($event: any) => ((questionOptionItem.children.answer) = $event)
                                      }, null, 42, _hoisted_18), [
                                        [_vModelText, questionOptionItem.children.answer]
                                      ]),
                                      _createElementVNode("div", _hoisted_19, [
                                        (
                          (questionItem?.answer === questionOptionItem.value ||
                            questionItem?.answers?.includes(questionOptionItem.value)) &&
                          questionOptionItem.children.required == 'true'
                        )
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_20, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _createElementVNode("div", _hoisted_21, [
                                        ( (questionItem?.answer === questionOptionItem.value ||
                            questionItem?.answers?.includes(questionOptionItem.value)) && questionOptionItem.children.answer.length > 100)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_22, _toDisplayString(_ctx.$t('Errors.E060')), 1))
                                          : _createCommentVNode("", true)
                                      ])
                                    ], 64))
                                  : _createCommentVNode("", true),
                                (questionOptionItem.children.anserType === 'checkbox')
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                      _createElementVNode("div", _hoisted_24, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questionOptionItem.children.options, (checkboxOptionItem, index) => {
                                          return (_openBlock(), _createElementBlock("div", {
                                            class: "col-3",
                                            key: index
                                          }, [
                                            _createElementVNode("div", {
                                              class: "form-check",
                                              required: questionOptionItem.children.required == 'true'
                                            }, [
                                              _withDirectives(_createElementVNode("input", {
                                                class: "form-check-input",
                                                type: "checkbox",
                                                name: "inner-checkbox",
                                                onChange: ($event: any) => (_ctx.clearCheckBox(checkboxOptionItem, $event)),
                                                value: checkboxOptionItem.value,
                                                id: `${questionItem.id}-${checkboxOptionItem.text}`,
                                                "onUpdate:modelValue": ($event: any) => ((questionOptionItem.children.answers) = $event),
                                                disabled: !questionItem.answer.includes(questionOptionItem.value),
                                                required: 
                                questionItem.required == 'true' &&
                                questionOptionItem.children.answers.length === 0
                              
                                              }, null, 40, _hoisted_26), [
                                                [_vModelCheckbox, questionOptionItem.children.answers]
                                              ]),
                                              _createElementVNode("label", {
                                                class: "form-check-label",
                                                for: `${questionItem.id}-${checkboxOptionItem.text}`
                                              }, _toDisplayString(checkboxOptionItem.text), 9, _hoisted_27),
                                              (checkboxOptionItem.children)
                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                    (checkboxOptionItem.children.anserType === 'text')
                                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                          (checkboxOptionItem.children.text)
                                                            ? (_openBlock(), _createElementBlock("label", {
                                                                key: 0,
                                                                for: `${questionItem.id}-${checkboxOptionItem.text}`,
                                                                class: "textarea-title fw-bold"
                                                              }, _toDisplayString(checkboxOptionItem.children.text), 9, _hoisted_28))
                                                            : _createCommentVNode("", true),
                                                          _withDirectives(_createElementVNode("textarea", {
                                                            name: "q1-note",
                                                            class: _normalizeClass(["feedback-container", {
                                    'border border-danger':
                                      (questionOptionItem.children?.answer ===
                                        checkboxOptionItem.value ||
                                        questionOptionItem.children?.answers.includes(
                                          checkboxOptionItem.value
                                        )) &&
                                      checkboxOptionItem.children.required == 'true'
                                  }]),
                                                            rows: "3",
                                                            required: 
                                    (questionOptionItem.children?.answer ===
                                      checkboxOptionItem.value ||
                                      questionOptionItem.children?.answers.includes(
                                        checkboxOptionItem.value
                                      )) &&
                                    checkboxOptionItem.children.required == 'true'
                                  ,
                                                            onKeyup: 
                                    () => { 
                                      if (
                                        !questionOptionItem.children.answers.includes(
                                          checkboxOptionItem.value
                                        )
                                      ) {
                                        questionOptionItem.children.answers.push(
                                          checkboxOptionItem.value
                                        );
                                      }
                                      checkboxOptionItem.children.answer = _ctx.convertToFullWidthKana(
                                        checkboxOptionItem.children.answer
                                      );
                                      if(checkboxOptionItem.children.answer.length > 100){
                                        _ctx.setError()
                                      } else {
                                        _ctx.resetError()
                                      }
                                    }
                                  ,
                                                            onBlur: 
                                    () => {
                                      checkboxOptionItem.children.answer =
                                        checkboxOptionItem.children.answer.trim();
                                      checkboxOptionItem.children.answer = _ctx.convertToFullWidthKana(
                                        checkboxOptionItem.children.answer
                                      );
                                    }
                                  ,
                                                            disabled: 
                                    !questionOptionItem.children.answers.includes(checkboxOptionItem.value)
                                  ,
                                                            "onUpdate:modelValue": ($event: any) => ((checkboxOptionItem.children.answer) = $event),
                                                            style: {"max-width":"108px","margin-left":"0"}
                                                          }, null, 42, _hoisted_29), [
                                                            [_vModelText, checkboxOptionItem.children.answer]
                                                          ]),
                                                          _createElementVNode("div", _hoisted_30, [
                                                            (
                                      (questionOptionItem.children?.answer ===
                                        checkboxOptionItem.value ||
                                        questionOptionItem.children?.answers.includes(
                                          checkboxOptionItem.value
                                        )) &&
                                      checkboxOptionItem.children.required == 'true'
                                    )
                                                              ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1))
                                                              : _createCommentVNode("", true)
                                                          ]),
                                                          _createElementVNode("div", _hoisted_32, [
                                                            ((questionOptionItem.children?.answer ===
                                        checkboxOptionItem.value ||
                                        questionOptionItem.children?.answers.includes(
                                          checkboxOptionItem.value
                                        )) &&
                                      checkboxOptionItem.children.required == 'true' && checkboxOptionItem.children.answer.length > 100)
                                                              ? (_openBlock(), _createElementBlock("span", _hoisted_33, _toDisplayString(_ctx.$t('Errors.E060')), 1))
                                                              : _createCommentVNode("", true)
                                                          ])
                                                        ], 64))
                                                      : _createCommentVNode("", true)
                                                  ], 64))
                                                : _createCommentVNode("", true)
                                            ], 8, _hoisted_25)
                                          ]))
                                        }), 128))
                                      ])
                                    ]))
                                  : _createCommentVNode("", true)
                              ], 64))
                            : _createCommentVNode("", true)
                        ], 2))
                      }), 128))
                    ], 2)
                  ])
                ]),
                (index + 1 !== _ctx.symptomQuestions.contents.length)
                  ? (_openBlock(), _createElementBlock("hr", _hoisted_34))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128)),
            _createElementVNode("div", _hoisted_35, [
              _createElementVNode("button", {
                class: "btn btn-secondary",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPrevious()))
              }, _toDisplayString(_ctx.$t('Ticket.Return')), 1),
              _createElementVNode("button", {
                class: "btn btn-primary",
                type: "submit",
                disabled: _ctx.showError
              }, _toDisplayString(_ctx.$t('Ticket.Next')), 9, _hoisted_36)
            ])
          ])
        ], 32))
      : _createCommentVNode("", true)
  ], 64))
}