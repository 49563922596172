import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store, key } from "@/core/store";
import i18n from "./i18n";
import "bootstrap/dist/js/bootstrap.min.js";
import axios from "axios";
import VueAxios from "vue-axios";
import Toast from "vue-toastification";


// const options: PluginOptions = {
//     timeout: 2000,
// };

createApp(App)
  .use(i18n)
  .use(router)
  .use(store, key)
  .use(VueAxios, axios)
    .use(Toast)
  .mount("#app");
