<template>
  <Modal id="alertModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <div class="modal-header">
          <h3>CONFIRM</h3>
        </div>
        <div class="modal-body">
          <span>{{ $t('Errors.C100') }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            {{ $t('TodayList.Cancel') }}
          </button>
          <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="accountingBtnClick">
            {{ $t('Calendar.Details.Accounting') }}
          </button>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { defineComponent } from 'vue';
import Modal from './Modal.vue';

export default defineComponent({
  name: 'AlertModal',
  components: { Modal },
  emits: ['accounting'],
  setup(props, {emit}) {
    const accountingBtnClick = () => {
      emit('accounting');
    }

    return {
      accountingBtnClick,
    };
  }
});
</script>

<style lang="scss" scoped>
.modal-dialog {
  width: 550px;

  .modal-footer > button {
    flex: 1;
  }
}
</style>
