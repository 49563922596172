
import { defineComponent, watchEffect, ref } from "vue";
import Header from "../../components/Header.vue";
import Sidebar from "../../components/Sidebar.vue";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "Top",
  components: {
    Header,
    Sidebar,
  },
  setup() {
    const router = useRouter();
    const isPreliminary = ref(false);

    watchEffect(() => {
      router.currentRoute.value.path === "/preliminary-examination-steps" ||
      router.currentRoute.value.path === "/review-preliminary-slip"
        ? (isPreliminary.value = true)
        : (isPreliminary.value = false);
    });

    return {
      isPreliminary,
    };
  },
});
