
import { defineComponent, ref } from 'vue';
import { store } from '@/core/store';
import { fetch } from '@/core/api/api-service';
import { buildQuestionJSONWithAnswers, convertToFullWidthKana } from '@/core/utils';

export default defineComponent({
  name: 'Step1',
  components: {},
  emits: ['showPrevious', 'showNext', 'symptomQuestions'],
  props: {
    previousPage: {
      required: true,
      type: Number
    },
    nextPage: {
      required: true,
      type: Number
    }
  },
  setup(props, { emit }) {
    const priliminarySymptomsQuestions = {
      themeName: 'ScreeningQuestionnaire',
      formatVer: '1.0',
      contents: [
        {
          id: 'q1',
          question: 'どんな症状でお悩みですか（複数選択）',
          anserType: 'checkbox',
          answers: [],
          required: 'true',
          options: [
            {
              value: '1',
              text: '痛い'
            },
            {
              value: '5',
              text: '硬くなっている'
            },
            {
              value: '11',
              text: 'しびれる'
            },
            {
              value: '15',
              text: 'だるい'
            },
            {
              value: '20',
              text: '動かない'
            },
            {
              value: '99',
              text: 'その他',
              children: {
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            }
          ]
        },
        {
          id: 'q2',
          question: 'どこに症状がありますか（複数選択）',
          anserType: 'checkbox',
          answers: [],
          required: 'true',
          options: [
            {
              value: '1',
              text: '頭'
            },
            {
              value: '5',
              text: '首'
            },
            {
              value: '8',
              text: '肩'
            },
            {
              value: '12',
              text: '背中'
            },
            {
              value: '15',
              text: '腰'
            },
            {
              value: '18',
              text: 'お尻'
            },
            {
              value: '21',
              text: '股関節'
            },
            {
              value: '24',
              text: '腕'
            },
            {
              value: '27',
              text: 'ひじ'
            },
            {
              value: '30',
              text: '手首'
            },
            {
              value: '33',
              text: '手'
            },
            {
              value: '36',
              text: '指'
            },
            {
              value: '39',
              text: 'ふともも'
            },
            {
              value: '42',
              text: 'ひざ'
            },
            {
              value: '45',
              text: 'すね'
            },
            {
              value: '48',
              text: 'ふくらはぎ'
            },
            {
              value: '51',
              text: '足'
            },
            {
              value: '54',
              text: '足首'
            },
            {
              value: '57',
              text: '足指'
            },
            {
              value: '99',
              text: 'その他',
              children: {
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            }
          ]
        },
        {
          id: 'q3',
          question: 'いつ頃から症状が出ていますか？',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: '1',
              text: '10年以上前'
            },
            {
              value: '5',
              text: '1年前以上'
            },
            {
              value: '8',
              text: '半年－3ヶ月前'
            },
            {
              value: '12',
              text: '1－3ヶ月前'
            },
            {
              value: '15',
              text: '1ヶ月以内'
            },
            {
              value: '18',
              text: '2－3日以内'
            },
            {
              value: '21',
              text: '昨日'
            }
          ]
        },
        {
          id: 'q4',
          question: 'ペースメーカーが入ってますか？',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: 'yes',
              text: 'はい'
            },
            {
              value: 'no',
              text: 'いいえ'
            }
          ]
        },
        {
          id: 'q5',
          question: '体内に金属が入っていますか？',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: 'yes',
              text: 'はい',
              children: {
                text: '部位',
                anserType: 'checkbox',
                answers: [],
                required: 'true',
                options: [
                  {
                    value: '1',
                    text: '頭'
                  },
                  {
                    value: '5',
                    text: '首'
                  },
                  {
                    value: '8',
                    text: '肩'
                  },
                  {
                    value: '12',
                    text: '背中'
                  },
                  {
                    value: '15',
                    text: '腰'
                  },
                  {
                    value: '18',
                    text: 'お尻'
                  },
                  {
                    value: '21',
                    text: '股関節'
                  },
                  {
                    value: '24',
                    text: '腕'
                  },
                  {
                    value: '27',
                    text: 'ひじ'
                  },
                  {
                    value: '30',
                    text: '手首'
                  },
                  {
                    value: '33',
                    text: '手'
                  },
                  {
                    value: '36',
                    text: '指'
                  },
                  {
                    value: '39',
                    text: 'ふともも'
                  },
                  {
                    value: '42',
                    text: 'ひざ'
                  },
                  {
                    value: '45',
                    text: 'すね'
                  },
                  {
                    value: '48',
                    text: 'ふくらはぎ'
                  },
                  {
                    value: '51',
                    text: '足'
                  },
                  {
                    value: '54',
                    text: '足首'
                  },
                  {
                    value: '57',
                    text: '足指'
                  },
                  {
                    value: '99',
                    text: 'その他',
                    children: {
                      anserType: 'text',
                      required: 'true',
                      answer: ''
                    }
                  }
                ]
              }
            },
            {
              value: 'no',
              text: 'いいえ'
            }
          ]
        },
        {
          id: 'q6',
          question: '妊婦または妊娠の可能性はありますか？',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: 'yes',
              text: 'はい'
            },
            {
              value: 'no',
              text: 'いいえ'
            }
          ]
        },
        {
          id: 'q7',
          question:
            '今までに病院でヘルニア・脊柱管狭窄・坐骨神経痛等の診断を受けられたことはありますか？',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: 'no',
              text: 'いいえ'
            },
            {
              value: 'yes',
              text: 'はい',
              children: {
                text: '診断名',
                anserType: 'text',
                answer: ''
              }
            }
          ]
        },
        {
          id: 'q8',
          question: '今までに手術を受けられたことは有りますか？',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: 'no',
              text: 'いいえ'
            },
            {
              value: 'yes',
              text: 'はい',
              children: {
                text: 'なんの手術ですか？',
                anserType: 'text',
                answer: ''
              }
            }
          ]
        },
        {
          id: 'q9',
          question: '現在、ご家族で下記のことでお困りの方はいらっしゃいますか？',
          anserType: 'checkbox',
          answers: [],
          options: [
            {
              value: '1',
              text: '慢性的な肩こり・腰痛'
            },
            {
              value: '5',
              text: 'スポーツにより疲労や怪我'
            },
            {
              value: '11',
              text: '交通事故による怪我'
            }
          ]
        },
        {
          id: 'q10',
          question: '困られている方はどちらの方になるでしょうか？',
          anserType: 'checkbox',
          answers: [],
          options: [
            {
              value: '1',
              text: '父'
            },
            {
              value: '3',
              text: '母'
            },
            {
              value: '5',
              text: '兄'
            },
            {
              value: '7',
              text: '弟'
            },
            {
              value: '9',
              text: '姉'
            },
            {
              value: '12',
              text: '妹'
            },
            {
              value: '15',
              text: '夫'
            },
            {
              value: '18',
              text: '妻'
            },
            {
              value: '21',
              text: '子'
            }
          ]
        }
      ]
    };

    let showError = ref(false)

    // const questions = Object.assign({}, priliminarySymptomsQuestions);
    let symptomQuestions = ref(store?.getters?.registrationSteps?.symptomQuestions);

    if (!symptomQuestions.value) {
      fetch('/api/v1/preliminary-examination-question-json').then((res) => {
        if (res.status === 200) {
          symptomQuestions.value = buildQuestionJSONWithAnswers(res.data.data);
        }
      });
    }

    const setError = () =>{
      showError.value = true
    }

    const resetError = () =>{
      showError.value = false;
    }

    const clearCheckBox = (value: any) => {
      if (value.text === 'その他') {
        value.children.answer = '';
      }

      if (value.children) {
        value.children.answer ='';
      }
    };



    const clearRadioField = (value: any, questionItem: any, event: any) => {
      if (!value.children) {
        const filterArr = questionItem.options.filter((e: any) => { 
          if(e.value !== value.value) {
            return e;
          }
        });

        filterArr.forEach((element: any) => {
          if (element.children && element.children.answers) {
            element.children.answers = [];
          }

          if (element.children && element.children.options) {
            element.children.options.forEach((ele: any) => {
              if (ele.children) {
                ele.children.answer = '';
              }
            })

          } else {
            if (element.children && element.children.answer) {
              element.children.answer = '';
            }
          }
        });

        // USED ONLY IF THERE IS SOME DOM MANUPULAION IS NEEDED

        // const parentElememt = event.target.parentElement.parentElement.parentElement.parentElement;

        // if (parentElememt.getElementsByTagName('textarea').length !== 0) {
        //   parentElememt.getElementsByTagName('textarea')[0].value = '';

        //   for (let checkbox of parentElememt!.querySelectorAll('input[type=checkbox]') as any) {
        //     checkbox.checked = false;
        //   }
        // }
      }
    };

    const showPrevious = () => {
      emit('showPrevious', props.previousPage);
    };

    const showNext = () => {
      store.dispatch('setStepSymptomQuestions', symptomQuestions.value).then(() => {
        emit('symptomQuestions', symptomQuestions.value);
        emit('showNext', props.nextPage);
      });
    };

    const checkDisabled = (id: string) => {
      const element = document.getElementById(id) as any
      if (element === null) {
        return true;
      } else if (element) {
        return !element?.checked;
      } else {
        return false;
      }
    };

    return {
      convertToFullWidthKana,
      //
      checkDisabled,
      showPrevious,
      clearRadioField,
      clearCheckBox,
      showNext,
      symptomQuestions,
      setError,
      resetError,
      showError
    };
  }
});
