export const sampleEvents = [
  {
    start: "2021-10-31T09:00:00+00:00",
    end: "2021-10-31T09:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-01T09:00:00+00:00",
    end: "2021-11-01T09:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-02T09:00:00+00:00",
    end: "2021-11-02T09:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-03T09:00:00+00:00",
    end: "2021-11-03T09:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-04T09:00:00+00:00",
    end: "2021-11-04T09:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-05T09:00:00+00:00",
    end: "2021-11-05T09:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-10-31T09:30:00+00:00",
    end: "2021-10-31T10:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T09:30:00+00:00",
    end: "2021-11-01T10:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T09:30:00+00:00",
    end: "2021-11-02T10:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T09:30:00+00:00",
    end: "2021-11-03T10:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T09:30:00+00:00",
    end: "2021-11-04T10:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T09:30:00+00:00",
    end: "2021-11-05T10:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T10:00:00+00:00",
    end: "2021-10-31T10:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T10:00:00+00:00",
    end: "2021-11-01T10:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T10:00:00+00:00",
    end: "2021-11-02T10:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T10:00:00+00:00",
    end: "2021-11-03T10:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T10:00:00+00:00",
    end: "2021-11-04T10:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T10:00:00+00:00",
    end: "2021-11-05T10:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T10:30:00+00:00",
    end: "2021-10-31T11:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T10:30:00+00:00",
    end: "2021-11-01T11:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T10:30:00+00:00",
    end: "2021-11-02T11:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T10:30:00+00:00",
    end: "2021-11-03T11:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T10:30:00+00:00",
    end: "2021-11-04T11:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T10:30:00+00:00",
    end: "2021-11-05T11:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T11:00:00+00:00",
    end: "2021-10-31T11:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T11:00:00+00:00",
    end: "2021-11-01T11:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T11:00:00+00:00",
    end: "2021-11-02T11:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T11:00:00+00:00",
    end: "2021-11-03T11:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T11:00:00+00:00",
    end: "2021-11-04T11:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T11:00:00+00:00",
    end: "2021-11-05T11:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T11:30:00+00:00",
    end: "2021-10-31T12:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T11:30:00+00:00",
    end: "2021-11-01T12:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T11:30:00+00:00",
    end: "2021-11-02T12:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T11:30:00+00:00",
    end: "2021-11-03T12:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T11:30:00+00:00",
    end: "2021-11-04T12:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T11:30:00+00:00",
    end: "2021-11-05T12:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T12:00:00+00:00",
    end: "2021-10-31T12:30:00+00:00",
    status: "few",
  },
  {
    start: "2021-11-01T12:00:00+00:00",
    end: "2021-11-01T12:30:00+00:00",
    status: "few",
  },
  {
    start: "2021-11-02T12:00:00+00:00",
    end: "2021-11-02T12:30:00+00:00",
    status: "few",
  },
  {
    start: "2021-11-03T12:00:00+00:00",
    end: "2021-11-03T12:30:00+00:00",
    status: "few",
  },
  {
    start: "2021-11-04T12:00:00+00:00",
    end: "2021-11-04T12:30:00+00:00",
    status: "few",
  },
  {
    start: "2021-11-05T12:00:00+00:00",
    end: "2021-11-05T12:30:00+00:00",
    status: "few",
  },
  {
    start: "2021-10-31T12:30:00+00:00",
    end: "2021-10-31T13:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-01T12:30:00+00:00",
    end: "2021-11-01T13:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-02T12:30:00+00:00",
    end: "2021-11-02T13:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-03T12:30:00+00:00",
    end: "2021-11-03T13:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-04T12:30:00+00:00",
    end: "2021-11-04T13:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-05T12:30:00+00:00",
    end: "2021-11-05T13:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-10-31T13:00:00+00:00",
    end: "2021-10-31T13:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-01T13:00:00+00:00",
    end: "2021-11-01T13:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-02T13:00:00+00:00",
    end: "2021-11-02T13:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-03T13:00:00+00:00",
    end: "2021-11-03T13:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-04T13:00:00+00:00",
    end: "2021-11-04T13:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-05T13:00:00+00:00",
    end: "2021-11-05T13:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-10-31T13:30:00+00:00",
    end: "2021-10-31T14:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-01T13:30:00+00:00",
    end: "2021-11-01T14:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-02T13:30:00+00:00",
    end: "2021-11-02T14:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-03T13:30:00+00:00",
    end: "2021-11-03T14:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-04T13:30:00+00:00",
    end: "2021-11-04T14:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-05T13:30:00+00:00",
    end: "2021-11-05T14:00:00+00:00",
    status: "full",
  },
  {
    start: "2021-10-31T14:00:00+00:00",
    end: "2021-10-31T14:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-01T14:00:00+00:00",
    end: "2021-11-01T14:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-02T14:00:00+00:00",
    end: "2021-11-02T14:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-03T14:00:00+00:00",
    end: "2021-11-03T14:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-04T14:00:00+00:00",
    end: "2021-11-04T14:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-11-05T14:00:00+00:00",
    end: "2021-11-05T14:30:00+00:00",
    status: "full",
  },
  {
    start: "2021-10-31T14:30:00+00:00",
    end: "2021-10-31T15:00:00+00:00",
    status: "few",
  },
  {
    start: "2021-11-01T14:30:00+00:00",
    end: "2021-11-01T15:00:00+00:00",
    status: "few",
  },
  {
    start: "2021-11-02T14:30:00+00:00",
    end: "2021-11-02T15:00:00+00:00",
    status: "few",
  },
  {
    start: "2021-11-03T14:30:00+00:00",
    end: "2021-11-03T15:00:00+00:00",
    status: "few",
  },
  {
    start: "2021-11-04T14:30:00+00:00",
    end: "2021-11-04T15:00:00+00:00",
    status: "few",
  },
  {
    start: "2021-11-05T14:30:00+00:00",
    end: "2021-11-05T15:00:00+00:00",
    status: "few",
  },
  {
    start: "2021-10-31T15:00:00+00:00",
    end: "2021-10-31T15:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T15:00:00+00:00",
    end: "2021-11-01T15:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T15:00:00+00:00",
    end: "2021-11-02T15:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T15:00:00+00:00",
    end: "2021-11-03T15:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T15:00:00+00:00",
    end: "2021-11-04T15:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T15:00:00+00:00",
    end: "2021-11-05T15:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T16:00:00+00:00",
    end: "2021-10-31T16:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T16:00:00+00:00",
    end: "2021-11-01T16:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T16:00:00+00:00",
    end: "2021-11-02T16:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T16:00:00+00:00",
    end: "2021-11-03T16:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T16:00:00+00:00",
    end: "2021-11-04T16:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T16:00:00+00:00",
    end: "2021-11-05T16:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T17:00:00+00:00",
    end: "2021-10-31T17:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T17:00:00+00:00",
    end: "2021-11-01T17:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T17:00:00+00:00",
    end: "2021-11-02T17:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T17:00:00+00:00",
    end: "2021-11-03T17:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T17:00:00+00:00",
    end: "2021-11-04T17:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T17:00:00+00:00",
    end: "2021-11-05T17:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T18:00:00+00:00",
    end: "2021-10-31T18:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T18:00:00+00:00",
    end: "2021-11-01T18:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T18:00:00+00:00",
    end: "2021-11-02T18:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T18:00:00+00:00",
    end: "2021-11-03T18:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T18:00:00+00:00",
    end: "2021-11-04T18:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T18:00:00+00:00",
    end: "2021-11-05T18:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T19:00:00+00:00",
    end: "2021-10-31T19:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T19:00:00+00:00",
    end: "2021-11-01T19:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T19:00:00+00:00",
    end: "2021-11-02T19:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T19:00:00+00:00",
    end: "2021-11-03T19:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T19:00:00+00:00",
    end: "2021-11-04T19:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T19:00:00+00:00",
    end: "2021-11-05T19:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T20:00:00+00:00",
    end: "2021-10-31T20:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T20:00:00+00:00",
    end: "2021-11-01T20:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T20:00:00+00:00",
    end: "2021-11-02T20:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T20:00:00+00:00",
    end: "2021-11-03T20:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T20:00:00+00:00",
    end: "2021-11-04T20:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T20:00:00+00:00",
    end: "2021-11-05T20:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T21:00:00+00:00",
    end: "2021-10-31T21:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T21:00:00+00:00",
    end: "2021-11-01T21:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T21:00:00+00:00",
    end: "2021-11-02T21:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T21:00:00+00:00",
    end: "2021-11-03T21:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T21:00:00+00:00",
    end: "2021-11-04T21:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T21:00:00+00:00",
    end: "2021-11-05T21:30:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T15:30:00+00:00",
    end: "2021-10-31T16:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T15:30:00+00:00",
    end: "2021-11-01T16:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T15:30:00+00:00",
    end: "2021-11-02T16:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T15:30:00+00:00",
    end: "2021-11-03T16:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T15:30:00+00:00",
    end: "2021-11-04T16:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T15:30:00+00:00",
    end: "2021-11-05T16:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T16:30:00+00:00",
    end: "2021-10-31T17:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T16:30:00+00:00",
    end: "2021-11-01T17:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T16:30:00+00:00",
    end: "2021-11-02T17:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T16:30:00+00:00",
    end: "2021-11-03T17:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T16:30:00+00:00",
    end: "2021-11-04T17:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T16:30:00+00:00",
    end: "2021-11-05T17:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T17:30:00+00:00",
    end: "2021-10-31T18:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T17:30:00+00:00",
    end: "2021-11-01T18:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T17:30:00+00:00",
    end: "2021-11-02T18:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T17:30:00+00:00",
    end: "2021-11-03T18:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T17:30:00+00:00",
    end: "2021-11-04T18:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T17:30:00+00:00",
    end: "2021-11-05T18:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T18:30:00+00:00",
    end: "2021-10-31T19:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T18:30:00+00:00",
    end: "2021-11-01T19:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T18:30:00+00:00",
    end: "2021-11-02T19:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T18:30:00+00:00",
    end: "2021-11-03T19:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T18:30:00+00:00",
    end: "2021-11-04T19:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T18:30:00+00:00",
    end: "2021-11-05T19:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T19:30:00+00:00",
    end: "2021-10-31T20:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T19:30:00+00:00",
    end: "2021-11-01T20:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T19:30:00+00:00",
    end: "2021-11-02T20:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T19:30:00+00:00",
    end: "2021-11-03T20:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T19:30:00+00:00",
    end: "2021-11-04T20:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T19:30:00+00:00",
    end: "2021-11-05T20:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-10-31T20:30:00+00:00",
    end: "2021-10-31T21:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-01T20:30:00+00:00",
    end: "2021-11-01T21:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-02T20:30:00+00:00",
    end: "2021-11-02T21:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-03T20:30:00+00:00",
    end: "2021-11-03T21:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-04T20:30:00+00:00",
    end: "2021-11-04T21:00:00+00:00",
    status: "open",
  },
  {
    start: "2021-11-05T20:30:00+00:00",
    end: "2021-11-05T21:00:00+00:00",
    status: "open",
  },
];
