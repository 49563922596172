<template>
  <label
    class="d-flex justify-content-center input-area mt-24 file-input-label"
  >
    <input
      type="text"
      class="form-control"
      id="date"
      :placeholder="placeholderText"
      v-if="this.file1 === null"
      :disabled="!allowImport"
    />
    <span v-else>
      <input
        type="text"
        :class="['form-control', { 'active-input': file1 !== null }]"
        id="date"
        v-for="file in file1"
        :key="file.name"
        :placeholder="file.name"
        :disabled="!allowImport"
      />
    </span>
    <button class="btn btn-primary" :disabled="!allowImport">
      {{ buttonText }}
    </button>
    <input
      type="file"
      class="file-input"
      @change="firstFileSelected"
      accept="image/png, image/jpeg"
      :disabled="!allowImport"
    />
  </label>
</template>

<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "FileInput",
  emits: ["selectedFiles"],
  props: {
    placeholderText: {
      type: String,
      default: "Please select a file",
    },
    buttonText: {
      type: String,
      default: "Upload",
    },
    allowImport: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const file1 = ref(null);
    const firstFileSelected = (e) => {
      if (e.target.files.length > 0) {
        file1.value = e.target.files;
        emit("selectedFiles", e.target.files);
      }
    };
    return {
      file1,
      firstFileSelected,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variable.scss";
// @import "../../assets/styles/partials/buttons.scss";

input {
  width: 280px;
}

.file-input-label {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: max-content;
}

.file-input {
  opacity: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

.traffic-accident {
  .input-area {
    .file-input {
      width: 100%;
    }
  }
}

.btn-primary {
  padding-right: 16px;
  padding-left: 16px;
  margin-left: 8px;
  white-space: nowrap;
  font-size: 14px;
  width: 158px;
}

.active-input {
  background: #e6f6fb;
  border: 0;
  border-radius: 0;
  &::placeholder {
    color: $primary;
    text-decoration: underline;
  }
}
</style>
