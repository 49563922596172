<template>
  <div class="title">
    <div class="d-flex justify-content-between">
      <h2 v-if="reservation?.customer !== null">{{ $t('CustomerDetails.Title') }}</h2>
      <h2 v-if="reservation?.customer == null">{{ $t('CustomerDetails.CustomerRegistration') }}</h2>
      <button class="btn-back" @click="openBackModal">
        <img src="../../assets/icons/return.svg" />
        {{ $t('Return') }}
      </button>
    </div>
    <hr />
  </div>
  <div class="mb-5">
    <ReservationDetailCard :reservation="reservation" />
  </div>
  <div class="button-container mt-40">
    <button class="btn btn-outline-danger form-control ms-auto" @click="pay">
      <!-- :disabled="
        !reservation.customer?.latestReservation?.customerNumber ||
        reservation?.customer?.latestReservation?.reservationType === RESERVATION_STATUS.NOT_CONFIRMED ||
        reservation?.customer?.latestReservation?.reservationType === RESERVATION_STATUS.CONFIRMED ||
        reservation?.customer?.latestReservation?.reservationType === RESERVATION_STATUS.CANCEL ||
        ((reservation?.customer?.latestReservation?.reservationType === RESERVATION_STATUS.TOTAL ||
          reservation?.customer?.latestReservation?.reservationType === RESERVATION_STATUS.ACCOUNTED) &&
          !isToday)
      " -->
      {{ $t('ReservationDetails.Total') }}
    </button>
  </div>
  <div class="custom-tabs">
    <ul class="d-flex align-items-end">
      <li @click="this.selectedTab = 'detail'" :class="{ active: this.selectedTab === 'detail' }">
        詳細
      </li>
      <li
        @click="this.selectedTab = 'member'"
        :class="{ active: this.selectedTab === 'member' }"
        v-if="isEditParam"
      >
        会員
      </li>
      <li
        @click="this.selectedTab = 'bookTicket'"
        :class="{ active: this.selectedTab === 'bookTicket' }"
        v-if="isEditParam"
      >
        回数券
      </li>
      <li
        @click="this.selectedTab = 'accounts'"
        :class="{ active: this.selectedTab === 'accounts' }"
        v-if="isEditParam"
      >
        売掛金
      </li>
      <li
        @click="this.selectedTab = 'treatment'"
        :class="{ active: this.selectedTab === 'treatment' }"
        v-if="isEditParam"
      >
        施術
      </li>
      <li
        @click="this.selectedTab = 'sales'"
        :class="{ active: this.selectedTab === 'sales' }"
        v-if="isEditParam"
      >
        物販
      </li>
    </ul>
  </div>

  <CustomerDetail v-if="this.selectedTab === 'detail'" @updateNext="getUpdateCardDetail($event)" />
  <Member v-if="this.selectedTab === 'member'" @updateNext="getUpdateCardDetail($event)" />
  <BookTicket v-if="this.selectedTab === 'bookTicket'" @updateNext="getUpdateCardDetail($event)" />
  <Accounts v-if="this.selectedTab === 'accounts'" />
  <Treatment v-if="this.selectedTab === 'treatment'" />
  <Sales v-if="this.selectedTab === 'sales'" />
  <BackConfirmationModal id="backModal" @goBack="goBack" />

  <loading v-model:active="apiLoading" :can-cancel="false" :is-full-page="true" />
</template>

<script>
import { computed, defineComponent, onMounted, ref } from 'vue';
import Accounts from '../../components/CustomerAccounts.vue';
import BookTicket from '../../components/BookTicket.vue';
import CustomerDetail from '../../components/CustomerDetail.vue';
import ReservationDetailCard from '../../components/ReservationDetailCard.vue';
import Member from '../../components/CustomerMember.vue';
import Sales from '../../components/CustomerSales.vue';
import BackConfirmationModal from '../../components/modals/BackConfirmationModal.vue';
import Treatment from '../../components/CustomerTreatment.vue';
import { useRoute, useRouter } from 'vue-router';
import Loading from 'vue-loading-overlay';
import { fetch } from '@/core/api/api-service';
import { createTemoraryTransaction } from '@/core/api/transaction-service';
import { store } from '@/core/store';
import { RESERVATION_STATUS, CONSULTATION_CLASSIFICATION } from '@/core/store/enums';
import { getFutureReservations } from '@/core/api/accounting-service';
import * as bootstrap from 'bootstrap';
import moment from 'moment';
import axios from 'axios';
import { useToast, POSITION } from 'vue-toastification';
import i18n from '@/i18n';

export default defineComponent({
  name: 'CustomerDetails',
  components: {
    Loading,
    Accounts,
    BackConfirmationModal,
    BookTicket,
    CustomerDetail,
    ReservationDetailCard,
    Member,
    Sales,
    Treatment
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const reservation = ref({
      customer: {}
    });
    let customerSaleListData = ref([]);
    let selectedTab = ref('detail');
    const apiLoading = ref(false);
    const isCurrentOrPastDay = ref(false);
    const isToday = ref(false);

    const {
      getters: { getTrafficAccidentRegistration }
    } = store;

    onMounted(() => {
      getCustomerData();
      // const customerID = await route.params.id.split("?")?.[2];
      // isCurrentOrPastDay.value = false;

      // if (customerID) {
      //   fetch(`api/v1/customers/${customerID}`).then((res) => {
      //     const customer = res?.data?.data;
      //     reservation.value.customer = customer;
      //     isCurrentOrPastDay.value = moment(
      //       reservation.value.customer?.latestReservation?.date
      //     ).isSameOrBefore(moment(), "day");
      //   });
      // } else {
      //   reservation.value.customer = getTrafficAccidentRegistration;
      // }
    });

    const getCustomerData = async () => {
      apiLoading.value = true;
      const customerID = await route.params.id.split('?')?.[2];
      isCurrentOrPastDay.value = false;

      if (customerID) {
        fetch(`api/v1/customers/${customerID}`)
          .then((res) => {
            const customer = res?.data?.data;
            reservation.value.customer = customer;
            isCurrentOrPastDay.value = moment(
              reservation.value.customer?.latestReservation?.date
            ).isSameOrBefore(moment(), 'day');
            isToday.value = moment(reservation.value.customer).isSame(moment(), 'day');
            apiLoading.value = false;
          })
          .catch((err) => {
            apiLoading.value = false;
          });
      } else {
        reservation.value.customer = getTrafficAccidentRegistration;
        apiLoading.value = false;
      }
    };

    const isEditParam = computed(() => {
      let disabled;
      if (
        route.params.id === 'new-customer-traffic-accident' ||
        route.params.id.split('?')?.[0] === 'edit-customer-traffic-accident'
      ) {
        disabled = false;
      } else {
        disabled = true;
      }
      return disabled;
    });

    const pay = () => {
      const toast = useToast();
      if (!reservation.value?.customer?.customerNumber) {
        toast.error(i18n.global.t('Errors.E102'), {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      }

      if (store.getters.user.currentAssignment.length == 0) {
        toast.error('こちらのスタッフは店舗配属されておりません。', {
          timeout: 3000,
          position: POSITION.BOTTOM_RIGHT
        });
        return;
      }

      // If the next reservation date is undecided, Alert will be displayed.

      // Accounting cooperation is not possible if the reservation date is past the day
      // Today> The date of the selected reservation slot (V_calendar.date) is error E102

      // if (
      //   moment(moment().format("YYYY-MM-DD")).isAfter(
      //     moment(
      //       reservation.value.customer.latestReservation.date,
      //       "YYYY-MM-DD"
      //     )
      //   )
      // ) {
      //   toast.error(i18n.global.t("Errors.E102"), {
      //     timeout: 3000,
      //     position: POSITION.BOTTOM_RIGHT,
      //   });
      //   return;
      // }
      apiLoading.value = true;

      createTemoraryTransaction(
        reservation.value?.customer?.customerNumber,
        // reservation.value?.customer?.store.id,
        store.getters.user.currentAssignment[0].storeId, //sending storeId of current user
        'customer'
      )
        .then((responseFromApi) => {
          apiLoading.value = false;
          // Added record to temporary sales = 仮売上高にレコードを追加
          toast.success('レジにデータ送信完了', {
            //Added record to temporary sales
            timeout: 3000,
            position: POSITION.BOTTOM_RIGHT
          });
          if (reservation.value.customer?.remainingCoupon === 1) {
            toast.error(i18n.global.t('Errors.C103'), {
              //Added record to temporary sales
              timeout: 3000,
              position: POSITION.BOTTOM_RIGHT
            });
          }
          getCustomerData();
        })
        .catch((err) => {
          // console.log(err)
          apiLoading.value = false;
          toast.error(`${err.response.data?.errors?.[0]?.title}`, {
            timeout: 2000,
            position: POSITION.BOTTOM_RIGHT
          });
        });
    };

    const openBackModal = () => {
      const backModalElement = document.getElementById('backModal');
      let backModal = bootstrap.Modal.getInstance(backModalElement);
      if (!backModal) {
        backModal = new bootstrap.Modal(backModalElement);
      }
      backModal.toggle();
    };

    const goBack = () => {
      const currentDate = store.state.reservationCalendarStoreDate;
      store.dispatch('setReservationCalendarStoreDate', currentDate);
      router.back();
    };
    const getUpdateCardDetail = (shouldUpdate) => {
      if (shouldUpdate === 'true') {
        getCustomerData();
      }
    };

    return {
      getUpdateCardDetail,
      RESERVATION_STATUS,
      customerSaleListData,
      isEditParam,
      reservation,
      selectedTab,
      apiLoading,
      goBack,
      openBackModal,
      pay,
      isToday
    };
  }
});
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variable.scss';
@import '../../assets/styles/partials/details.scss';
@import '../../assets/styles/partials/page-title.scss';

.title {
  hr {
    color: $primary;
  }
}

.button-container {
  display: flex;
  width: 884px;
  margin: auto;

  button {
    width: 128px;
  }
}

.custom-tabs {
  max-width: 884px;
  margin: 0 auto;
  margin-top: 40px;
  ul {
    list-style: none;
    border-bottom: 1px solid $bgLine;
    padding: 0;
    margin: 0;
    li {
      padding: 10px 12px 6px 12px;
      background: $bgLightSecondary;
      border-radius: 6px 6px 0 0;
      margin-right: 2px;
      min-width: 104px;
      width: max-content;
      height: 37px;
      text-align: center;
      cursor: pointer;
      color: $primary;
      &.active {
        background: $bgSecondary;
        margin-right: 0;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
