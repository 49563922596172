import { AxiosRequestConfig } from "axios";
import { httpBase } from "./http-common";

export function store(endpoint: any, data: any, config?: AxiosRequestConfig) {
  return config
    ? httpBase().post(`${endpoint}`, data, config)
    : httpBase().post(`${endpoint}`, data);
}

export function update(endpoint: any, data: any) {
  return httpBase().put(`/${endpoint}`, data);
}

export function fetch(endpoint: any, config?: AxiosRequestConfig) {
  return config
    ? httpBase().get(`${endpoint}`, config)
    : httpBase().get(`${endpoint}`);
  // return httpBase().get(`/${endpoint}`);
}

export function remove(endpoint: string) {
  return httpBase().delete(`${endpoint}`);
}
