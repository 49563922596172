
import { defineComponent } from "vue";

export default defineComponent({
  name: "Modal",
  props: {
    id: {
      required: true,
      type: String,
    },
    modalClass: {
      required: false,
      type: String,
    },
  },
});
