
import { store as post, fetch } from "@/core/api/api-service";
import { MpGraph } from "@/core/interface";
import { defineComponent, ref, onMounted } from "vue";
import Modal from "./Modal.vue";
import DatePicker from "../DatePicker.vue";
// import second from 'first'

export default defineComponent({
  name: "MpGraph",
  components: {
    Modal,
    DatePicker,
  },
  emits: ["hideMPG"],
  props: {
    reservation: Object || null,
  },
  setup(props, { emit }) {
    const mpGraphDataList = ref<Array<{ data: Array<MpGraph> }>>([]);
    const showCalendar = ref<boolean>(false);
    const mpGraphFormSubmitted = ref<boolean>(false);
    const selectedMpGraphListIndex = ref<number>(0);
    const selectedMpGraphCoordsIndex = ref<number>(0);
    const selectedMpData = ref<MpGraph>({
      xAxis: "",
      yAxis: "",
      date: "",
      comment: "",
    });
    onMounted(() => {
      if (props.reservation?.medicalRecord) {
        fetch(
          `api/v1/graphs?medicalRecordId=${props.reservation?.medicalRecord.id}`
        ).then((res) => {
          if (res.status === 200) {
            const mpGraphResData = res.data.data;
            if (mpGraphResData.length > 0) {
              let highestXAxis = 14;
              mpGraphResData.forEach((data: MpGraph) => {
                highestXAxis = Number(data.xAxis > highestXAxis)
                  ? Number(data.xAxis) + 2
                  : highestXAxis;
              });

              buildMPGraph(highestXAxis, mpGraphResData);
            } else {
              buildMPGraph();
            }
          }
        });
      }
    });

    const buildMPGraph = (maxXAxis = 14, graphList?: Array<MpGraph>) => {
      for (let y = 3; y >= -3; y--) {
        let mpGraphData: Array<any> = [];
        if (y !== 0) {
          for (let x = 1; x <= maxXAxis; x++) {
            let mpGraph: MpGraph = {
              xAxis: x,
              yAxis: y,
              date: "",
              comment: "",
            };
            if (graphList && graphList?.length > 0) {
              const graphData = graphList.filter(
                (graph) => graph.xAxis === x && graph.yAxis === y
              )[0];
              mpGraph.id = graphData?.id;
              mpGraph.date = graphData?.date || "";
              mpGraph.comment = graphData?.comment || "";
            }
            mpGraphData.push(mpGraph);
          }
          mpGraphDataList.value.push({ data: mpGraphData });
        }
      }
    };

    const handleSelectedMpData = (
      mpData: any,
      mpGraphListIndex: number,
      mpGraphCoordsIndex: number
    ) => {
      selectedMpData.value = { ...mpData };
      selectedMpGraphListIndex.value = mpGraphListIndex;
      selectedMpGraphCoordsIndex.value = mpGraphCoordsIndex;
    };

    const hideMPG = () => {
      emit("hideMPG");
    };

    const addCol = () => {
      mpGraphDataList.value.forEach((mpGraphData) => {
        const lastMpGraphData = mpGraphData.data[mpGraphData.data.length - 1];
        mpGraphData.data.push({
          xAxis: Number(lastMpGraphData.xAxis) + 1,
          yAxis: lastMpGraphData.yAxis,
          date: "",
          comment: "",
        });
      });
    };

    const registerMpGraphData = (selectedMpGraphData: MpGraph) => {
      if (mpGraphFormSubmitted.value) {
        return;
      }
      mpGraphFormSubmitted.value = true;
      let formData = new FormData();
      let mpGraphUrl = "/api/v1/graphs";
      if (selectedMpGraphData?.id) {
        formData.append("_method", "PUT");
        mpGraphUrl += `/${selectedMpGraphData?.id}`;
      }

      formData.append(
        "medical_record_id",
        props?.reservation?.medicalRecord?.id
      );
      formData.append("x_axis", String(selectedMpGraphData.xAxis));
      formData.append("y_axis", String(selectedMpGraphData.yAxis));
      formData.append("date", selectedMpGraphData.date);
      formData.append("comment", selectedMpGraphData?.comment || "");

      post(mpGraphUrl, formData).then((res) => {
        if (res.status === 200) {
          const mpGraphData = res.data.data;
          const mpGraph = {
            xAxis: mpGraphData.xAxis,
            yAxis: mpGraphData.yAxis,
            date: mpGraphData.date,
            comment: mpGraphData.comment,
          };
          mpGraphDataList.value[selectedMpGraphListIndex.value].data[
            selectedMpGraphCoordsIndex.value
          ] = mpGraph;
        }
        mpGraphFormSubmitted.value = false;
      });
    };

    return {
      mpGraphDataList,
      selectedMpData,
      showCalendar,
      mpGraphFormSubmitted,
      handleSelectedMpData,
      addCol,
      hideMPG,
      registerMpGraphData,
    };
  },
});
