
import {defineComponent, onMounted, ref, watchEffect} from 'vue';

import {fetch} from '@/core/api/api-service';
import Table3 from '../../components/tables/Table3.vue';
import CustomerListSearch from '../../components/search-cards/CustomerListSearch.vue';
import {store} from '@/core/store';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {getCurrentDivision, paginate} from '@/core/utils';

export default defineComponent({
  name: 'CustomerList',
  components: {
    Table3,
    CustomerListSearch,
    Loading
  },
  setup: function () {
    let storeID = ref();
    let userData = ref();
    let isCustomerLoading = ref(false);
    let customerListData = ref<any>([]);
    let fieldHereSearch = ref({
      ageFirst: '',
      ageSecond: '',
      baoyeTreatmentHas: '',
      birthMonth: '',
      customerName: '',
      customerNumber: '',
      lastVisitDate: '',
      noOfHospitalVisit1: '',
      noOfHospitalVisit2: '',
      phoneNumber: '',
      reviewEkiten: '',
      reviewGReview: '',
      reviewWB: '',
      store: '',
      trafficOrIndustrialTreatmentAvailable: '',
      remainingCoupon: '',
      accountReceivable: '',
      nonMember: '',
      dmok: '',
      anonymous: '',
      member: false,
      productSalesMember: false,
      unsettledThisMonth: null,
      memberCoupon10TimesOrMore: false,
      memberCouponZero: false,
      memberCouponMinus: false,
      psmUnsettled: null,
      psmNotDelivered: false
    });
    let currentPage = ref();
    let pagination = ref();
    let activeIndex = ref(0);
    let paginationButton = ref([{id: 0, buttonName: 1, active: false}]);
    let sortData = ref({field: '', sortBy: ''});
    watchEffect(async () => {
      storeID.value = (await store.getters?.user?.currentAssignment[0]?.storeId) || '';
    });

    onMounted(async () => {
      if (localStorage.customerListResponseFieldSearch) {
        customerListData.value = JSON.parse(localStorage.customerListResponseFieldSearch);
      }
      if (localStorage.customerListResponseSort) {
        customerListData.value = JSON.parse(localStorage.customerListResponseSort);
      }
      await getCurrentUserData();
    });
    const getCurrentUserData = async () => {
      // isCustomerLoading.value = true;
      fetch(`api/v1/getEmployee`).then(async (res: any) => {
        if (res) {
          const user = res?.data?.data;
          user.currentEmployeeDivision = getCurrentDivision(user.employeeDivsions);
          userData.value = user;
          // await onMountFetch();
        }
      });
    };

    // const onMountFetch = async () => {
    //   const page = `&page=${1}`;
    //   const url = `api/v1/customers${page}`;
    //   let customerUrlSplit =
    //     url.split("?").length == 0 ? `customers` : url.replace(/&/, "?");
    //   await fetch(`${customerUrlSplit}`)
    //     .then((res) => {
    //       const response = res?.data?.data;
    //       customerListData.value = response;
    //       // pagination.value = res?.data?.meta?.pagination;
    //       const paginationData = res?.data?.meta?.pagination;
    //       currentPage.value = res?.data?.meta?.pagination?.current_page;
    //       pagination.value = {
    //         ...paginate(
    //           paginationData.total,
    //           paginationData.current_page,
    //           paginationData.per_page,
    //           3
    //         ),
    //         previous: paginationData.links?.previous,
    //         next: paginationData.links?.next,
    //       };
    //       isCustomerLoading.value = false;
    //     })
    //     .catch((err) => (isCustomerLoading.value = false));
    // };

    const onPaginationChange = (selectedButton: any) => {
      isCustomerLoading.value = true;
      const pageNumber = selectedButton ? `&page=${selectedButton}` : '';
      const sortingParam = sortData.value.field
          ? `&sort=${sortData.value.sortBy}=${sortData.value.field}`
          : '';
      const lastVisitDate = fieldHereSearch.value.lastVisitDate
          ? `&lastVisitDate=${fieldHereSearch.value.lastVisitDate}`
          : '';
      const storeData =
          fieldHereSearch.value.store || store.getters.user?.currentAssignment?.storeId
              ? `store=${
                  fieldHereSearch.value.store ||
                  store.getters.user?.currentAssignment?.storeId ||
                  userData.value?.currentAssignment[0]?.storeId
              }`
              : '';
      const noOfHospitalVisit1 = fieldHereSearch.value.noOfHospitalVisit1
          ? `&noOfHospitalVisitFrom=${fieldHereSearch.value.noOfHospitalVisit1}`
          : '';
      const noOfHospitalVisit2 = fieldHereSearch.value.noOfHospitalVisit2
          ? `&noOfHospitalVisiTo=${fieldHereSearch.value.noOfHospitalVisit2}`
          : '';
      const ageFirst = fieldHereSearch.value.ageFirst
          ? `&ageFrom=${fieldHereSearch.value.ageFirst}`
          : '';
      const ageSecond = fieldHereSearch.value.ageSecond
          ? `&ageTo=${fieldHereSearch.value.ageSecond}`
          : '';
      const birthMonth = fieldHereSearch.value.birthMonth
          ? `&birthMonth=${fieldHereSearch.value.birthMonth}`
          : '';
      const phoneNumber = fieldHereSearch.value.phoneNumber
          ? `&phoneNumber=${fieldHereSearch.value.phoneNumber}`
          : '';
      const customerName = fieldHereSearch.value.customerName
          ? `&customerName=${fieldHereSearch.value.customerName}`
          : '';
      const customerNumber = fieldHereSearch.value.customerNumber
          ? `&customerNumber=${fieldHereSearch.value.customerNumber}`
          : '';
      const reviewEkiten = fieldHereSearch.value?.reviewEkiten
          ? `&reviewEkiten=${fieldHereSearch.value?.reviewEkiten}`
          : '';
      const reviewGReview = fieldHereSearch.value?.reviewGReview
          ? `&reviewGReview=${fieldHereSearch.value?.reviewGReview}`
          : '';
      const reviewWB = fieldHereSearch.value?.reviewWB
          ? `&reviewWB=${fieldHereSearch.value?.reviewWB}`
          : '';
      const baoyeTreatmentHas = fieldHereSearch.value.baoyeTreatmentHas
          ? `&baoyeTreatmentHas=${fieldHereSearch.value.baoyeTreatmentHas}`
          : '';
      const trafficOrIndustrialTreatmentAvailable = fieldHereSearch.value
          .trafficOrIndustrialTreatmentAvailable
          ? `&trafficOrIndustrialTreatmentAvailable=${fieldHereSearch.value.trafficOrIndustrialTreatmentAvailable}`
          : '';
      const isMember = fieldHereSearch.value?.member
          ? `&member=${fieldHereSearch.value?.member}`
          : '';
      const productSalesMember = fieldHereSearch.value?.productSalesMember
          ? `&productSalesMember=${fieldHereSearch.value?.productSalesMember}`
          : '';
      const unsettledThisMonth =
          fieldHereSearch.value?.unsettledThisMonth == 1 ? `&unsettledThisMonth=true` : '';
      const memberCoupon10TimesOrMore =
          fieldHereSearch.value.unsettledThisMonth == 2 ? `&memberCoupon10TimesOrMore=true` : '';
      const memberCouponZero =
          fieldHereSearch.value.unsettledThisMonth == 3 ? `&memberCouponZero=true` : '';
      const memberCouponMinus =
          fieldHereSearch.value.unsettledThisMonth == 4 ? `&memberCouponMinus=true` : '';
      const psmUnsettled = fieldHereSearch.value.psmUnsettled == 1 ? `&psmUnsettled=true` : '';
      const psmNotDelivered =
          fieldHereSearch.value.psmUnsettled == 2 ? `&psmNotDelivered=true` : '';

      fetch(
          `api/v1/customers?${storeData}${customerName}${customerNumber}${phoneNumber}${birthMonth}${ageFirst}${ageSecond}${noOfHospitalVisit1}${noOfHospitalVisit2}${lastVisitDate}${baoyeTreatmentHas}${reviewEkiten}${reviewGReview}${reviewWB}${trafficOrIndustrialTreatmentAvailable}${sortingParam}${pageNumber}${isMember}${productSalesMember}${unsettledThisMonth}${memberCoupon10TimesOrMore}${memberCouponZero}${memberCouponMinus}${psmUnsettled}${psmNotDelivered}`
      )
          .then((res) => {
            const response = res?.data?.data;
            customerListData.value = response;
            const paginationData = res?.data?.meta?.pagination;
            currentPage.value = res?.data?.meta?.pagination?.current_page;
            pagination.value = {
              ...paginate(
                  paginationData.total,
                  paginationData.current_page,
                  paginationData.per_page,
                  3
              ),
              previous: paginationData.links?.previous,
              next: paginationData.links?.next
            };
            isCustomerLoading.value = false;
          })
          .catch((err) => (isCustomerLoading.value = false));
    };
    const changePage = (urlData: any) => {
      isCustomerLoading.value = true;
      const url = urlData;
      fetch(`${url}`)
          .then((res) => {
            const response = res?.data?.data;
            customerListData.value = response;
            const paginationData = res?.data?.meta?.pagination;
            currentPage.value = res?.data?.meta?.pagination?.current_page;
            pagination.value = {
              ...paginate(
                  paginationData.total,
                  paginationData.current_page,
                  paginationData.per_page,
                  3
              ),
              previous: paginationData.links?.previous,
              next: paginationData.links?.next
            };
            isCustomerLoading.value = false;
          })
          .catch((err) => (isCustomerLoading.value = false));
    };
    const getCustomerList = (customer: any, type: any) => {
      isCustomerLoading.value = true;
      if (type === 'fieldSearch') {
        fieldHereSearch.value = customer;
        const sortingParam = sortData.value.field
            ? `&sort=${sortData.value.sortBy}&field=${sortData.value.field}`
            : '';
        const lastVisitDate = customer.lastVisitDate
            ? `&lastVisitDate=${customer.lastVisitDate}`
            : '';
        const store = customer.store ? `&store=${customer.store}` : '&store=';
        const noOfHospitalVisit1 = customer.noOfHospitalVisit1
            ? `&noOfHospitalVisitFrom=${customer.noOfHospitalVisit1}`
            : '';
        const noOfHospitalVisit2 = customer.noOfHospitalVisit2
            ? `&noOfHospitalVisitTo=${customer.noOfHospitalVisit2}`
            : '';
        const ageFirst = customer.ageFirst ? `&ageFrom=${customer.ageFirst}` : '';
        const ageSecond = customer.ageSecond ? `&ageTo=${customer.ageSecond}` : '';
        const birthMonth = customer.birthMonth ? `&birthMonth=${customer.birthMonth}` : '';
        const phoneNumber = customer.phoneNumber ? `&phoneNumber=${customer.phoneNumber}` : '';
        const customerName = customer.customerName ? `&customerName=${customer.customerName}` : '';
        const customerNumber = customer.customerNumber
            ? `&customerNumber=${customer.customerNumber}`
            : '';
        const reviewEkiten = customer?.reviewEkiten
            ? `&reviewEkiten=${customer?.reviewEkiten}`
            : '';
        const reviewGReview = customer?.reviewGReview
            ? `&reviewGReview=${customer?.reviewGReview}`
            : '';

        const reviewWB = customer?.reviewWB ? `&reviewWB=${customer?.reviewWB}` : '';
        const othersRemainingCoupon = customer?.remainingCoupon
            ? `&remainingCoupon=${customer?.remainingCoupon}`
            : '';
        const accountReceivable = customer?.accountReceivable
            ? `&accountReceivable=${customer?.accountReceivable}`
            : '';
        const nonMember = customer?.nonMember ? `&nonMember=${customer?.nonMember}` : '';
        const dmok = customer?.dmok ? `&dmok=${customer?.dmok}` : '';
        const anonymous = customer?.anonymous ? `&anonymous=${customer?.anonymous}` : '';
        const baoyeTreatmentHas = customer.baoyeTreatmentHas
            ? `&baoyeTreatmentHas=${customer.baoyeTreatmentHas}`
            : '';
        const trafficOrIndustrialTreatmentAvailable = customer.trafficOrIndustrialTreatmentAvailable
            ? `&trafficOrIndustrialTreatmentAvailable=${customer.trafficOrIndustrialTreatmentAvailable}`
            : '';
        const isMember = customer.member ? `&member=${customer.member}` : '';
        const productSalesMember = customer.productSalesMember
            ? `&productSalesMember=${customer.productSalesMember}`
            : '';
        const unsettledThisMonth =
            customer.unsettledThisMonth == 1 ? `&unsettledThisMonth=true` : '';
        const memberCoupon10TimesOrMore =
            customer.unsettledThisMonth == 2 ? `&memberCoupon10TimesOrMore=true` : '';
        const memberCouponZero = customer.unsettledThisMonth == 3 ? `&memberCouponZero=true` : '';
        const memberCouponMinus = customer.unsettledThisMonth == 4 ? `&memberCouponMinus=true` : '';
        const psmUnsettled = customer.psmUnsettled == 1 ? `&psmUnsettled=true` : '';
        const psmNotDelivered = customer.psmUnsettled == 2 ? `&psmNotDelivered=true` : '';

        const page = `&page=${1}`;
        let customerUrl = `customers${store}${customerName}${nonMember}${dmok}${anonymous}${customerNumber}${phoneNumber}${birthMonth}${ageFirst}${ageSecond}${noOfHospitalVisit1}${noOfHospitalVisit2}${lastVisitDate}${baoyeTreatmentHas}${reviewEkiten}${reviewGReview}${reviewWB}${trafficOrIndustrialTreatmentAvailable}${sortingParam}${othersRemainingCoupon}${accountReceivable}${page}${isMember}${productSalesMember}${unsettledThisMonth}${memberCoupon10TimesOrMore}${memberCouponZero}${memberCouponMinus}${psmUnsettled}${psmNotDelivered}`;
        let customerUrlSplit =
            customerUrl.split('?').length == 0 ? `customers` : customerUrl.replace(/&/, '?');
        fetch(`api/v1/${customerUrlSplit}`)
            .then((res) => {
              const response = res?.data?.data;
              localStorage.setItem('customerListResponseFieldSearch', JSON.stringify(response));
              localStorage.removeItem('customerListResponseSort');
              customerListData.value = response;
              const paginationData = res?.data?.meta?.pagination;
              currentPage.value = res?.data?.meta?.pagination?.current_page;
              pagination.value = {
                ...paginate(
                    paginationData.total,
                    paginationData.current_page,
                    paginationData.per_page,
                    3
                ),
                previous: paginationData.links?.previous,
                next: paginationData.links?.next
              };
              isCustomerLoading.value = false;
            })
            .catch((err) => (isCustomerLoading.value = false));
      } else {
        sortData.value = customer;

        const sortingParam = customer ? `&sort=${customer.sortBy}&field=${customer.field}` : '';
        const lastVisitDate = fieldHereSearch.value.lastVisitDate
            ? `&lastVisitDate=${fieldHereSearch.value.lastVisitDate}`
            : '';

        const storeData = fieldHereSearch.value.store
            ? `&store=${fieldHereSearch.value.store}`
            : '';
        const noOfHospitalVisit1 = fieldHereSearch.value.noOfHospitalVisit1
            ? `&noOfHospitalVisitFrom=${fieldHereSearch.value.noOfHospitalVisit1}`
            : '';
        const noOfHospitalVisit2 = fieldHereSearch.value.noOfHospitalVisit2
            ? `&noOfHospitalVisiTo=${fieldHereSearch.value.noOfHospitalVisit2}`
            : '';
        const ageFirst = fieldHereSearch.value.ageFirst
            ? `&ageFrom=${fieldHereSearch.value.ageFirst}`
            : '';
        const ageSecond = fieldHereSearch.value.ageSecond
            ? `&ageTo=${fieldHereSearch.value.ageSecond}`
            : '';
        const birthMonth = fieldHereSearch.value.birthMonth
            ? `&birthMonth=${fieldHereSearch.value.birthMonth}`
            : '';
        const phoneNumber = fieldHereSearch.value.phoneNumber
            ? `&phoneNumber=${fieldHereSearch.value.phoneNumber}`
            : '';
        const customerName = fieldHereSearch.value.customerName
            ? `&customerName=${fieldHereSearch.value.customerName}`
            : '';
        const customerNumber = fieldHereSearch.value.customerNumber
            ? `&customerNumber=${fieldHereSearch.value.customerNumber}`
            : '';
        const reviewEkiten = fieldHereSearch.value?.reviewEkiten
            ? `&reviewEkiten=${fieldHereSearch.value?.reviewEkiten}`
            : '';
        const reviewGReview = fieldHereSearch.value?.reviewGReview
            ? `&reviewGReview=${fieldHereSearch.value?.reviewGReview}`
            : '';
        const reviewWB = fieldHereSearch.value?.reviewWB
            ? `&reviewWB=${fieldHereSearch.value?.reviewWB}`
            : '';
        const baoyeTreatmentHas = fieldHereSearch.value.baoyeTreatmentHas
            ? `&baoyeTreatmentHas=${fieldHereSearch.value.baoyeTreatmentHas}`
            : '';
        const trafficOrIndustrialTreatmentAvailable = fieldHereSearch.value
            .trafficOrIndustrialTreatmentAvailable
            ? `&trafficOrIndustrialTreatmentAvailable=${fieldHereSearch.value.trafficOrIndustrialTreatmentAvailable}`
            : '';
        const othersRemainingCoupon = fieldHereSearch.value?.remainingCoupon
            ? `&remainingCoupon=${fieldHereSearch.value?.remainingCoupon}`
            : '';
        const accountReceivable = fieldHereSearch.value?.accountReceivable
            ? `&accountReceivable=${fieldHereSearch.value?.accountReceivable}`
            : '';
        const nonMember = fieldHereSearch.value?.nonMember
            ? `&nonMember=${fieldHereSearch.value?.nonMember}`
            : '';
        const dmok = fieldHereSearch.value?.dmok ? `&dmok=${fieldHereSearch.value?.dmok}` : '';
        const anonymous = fieldHereSearch.value?.anonymous
            ? `&anonymous=${fieldHereSearch.value?.anonymous}`
            : '';
        const isMember = fieldHereSearch.value?.member
            ? `&member=${fieldHereSearch.value?.member}`
            : '';
        const productSalesMember = fieldHereSearch.value?.productSalesMember
            ? `&productSalesMember=${fieldHereSearch.value?.productSalesMember}`
            : '';

        const unsettledThisMonth =
            fieldHereSearch.value.unsettledThisMonth == 1 ? `&unsettledThisMonth=true` : '';
        const memberCoupon10TimesOrMore =
            fieldHereSearch.value.unsettledThisMonth == 2 ? `&memberCoupon10TimesOrMore=true` : '';
        const memberCouponZero =
            fieldHereSearch.value.unsettledThisMonth == 3 ? `&memberCouponZero=true` : '';
        const memberCouponMinus =
            fieldHereSearch.value.unsettledThisMonth == 4 ? `&memberCouponMinus=true` : '';
        const psmUnsettled = fieldHereSearch.value.psmUnsettled == 1 ? `&psmUnsettled=true` : '';
        const psmNotDelivered =
            fieldHereSearch.value.psmUnsettled == 2 ? `&psmNotDelivered=true` : '';

        const page = `&page=${1}`;
        let customerUrl = `customers${storeData}${othersRemainingCoupon}${accountReceivable}${nonMember}${dmok}${anonymous}${customerName}${customerNumber}${phoneNumber}${birthMonth}${ageFirst}${ageSecond}${noOfHospitalVisit1}${noOfHospitalVisit2}${lastVisitDate}${baoyeTreatmentHas}${reviewEkiten}${reviewGReview}${reviewWB}${trafficOrIndustrialTreatmentAvailable}${sortingParam}${page}${isMember}${productSalesMember}${unsettledThisMonth}${memberCoupon10TimesOrMore}${memberCouponZero}${memberCouponMinus}${psmUnsettled}${psmNotDelivered}`;
        let customerUrlSplit =
            customerUrl.split('?').length == 0 ? `customers` : customerUrl.replace(/&/, '?');
        fetch(`api/v1/${customerUrlSplit}`)
            .then((res) => {
              const response = res?.data?.data;
              localStorage.setItem('customerListResponseSort', JSON.stringify(response));
              localStorage.removeItem('customerListResponseFieldSearch');
              customerListData.value = response;
              const paginationData = res?.data?.meta?.pagination;
              currentPage.value = res?.data?.meta?.pagination?.current_page;
              pagination.value = {
                ...paginate(
                    paginationData.total,
                    paginationData.current_page,
                    paginationData.per_page,
                    3
                ),
                previous: paginationData.links?.previous,
                next: paginationData.links?.next
              };
              isCustomerLoading.value = false;
            })
            .catch((err) => (isCustomerLoading.value = false));
      }
    };
    return {
      isCustomerLoading,
      Loading,
      activeIndex,
      onPaginationChange,
      changePage,
      paginationButton,
      pagination,
      customerListData,
      getCustomerList
    };
  }
});
