<template>
  <h2 class="step-title">{{ thankYouGuide?.sentence || "" }}</h2>
  <PersonalInformation
    :personalInformationGuide="personalInformationGuide"
    :formGuidence="formGuidence"
    @getPersonalInfo="getPersonalInfo"
    @getTerms="getTerms"
  >
    <template v-slot:footer>
      <div class="button-container mt-40">
        <button class="btn btn-secondary" @click="showPrevious()">
          {{ $t("PersonalInformation.Return") }}
        </button>
        <button
          :disabled="isSignupButtonDisabled"
          class="btn btn-primary"
          @click="showNext()"
        >
          {{ $t("PersonalInformation.Next") }}
        </button>
      </div>
    </template>
  </PersonalInformation>
</template>
<script>
import { defineComponent, onMounted, ref, computed,watchEffect } from "vue";
import PersonalInformation from "../PersonalInformation.vue";
import axios from "axios";
import { store } from "@/core/store";

export default defineComponent({
  name: "Step1",
  components: {
    PersonalInformation,
  },
  emits: ["showPrevious", "showNext"],
  props: {
    previousPage: {
      required: true,
      type: Number,
    },
  },
  setup(props, { emit }) {
    let thankYouGuide = ref(null);
    let personalInformationGuide = ref(null);
    let formGuidence = ref(null);
    let personalInfo = ref(
      store?.getters?.registrationSteps?.personalInfo || ""
    );
    const {
      getters: {
        registrationSteps,
      },
    } = store;
    let termsChecked = ref(false);

    onMounted(() => {
      const getPageGuideData = async () => {
        let endpoints = [
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/getPageGuide?code=120_01`,
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/getPageGuide?code=120_02`,
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/getPageGuide?code=120_03`,
        ];
        Promise.all(
          endpoints.map((endpoint) =>
            axios.get(endpoint, {
              headers: { Authorization: "Bearer " + store.getters.token },
            })
          )
        ).then(
          axios.spread((...allData) => {
            personalInformationGuide.value = allData?.[0]?.data?.data?.sentence
              .replace(/<[^>]+>/g, '').replace(/&nbsp;/g, '');
            thankYouGuide.value = allData?.[1]?.data?.data;
            formGuidence.value = allData?.[2]?.data?.data;
          })
        );
      };

      getPageGuideData();
    });

    watchEffect(() => {
      if (registrationSteps.personalInfo) {
        termsChecked.value = JSON.parse(registrationSteps.personalInfo) ? true : false;
      }
    })

    const showPrevious = () => {
      emit("showPrevious", props.previousPage);
    };
    const showNext = () => {
      if (termsChecked?.value === true) {
        store.dispatch("setStepPersonalInfo", termsChecked.value).then(() => {
          emit("showNext");
        });
      }
    };

    const getPersonalInfo = (value) => {
      personalInfo.value = value;
      store.dispatch("setStepPersonalInfo", personalInfo.value);
    };
    const getTerms = (value) => {
      termsChecked.value = value;

    };

    const isSignupButtonDisabled = computed(() => {
      let disabled;
      if (termsChecked.value === true) {
        disabled = false;
      } else {
        disabled = true;
      }
      return disabled;
    });

    return {
      getTerms,
      isSignupButtonDisabled,
      thankYouGuide,
      personalInformationGuide,
      formGuidence,
      getPersonalInfo,
      showPrevious,
      showNext,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../../assets/styles/variable.scss";
@import "../../assets/styles/partials/steps.scss";
</style>
