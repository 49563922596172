
import {defineComponent, computed, ref, watchEffect} from "vue";
import Modal from "./Modal.vue";
import { RESERVATION_STATUS, CANCELLATION_TYPE } from "@/core/store/enums";
import moment from "moment";
export default defineComponent({
  name: "ReservationCancleModal",
  components: { Modal },
  props: {
    reservation: {
      required: false,
      type: Object,
    },
    type: {
      required: false,
      type: Number,
      default: 1,
    },
    reservationDetail: {
      required: false,
      type: Object,
    },
  },
  emits: ["submitCancellationType"],
  setup(props) {
    let cancellationType = ref(CANCELLATION_TYPE.CONTACTED);
    let disableButton = ref(false);
    watchEffect(() => {
      (cancellationType.value = props.reservationDetail?.reservationStatus === 3 ? CANCELLATION_TYPE.AFTER_RECEPTION : props?.reservation?.extendedProps?.reservationStatus === 3 ? CANCELLATION_TYPE.AFTER_RECEPTION :CANCELLATION_TYPE.CONTACTED),
          (disableButton.value = (props.reservationDetail?.reservationStatus || props?.reservation?.extendedProps?.reservationStatus ) !== 3 ? true :false )
    });
    return {
      disableButton,
      RESERVATION_STATUS,
      CANCELLATION_TYPE: CANCELLATION_TYPE,
      // //
      // cancellationType:
      //     ((props.reservation?.extendedProps?.reservationStatus ) || (props?.reservationDetail?.reservationStatus)
      //         === RESERVATION_STATUS.RECEPTION)
      //     ? CANCELLATION_TYPE.AFTER_RECEPTION
      //     : CANCELLATION_TYPE.CONTACTED,
      cancellationType,
    };
  },
  methods: {
    submitCancellationType() {
      this.$emit("submitCancellationType", this.cancellationType);
    },
  },

});
