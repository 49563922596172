
import { defineComponent, ref, PropType, watchEffect } from "vue";
import { $date as useDate } from "alga-js";

export default defineComponent({
  name: "DatePicker",
  emits: ["selectedDate", "prevBtnClick", "nextBtnClick"],
  props: {
    type: {
      required: false,
      type: String as PropType<"default" | "previousMonth" | "nextMonth">,
      default: "default",
    },
    date: {
      required: false,
      type: Date,
      default: new Date(),
    },
  },
  setup(props, { emit }) {
    const year = ref<number>(0);
    const month = ref<number>(0);
    const resultDate = ref<string>("");
    const calendar = ref<any>(null);

    const getDayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const getDaysNamesJa: { [key: string]: string } = {
      Sunday: "日",
      Monday: "月",
      Tuesday: "火",
      Wednesday: "水",
      Thursday: "木",
      Friday: "金",
      Saturday: "土",
    };

    const calendarUpdateWatcher = watchEffect(() => {
      year.value = props.date.getFullYear();
      month.value = Number(props.date.getMonth()) + 1;
      calendar.value = useDate.calendar(year.value, month.value);
    });

    const stopCalendarUpdateWatcher = () => {
      calendarUpdateWatcher();
    };

    if (props.type === "default") {
      stopCalendarUpdateWatcher();
    }

    const isCurrentDate = (date: string) => {
      if (getDayNames.includes(date)) return false;
      const formattedDate = new Date(date);
      const currentDate = new Date();
      return (
        currentDate.getFullYear() === formattedDate.getFullYear() &&
        currentDate.getMonth() === formattedDate.getMonth() &&
        currentDate.getDate() === formattedDate.getDate()
      );
    };

    const filterDate = (date: string) => {
      let newDate = "";
      if (getDayNames.includes(date)) {
        // newDate = date.slice(0, 3);
        newDate = getDaysNamesJa[date];
      } else {
        const splitDate = date.split("-");
        newDate =
          Number(splitDate[2]) < 10 ? splitDate[2].charAt(1) : splitDate[2];
      }
      return newDate;
    };

    const blurDate = (date: string) => {
      let blurText = false;
      if (
        !getDayNames.includes(date) &&
        Number(date.split("-")[1]) !== month.value
      ) {
        blurText = true;
      }
      return blurText;
    };

    const disabledClickDate = (date: string) => {
      let disabledClick = false;
      if (
        getDayNames.includes(date) ||
        Number(date.split("-")[1]) !== month.value
      ) {
        disabledClick = true;
      }
      return disabledClick;
    };

    const eventDate = (date: string) => {
      if (!getDayNames.includes(date)) {
        resultDate.value = date;
        emit("selectedDate", resultDate.value);
      }
    };

    const handleDefaultPrevBtnClick = () => {
      if (month.value == 1) {
        year.value = year.value - 1;
        month.value = 12;
      } else {
        month.value = month.value - 1;
      }
      calendar.value = useDate.calendar(year.value, month.value);
    };

    const handleDefaultNextBtnClick = () => {
      if (month.value == 12) {
        year.value = year.value + 1;
        month.value = 1;
      } else {
        month.value = month.value + 1;
      }
      calendar.value = useDate.calendar(year.value, month.value);
    };

    const handlePrevBtnClick = () => {
      emit("prevBtnClick");
    };

    const handleNextBtnClick = () => {
      emit("nextBtnClick");
    };

    return {
      calendar,
      filterDate,
      isCurrentDate,
      blurDate,
      disabledClickDate,
      eventDate,
      handlePrevBtnClick,
      handleNextBtnClick,
      handleDefaultPrevBtnClick,
      handleDefaultNextBtnClick,
      resultDate,
      year,
      month,
      showButton: true,
      getDayNames,
    };
  },
});
