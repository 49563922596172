
import {defineComponent, onMounted, ref, watchEffect} from "vue";
import {useRoute} from "vue-router";
import Modal from "./modals/Modal.vue";
import {POSITION, useToast} from "vue-toastification";
import {fetch, remove, store as post} from "@/core/api/api-service";
import "vue-toastification/dist/index.css";
import DatePicker from "./DatePicker.vue";
import {Modal as BootstrapModal} from "bootstrap";
import {couponPaymentMethod} from "@/core/store/constants";
import {COUPON_PAYMENT_METHODS} from "@/core/store/enums";
import {paginate} from "@/core/utils";
import moment from "moment";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "BookTicket",
  components: {
    Modal,
    DatePicker,
  },
  emits: ["updateNext"],

  setup(props, {emit}) {
    const route = useRoute();
    const toast = useToast();
    const i18n = useI18n();
    const customerNumber = ref("");
    let errors: any = ref([]);
    let showCalendar = ref(false);
    const couponsData = ref([]);
    const currentSelectedCouponsData = ref();
    let pagination = ref();
    let disableButton = ref(false);
    let currentPage = ref();
    let customer = ref({
      date: "",
      numberOfPurchase: "",
      numberOfUses: "",
      remarks: "",
      customerNumber: "",
    });
    let status = ref({
      remaining: "",
      totalPurchase: "",
      totalUses: "",
    });

    watchEffect(async () => {
      customer.value.customerNumber = await (route.params.id as string)?.split(
        "=?"
      )?.[1];
    });

    const checkPayment = (selectedCouponsData: any) => {
      if (selectedCouponsData.paymentMethod === 3) {
        return true;
      } else {
        return false;
      }
    };

    let paginationButton = ref([{id: 0, buttonName: 1, active: false}]);

    onMounted(async () => {
      const customerID = await (route.params.id as string).split("=?")?.[1];
      customerNumber.value = customerID;
      getCouponsData(customerID);
      getStatusFor(customerID);
    });
    const getCouponsData = (customerNumberFromRoute: any) => {
      const customerNumberForCoupon = customerNumberFromRoute;
      const page = `&page=${1}`;
      fetch(`api/v1/coupons?customerNumber=${customerNumberForCoupon}${page}`).then(
        (res: any) => {
          const customerSales = res?.data?.data;
          const paginationData = res?.data?.meta?.pagination;
          currentPage.value = res?.data?.meta?.pagination?.current_page;
          pagination.value = {
            ...paginate(
              paginationData.total,
              paginationData.current_page,
              paginationData.per_page,
              3
            ),
            previous: paginationData.links?.previous,
            next: paginationData.links?.next,
          };
          couponsData.value = customerSales;
        }
      );
    };
    const getStatusFor = (customerNumberFromRoute: any) => {
      const customerNumberForStatus = customerNumberFromRoute;
      fetch(`api/v1/coupons-status?customerNumber=${customerNumberForStatus}`).then(
        (res: any) => {
          const response = res?.data?.data;
          status.value = response;
        }
      );
    };
    const changePage = (urlData: any) => {
      const url = urlData;
      fetch(`${url}`).then((res: any) => {
        const response = res?.data?.data;
        const paginationValues = res?.data?.meta?.pagination
        setPaginationData(response, paginationValues)
      });
    };

    const setPaginationData = (responseForPagination: any, paginationValues: any) => {
      couponsData.value = responseForPagination;
      const paginationData = paginationValues;
      currentPage.value = paginationValues?.current_page;
      pagination.value = {
        ...paginate(
          paginationData.total,
          paginationData.current_page,
          paginationData.per_page,
          3
        ),
        previous: paginationData.links?.previous,
        next: paginationData.links?.next,
      };

    };

    const onPaginationChange = (selectedButton: any) => {
      const customerNumberFromRoute = customerNumber.value;
      fetch(
        `api/v1/coupons?customerNumber=${customerNumberFromRoute}&page=${selectedButton}`
      ).then((res: any) => {
        const response = res?.data?.data;
        const paginationValues = res?.data?.meta?.pagination
        setPaginationData(response, paginationValues)
      });
    };

    const adjustLineAppend = () => {
      errors.value = [];
      disableButton.value = true;
      if (customer.value.numberOfPurchase) {
        const getValue1 = customer.value.numberOfPurchase;
        var numberPattern1 = /^-?[0-9]+$/g;
        const test = numberPattern1.test(getValue1);
        if (test !== true) {
          errors.value.push({
            title: 'numberOfPurchase',
            message: i18n.t('Errors.E063')
          });
        }
      }

      if (customer.value.numberOfUses) {
        const getValue1 = customer.value.numberOfUses;
        var numberPattern12 = /^-?[0-9]+$/g;
        const test = numberPattern12.test(getValue1);
        if (test !== true) {
          errors.value.push({
            title: 'numberOfUses',
            message: i18n.t('Errors.E063')
          });
        }
      }

      if (errors.value.length === 0) {
        let formData: any = customer?.value;
        if (currentSelectedCouponsData.value?.id) {
          formData._method = "PUT";
        } else if (!currentSelectedCouponsData.value?.id && formData?._method){
          delete formData["_method"];
        }
        let urlType = currentSelectedCouponsData.value?.id
          ? `coupons/${currentSelectedCouponsData.value?.id}`
          : "coupons-adjustment";
        return post(
          `${process.env.VUE_APP_BASE_API_URL}/api/v1/${urlType}`,
          formData
        )
          .then((res) => {
            const customerID = customer.value.customerNumber;
            if (res?.status === 200) {
              disableButton.value = false;
              const calendarModalElement = document.getElementById('editModal') as HTMLElement;
              let calendarModal = BootstrapModal.getInstance(calendarModalElement);
              if (!calendarModal || calendarModal === null) {
                calendarModal = new BootstrapModal(calendarModalElement);
                calendarModal.hide();
              } else {
                calendarModal.hide();
              }
              window.scrollTo(0, 0);
              toast.success("登録しました", {
                timeout: 2000,
                position: POSITION.BOTTOM_RIGHT,
              });
              getCouponsData(customerID);
              getStatusFor(customerID);
              customer.value.date = "";
              customer.value.numberOfPurchase = "";
              customer.value.numberOfUses = "";
              customer.value.remarks = "";
              currentSelectedCouponsData.value = "";
            }
            emit("updateNext", "true");
          })
          .catch((err) => {
            currentSelectedCouponsData.value = "";
            customer.value.date = "";
            customer.value.numberOfPurchase = "";
            customer.value.numberOfUses = "";
            customer.value.remarks = "";
            toast.error(`${err.response.data?.errors?.[0]?.title}`, {
              timeout: 2000,
              position: POSITION.BOTTOM_RIGHT,
            });
          });
      }
    };

    const filterErrorColor = (fieldName: any) => {
      if (errors.value.length > 0) {
        const errorMessage = errors.value.filter((x: { title: any }) => x.title === fieldName);
        if (errorMessage && errorMessage.length > 0) {
          return true;
        } else if (errorMessage.length === 0) {
          return false;
        }
      }
    };

    const filterErrors = (fieldName: any) => {
      if (errors.value.length > 0) {
        const errorMessage = errors.value.filter((x: { title: any }) => x.title === fieldName);
        return errorMessage[0]?.message;
      }
    };

    const deleteModal = (selectedCouponsData: any) => {
      currentSelectedCouponsData.value = selectedCouponsData;
      const element = document.getElementById("deleteModal") as HTMLElement;
      const myModal = new BootstrapModal(element);
      myModal.show();
    };

    const deleteData = (type: any) => {
      if (type === "delete") {
        remove(`api/v1/coupons/${currentSelectedCouponsData.value.id}`)
          .then((res) => {
            if (res?.status === 200) {
              window.scrollTo(0, 0);
              toast.success("削除しました", {
                timeout: 2000,
                position: POSITION.BOTTOM_RIGHT,
              });
              emit("updateNext", "true");
              currentSelectedCouponsData.value = '';
              const customerID = customer.value.customerNumber;
              getCouponsData(customerID);
              getStatusFor(customerID);
              const element = document.getElementById(
                "deleteModal"
              ) as HTMLElement;
              const myModal = new BootstrapModal(element);
              myModal.hide();
            }
          })
          .catch((err) => {
            currentSelectedCouponsData.value = '';
            customer.value.date = '';
            customer.value.numberOfPurchase = '';
            customer.value.numberOfUses = '';
            customer.value.remarks = '';
            toast.error(`${err.response.data?.errors?.[0]?.title}`, {
              timeout: 2000,
              position: POSITION.BOTTOM_RIGHT
            });
          });
      } else {
        const element = document.getElementById("deleteModal") as HTMLElement;
        const myModal = new BootstrapModal(element);
        myModal.hide();
      }
    };

    const edit = (selectedData: any) => {
      currentSelectedCouponsData.value = selectedData;
      (customer.value.date = selectedData?.date);
      (customer.value.numberOfPurchase = selectedData?.purchaseNumber);
      (customer.value.numberOfUses = selectedData?.numberofUse);
      (customer.value.remarks = selectedData?.remarks);

      const element = document.getElementById("editModal") as HTMLElement;
      const myModal = new BootstrapModal(element);
      myModal.show();
    };

    const create = () => {
      const element = document.getElementById("editModal") as HTMLElement;
      const myModal = new BootstrapModal(element);
      myModal.show();
    };

    const cancelLineAppend = () => {
      const calendarModalElement = document.getElementById(
        "editModal"
      ) as HTMLElement;
      let calendarModal = BootstrapModal.getInstance(calendarModalElement);
      if (!calendarModal || calendarModal === null) {
        calendarModal = new BootstrapModal(calendarModalElement);
        calendarModal.hide();
      } else {
        calendarModal.hide();
      }
      currentSelectedCouponsData.value = "";
      customer.value.date = "";
      customer.value.numberOfPurchase = "";
      customer.value.numberOfUses = "";
      customer.value.remarks = "";
      errors.value = [];
    };

    const convertToMMDD = (date: any) => {
      return moment(date, "YYYY-MM-DD").format("YYYY/MM/DD");
    };

    return {
      create,
      convertToMMDD,
      couponPaymentMethod,
      COUPON_PAYMENT_METHODS,
      cancelLineAppend,
      edit,
      errors,
      filterErrors,
      filterErrorColor,
      deleteData,
      deleteModal,
      status,
      showCalendar,
      adjustLineAppend,
      customer,
      checkPayment,
      onPaginationChange,
      changePage,
      pagination,
      disableButton,
      paginationButton,
      couponsData,
    };
  },
});
