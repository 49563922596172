
import { defineComponent, ref } from "vue";
import DatePicker from "../DatePicker.vue";
import moment from "moment";

export default defineComponent({
  name: "CalendarModal",
  components: {
    DatePicker,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  setup(props, { emit }) {
    let choosenData = '';
    let currentDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
    let previousDate = new Date(); //new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);

    if (sessionStorage.vuex) {
      const getSavedDate = JSON.parse(sessionStorage.vuex).reservationCalendarStoreDate;

      if (getSavedDate && moment(getSavedDate).isValid()) {
        currentDate = new Date(
          new Date(getSavedDate).getFullYear(),
          new Date(getSavedDate).getMonth() + 1,
          1
        );
        previousDate = new Date(getSavedDate); 
      }
    }
    // previousDate.setMonth(previousDate.getMonth() - 1);
    const dateNow = ref<Date>(currentDate);
    const datePrevious = ref<Date>(previousDate);
    const handlePrevBtnClick = () => {
      let newCurrentDate = moment();
      newCurrentDate.set({
        date: 1,
        month: dateNow.value.getMonth() - 1,
        year: dateNow.value.getFullYear(),
      });
      dateNow.value = newCurrentDate.toDate();

      let newPreviousDate = moment();
      newPreviousDate.set({
        date: 1,
        month: datePrevious.value.getMonth() - 1,
        year: datePrevious.value.getFullYear(),
      });
      datePrevious.value = newPreviousDate.toDate();
    };

    const handleNextBtnClick = () => {
      let newCurrentDate = moment();
      newCurrentDate.set({
        date: 1,
        month: dateNow.value.getMonth() + 1,
        year: dateNow.value.getFullYear(),
      });
      dateNow.value = newCurrentDate.toDate();

      let newPreviousDate = moment();
      newPreviousDate.set({
        date: 1,
        month: datePrevious.value.getMonth() + 1,
        year: datePrevious.value.getFullYear(),
      });
      datePrevious.value = newPreviousDate.toDate();
    };

    const handleSelectedDate = (selectedDate: string) => {
      emit("selectedDate", selectedDate);
    };

    // const resetField = () => {debugger;
    //   dateNow.value = new Date();
    //   datePrevious.value = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
    // };

    return {
      dateNow,
      datePrevious,
      handlePrevBtnClick,
      handleNextBtnClick,
      handleSelectedDate,
      // resetField,
      choosenData,
    };
  },
  methods: {
    resetField() {
      if (
        this.$store.getters.reservationCalendarStoreDate !== 'undefined' &&
        moment(this.$store.getters.reservationCalendarStoreDate).isValid()
      ) {
        this.dateNow = new Date(
          new Date(this.$store.getters.reservationCalendarStoreDate).getFullYear(),
          new Date(this.$store.getters.reservationCalendarStoreDate).getMonth() + 1,
          1
        );
        this.datePrevious = new Date(this.$store.getters.reservationCalendarStoreDate);
      } else {
        this.dateNow = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1);
        this.datePrevious = new Date();
      }
    }

  }
});
