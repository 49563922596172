<template>
  <div class="personal-information content-width">
    <span class="fw-bold">
      {{ $t("PersonalInformation.Title") }}
    </span>
    <div>
      <textarea
        id="administrative-notes"
        name="admin-note"
        class="feedback-container scrollable-textArea"
        rows="3"
        @input="getPersonalInfo($event)"
        v-model="personalInfo"
        :placeholder="personalInformationGuide || ''"
        disabled="true"
      >
      </textarea>
    </div>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        :value="1"
        @change="getTermData($event)"
        v-model="terms"
        id="flexCheckChecked"
      />
      <label class="form-check-label text-primary" for="flexCheckChecked">
        {{ $t("PersonalInformation.Agree") }}
      </label>
      <span class="required">{{ $t("Ticket.Step1.Required") }}</span>
    </div>
    <div class="instruction-text fw-bold text-center">
      {{ formGuidence?.sentence || "" }}
    </div>
    <!-- slot for footer buttons -->
    <slot name="footer"></slot>
  </div>
</template>

<script>
import { defineComponent, ref,watchEffect } from "vue";
import { store } from "@/core/store";
export default defineComponent({
  name: "PersonalInformation",
  emits: ["getPersonalInfo", "getTerms"],
  props: {
    personalInformationGuide: {
      type: Object,
      requried: false,
    },
    formGuidence: {
      type: Object,
      requried: false,
    },
  },
  setup(props, { emit }) {
    const personalInfo = ref(props?.personalInformationGuide || "");
    const terms = ref(0);
    const {
      getters: {
        registrationSteps,
      },
    } = store;
    watchEffect(() => {
      if (registrationSteps.personalInfo) {
        terms.value = JSON.parse(registrationSteps.personalInfo) ? true : false;
      }
    })

    const getPersonalInfo = ($event) => {
      emit("getPersonalInfo", $event.target.value);
    };

    const getTermData = ($event) => {
      emit("getTerms", terms.value);
    };

    return {
      terms,
      personalInfo,
      getPersonalInfo,
      getTermData,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "../assets/styles/variable.scss";
@import "../assets/styles/_mixins.scss";
@import "../assets/styles/partials/tabs.scss";
@import "../assets/styles/partials/details.scss";

.personal-information {
  textarea {
    width: 100%;
    height: 188px;
    padding: 12px 14px;
    border: 1px solid #d2d6de;
    border-radius: 6px;
    margin-top: 8px;
    color: #444444;
  }
  .form-check {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    input {
      margin-right: 8px;
    }
    label {
      line-height: 23.8px;
    }
  }
  .instruction-text {
    margin-top: 32px;
    max-width: 368px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
  .scrollable-textArea {
    overflow-y: scroll;
  }
  .button-container {
    display: flex;
    justify-content: space-between;

    & > * {
      height: 45px;
      font-size: 16px;
    }

    .btn-secondary {
      width: 128px;
      color: #888;
    }
    .btn-primary {
      width: 240px;
    }
  }
}

.required {
  margin-left: 8px;
  padding: 1px 6px;
  background-color: #f98585;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.7;
  color: #fff;
}

textarea#administrative-notes::placeholder {
  color: #111111;
}
</style>
