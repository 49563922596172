import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-26cf8c0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mobile-screen" }
const _hoisted_2 = { class: "mobile-box" }
const _hoisted_3 = { class: "page-title" }
const _hoisted_4 = { class: "confirmation-number text-primary fw-bold text-center" }
const _hoisted_5 = { class: "reservation-details-heading" }
const _hoisted_6 = { class: "troublesome-symptoms" }
const _hoisted_7 = { class: "text-primary register-appointment" }
const _hoisted_8 = { class: "note-section" }
const _hoisted_9 = {
  class: "btn btn-primary d-block mx-auto search-button",
  href: "#"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.Title")), 1),
      _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ReservationConfirmationNumber")), 1),
      _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ReservationDetails")), 1),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ReservationDate")) + " : " + _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.Date")), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("span", null, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.ReservationStore")) + " : " + _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.Minami")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.TroublesomeSymptoms")) + ": ", 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.SymptomsText")), 1),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("u", null, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.RegisterAppointment")), 1)
      ]),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.Notes")), 1),
      _createElementVNode("div", null, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.NoteText")), 1)
    ]),
    _createElementVNode("a", _hoisted_9, _toDisplayString(_ctx.$t("ReservationAcceptanceCompleted.Close")), 1)
  ]))
}