import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelRadio as _vModelRadio, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35770bbe"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "step-title"
}
const _hoisted_2 = {
  key: 1,
  class: "step-title"
}
const _hoisted_3 = { class: "form-container" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-3" }
const _hoisted_6 = { class: "d-flex mt-2" }
const _hoisted_7 = { class: "form-title" }
const _hoisted_8 = { class: "required" }
const _hoisted_9 = { class: "col" }
const _hoisted_10 = { class: "short-input" }
const _hoisted_11 = { class: "me-3" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_14 = { class: "me-3" }
const _hoisted_15 = ["placeholder"]
const _hoisted_16 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_17 = { class: "row align-items-start" }
const _hoisted_18 = { class: "col-3" }
const _hoisted_19 = { class: "d-flex mt-2" }
const _hoisted_20 = { class: "form-title" }
const _hoisted_21 = { class: "required" }
const _hoisted_22 = { class: "col" }
const _hoisted_23 = { class: "short-input align-items-start" }
const _hoisted_24 = { class: "me-3" }
const _hoisted_25 = ["placeholder"]
const _hoisted_26 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_27 = { class: "me-3" }
const _hoisted_28 = ["placeholder"]
const _hoisted_29 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col-3" }
const _hoisted_32 = { class: "d-flex mt-2" }
const _hoisted_33 = { class: "form-title" }
const _hoisted_34 = { class: "required" }
const _hoisted_35 = { class: "col" }
const _hoisted_36 = { class: "radio-container" }
const _hoisted_37 = { class: "form-check me-3" }
const _hoisted_38 = {
  class: "form-check-label",
  for: "man"
}
const _hoisted_39 = { class: "form-check" }
const _hoisted_40 = {
  class: "form-check-label",
  for: "woman"
}
const _hoisted_41 = { class: "row" }
const _hoisted_42 = { class: "col-3" }
const _hoisted_43 = { class: "d-flex mt-2" }
const _hoisted_44 = { class: "form-title" }
const _hoisted_45 = { class: "required" }
const _hoisted_46 = { class: "col date-selector align-items-start" }
const _hoisted_47 = { class: "d-flex flex-column" }
const _hoisted_48 = {
  value: 0,
  disabled: "",
  hidden: ""
}
const _hoisted_49 = ["value"]
const _hoisted_50 = { class: "" }
const _hoisted_51 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_52 = { class: "fw-bold" }
const _hoisted_53 = { class: "" }
const _hoisted_54 = { class: "d-flex flex-column" }
const _hoisted_55 = ["disabled"]
const _hoisted_56 = {
  value: 0,
  disabled: "",
  hidden: ""
}
const _hoisted_57 = ["value"]
const _hoisted_58 = { class: "" }
const _hoisted_59 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_60 = { class: "fw-bold" }
const _hoisted_61 = { class: "d-flex flex-column" }
const _hoisted_62 = ["disabled"]
const _hoisted_63 = {
  value: 0,
  disabled: "",
  hidden: ""
}
const _hoisted_64 = ["value"]
const _hoisted_65 = { class: "" }
const _hoisted_66 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_67 = { class: "fw-bold" }
const _hoisted_68 = { class: "row" }
const _hoisted_69 = { class: "col-3" }
const _hoisted_70 = { class: "d-flex mt-2" }
const _hoisted_71 = { class: "form-title" }
const _hoisted_72 = { class: "required" }
const _hoisted_73 = { class: "form-title fw-normal font-12" }
const _hoisted_74 = { class: "col" }
const _hoisted_75 = { class: "short-input" }
const _hoisted_76 = { class: "me-3" }
const _hoisted_77 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_78 = ["disabled"]
const _hoisted_79 = { class: "row" }
const _hoisted_80 = { class: "col-3" }
const _hoisted_81 = { class: "d-flex mt-2" }
const _hoisted_82 = { class: "form-title" }
const _hoisted_83 = { class: "required" }
const _hoisted_84 = { class: "col" }
const _hoisted_85 = { class: "short-input" }
const _hoisted_86 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_87 = { class: "row" }
const _hoisted_88 = { class: "col-3" }
const _hoisted_89 = { class: "d-flex mt-2" }
const _hoisted_90 = { class: "form-title" }
const _hoisted_91 = { class: "required" }
const _hoisted_92 = { class: "col" }
const _hoisted_93 = {
  key: 2,
  class: "error-box",
  role: "alert"
}
const _hoisted_94 = { class: "row" }
const _hoisted_95 = { class: "col-3" }
const _hoisted_96 = { class: "d-flex mt-2" }
const _hoisted_97 = { class: "form-title" }
const _hoisted_98 = { class: "col" }
const _hoisted_99 = { class: "row" }
const _hoisted_100 = { class: "col-3" }
const _hoisted_101 = { class: "d-flex" }
const _hoisted_102 = { class: "" }
const _hoisted_103 = { class: "form-title" }
const _hoisted_104 = { class: "form-title fw-normal font-12" }
const _hoisted_105 = { class: "" }
const _hoisted_106 = { class: "required" }
const _hoisted_107 = { class: "col" }
const _hoisted_108 = { class: "short-input align-items-start" }
const _hoisted_109 = { class: "fw-bold mt-2 me-2" }
const _hoisted_110 = { class: "me-3" }
const _hoisted_111 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_112 = { class: "fw-bold mt-2 me-2" }
const _hoisted_113 = { class: "me-3" }
const _hoisted_114 = {
  key: 0,
  class: "error-box",
  role: "alert"
}
const _hoisted_115 = { class: "row" }
const _hoisted_116 = { class: "col-3" }
const _hoisted_117 = { class: "d-flex mt-2" }
const _hoisted_118 = { class: "form-title" }
const _hoisted_119 = { class: "col" }
const _hoisted_120 = { class: "short-input" }
const _hoisted_121 = { class: "button-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Multiselect = _resolveComponent("Multiselect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.consultationType === _ctx.CONSULTATION_CLASSIFICATION.FIRST_VISIT)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_1, _toDisplayString(_ctx.$t('Ticket.Step1.Title')), 1))
      : _createCommentVNode("", true),
    (_ctx.consultationType === _ctx.CONSULTATION_CLASSIFICATION.RE_EXAMINATION)
      ? (_openBlock(), _createElementBlock("h2", _hoisted_2, _toDisplayString('お客様情報に変更があれば修正をお願い致します。')))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t('Ticket.Step1.Name')), 1),
            _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.chineseLastName) = $event)),
                class: _normalizeClass([_ctx.filterErrorColor('surname') ? 'error' : '', 'form-control']),
                placeholder: _ctx.$t('Ticket.Step1.Placeholder3'),
                type: "text",
                onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.validateBlankSpace($event, 'chineseLastName')))
              }, null, 42, _hoisted_12), [
                [_vModelText, _ctx.chineseLastName]
              ]),
              (_ctx.filterErrorColor('surname'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(_ctx.filterErrors('surname')), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_14, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.chineseFirstName) = $event)),
                class: _normalizeClass([_ctx.filterErrorColor('chineseFirstName') ? 'error' : '', 'form-control']),
                placeholder: _ctx.$t('Ticket.Step1.Placeholder4'),
                type: "text",
                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.validateBlankSpace($event, 'chineseFirstName')))
              }, null, 42, _hoisted_15), [
                [_vModelText, _ctx.chineseFirstName]
              ]),
              (_ctx.filterErrorColor('chineseFirstName'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_ctx.filterErrors('chineseFirstName')), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("span", _hoisted_20, _toDisplayString(_ctx.$t('Ticket.Step1.Phonetic')), 1),
            _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createElementVNode("div", _hoisted_24, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.katakanaSurname) = $event)),
                class: _normalizeClass([_ctx.filterErrorColor('katakanaSurname') ? 'error' : '', 'form-control']),
                placeholder: _ctx.$t('Ticket.Step1.Placeholder1'),
                type: "text",
                onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.validateBlankSpace($event, 'katakanaSurname')))
              }, null, 42, _hoisted_25), [
                [_vModelText, _ctx.katakanaSurname]
              ]),
              (_ctx.filterErrorColor('katakanaSurname'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_26, _toDisplayString(_ctx.filterErrors('katakanaSurname')), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_27, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.katakanaName) = $event)),
                class: _normalizeClass([_ctx.filterErrorColor('katakanaName') ? 'error' : '', 'form-control']),
                placeholder: _ctx.$t('Ticket.Step1.Placeholder2'),
                type: "text",
                onChange: _cache[7] || (_cache[7] = ($event: any) => (_ctx.validateBlankSpace($event, 'katakanaName')))
              }, null, 42, _hoisted_28), [
                [_vModelText, _ctx.katakanaName]
              ]),
              (_ctx.filterErrorColor('katakanaName'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_29, _toDisplayString(_ctx.filterErrors('katakanaName')), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_30, [
        _createElementVNode("div", _hoisted_31, [
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("span", _hoisted_33, _toDisplayString(_ctx.$t('Ticket.Step1.Gender')), 1),
            _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_36, [
              _createElementVNode("div", _hoisted_37, [
                _withDirectives(_createElementVNode("input", {
                  id: "man",
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.gender) = $event)),
                  checked: true,
                  class: "form-check-input",
                  name: "gender",
                  type: "radio",
                  value: "1"
                }, null, 512), [
                  [_vModelRadio, _ctx.gender]
                ]),
                _createElementVNode("label", _hoisted_38, _toDisplayString(_ctx.$t('Ticket.Step1.Man')), 1)
              ]),
              _createElementVNode("div", _hoisted_39, [
                _withDirectives(_createElementVNode("input", {
                  id: "woman",
                  "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.gender) = $event)),
                  class: "form-check-input",
                  name: "gender",
                  type: "radio",
                  value: "2"
                }, null, 512), [
                  [_vModelRadio, _ctx.gender]
                ]),
                _createElementVNode("label", _hoisted_40, _toDisplayString(_ctx.$t('Ticket.Step1.Woman')), 1)
              ])
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_41, [
        _createElementVNode("div", _hoisted_42, [
          _createElementVNode("div", _hoisted_43, [
            _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.$t('Ticket.Step1.DoB')), 1),
            _createElementVNode("span", _hoisted_45, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_46, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_47, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.dobYear) = $event)),
                class: _normalizeClass({
                'form-control form-select custom-select': true,
                error: _ctx.filterErrorColor('year')
              }),
                label: "name"
              }, [
                _createElementVNode("option", _hoisted_48, _toDisplayString(_ctx.$t('Ticket.Step1.Select')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.yearOptions, (item, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: item.value
                  }, _toDisplayString(item.name), 9, _hoisted_49))
                }), 128))
              ], 2), [
                [_vModelSelect, _ctx.dobYear]
              ]),
              _createElementVNode("div", _hoisted_50, [
                (_ctx.filterErrorColor('year'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_51, _toDisplayString(_ctx.filterErrors('year')), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("span", _hoisted_52, _toDisplayString(_ctx.$t('Ticket.Step1.Year')), 1)
          ]),
          _createElementVNode("div", _hoisted_53, [
            _createElementVNode("div", _hoisted_54, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.dobMonth) = $event)),
                class: _normalizeClass([
                _ctx.filterErrorColor('month') ? 'error' : '',
                'form-control form-select custom-select'
              ]),
                disabled: !_ctx.dobYear || _ctx.dobYear === '0',
                label: "name"
              }, [
                _createElementVNode("option", _hoisted_56, _toDisplayString(_ctx.$t('Ticket.Step1.Select')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.monthOptions, (item, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: item.value
                  }, _toDisplayString(item.name), 9, _hoisted_57))
                }), 128))
              ], 10, _hoisted_55), [
                [_vModelSelect, _ctx.dobMonth]
              ]),
              _createElementVNode("div", _hoisted_58, [
                (_ctx.filterErrorColor('month'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_59, _toDisplayString(_ctx.filterErrors('month')), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("span", _hoisted_60, _toDisplayString(_ctx.$t('Ticket.Step1.Month')), 1)
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_61, [
              _withDirectives(_createElementVNode("select", {
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.dobDay) = $event)),
                class: _normalizeClass({
                'form-control form-select custom-select': true,
                error: _ctx.filterErrorColor('day')
              }),
                disabled: !_ctx.dobMonth || _ctx.dobMonth === '0',
                label: "name",
                placeholder: "$t('Ticket.Step1.Select')"
              }, [
                _createElementVNode("option", _hoisted_63, _toDisplayString(_ctx.$t('Ticket.Step1.Select')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.daysOption, (item, index) => {
                  return (_openBlock(), _createElementBlock("option", {
                    key: index,
                    value: item.value
                  }, _toDisplayString(item.name), 9, _hoisted_64))
                }), 128))
              ], 10, _hoisted_62), [
                [_vModelSelect, _ctx.dobDay]
              ]),
              _createElementVNode("div", _hoisted_65, [
                (_ctx.filterErrorColor('day'))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_66, _toDisplayString(_ctx.filterErrors('day')), 1))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("span", _hoisted_67, _toDisplayString(_ctx.$t('Ticket.Step1.Day')), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_68, [
        _createElementVNode("div", _hoisted_69, [
          _createElementVNode("div", _hoisted_70, [
            _createElementVNode("p", _hoisted_71, _toDisplayString(_ctx.$t('Ticket.Step1.Zip')), 1),
            _createElementVNode("span", _hoisted_72, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1)
          ]),
          _createElementVNode("p", _hoisted_73, _toDisplayString(_ctx.$t('CustomerDetails.Details.NoHyphen')), 1)
        ]),
        _createElementVNode("div", _hoisted_74, [
          _createElementVNode("div", _hoisted_75, [
            _createElementVNode("div", _hoisted_76, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.postCode) = $event)),
                class: _normalizeClass([_ctx.filterErrorColor('postCode') ? 'error' : '', 'form-control address']),
                type: "text",
                onBlur: _cache[14] || (_cache[14] = ($event: any) => (_ctx.handlePostalcodeRegex($event))),
                onFocus: _cache[15] || (_cache[15] = ($event: any) => (_ctx.removeError('postCode'))),
                onKeyup: _cache[16] || (_cache[16] = ($event: any) => (_ctx.checkPostalCodeValid(_ctx.postCode))),
                onChange: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.clearFieldsOnPostalCodeChange && _ctx.clearFieldsOnPostalCodeChange(...args)))
              }, null, 34), [
                [_vModelText, _ctx.postCode]
              ]),
              (_ctx.filterErrorColor('postCode'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_77, _toDisplayString(_ctx.filterErrors('postCode')), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("button", {
              disabled: !_ctx.postCode,
              class: "btn btn-primary",
              onClick: _cache[18] || (_cache[18] = 
//@ts-ignore
(...args) => (_ctx.searchPostalCode && _ctx.searchPostalCode(...args))),
              id: "adressSearch"
            }, _toDisplayString(_ctx.$t('Ticket.Step1.AddressSearch')), 9, _hoisted_78)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_79, [
        _createElementVNode("div", _hoisted_80, [
          _createElementVNode("div", _hoisted_81, [
            _createElementVNode("span", _hoisted_82, _toDisplayString(_ctx.$t('Ticket.Step1.Prefectures')), 1),
            _createElementVNode("span", _hoisted_83, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_84, [
          _createElementVNode("div", _hoisted_85, [
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.prefectures) = $event)),
                class: _normalizeClass([_ctx.filterErrorColor('prefectures') ? 'error' : '', 'form-control']),
                placeholder: "（記入してください）",
                type: "text",
                onChange: _cache[20] || (_cache[20] = ($event: any) => (_ctx.validateBlankSpace($event, 'prefectures')))
              }, null, 34), [
                [_vModelText, _ctx.prefectures]
              ]),
              (_ctx.filterErrorColor('prefectures'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_86, _toDisplayString(_ctx.filterErrors('prefectures')), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_87, [
        _createElementVNode("div", _hoisted_88, [
          _createElementVNode("div", _hoisted_89, [
            _createElementVNode("span", _hoisted_90, _toDisplayString(_ctx.$t('Ticket.Step1.City')), 1),
            _createElementVNode("span", _hoisted_91, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_92, [
          _createElementVNode("div", null, [
            (_ctx.hasMultipleAddresses)
              ? (_openBlock(), _createBlock(_component_Multiselect, {
                  key: 0,
                  modelValue: _ctx.cityAddress,
                  "onUpdate:modelValue": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.cityAddress) = $event)),
                  options: _ctx.multipleCityAddressArr,
                  class: _normalizeClass([_ctx.filterErrorColor('city') ? 'error' : '', 'form-control']),
                  placeholder: "（選択してください）"
                }, null, 8, ["modelValue", "options", "class"]))
              : _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 1,
                  "onUpdate:modelValue": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.cityAddress) = $event)),
                  class: _normalizeClass([_ctx.filterErrorColor('city') ? 'error' : '', 'form-control']),
                  maxlength: "255",
                  placeholder: "（記入してください）",
                  type: "text",
                  onChange: _cache[23] || (_cache[23] = ($event: any) => (_ctx.validateBlankSpace($event, 'cityAddress')))
                }, null, 34)), [
                  [_vModelText, _ctx.cityAddress]
                ]),
            (_ctx.filterErrorColor('city'))
              ? (_openBlock(), _createElementBlock("div", _hoisted_93, _toDisplayString(_ctx.filterErrors('city')), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_94, [
        _createElementVNode("div", _hoisted_95, [
          _createElementVNode("div", _hoisted_96, [
            _createElementVNode("span", _hoisted_97, _toDisplayString(_ctx.$t('Ticket.Step1.Building')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_98, [
          _withDirectives(_createElementVNode("input", {
            "onUpdate:modelValue": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.nameOfBuildingsEtc) = $event)),
            class: "form-control address",
            maxlength: "255",
            type: "text",
            onChange: _cache[25] || (_cache[25] = ($event: any) => (_ctx.validateBlankSpace($event, 'buildingName')))
          }, null, 544), [
            [_vModelText, _ctx.nameOfBuildingsEtc]
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_99, [
        _createElementVNode("div", _hoisted_100, [
          _createElementVNode("div", _hoisted_101, [
            _createElementVNode("div", _hoisted_102, [
              _createElementVNode("p", _hoisted_103, _toDisplayString(_ctx.$t('Ticket.Step1.PhoneNo')), 1),
              _createElementVNode("p", _hoisted_104, _toDisplayString(_ctx.$t('CustomerDetails.Details.NoHyphen')), 1)
            ]),
            _createElementVNode("div", _hoisted_105, [
              _createElementVNode("span", _hoisted_106, _toDisplayString(_ctx.$t('Ticket.Step1.Required')), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_107, [
          _createElementVNode("div", _hoisted_108, [
            _createElementVNode("span", _hoisted_109, _toDisplayString(_ctx.$t('Ticket.Step1.Mobile')), 1),
            _createElementVNode("div", _hoisted_110, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.cellNumber) = $event)),
                class: _normalizeClass([_ctx.filterErrorColor('cellphone_number') ? 'error' : '', 'form-control']),
                placeholder: "（記入してください）",
                type: "text"
              }, null, 2), [
                [_vModelText, _ctx.cellNumber]
              ]),
              (_ctx.filterErrorColor('cellphone_number'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_111, _toDisplayString(_ctx.filterErrors('cellphone_number')), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("span", _hoisted_112, "or " + _toDisplayString(_ctx.$t('Ticket.Step1.Home')), 1),
            _createElementVNode("div", _hoisted_113, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.homeNumber) = $event)),
                class: _normalizeClass([[_ctx.filterErrorColor('homeNumber') ? 'error' : '', 'form-control'], "form-control"]),
                placeholder: "（記入してください）",
                type: "text",
                onPaste: _cache[28] || (_cache[28] = ($event: any) => ($event.preventDefault()))
              }, null, 34), [
                [_vModelText, _ctx.homeNumber]
              ]),
              (_ctx.filterErrorColor('homeNumber'))
                ? (_openBlock(), _createElementBlock("div", _hoisted_114, _toDisplayString(_ctx.filterErrors('homeNumber')), 1))
                : _createCommentVNode("", true)
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_115, [
        _createElementVNode("div", _hoisted_116, [
          _createElementVNode("div", _hoisted_117, [
            _createElementVNode("span", _hoisted_118, _toDisplayString(_ctx.$t('Ticket.Step1.Occupation')), 1)
          ])
        ]),
        _createElementVNode("div", _hoisted_119, [
          _createElementVNode("div", _hoisted_120, [
            _withDirectives(_createElementVNode("input", {
              "onUpdate:modelValue": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.profession) = $event)),
              class: "form-control",
              maxlength: "255",
              type: "text",
              onChange: _cache[30] || (_cache[30] = ($event: any) => (_ctx.validateBlankSpace($event, 'profession')))
            }, null, 544), [
              [_vModelText, _ctx.profession]
            ])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_121, [
      _createElementVNode("button", {
        class: "btn btn-secondary",
        onClick: _cache[31] || (_cache[31] = ($event: any) => (_ctx.showPrevious()))
      }, _toDisplayString(_ctx.$t('Ticket.Return')), 1),
      _createElementVNode("button", {
        class: "btn btn-primary",
        onClick: _cache[32] || (_cache[32] = 
//@ts-ignore
(...args) => (_ctx.showNext && _ctx.showNext(...args)))
      }, _toDisplayString(_ctx.$t('Ticket.Next')), 1)
    ])
  ], 64))
}