
import { defineComponent, PropType, ref, watchEffect } from "vue";
import Multiselect from "@vueform/multiselect";
import DatePicker from "../../components/DatePicker.vue";
import { TASK_STATUS } from "@/core/store/enums";
import { taskStatus } from "@/core/store/constants";
import tickIcon from "../../assets/icons/check-solid.svg";
import { isCurrentDateBetween } from "@/core/utils";
import moment from "moment";

export default defineComponent({
  name: "TaskListSearch",
  emits: ["search"],
  components: {
    Multiselect,
    DatePicker,
  },
  props: {
    searchFields: {
      type: Object,
    },
    stores: {
      type: Array as PropType<Array<any>>,
    },
    practitioners: {
      type: Array as PropType<Array<any>>,
    },
    taskTypes: {
      type: Array as PropType<Array<any>>,
    },
  },
  setup(props, { emit }) {
    const taskSearchFields = ref();
    let storesList: Array<any> = [];
    let taskTypeList: Array<any> = [];
    const showCalendarStart = ref<boolean>(false);
    const showCalendarEnd = ref<boolean>(false);
    const storeOptions = ref<Array<any>>([]);
    const practitionerOptions = ref<Array<any>>([]);
    const taskTypeListOptions = ref<Array<any>>([]);

    const search = () => {
      emit("search", taskSearchFields.value);
    };

    // Update practitioner options
    const updatePractitionerOptions = (storeId?: any) => {
      const practitioners = props.practitioners;
      const filteredPractitionerWithStoreId = storeId
        ? practitioners?.filter(
            (practitioner) => practitioner.storeId == storeId
          )
        : practitioners?.reduce(
            (acc: Array<any>, currentValue: any) => {
              if (currentValue.employeeInfo) {
                acc[0].employeeInfo = [
                  ...acc[0].employeeInfo,
                  ...currentValue.employeeInfo,
                ];
              }
              return acc;
            },
            [{ employeeInfo: [] }]
          );
      if (
        filteredPractitionerWithStoreId &&
        filteredPractitionerWithStoreId.length > 0
      ) {
        taskSearchFields.value.practitioner = "";
        practitionerOptions.value = [];
        let practitioners =
          filteredPractitionerWithStoreId?.[0].employeeInfo?.reduce(
            (accumulator: Array<any>, currentEmployeeData: any) => {
              if (
                isCurrentDateBetween(
                  moment().format("YYYY-MM-DD"),
                  currentEmployeeData.employeeStartDate,
                  currentEmployeeData.employeeEndDate
                ) &&
                accumulator.filter(
                  (acc) => acc.value === currentEmployeeData.employeeId
                ).length === 0
              ) {
                accumulator.push({
                  value: currentEmployeeData?.employeeId,
                  name: currentEmployeeData?.employeeName,
                  icon: tickIcon,
                });
              }
              return accumulator;
            },
            []
          );
        practitionerOptions.value = practitioners;
        practitionerOptions.value.unshift({
          value: "",
          name: "",
          icon: tickIcon,
        });
      } else {
        taskSearchFields.value.practitioner = "";
        practitionerOptions.value = [];
      }
    };

    watchEffect(() => {
      if (props.searchFields) {
        taskSearchFields.value = props.searchFields;
      }
      if (props.stores) {
        storesList = props.stores;
        storeOptions.value =
          storesList
            ?.sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            ?.map((store: any) => {
              return {
                value: store.id,
                name: store.storeName,
                icon: tickIcon,
                storeBusinessHours: store.storeBusinessHours,
                timeFrame: store.timeFrame,
              };
            }) || [];
        storeOptions.value.unshift({
          value: "",
          name: "",
          icon: tickIcon,
          storeBusinessHours: "",
          timeFrame: "",
        });
      }
      if (props.taskTypes) {
        taskTypeList = props.taskTypes;
        taskTypeListOptions.value =
          taskTypeList
            ?.sort((a: any, b: any) => a.displayOrder - b.displayOrder)
            ?.map((task: any) => {
              return {
                value: task?.id,
                name: task?.name,
                icon: tickIcon,
                requiredTime: task?.timeRequired,
                timeZoneDivision: task?.timeZoneDivision,
                startTime: task?.startTime,
                endTime: task?.endTime,
              };
            }) || [];
      }
      if (props.practitioners) {
        updatePractitionerOptions();
      }
    });

    const onStoreSelect = (storeId: any) => {
      updatePractitionerOptions(storeId);
    };

    return {
      TASK_STATUS,
      taskStatus,
      //
      showCalendarStart,
      showCalendarEnd,
      taskSearchFields,
      storeOptions,
      practitionerOptions,
      taskTypeListOptions,
      //
      search,
      onStoreSelect,
    };
  },
});
