
import { defineComponent } from "vue";
import CustomError  from "../../components/CustomError.vue";

export default defineComponent({
  name: "Login",
  components: {
    CustomError,
  },
  methods: {
    handleSubmit(e: any) {
      this.errors = "";
      if (!this.formValues.name) {
        this.errors = "Name is required.";
      }
      if (!this.formValues.password) {
        this.errors = "Password is required.";
      }
      e.preventDefault();
      if (this.formValues.name && this.formValues.password) {
        this.$store
          .dispatch("login", this.formValues)
          .then((res: any) => {
            this.errors = "";
            if (!this.errors) {
              this.$router.push({ name: "Home" });
            }
          })
          .catch((err) => {
            this.errors = err.response.data?.errors?.[0]?.title;
          });
      }
    },
  },
  data() {
    return {
      errors: "",
      formValues: {
        name: "",
        password: "",
      },
    };
  },
});
