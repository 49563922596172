
import { defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import moment from "moment";
import { fetch } from "@/core/api/api-service";
import {
  CONSULTATION_CLASSIFICATION,
  SYMPTOMS,
  RESERVATION_ROUTE,
} from "@/core/store/enums";
import {
  reservationStatus,
  cancellationTypes,
  reservationRoutes,
} from "@/core/store/constants";

export default defineComponent({
  name: "LastTime",

  setup() {
    const route = useRoute();
    const reservation = ref<any>();

    onMounted(() => {
      getReservation();
    });

    const getReservation = async () => {
      const response = await fetch(`api/v1/reservations/previous`, {
        params: {
          customerNumber: route.query.customerNumber,
          reservationId: route.query.reservationId,
        },
      });
      reservation.value = response.data.data;
    };

    const formatDate = (date: string) => moment.utc(date).format("LL (dd)");

    const formatTime = (time: string) =>
      moment.utc(time, "HH:mm:s").format("HH:mm");

    return {
      reservationStatus,
      cancellationTypes,
      reservationRoutes,
      //
      CONSULTATION_CLASSIFICATION,
      SYMPTOMS,
      RESERVATION_ROUTE,
      //
      reservation,
      formatDate,
      formatTime,
    };
  },
});
