<template>
  <h2 class="step-title">{{ $t('Ticket.Step3.Title') }}</h2>
  <form @submit="showNext()" v-if="reviewQuestions">
    <div class="form-container">
      <template v-for="(questionItem, index) of reviewQuestions.contents" :key="index">
        <div class="row" :class="[questionItem.id]">
          <div class="col-2">
            <div class="d-flex justify-content-end">
              <span class="form-title" id="questionaire1">{{ questionItem.id.toUpperCase() }}</span>
            </div>
          </div>
          <div class="col">
            <div class="mb-3">
              <p class="form-title d-inline-block mb-0">
                {{ questionItem.question }}
              </p>
              <span class="required" v-if="questionItem.required == 'true'">
                {{ $t('Ticket.Step1.Required') }}
              </span>
            </div>
            <template v-if="questionItem.anserType === 'radio'">
              <div class="checkbox-container">
                <template v-for="(optionItem, index) of questionItem.options" :key="index">
                  <div
                    :class="{
                      'form-check': true,
                      'd-inline-block': questionItem.options.length === 2
                    }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      @change="clearRadioField(optionItem, questionItem, $event)"
                      :id="`${questionItem.id}=${optionItem.text}`"
                      :value="optionItem.value"
                      :name="questionItem.id"
                      :required="questionItem.required == 'true'"
                      v-model="questionItem.answer"
                    />
                    <label class="form-check-label" :for="`${questionItem.id}=${optionItem.text}`">
                      {{ optionItem.text }}
                    </label>
                    <template v-if="optionItem.children">
                      <template v-if="optionItem.children.anserType === 'text'">
                        <div class="d-flex align-items-center">
                          <span class="me-3">{{ optionItem.children.text }}</span>
                          <input
                            type="text"
                            class="form-control"
                            @keyup="
                              () => {
                                questionItem.answer = optionItem.value;
                                optionItem.children.answer = convertToFullWidthKana(
                                  optionItem.children.answer
                                );
                                if(optionItem.children.answer.length > 100){
                                  setError()
                                } else {
                                  resetError()
                                }
                              }
                            "
                            @blur="
                              () => {
                                optionItem.children.answer = optionItem.children.answer.trim();
                                optionItem.children.answer = convertToFullWidthKana(
                                  optionItem.children.answer
                                );
                              }
                            "
                            :disabled="checkDisabled(`${questionItem.id}=${optionItem.text}`)"
                            v-model="optionItem.children.answer"
                            :required="
                              (questionItem.answer === optionItem.value &&
                              optionItem.children.required == 'true')
                            "
                          />
                          <div>
                            <span
                              class="required"
                              v-if="
                                questionItem.answer === optionItem.value &&
                                optionItem.children.required == 'true'
                              "
                            >
                              {{ $t('Ticket.Step1.Required') }}
                            </span>
                          </div>
                        <div style="margin-left: 23px">
                        <span
                            class="required"
                            v-if="questionItem.answer === optionItem.value &&
                            (optionItem.children.required == 'true' || optionItem.children.required == 'false') && optionItem.children.answer.length > 100"
                          >
                            {{ $t('Errors.E060') }}
                        </span>
                        </div>
                        </div>
                      </template>
                    </template>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
        <hr v-if="index + 1 !== reviewQuestions.contents.length" />
      </template>
    </div>
    <div class="button-container">
      <button class="btn btn-secondary" @click="showPrevious()">
        {{ $t('Ticket.Return') }}
      </button>
      <button class="btn btn-primary" type="submit" :disabled="showError">
        {{ $t('Ticket.Next') }}
      </button>
    </div>
  </form>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { store } from '@/core/store';
import { buildQuestionJSONWithAnswers, convertToFullWidthKana } from '@/core/utils';
import { fetch } from '@/core/api/api-service';

export default defineComponent({
  name: 'Step3',
  emits: ['showPrevious', 'showNext', 'reviewQuestions'],
  props: {
    previousPage: {
      required: true,
      type: Number
    },
    nextPage: {
      required: true,
      type: Number
    }
  },
  setup(props, { emit }) {
    let showError = ref(false)
    const reservationDetailReviewQuestions = {
      themeName: 'survey',
      formatVer: '1.0',
      contents: [
        {
          id: 'q1',
          question: '当院に来院する決め手となったものを一つお選びください',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: '1',
              text: '知人より',
              children: {
                text: 'お名前',
                required: 'false',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '2',
              text: '家族から'
            },
            {
              value: '3',
              text: '会社の同僚'
            },
            {
              value: '4',
              text: 'チラシ'
            },
            {
              value: '5',
              text: '当院のホームページ',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '6',
              text: 'Googleマップ',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '7',
              text: 'エキテン',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '8',
              text: 'Facebook',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '9',
              text: 'ホットペッパー',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '10',
              text: 'その他オンライン',
              children: {
                text: '検索キーワード',
                required: 'true',
                anserType: 'text',
                answer: ''
              }
            },
            {
              value: '11',
              text: '外観　通りがかり　看板を見て'
            },
            {
              value: '12',
              text: 'その他',
              children: {
                anserType: 'text',
                answer: '',
                required: 'true'
              }
            }
          ]
        },
        {
          id: 'q2',
          question: 'ハガキなどでご連絡を差し上げてもよろしいでしょうか？',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: 'true',
              text: 'はい'
            },
            {
              value: 'false',
              text: 'いいえ'
            }
          ]
        },
        {
          id: 'q3',
          question: 'サービス向上の為にカウンセリング内容を記録しています',
          anserType: 'radio',
          answer: '',
          required: 'true',
          options: [
            {
              value: 'true',
              text: '撮影を許可する'
            },
            {
              value: 'false',
              text: '撮影を許可しない'
            }
          ]
        }
      ]
    };

    // const questions = Object.assign({}, reservationDetailReviewQuestions);
    
    let reviewQuestions = ref(store?.getters?.registrationSteps?.reviewQuestions);
    if (!reviewQuestions.value) {
      fetch('/api/v1/questionnaire-json').then((res) => {
        if (res.status === 200) {
          reviewQuestions.value = buildQuestionJSONWithAnswers(res.data.data);
        }
      });
    }

    const clearRadioField = (value, questionItem, event) => {
      showError.value = false;
      const filterArr = questionItem.options.filter((e) => {
        if (e.value !== value.value) {
          return e;
        }
      });
      filterArr.forEach((element) => {
        if (element.children) {
          element.children.answer = '';
        }
      });
    };

    const showPrevious = () => {
      emit('showPrevious', props.previousPage);
    };
    const showNext = () => {
      emit('reviewQuestions', reviewQuestions.value);
      emit('showNext', props.nextPage);
    };

    const setError = () =>{
      showError.value = true
    }

    const resetError = () =>{
      showError.value = false;
    }


    const checkDisabled = (id) => {
      const element = document.getElementById(id);
      if (element === null) {
        return true;
      } else if (element) {
        return !element?.checked;
      } else {
        return false;
      }
    };


    return {
      convertToFullWidthKana,
      //
      checkDisabled,
      reviewQuestions,
      showPrevious,
      clearRadioField,
      showNext,
      setError,
      resetError,
      showError
    };
  }
});
</script>

<style lang="scss" scoped>
@import '../../assets/styles/variable.scss';
@import '../../assets/styles/partials/steps.scss';

.form-container {
  max-width: 764px;

  .form-title {
    font-size: 16px;
    width: unset;
    text-align: unset;
  }

  p.form-title {
    max-width: 416px;
    margin-bottom: 16px;
  }

  .checkbox-container {
    .form-check {
      display: block;
      margin-bottom: 16px;
      padding-left: 24px;

      .form-check-input {
        margin-left: -24px;
      }

      label {
        margin-top: 1px;
      }

      & > div.d-flex {
        margin-top: 8px;
      }

      span {
        white-space: nowrap;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .radio-container {
    .form-check {
      label {
        min-width: 80px;
      }
    }
  }

  hr {
    margin: 24px auto;
    border-top: 3px dashed #bfe6f4;
    color: white;
    width: 750px;
  }
}

::v-deep(.required) {
  margin-left: 8px;
  padding: 1px 6px;
  background-color: #f98585;
  border-radius: 3px;
  font-size: 12px;
  line-height: 1.7;
  color: #fff;
}

// .button-container {
//   display: flex;
//   justify-content: space-between;
//   margin: auto;
//   max-width: 836px;
//   margin-top: 32px;
//   margin-bottom: 64px;
//   border-top: 1px solid $bgLine;
//   padding-top: 32px;

//   .btn-secondary {
//     height: 45px;
//     width: 88px;
//     float: left;
//   }

//   .btn-primary {
//     height: 45px;
//     width: 240px;
//     float: right;
//   }
// }
</style>