import { InjectionKey } from 'vue';
import { createStore, Store, useStore as baseUseStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import axios from 'axios';
import { State } from '@/vuex';
import { fetch } from '@/core/api/api-service';
import { getCurrentDivision } from '../utils';
import { useRoute, useRouter } from 'vue-router';

// define injection key
export const key: InjectionKey<Store<State>> = Symbol();
const router = useRouter();

export const store = createStore<State>({
  state: {
    authenticated: !!localStorage.getItem('token'),
    user: null,
    selectedStoreId: '',
    token: localStorage.getItem('token') ? localStorage.getItem('token') : '',
    reservation: null,
    reservationCalendarSecondBackButton: false,
    reservationCalendarSecondBackId: '',
    reservationCalendarStoreDate: 'undefined',
    reservationCalendarStoreId: 'undefined',
    reservationRegistrationPageType: null,
    trafficAccident: null,
    registrationSteps: {
      customerRegistration: null,
      symptomQuestions: null,
      reviewQuestions: null,
      personalInfo: ''
    },
    fromCustomer: false,
    routeFrom: null,
    isRevisitAccounted: 2
  },
  mutations: {
    setIsRevisitAccounted(state: State, status: number) {
      state.isRevisitAccounted = status;
    },
    setAuthentication(state: State, status: boolean) {
      state.authenticated = status;
    },
    setToken(state: State, token: string) {
      state.token = token;
      localStorage.setItem('token', token);
    },
    setUser(state: State, user: any) {
      state.user = user;
    },
    setFromCustomer(state: State, fromCustomer: boolean) {
      state.fromCustomer = fromCustomer;
    },
    setSelectedStoreID(state: State, selectedStoreId: any) {
      state.selectedStoreId = selectedStoreId;
    },
    setReservation(state: State, reservation: any) {
      state.reservation = reservation;
    },
    setReservationRegistrationPageType(state: State, reservationRegistrationPageType: any) {
      state.reservationRegistrationPageType = reservationRegistrationPageType;
    },
    setReservationCalendarStoreId(state: State, reservationCalendarStoreId: any) {
      state.reservationCalendarStoreId = reservationCalendarStoreId;
    },
    setSecondBackButton(state: State, reservationCalendarSecondBackButton: boolean) {
      state.reservationCalendarSecondBackButton = reservationCalendarSecondBackButton;
    },
    setSecondBackId(state: State, reservationCalendarSecondBackId: string) {
      state.reservationCalendarSecondBackId = reservationCalendarSecondBackId;
    },
    setReservationCalendarStoreDate(state: State, reservationCalendarStoreDate: any) {
      state.reservationCalendarStoreDate = reservationCalendarStoreDate;
    },
    setStepCustomerRegistration(state: State, customerRegistration: any) {
      state.registrationSteps.customerRegistration = customerRegistration;
    },
    setTrafficAccidentRegistration(state: State, trafficAccident: any) {
      state.trafficAccident = trafficAccident;
    },
    setStepSymptomQuestions(state: State, symptomQuestions: any) {
      state.registrationSteps.symptomQuestions = symptomQuestions;
    },
    setStepReviewQuestions(state: State, reviewQuestions: any) {
      state.registrationSteps.reviewQuestions = reviewQuestions;
    },
    setStepPersonalInfo(state: State, personalInfo: string) {
      state.registrationSteps.personalInfo = personalInfo;
    },
    resetRegistrationSteps(state: State, payload: any) {
      (state.reservationRegistrationPageType = null), (state.reservation = null);
      // (state.reservationCalendarStoreDate = null),
      state.registrationSteps = {
        customerRegistration: null,
        symptomQuestions: null,
        reviewQuestions: null,
        personalInfo: ''
      };
    },
    resetQuestionnaireSteps(state: State, payload: any) {
      state.registrationSteps = {
        customerRegistration: payload?.deletedSymptomQuestions?.customerRegistration,
        symptomQuestions: payload?.deletedSymptomQuestions?.symptomQuestions,
        reviewQuestions: payload?.deletedSymptomQuestions?.reviewQuestions,
        personalInfo: ''
      };
    },
    resetTrafficAccidentRegistration(state: State, payload: any) {
      state.trafficAccident = null;
    },
    setRouteFrom(state: State, payload: string) {
      state.routeFrom = payload;
    }
  },
  actions: {
    async login({ commit }, payload) {
      const response = await axios.post(
        `${process.env.VUE_APP_BASE_API_URL}/api/v1/login/employee`,
        {
          login_id: payload.name,
          login_password: payload.password,
          clientId: process.env.VUE_APP_CLIENT_ID,
          clientSecret: process.env.VUE_APP_CLIENT_SECRET,
          grantType: 'password'
        }
      );
      const { accessToken } = response.data.data;
      commit('setAuthentication', true);
      commit('setToken', accessToken);
      // store.dispatch("getEmployeeProfile");
    },
    async getEmployeeProfile({ commit }) {
      await fetch(`api/v1/getEmployee`).then((res: any) => {
        if (res) {
          const user = res?.data?.data;
          user.currentEmployeeDivision = getCurrentDivision(user.employeeDivsions);
          commit('setUser', user);
        }
      });
    },
    async logout({ commit }) {
      if (this.state.token) {
        const response = await axios(`${process.env.VUE_APP_BASE_API_URL}/api/v1/logout`, {
          headers: { Authorization: 'Bearer ' + this.state.token }
        })
          .then((response) => {
            commit('setReservationCalendarStoreId', 'undefined');
            commit('setReservationCalendarStoreDate', 'undefined');
            commit('setAuthentication', false);
            commit('setToken', '');
            commit('setUser', null);
            // this.$router.push({ path: Login });
            const b = true;
            return b;
          })
          .catch((err) => {
            commit('setReservationCalendarStoreId', 'undefined');
            commit('setReservationCalendarStoreDate', 'undefined');
            commit('setAuthentication', false);
            commit('setToken', '');
            commit('setUser', null);
            // router.push({ name: "Login" });
            const b = true;
            return b;
          });
      } else {
        commit('setReservationCalendarStoreId', 'undefined');
        commit('setReservationCalendarStoreDate', 'undefined');
        commit('setAuthentication', false);
        commit('setToken', '');
        commit('setUser', null);
        router.push({ name: 'Login' });
      }
      return true;
    },

    async getEmployee({ commit }) {
      const response = await axios(`${process.env.VUE_APP_BASE_API_URL}/api/v1/getStores"`, {
        headers: { Authorization: 'Bearer ' + this.state.token }
      });
      if (response.status) {
        commit('setStore', response.data.data);
      }
    },

    setReservation({ commit }, payload) {
      commit('setReservation', payload);
    },
    setSelectedStoreID({ commit }, payload) {
      commit('setSelectedStoreID', payload);
    },
    setFromCustomer({ commit }, payload) {
      commit('setFromCustomer', payload);
    },

    setReservationRegistrationPageType({ commit }, payload) {
      commit('setReservationRegistrationPageType', payload);
    },
    setReservationCalendarStoreId({ commit }, payload) {
      commit('setReservationCalendarStoreId', payload);
    },
    setSecondBackButton({ commit }, payload) {
      commit('setSecondBackButton', payload);
    },
    setSecondBackId({ commit }, payload) {
      commit('setSecondBackId', payload);
    },
    setReservationCalendarStoreDate({ commit }, payload) {
      commit('setReservationCalendarStoreDate', payload);
    },
    setStepCustomerRegistration({ commit }, payload) {
      commit('setStepCustomerRegistration', payload);
    },
    setTrafficAccidentRegistration({ commit }, payload) {
      commit('setTrafficAccidentRegistration', payload);
    },

    setStepSymptomQuestions({ commit }, payload) {
      commit('setStepSymptomQuestions', payload);
    },

    setStepReviewQuestions({ commit }, payload) {
      commit('setStepReviewQuestions', payload);
    },

    setStepPersonalInfo({ commit }, payload) {
      commit('setStepPersonalInfo', payload);
    },
    resetRegistrationSteps({ commit }, payload) {
      commit('resetRegistrationSteps', payload);
    },
    resetQuestionnaireSteps({ commit }, payload) {
      commit('resetQuestionnaireSteps', payload);
    },
    resetTrafficAccidentRegistration({ commit }, payload) {
      commit('resetTrafficAccidentRegistration', payload);
    },
    setIsRevisitAccounted({ commit }, payload) {
      commit('setIsRevisitAccounted', payload);
    },
    setRouteFrom({ commit }, payload) {
      commit('setRouteFrom', payload);
    }
  },
  modules: {},
  getters: {
    token: (state) => state.token,
    user: (state) => state.user,
    reservationCalendarSecondBackId: (state) => state.reservationCalendarSecondBackId,
    reservation: (state) => state.reservation,
    registrationSteps: (state) => state.registrationSteps,
    fromCustomer: (state) => state.fromCustomer,
    reservationRegistrationPageType: (state) => state.reservationRegistrationPageType,
    reservationCalendarStoreId: (state) => state.reservationCalendarStoreId,
    reservationCalendarStoreDate: (state) => state.reservationCalendarStoreDate,
    getTrafficAccidentRegistration: (state) => state.trafficAccident,
    getStoreId: (state) => state.selectedStoreId,
    getReservationCalendarSecondBackButton: (state) => state.reservationCalendarSecondBackButton,
    getIsRevisitAccounted: (state) => state.isRevisitAccounted,
    getRouteFrom: (state) => state.routeFrom
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
});

export function useStore() {
  return baseUseStore(key);
}
